import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import {
    S,
    g_consignators,
    g_template_dataTable,
    pair,
    LS,
    TablePage,
    g_checkAlc,
    g_dataTable,
    g_checkLoginACL,
    g_entityFilter,
    g_genericRequired,
    g_getData,
    g_getHeaderTemplate,
    g_hideSearch,
} from '../GenericFunctions';
import { states } from './Contract';
import { url as fURL } from '../freight/lists';
import { states as fstates } from '../freight/freight';

let url = (id, workflowType) => `#/contract${workflowType === 5 || workflowType === 6 ? 'P' : ''}?type=1&${id ? 'id=' + id : ''}`;
let size = [1400, 700];
export const contractManagement = {
    url: url,
    size: size,
};

export class ContractManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let t = query.get('t');
        let extraFilter = '-100';
        if (t === '2') {
            if (g_checkAlc(5, 'contract#steps-numo')) extraFilter = '7,4';
            else if (g_checkAlc(5, 'contract#steps-financier')) extraFilter = '8,9';
            else if (g_checkAlc(5, 'contract#steps-provider')) extraFilter = '10,11';
            else if (g_checkAlc(5, 'contract#steps-consignator')) extraFilter = '13,14';
        } else extraFilter = '';
        g_hideSearch();
        this.setState({ extraFilter: extraFilter }, this.getData);
    }

    componentDidUpdate() {
        let query = new URLSearchParams(this.props.location.search);
        let t = query.get('t');
        let extraFilter = '-100';
        if (t === '2') {
            if (g_checkAlc(5, 'contract#steps-numo')) extraFilter = '7,4';
            else if (g_checkAlc(5, 'contract#steps-financier')) extraFilter = '8,9';
            else if (g_checkAlc(5, 'contract#steps-provider')) extraFilter = '10,11';
            else if (g_checkAlc(5, 'contract#steps-consignator')) extraFilter = '13,14';
        } else extraFilter = '';
        if (extraFilter === this.state.extraFilter) return;
        g_hideSearch();
        this.setState({ extraFilter: extraFilter }, this.getData);
    }

    getData = () =>
        g_checkAlc(5, 'contract-management#totalaccess')
            ? g_getData(
                  this,
                  data => {
                      if (this.state.extraFilter !== '') data.filters = [...data.filters, { key: 'contractStatus', value: this.state.extraFilter }];
                      return S.contract.list(data);
                  },
                  'contracts'
              )
            : g_getData(
                  this,
                  data => {
                      let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                      if (!f) return { code: 0, contracts: [], pageTotal: 0, recordsTotal: 0 };
                      data.filters = [...f[0]];
                      if (this.state.extraFilter !== '') data.filters = [...data.filters, { key: 'contractStatus', value: this.state.extraFilter }];
                      data.filters = [
                          ...data.filters,
                          {
                              key: 'filterBehaviour',
                              value: g_checkAlc(5, 'contract-management#financier') ? 1 : 2,
                          },
                      ];
                      return S.contract.list(data);
                  },
                  'contracts'
              );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('contracts', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_dataTable(
                    this,
                    g_getHeaderTemplate(this, url(), 'Adicionar Contrato', this.getData, 1400, 700, e.c === 1),
                    [
                        { type: 'd', data: 'consigneeFullname', sortable: true, filter: true },
                        { type: 'd', data: 'consigneeVatNumber', sortable: true, filter: true },
                        { type: 'd', data: 'productName', sortable: true, filter: true },
                        { type: 'd', data: 'contractTotalValue', sortable: true, filter: true },
                        { type: 'd', data: 'installmentTotal', sortable: true, filter: true },
                        { type: 'spacer', space: '3' },
                        { type: 'date', data: 'dateContractStart', sortable: true, filter: true },
                        { type: 'date', data: 'dateContractEnd', sortable: true, filter: true },
                        { type: 'spacer' },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'contractStatus', states, filter: this.state.extraFilter === '' },
                        { type: 'url', url: raw => url(raw.idUUID, raw.workflowType), width: 1400, height: 700, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData,
                    { enabled: true }
                )}
            </div>
        ));
    }
}

export class ServicesManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    tooltipFs = raw => this.state['tt' + raw.idUUID];
    tooltipOver = raw => {
        let toSet = {};
        toSet['tt' + raw.idUUID] = true;
        this.setState(toSet);
    };
    tooltipLeave = raw => {
        let toSet = {};
        toSet['tt' + raw.idUUID] = false;
        this.setState(toSet);
    };

    componentDidMount() {
        //let query = new URLSearchParams(this.props.location.search);
        //let t = query.get('t');
        this.getData();
        g_hideSearch();
    }

    componentDidUpdate() {
        //let query = new URLSearchParams(this.props.location.search);
        //let t = query.get('t');
        //this.getData();
        //g_hideSearch();
    }

    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess', g_consignators());
                if (!f) return { code: 0, contracts: [], pageTotal: 0, recordsTotal: 0 };
                data.filters = [...f[0], pair('joinType', 2), pair('workflowtype', 8)];
                return S.contract.list(data);
            },
            'contracts'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('servicesManagement', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, url(), 'Adicionar Contrato', this.getData, 1400, 700, e.c === 1),
                    [
                        { type: 'd', data: 'obs', filter: true, sortable: true },
                        { type: 'd', data: 'providerName', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'contractStatus',
                            states: fstates,
                            sortable: true,
                            filter: true,
                        },
                        { type: 'url', url: fURL, width: 1400, height: 900, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData,
                    {
                        0: 'consigneeFullname',
                        1: 'consigneeVatNumber',
                        2: 'productName',
                        3: 'contractTotalValue',
                        4: 'installmentTotal',
                        5: 'dateContractStart',
                        6: 'dateContractEnd',
                        7: 'dateCreation',
                    },
                    [5, 6, 7]
                )}
            </div>
        ));
    }
}

export class RequestsManagement extends Component {
    constructor() {
        super();
        this.state = { ...g_genericRequired(), t: null };
    }
    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        g_hideSearch();
        this.setState({ t: query.get('t') }, this.getData);
    }
    componentDidUpdate() {
        let query = new URLSearchParams(this.props.location.search),
            t = query.get('t');
        if (t !== this.state.t) this.setState({ t: query.get('t') }, this.getData);
        g_hideSearch();
    }

    getData = () =>
        this.state.t === '1'
            ? g_getData(
                  this,
                  data => {
                      let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 0, '', g_consignators());
                      if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                      data.filters = f[0];
                      return S.contract.list(data);
                  },
                  'contracts',
                  { filters: [...this.state.filter, pair('joinType', '2'), pair('workflowType', '9'), pair('showParentInfoIfNull', '1')] }
              )
            : g_getData(this, S.contract.list, 'contracts', { filters: [...this.state.filter, pair('joinType', '2'), pair('contractStatus', '422')] });

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('requetsManagement', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    null,
                    [
                        { type: 'd', data: 'obs', filter: true, sortable: true },
                        { type: 'd', data: 'providerName', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'contractStatus',
                            states: fstates,
                            sortable: true,
                            filter: true,
                        },
                        { type: 'url', url: r => fURL({ ...r, idUUID: this.state.t === '1' ? r.parentContractIdUUID : r.idUUID }), width: 1400, height: 900, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}

//Group bys

export const contractGroupByProductType = () => (
    <TablePage
        rg={[pair('name', 'desc')]}
        getData={c =>
            g_checkAlc(5, 'contract-management#totalaccess')
                ? g_getData(c, S.contract.groupByContractProductType, 'contractGroupByProductType')
                : g_getData(
                      this,
                      data => {
                          let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                          if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                          data.filters = [
                              ...f[0],
                              {
                                  key: 'filterBehaviour',
                                  value: g_checkAlc(5, 'contract-management#financier') ? 1 : 2,
                              },
                          ];
                          return S.contract.groupByContractProductType(data);
                      },
                      'contractGroupByProductType'
                  )
        }
        fakename="contract-management"
        table={() => [
            { t: 'd', data: 'name', sortable: true, filter: true },
            { t: 'd', data: 'count', sortable: true },
            { t: 'd', data: 'value', title: LS('totalValue'), sortable: true },

            { type: 'd', data: 'consigneeFullname', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'd', data: 'consigneeVatNumber', onlyFilter: true, filter: true },
            { type: 'd', data: 'installmentTotal', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'date', data: 'dateContractStart', onlyFilter: true, filter: true },
            { type: 'date', data: 'dateContractEnd', onlyFilter: true, filter: true },
            { type: 'spacer' },
            { type: 'date', data: 'dateCreation', onlyFilter: true, filter: true },
            { type: 'state', data: 'contractStatus', states, onlyFilter: true, filter: true },
        ]}
        ad={{ enabled: true }}
    />
);

export const contractGroupByTemplate = () => (
    <TablePage
        rg={[pair('name', 'desc')]}
        getData={c =>
            g_checkAlc(5, 'contract-management#totalaccess')
                ? g_getData(c, S.contract.groupByContractTemplate, 'contractGroupByContractTemplate')
                : g_getData(
                      this,
                      data => {
                          let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                          if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                          data.filters = [
                              ...f[0],
                              {
                                  key: 'filterBehaviour',
                                  value: g_checkAlc(5, 'contract-management#financier') ? 1 : 2,
                              },
                          ];
                          return S.contract.groupByContractTemplate(data);
                      },
                      'contractGroupByContractTemplate'
                  )
        }
        fakename="contract-management"
        table={() => [
            { t: 'd', data: 'name', sortable: true, filter: true },
            { t: 'd', data: 'count', sortable: true },
            { t: 'd', data: 'value', title: LS('totalValue'), sortable: true },

            { type: 'd', data: 'consigneeFullname', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'd', data: 'consigneeVatNumber', onlyFilter: true, filter: true },
            { type: 'd', data: 'installmentTotal', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'date', data: 'dateContractStart', onlyFilter: true, filter: true },
            { type: 'date', data: 'dateContractEnd', onlyFilter: true, filter: true },
            { type: 'spacer' },
            { type: 'date', data: 'dateCreation', onlyFilter: true, filter: true },
            { type: 'state', data: 'contractStatus', states, onlyFilter: true, filter: true },
        ]}
        ad={{ enabled: true }}
    />
);
