import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import BatchService from '../../service/BatchService';
import UtilsService from '../../service/UtilsService';
import {
    LS,
    g_checkLoginACL,
    g_displayMessageError,
    g_genericRequired,
    g_getData,
    g_getEditColumn,
    g_getHeaderTemplate,
    g_hideSearch,
    g_template_button,
    g_template_dataTable,
} from '../GenericFunctions';

export default class ZipCodeManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => g_getData(this, UtilsService.zipCodeList, 'zipcodes');
    onCreate = e => {
        e.preventDefault();
        document.getElementById('file').click();
    };
    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file);
        };
        readerArray.readAsArrayBuffer(file);
    };
    uploadHandler = async file => {
        const formData = new FormData();
        formData.append('file', file);
        var response = await BatchService.documentUpload(formData, '5b31ea05-8e0c-fa35-7a13-d538de8b6b79', '-', '-', '-', '0');
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleUpload', 'messages') });
        document.getElementById('file').value = '';
    };
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <div className="p-grid p-fluid">
                    <div className="p-col-11">
                        <h1>{LS('zipCode')}</h1>
                    </div>
                    <div className="p-col-1">
                        <form onSubmit={this.onCreate}>
                            <div className="contact-form">
                                <input id="file" type="file" accept=".csv,.dat,.xls,.xlsx,.txt" onChange={this.uploadDocument} style={{ display: 'none' }} />
                                <span style={{ textAlign: 'center' }}>{g_template_button('', 'pi pi-upload', false, null, e.c !== 1, 'p-col')}</span>
                            </div>
                        </form>
                    </div>
                </div>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/zipcode?type=1', 'Editar', this.getData, 1000, 600, e.c === 1),
                    [
                        { type: 'd', data: 'zipCode', sortable: true, filter: true },
                        { type: 'd', data: 'street', sortable: true, filter: true },
                        { type: 'd', data: 'city', sortable: true, filter: true },
                        { type: 'd', data: 'state', sortable: true, filter: true },
                        { type: 'd', data: 'countryName', sortable: true, filter: true },
                        g_getEditColumn(raw => `#/zipcode?type=1&id=${raw.zipCode}`, 1000, 600, e.u === 1 || e.d === 1, '5', this.getData),
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
