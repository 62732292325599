import React from 'react';
import NewsService from '../../service/NewsService';
import { ls, ListPage, g_getData, g_getHeaderTemplate, g_entityFilter } from '../GenericFunctions';
import { newsStates } from './segment';

const size = [1400, 700];
const url = (idUUID, st) => `#/segment?type=1${idUUID ? `&id=${idUUID}` : ''}${st ? `&st=${st}` : ''}`;

const table = (context, e, input) => [
    { type: 'd', data: 'description', sortable: true, filter: true },
    { type: 'd', data: 'tag', sortable: true, filter: true },
    { type: 'd', data: 'languageId', sortable: true, filter: true },
    { type: 'd', data: 'totalClickNumber', sortable: true, filter: true },
    { type: 'date', data: 'dateStartNews', sortable: true, filter: true },
    { type: 'date', data: 'dateEndNews', sortable: true, filter: true },
    { type: 'date', data: 'dateCreation', sortable: true, filter: true },
    { type: 'state', data: 'newsStatus', states: () => newsStates },
    { type: 'url', url: raw => url(raw.idUUID, input.get('st')), width: 1400, height: 700, callback: context.getData, c: e.u === 1 || e.d === 1 },
];

const header = (context, e, input) => g_getHeaderTemplate(context, url(null, input.get('st')), 'new noticias', context.getData, size[0], size[1], e.c);
const getData = (context, filter = []) =>
    g_getData(
        context,
        data => {
            let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'promotion#accessall');
            if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
            data.filters = f[0];
            return NewsService.list(data);
        },
        'news',
        { filters: [...filter, ...context.state.filter] }
    );

export const segment = {
    render: input => (
        <ListPage
            title={() => ls('segment' + ls(input.get('st'), 'segmentType') + (input.get('a') ? '#Active' : ''), 'titles')}
            header={(c, e) => header(c, e, input)}
            table={(c, i) => table(c, i, input)}
            getData={context => getData(context, [...(input.get('a') === '1' ? [{ key: 'newsStatus', value: '0,1' }] : []), ...(input.get('st') ? [{ key: 'newsType', value: input.get('st') }] : [])])}
            update={c => {
                if (c.state.olda !== input.get('a')) c.setState({ olda: input.get('a'), pageNumber: 1 }, c.getData);
                if (c.state.oldst !== input.get('st')) c.setState({ oldst: input.get('st'), pageNumber: 1 }, c.getData);
            }}
        />
    ),
    size: size,
    url: url,
};
