import React from 'react';
import { Messages } from 'primereact/messages';
import ContractService from '../../service/ContractService';
import { ls, g_checkLoginACL, g_checkAlc, g_entityFilter, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ContractTemplateManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired([{ key: 'dateCreation', value: 'desc' }]);
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        g_checkAlc(5, 'contract-management#totalaccess')
            ? g_getData(this, ContractService.getContractTemplate, 'contractTemplates')
            : g_getData(
                  this,
                  data => {
                      let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess');
                      if (!f) return { code: 0, contracts: [], pageTotal: 0, recordsTotal: 0 };
                      data.filters = f[0];
                      return ContractService.getContractTemplate(data);
                  },
                  'contractTemplates'
              );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{ls('contractManagement', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/contractTemplate?type=1', 'addContractTemplate', this.getData, 1200, 700, e.c === 1),
                    [
                        { type: 'd', data: 'title', sortable: true, filter: true },
                        { type: 'd', data: 'productName', sortable: true, filter: true },
                        { type: 'd', data: 'consignatorName', sortable: true, filter: true },
                        { type: 'd', data: 'contractTemplateTypeDescription', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'contractSignatureMode',
                            states: { 1: { color: 'grey', class: 'pi-minus' }, 2: { color: 'grey', class: 'pi-mobile' }, 3: { color: 'grey', class: 'pi-pencil' } },
                        },
                        { type: 'd', data: 'templateVersion', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'contractTemplateStatus',
                            states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                        },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/contractTemplate?type=1&id=${raw.idUUID}`, width: 1200, height: 700, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
