
import axios from './AxiosService'

class SubscriptionService {

    list = (data) => axios.request('POST','/subscription/list', data)
    listCycles = (data) => axios.request('POST','/subscription/cycle/list', data)
    add = (data) => axios.request('POST','/subscription/add', data) 

}

export default new SubscriptionService();
