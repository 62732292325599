import React from 'react';
import ConsignatorService from '../../service/ConsignatorService';
import { g_getHeaderTemplate, LS, ListPage } from '../GenericFunctions';
import { states } from './Consignator';

const url = id => `#/consignator?type=1${id ? `&id=${id}` : ''}`;
const size = [900, 600];
const table = (context, e) => [
    { type: 'd', data: 'name', filter: true, sortable: true },
    { type: 'd', data: 'fantasyName', filter: true, sortable: true },
    { type: 'd', data: 'email', filter: true, sortable: true },
    { type: 'd', align: 'right', title: LS('vatNumberC'), data: 'vatNumber', filter: true, sortable: true },
    { type: 'd', data: 'phoneMobile', filter: true, sortable: true },
    { type: 'd', align: 'right', data: 'erpCode', filter: true, sortable: true },
    { type: 'date', data: 'dateCreation', filter: true, sortable: true },
    { type: 'state', data: 'consignatorStatus', states: states },
    { type: 'url', c: e.u === 1 || e.d === 1, url: raw => url(raw.idUUID), width: size[0], height: size[1], callback: context.getData },
];
const header = (c, e) => g_getHeaderTemplate(c, url(), 'Criar Novo Funcionario', c.getData, size[0], size[1], e.c === 1);

export const consignatorManagement = {
    render: <ListPage title={LS('consignator', 'titles')} header={header} table={table} getData={ConsignatorService.processed.list} />,
    url: url,
    size: [900, 600],
};
