import React from 'react';
import PromotionsService from '../../service/PromotionsService';
import { Messages } from 'primereact/messages';
import { ls, g_entityFilter, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import { promotionsStates } from './promotion';

export default class PromotionsActive extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'promotion#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                data.filters = [...f[0], { key: 'notpromotionsStatus', value: '6,100,101' }];
                return PromotionsService.list(data);
            },
            'promotions'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{ls('promotionsActive', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(this, g_getHeaderTemplate(this, '/#/promotion?type=1', 'newPromotion', this.getData, 1400, 700, e.c), table(this, e), this.getData)}
            </div>
        ));
    }
}

export const table = (context, e) => [
    { type: 'd', data: 'referenceDescription', sortable: true, filter: true },
    { type: 'd', data: 'description', sortable: true, filter: true },
    { type: 'd', data: 'totalClickNumber', sortable: true, filter: true },
    { type: 'date', data: 'dateStart', sortable: true, filter: true },
    { type: 'date', data: 'dateEnd', sortable: true, filter: true },
    { type: 'date', data: 'dateCreation', sortable: true, filter: true },
    { type: 'state', data: 'promotionStatus', states: () => promotionsStates },
    { type: 'url', url: raw => `#/promotion?type=1&id=${raw.idUUID}`, width: 1400, height: 700, callback: context.getData, c: e.u === 1 || e.d === 1 },
];
