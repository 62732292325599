import React, { Component } from 'react';
import { Messages } from 'primereact/messages';
import ProfileService from '../../service/ProfileService';
import { LS, g_displayMessageError, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class AddNew extends Component {
    constructor() {
        super();
        this.state = {
            filter: [],
            pageNumber: 1,
            pageSize: 6,
            pageTotal: 0,
            recordsTotal: 0,
            multiSortMeta: [],
            data: [],
            first: 0,
            idUUID: null,
            list: [],
        };
    }

    componentDidMount() {
        g_hideSearch();
        if (this.props.idUUID && this.props.callBackData) {
            this.setState(
                {
                    idUUID: this.props.idUUID,
                    list: this.props.list,
                },
                this.getData
            );
        }
    }

    getData = async () => {
        if (this.state.idUUID === null) return;
        let data = {
            filters: [{ key: 'notInProfileIdUUID', value: this.props.idUUID }, { key: 'accessItemStatus', value: 1 }, ...this.state.filter],
            orderBy: this.state.orderBy,
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
        };
        let response = await ProfileService.accessItensList(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({
            data: response.accessItems,
            recordsTotal: response.recordsTotal,
            pageTotal: response.pageTotal,
        });
    };

    add = async e => {
        if (this.state.idUUID === null) return;
        let data = {
            profileACL: {
                accessItemIdUUID: e.idUUID,
                aclCreate: 0,
                aclDelete: 0,
                aclRead: 1,
                aclUpdate: 0,
                profileIdUUID: this.state.idUUID,
            },
        };
        let response = await ProfileService.aclSet(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
        this.props.callBackData();
        this.getData();
    };

    render() {
        return (
            <div className="card">
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, -1, -1, false),
                    [
                        { type: 'd', data: 'description', sortable: true, filter: true },
                        { type: 'd', data: 'accessItemTag', sortable: true, filter: true },
                        { type: 'd', data: 'accessItemTypeDescription', sortable: true, filter: true },
                        { type: 'btt', icon: 'pi pi-plus', click: this.add },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
