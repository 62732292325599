import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { ls, S } from '../GenericFunctions';
import '../login/Login.css';

export default class RecoverPassword extends Component {
    constructor() {
        super();
        this.state = {
            user: { userName: '' },
            visible: false,
            logged: false,
            merchant: '',
            merchantSuggestions: null,
            redirect: false,
        };
    }

    componentDidMount() {
        document.body.style.cssText += 'background-image: none !important;';
        document.body.style.cssText +=
            'background: #5098c8; background: -webkit-linear-gradient(-135deg, #5098c8, #19255e) !important; background: -o-linear-gradient(-135deg, #5098c8, #19255e)!important; background: -moz-linear-gradient(-135deg, #5098c8, #19255e)!important; background: linear-gradient(-135deg, #5098c8, #19255e)!important;';
    }
    componentDidUpdate() {
        document.body.style.cssText += 'background-image: none !important;';
        document.body.style.cssText +=
            'background: #5098c8; background: -webkit-linear-gradient(-135deg, #5098c8, #19255e) !important; background: -o-linear-gradient(-135deg, #5098c8, #19255e)!important; background: -moz-linear-gradient(-135deg, #5098c8, #19255e)!important; background: linear-gradient(-135deg, #5098c8, #19255e)!important;';
    }

    handleChange = event => {
        const user = { ...this.state.user };
        const { name, value } = event.target;
        user[name] = value;
        this.setState({ user });
    };

    handleSubmit = async event => {
        event.preventDefault();
        const response = await S.user.resetPassword({ userType: 1, username: this.state.user.userName });
        if (response && response.code === 200) this.messages.show({ severity: 'error', summary: ls('userNotFound', 'messages') });
        if (response && response.code === 0) this.messages.show({ severity: 'success', summary: ls('passwordResetSuccess', 'messages') });
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="login-div">
                    <div className="login-img-div">
                        <img src={'assets/images/logo.png'} width="100%" alt="" />
                    </div>
                    <Messages ref={el => (this.messages = el)} />
                    <div className="login-field-div">
                        <InputText className="login-field" name="userName" placeholder={ls('username', 'placeholder')} onChange={this.handleChange} required />
                    </div>
                    <div style={{ maxHeight: '0px' }}></div>
                    <Button label={ls('resetPassword', 'btt')} className="btt" style={{ justifySelf: 'center' }} />
                    <div className="login-new-div">
                        {ls('goback', 'login')}{' '}
                        <a className="login-new-account" href="#/login">
                            {ls('forgot', 'login')}
                        </a>
                    </div>
                </div>
            </form>
        );
    }
}
