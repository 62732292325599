export function LabelSelector(id, prefix = '_') {
    let selections = window.labelSelector ?? {
        contractPmodel: {
            0: 'A Emitir',
            1: 'Em Percurso',
            5: 'Completar Dados Utente',
            10: 'Pendente Operação',
            12: 'Agendado',
            100: 'Finalizado',
        },
        segmentType: { 1: 'News', 2: 'Info', 3: 'Testemonials', 4: 'Services' },
        promotionModel: {
            1: 'A Criar',
            2: 'Pendente Aporovação',
            3: 'Pendente Pagamento',
            4: 'Aprovado',
            5: 'Ativo',
            6: 'Fim',
        },
        featuringModes: {
            1: 'Normal',
            2: 'No Topo',
            3: 'Em Primeiro',
            4: 'Pop Up',
        },
        searchModel: { 1: 'A Criar', 2: 'Pendente Aporovação', 3: 'Pendente Pagamento', 4: 'Aprovado', 5: 'Ativo', 6: 'Fim' },
        cacheManagerType: {
            1: 'refresh call',
        },
        boletoModel: {
            0: 'A Criar',
            1: 'A Processar',
            2: 'Enviado Para o Banco',
            3: 'Pago',
        },
        scheduleTypes: {
            'Schedule com tempo fixo': '1',
            'Schedule com delay fixo': '2',
            'Baseado em cron string': '3',
            'Baseado em uma data predefenida': '4',
        },
        aclItems: {
            1: 'Menu',
            2: 'WS',
            3: 'Entidade',
            4: 'Pagina',
            5: 'Ação',
            6: 'Campos',
        },
        allowMultiplePackage: {
            0: 'Não Permite',
            1: 'Permite',
            2: 'Permite cancelando outros automaticamente',
        },
        productPackageRecurrences: {
            1: 'Diario',
            2: 'Semanal',
            3: 'Mensal',
            4: 'Trimestral',
            5: 'Semestral',
            6: 'Anual',
        },
        paymentType: { 1: 'Credito', 2: 'Debito' },
        paymentModes: {
            1: 'Recorente',
            2: 'Pré-pago',
        },
        visibilityModes: {
            0: 'Total',
            1: 'Apenas no Backoffice',
        },
        workflowTypes: {
            0: 'Emitir',
            1: 'Emitir\u2192 Numo\u2192 Financiadora',
            4: 'Emitir\u2192 Numo\u2192 Fornecedor',
            7: 'Emitir\u2192 Empresa',
            5: 'Requisição Viatura',
            6: 'VideoChamada',
            8: 'Frete',
            9: 'Licitacão',
        },
        billingTypes: {
            0: 'Empresa',
            2: 'Numo',
            3: 'Debito em Folha',
            4: 'Cliente',
            5: 'Cartão',
            6: 'Boleto',
            7: 'Pix',
            8: 'Cartão / Boleto',
        },
        liquidationEntityTypes: {
            1: 'Empresa',
            2: 'Provedor',
            3: 'Cliente',
            4: 'Financeira',
            5: 'Numo',
        },
        loyaltyAngariationTypes: {
            0: 'Sem pontos',
            1: 'Pontos fixo contrato',
            2: 'Pontos fixo por parcela',
            3: 'Percentagem por contrato',
            4: 'Percentagem por parcela',
        },
        placeholder: {
            username: 'Usuario',
            password: 'Senha',
            phoneMobile: '(99) 99999-9999',
            phoneHome: '(99) 99999-9999',
            phoneCommercial: '(99) 99999-9999',
            vatNumber: '999.999.999-99',
            vatNumberC: `99.999.999/9999-99`,
            zipCode: '99999-999',
            panMaked: '9999 9999 9999 9999',
        },
        callcenter: {
            noServices: 'Nao existe nenhum serviço configurado para atendimento de video-call. Por favor configure um serviço com video-call na aba de serviços do seu perfil.',
            gotInfo: 'A obter alguma informação. Por favor aguade.',
            connect: 'Connectar',
            clickConnect: 'Click para ligar',
        },
        tooltip: {
            validationProtetor: 'Validação Protetor',
            inRoute: 'Em curso',
            titleField: 'Por favor preencha este campo',
            inQueuePreAquisition: 'Em fila antes de aquisição',
            emit: 'A Emitir',
            emitNumo: 'Emitir Numo',
            dataMissing: 'Dados em Falta',
            signature: 'Assinatura',
            validationNumo: 'Validação Numo',
            validationProvider: 'Validação Fornecedores',
            emitProvider: 'Emição Fornecedor',
            validationFinancier: 'Validação Financiador',
            emitFinancier: 'Emição Financiador',
            validationConsignator: 'Validação Empresa',
            emitConsignator: 'Emição Empresa',
            scheduled: 'Agendado',
            finished: 'Terminado',
            errorAnalysing: 'Erro ao Analisar',
            delayConsignee: 'Atraso Empregado',
            delayConsignator: 'Atraso Empresa',
            delayFinancier: 'Atraso Financiador',
            delayProvider: 'Atraso Provedor',
            pendingEquimpment: 'Pendente Atribuição Equipamento',
            tripScheduled: 'Agendada Viagem',
            inTrip: 'Em Percurso',
            inReturn: 'Em retorno',
            pendingService: 'Pendente Atendimento',
            inService: 'Em Atendimento',
            seeMap: 'Ver no Mapa',
            getLngLat: 'Obter Latitude e Longitude baseado no endereço',
            editFields: 'Editar Campos',
            creation: 'Criação',
            canceled: 'Canceldado',
            bid: 'Licitação',
            confirmPublication: 'Confirmar publicação',
            approvedForContract: 'Aprovado para contrato',
            scheduledService: 'Agendado para serviço',
            gotDriver: 'Atribuído a Motorista',
            inExecution: 'Serviço em execução',
            ended: 'Finalizado',
            aproveByICargo: 'Por Aprovar ICargo',
            driverManagementAprove: 'Pendente ativação na APP',

            bidByTrasnporter: 'Licitado pelo Transportador',
            answerdByProvider: 'Respondido pela Empresa licitante',
            acceptedByProvider: 'Aceite pela empresa licitante',
            rejectedByProvider: 'Rejeitado pela empresa licitante',

            active: 'Ativo',
            disabled: 'Desativo',
            blocked: 'Bloqueado',
            forAproval: 'Por Aprovar',
            denyed: 'Negado',
            canceld: 'Cancelado',
            canceldAfterConfirmation: 'Cancelado apos confirmação',
            waitingForConfirmation: 'A espera de confirmação',
            waitTime: 'Tempo de Espera',
            open: 'Aberto',
            inList: 'Em Fila',
            processing: 'A Procesar',
            processed: 'Processado',
            sentToBank: 'Enviado para o Banco',
            rejectedByBank: 'Rejeitado para o Banco',
            payed: 'Pago',
            timeout: 'Timeout',
            deleted: 'Apagado',
            delete: 'Apagar',
            error: 'Erro',
            creating: 'Em Criação',
            confirmationAuth: 'Confirmado com confirmação',
            noConfirmAuth: 'Autorizado sem confirmação',
            canceldDueToFail: 'Cancelado devido a falha ou anti-fraud',
        },
        dialogBusiness: {
            start: 'Inicio',
            end: 'Fim',
            mininum: 'Tempo do evento',
            period: 'Tempo entre eventos',
            messageSucess: 'Geração de eventos automáticos iniciada com sucesso. Consulte a agenda.',
        },
        text: {
            options: 'Opções',
            useOfBank: 'Uso do Banco',
            amount: 'Quantidade',
            value: 'Valor',
            invalidJSON: 'Valor Json Invalido',

            callNotAvailable: 'Chamada não disponivel',
            closingPageIn: 'A fechar a pagina em',

            minutes: 'Minutos',
            hours: 'Horas',
            days: 'Dias',
            product: 'Produto',
            package: 'Subscrição',
            withIncidents: 'Com Incidentes',
            withoutIncidents: 'Sem Incidentes',
            all: 'Todos',
        },
        addressTypeValue: {
            addressType4: 'Outros',
            addressType3: 'Entregas',
            addressType2: 'Comercial',
            addressType1: 'Principal',
        },
        weekdays: {
            sunday: 'Domingo',
            monday: 'Segunda',
            tuesday: 'Terça-feira',
            wednesday: 'Quarta-feira',
            thursday: 'Quinta-feira ',
            friday: 'Sexta-feira',
            saturday: 'Sábado',
            weekdays: 'Dias da semana',
            weekends: 'Fim de semana',
            alldays: 'Todos os dias',
        },
        call: {
            premission: 'Por favor dê permissão para vídeo e áudio',
            dateBirth: 'Data de Nascimento',
            sex: 'Sexo',
            anamnesis: 'Anamnese',
            listProblems: 'Lista de problemas',
            diagnostic: 'Conclusão diagnóstico',
            prescription: 'Prescrição electrónica',
            exams: 'Exames',
            documents: 'Documentos',
            history: 'Historial',
            cid: 'CID',
            ended: 'Chamada Terminada',
        },
        provider: {
            chatMode0: 'Sem Chat',
            chatMode1: 'Chat Interno',
            chatMode2: 'Chat Externo',
            schedulingMode0: 'Sem Agendamento',
            schedulingMode1: 'Agendamento Interno',
            schedulingMode2: 'Agendamento Externo',
        },
        titles: {
            aprove: 'Por Aprovar',
            aproveICargo: 'Por Aprovar pela ICargo',
            active: 'Ativos',
            all: 'Todos',
            bid: 'Em Licitação',
            driverManagement: 'Gestão de Funcionários',
            requetsManagement: 'Gestão de Solicitações',
            requetsManagementByMe: 'Gestão de Solicitações Por min',
            servicesManagement: 'Gestão de Serviços',
            calls: 'Tele Assistência',
            'calls#today': 'Hoje - Atendimentos',
            'calls#Active': 'Tele Assistência: Ativos',
            'ambulance#Active': 'Ambulância: Ativos',
            ambulance: 'Ambulância',
            'lifeBtt#Active': 'Butão de Vida: Ativos',
            lifeBtt: 'Botão de Vida',
            promotionsActive: 'Promoções: Ativos',
            promotions: 'Promoções',
            categoriesList: 'Tipos de Categorias',
            segmentsSearch: 'Segementos de Pesquisa',
            segmentsActive: 'Segementos de Pesquisa: Ativos',
            trucks: 'Camiões',
            warehouses: 'Armazéns',
            vehicles: 'Viaturas',
            vouchers: 'Vouchers',
            stagingConsigneeManagement: 'Gestão de Funcionários - Base',
            cache: 'Gestão de Caches',
            help: 'Ajuda',
            multilanguage: 'Multilingue',
            configBatchManagement: 'Gestao de Serviços Batch',
            aclItem: 'Items de Acesso',
            deleteAll: 'Apagar Todos',
            addAll: 'Adicionar todos',
            contractTemplateField: 'Gestão de Campos',
            configContextManagement: 'Configurações:',
            productType: 'Tipos de produtos',
            productsPackageManagement: 'Gestão de Pacotes',
            campaignProduct: 'Gestão de Agregador',
            loyaltyAngariationType: 'Pontuação',
            financierFlowNumo: 'Fluxo Financeiro Numo',
            financierFlowConsignator: 'Fluxo Financeiro Empresa',
            pricing: 'Preçário',
            contractManagement: 'Gestão de Templates de Contrato',
            campaignManager: 'Gestão de Campanhas',
            contracts: 'Gestão de Contratos',
            add: 'Adicionar',
            addNewOne: 'Adicionar um novo',
            batchs: 'Folhas',
            uploadMargin: 'Cargas de Margem',
            uploadSheet: 'Cargas de Folha',
            uploadResgister: 'Cargas de Cadastro',
            uploadGuarantee: 'Cargas de Movimentos',
            titles: 'Cargas de Folha',
            callinfo: 'Histórico',
            serviceList: 'Tipos de Serviços',
            consigneeManagement: 'Gestão de Funcionários',
            consigneeManagementProtetor: 'Gestão de Utentes',
            consigneeManagementDrMoz: 'Gestão de Utentes',
            groups: 'Gestão de Groupos',
            'segmentNews#Active': 'Noticias: Ativos',
            segmentNews: 'Noticias',
            'segmentInfo#Active': 'Informação: Ativos',
            segmentInfo: 'Informação',
            'segmentTestemonials#Active': 'Testemunhos: Ativos',
            segmentTestemonials: 'Testemunhos',
            'segmentServices#Active': 'Serviços: Ativos',
            segmentServices: 'Serviços',
            'segment#Active': 'Segmentos: Ativos',
            segment: 'Segmentos',
            provider: 'Gestão de Empresas',
            consignator: 'Gestão de Transportadoras',
            product: 'Gestão de Serviços',
            product1: 'Gestão de Serviços',
            product2: 'Gestão de Produtos',

            feigthData: 'Dados do frete',
            feigthInfo: 'Informação da carga',
            otherServices: 'Outros serviços adicionais',
            dates: 'Datas de coleta',
            paymentMethods: 'Modo de pagamento',
            waranty: 'Seguro',
            addresses: 'Origem e Destino',
            confirm: 'Confirmar',
            creation: 'Criação',
            auction: 'Licitação',
            approvedForContract: 'Aprovado para contrato',
            scheduledService: 'Agendado para serviço',
            gotDriver: 'Atribuído a Motorista',
            inExecution: 'Serviço em execução',
            ended: 'Finalizado',
            aproveByICargo: 'Por Aprovar ICargo',
            driverManagementAprove: 'Pendente ativação na APP',

            userapp: 'Gestão de Usuário App',
            userserv: 'Gestão de Usuário Serviços',
            userback: 'Gestão de Usuário Backoffice',
            cards: 'Cartões',
            boletos: 'Boletos',
            job: 'Trabalho',
            changePassword: 'Alterar Password',
            change: 'Alterar',
            notification: 'Gestão de Notificações',
        },
        marginTypes: {
            0: 'Numo Texto com tamanho fixo',
            1: 'Numo Excel com campos simples',
        },
        address: {
            type1: 'Principal',
            type2: 'Comercial',
            type3: 'Entregas',
            type4: 'Outros',
        },
        ctemplatemargin: {
            type1: 'Margem Consignado',
            type2: 'Baixa em folha',
            context1: 'Empresa',
            context2: 'Grupo',
            context3: 'Funcionário',
            category1: 'Valor fixo',
            category2: 'Valor Percentual Sobre Margem Global',
            category3: 'Valor Percentual Sobre Margem Deste Produto',
        },
        menu: {
            creation: 'Em Criação',
            segmentPrmotions: 'Segmento Promoção',
            'promotions-active': 'Ativos',
            'promotions-prices': 'Preçario',
            'searcharea-active': 'Ativos',
            'searcharea-past': 'Todos',
            'searcharea-prices': 'Preços',
            'searcharea-services': 'Tipo de Serviços',
            'searcharea-categories': 'Tipo de Categorias',
            segmentSearch: 'Segmento Pesquisa',
            cms: 'CMS',
            consignee: 'Funcionários',
            'staging-consignee-management': 'Base',
            groups: 'Groupos',
            notification: 'Noticações',
            'notification-management': 'Operacionais',
            'notification-management#1': 'Aplicationais',
            'consignee-management-drmoz': 'Utentes',
            contract: 'Contratos',
            'contract-management#2': 'Aprovação',
            'contractTemplate-management': 'Templates',
            lifebtt: 'Botão Vida',
            'life-button-active#1': 'Hoje',
            'life-button-active': 'Ativos',
            'life-button-past': 'Todos',
            ambulanceRequest: 'Pedidos Ambulância',
            'ambulance-active#1': 'Hoje',
            'ambulance-active': 'Ativos',
            'ambulance-past': 'Hoje',
            'assets-management': 'Viaturas',
            assets: 'Viaturas',
            videoAssistence: 'Tele Assistência',
            calls: 'Atendimento',
            'calls-active#1': 'Hoje',
            'calls-active': 'Ativos',
            'calls-past': 'Todos',
            'callinfo-management': 'Histórico',
            administraion: 'Administração',
            access: 'Acessos',
            'admin-user-management-app': 'Aplicativo',
            'admin-user-management': 'Backoffice',
            'admin-user-management-serv': 'Serviços',
            acl: 'ACL',
            'control-item-management': 'Items de Acesso',
            'profile-management': 'Perfis',
            configuration: 'Configurações',
            'help-management': 'Ajuda',
            'cache-management': 'Cache',
            'zipcode-management': 'Codigo Postal',
            'multilanguage-management': 'Multilingue',
            'product-type-management': 'Tipos de Produtos',
            systemVars: 'Variáveis de sistema',
            'service-batch-management': 'Gestão de Serviços Batch',
            paying: 'Cobrança',
            'boleto-management': 'Boleto',
            card: 'Cartão',
            'card-management': 'Cartão',
            'card-movement-management': 'Movimentos',
            ted: 'TEDs',
            pix: 'PIX',
            financier: 'Financeiro',
            'provider-managementE': 'Operadoras',
            'financier-management': 'Financeiras',
            custumerCare: 'Custumer care',
            callsE: 'Chat com corretores',
            callsE1: 'Audio & video chamada',
            callsE2: 'Fila de contato',
            'voucher-management': 'Voucher',
            batchs: 'Folhas',
            batchM: 'Cargas',
            'batch-sheet': 'Folha',
            'batch-register': 'Cadastro',
            'batch-guarantee': 'Movimentos',
            'batch-margin': 'Margem',
            segmentNews: 'Noticias',
            segmentInformation: 'Informação',
            segmentWitness: 'Testemunhos',
            segmentServices: 'Serviços',
            active: 'Ativos',
            all: 'Todos',
            marketplace: 'Marketplace',
            'campaign-management': 'Campanhas',
            productServices: 'Serviços',
            productProduct: 'Produtos',
            'campaignproduct-management': 'Agregador de Produtos',
            'packageProduct-management': 'Pacotes de Produtos',
            myfreight: 'Meus Fretes',
            'contract-management': 'Contratos',
            services: 'Serviços',
            freight: 'Novo Frete',
            freightL: 'Fretes',
            byme: 'Em licitacão por mim',
            'account-info': 'Extrato conta',
            'account-transfer': 'Transferencias',
            'account-pay': 'Pagar contas',
            account: 'Contas',
            aprove: 'Por Aprovar',
            bid: 'Em Licitação',
            driver: 'Motorista',
            'consignee-management': 'Motorista',
            'invoices-management': 'Faturas',
            requests: 'Solicitações',
            consignator: 'Minha Empresa',
            'consignator-management': 'Transportadoras',
            provider: 'Minha Empresa',
            'provider-management': 'Clientes',
            cotacoes: 'Cotações',
            propostas: 'Proposta',
            anticipations: 'Antecipações',
            'anticipations-approval': 'Em aprovação',
            'anticipations-aproved': 'Aprovadas',
            'anticipations-rejected': 'Rejeitadas',
            'anticipations-all': 'Todas',
            onlendings: 'Repasses',
            'onlendings-daily': 'Diário',
            'onlendings-schedule': 'Agenda',
            register: 'Cadastro',
            'register-approval': 'Em aprovação',
            'register-aproved': 'Aprovadas',
            'register-rejected': 'Rejeitadas',
            'register-all': 'Todas',
            sale: 'Venda',
            'contract-management-action': 'Pendente Ação',
            'contract-managemnet-groupby-template': 'Agrupar por Produto',
            'contract-managemnet-groupby-product-type': 'Agrupar por Tipo',
        },
        batchsEditSteps: {
            0: 'Criação',
            1: 'Cadrasto',
            2: 'Preparar Cadrasto',
            3: 'Margem',
            4: 'Preparar Margem',
            5: 'Movimentos',
            6: 'Preparar Movimentos',
            7: 'Folha',
            8: 'Perparar Folha',
            9: 'Terminado',
        },
        configTypes: {
            0: `Normal`,
            1: `Escondido`,
            2: `Encriptado`,
            3: `Texto Rico`,
        },
        tab: {
            text: 'Texto',
            images: 'Imagens',
            category: 'Categoria',
            vehicles: 'Viaturas',
            vouchers: 'Vouchers',
            asset: 'Ativo',
            forArchive: 'Por Arquivo',
            voucher: 'Voucher',
            cache: 'Gestor de Cache',
            card: 'Cartão',
            boleto: 'Boleto',
            help: 'Ajuda',
            multilanguage: 'Multilingue',
            serviceBatch: 'Service Batch',
            aclItem: 'Items de Acesso',
            contractTemplateField: 'Campo',
            systemVariable: 'Variavel de Sistema',
            zipCode: 'Codigo Postal',
            campaignProduct: 'Agregador',
            jsScript: 'JS Script',
            contractForms: 'Formulários de Contrato',
            terms: 'Termos',
            contractTemplate: 'Template de Contrato',
            financier: 'Financiador',
            events: 'Evento',
            processing: 'Processamento',
            carga: 'Carga',
            generatedArchives: 'Arquivos gerados',
            movements: 'Movimentos',
            batchsEdit: 'Folha',
            verba: 'Verba',
            backoffice: 'Acesso Backoffice',
            payrollbudget: 'Verbas',
            payroll: 'Contra Cheque',
            clockinglocations: 'Locais de Ponto',
            contacts: 'Contatos',
            cards: 'Cartões',
            boletos: 'Boletos',
            schedulePuchIn: 'Ponto',
            profile: 'Perfil',
            details: 'Detalhes',
            businessHours: 'Horario',
            calendarEvent: 'Evento',
            providerSchedule: 'Agenda',
            group: 'Grupo',
            log: 'Logs',
            groupconsignee: 'Membros',
            marginManagement: 'Gestão de Margens',
            driver: 'Motorista',
            consignee: 'Driver',
            margin: 'Margem',
            provider: 'Fornecedor',
            providerServices: 'Serviços',
            services: 'Serviços',
            providerStock: 'Stock',
            addresses: 'Endereços',
            providerAccess: 'Acesso Backoffice',
            segmentNews: 'Noticias',
            segmentInfo: 'Informação',
            segmentTestemonials: 'Testemunhos',
            segmentServices: 'Serviços',
            segment: 'Segmento',
            textEditor: 'Texto',
            statistics: 'Estatísticas',
            ratting: 'Avaliação',
            providerBusinessHours: 'Horario',
            search: 'Pesquisa',
            contractFroms: 'Dados',
            contractChat: 'Chat',
            contractQuota: 'Parcelas',
            contract: 'Contracto',
            contracts: 'Contractos',
            contractRate: 'Avaliação',
            bid: 'Licitação',
            user: 'Usuário',
            association: 'Associação',
            users: 'Usuários',
            parameters: 'Parâmetros',
            subscriptions: 'Subscrições',
            attachments: 'Anexos',
            subscription: 'Subscrição',
            reacurenace: 'Recorencia',
            package: 'Pacote',
            consignator: 'Empresa',
            location: 'Localização',
            product: 'Produto',
            products: 'Produtos',
            marketingPhotos: 'Fotos de Marketing',
            configs: 'Configs',
            aclAccess: 'Acesso acl',
            notification: 'Notificação',

            campaign: 'Campanhas',
        },
        notificationSteps: {
            0: 'Criação',
            1: 'Em Fila',
            2: 'Em Processamento',
            3: 'Processado',
        },

        contractSteps: {
            emit: 'A Emitir',
            completeData: 'Completar Dados Cliente',
            needNumoValidation: 'Pendente de Validação Numo',
            needNumoEmission: 'Pendente de Emissão Numo',
            needFinancierValidation: 'Pendente de Validação Financiador',
            needFinancierEmission: 'Pendente de Emissão Financiador',
            needProviderValidation: 'Pendente de Validação Fornecedor',
            needProviderEmission: 'Pendente de Emissão Fornecedor',
            needConsignatorValidation: 'Pendente de Validação Empresa',
            needConsignatorEmission: 'Pendente de Emissão Empresa',
            needSignature: 'Pendente de Assinatura',
            active: 'Ativo',
        },

        messages: {
            askChangeStatusContract: 'Tem a certeza que quer alterar o estado do contracto?',
            messageToConsignee: 'Mensagem para o funcionário',
            vehiclesNeedToBeFilled: 'Campo viatura tem de ser preenchido!',
            askFinish: 'Tem a certeza que quer finalizar?',
            autoClose: 'Esta pagina vai fechar automaticamente.',
            uploadSuccessfulVoucher: 'Upload com sucesso. Pode demorar algum tempo até os voucher ficarem disponiveis.',
            baseRemove:
                'Ao eliminar o registro na base não desativa o usuário de aplicativo, apenas não permite cadastro de novos. Para desativar o usuário devera ir a ABA de Funcionários / Aplicativo e desativar o mesmo. Deseja eliminar o registro na base ?',
            userAssociated: 'User asociado com sucesso!',
            askMessagesToConsignee: 'Mensagem para o funcionário',
            askChangeToPayed: 'Tem a certeza que quer mudar o estado para pago?',
            addrrError:
                'Um dos endereços não foi possivelmente colocado incorretamente, pois não conseguimos encontrar as cordeadas para este endereço, por favor confirme se os endereços estão corretos e se estiverem continue.',
            askChange: 'Tem a certeza que quer alterar?',

            inWaitList: 'Em Fila de espera!',
            saveMap: 'Tem de Gravar o Mapa primeiro!',
            batchesFileEditDown: 'Deseja dar baixa de todos contra-cheques gerados por este arquivo? Atenção ao dar baixa do contra-cheque o mesmo saira do saldo do colaborador. Deseja continuar?',
            batchesPersonEditDown: 'Atenção ao dar baixa do contra-cheque o mesmo saira do saldo do colaborador. Deseja continuar?',
            simpleSave: `Gravado com Sucesso`,
            simpleCreate: `Criado com Sucesso`,
            simpleUpload: 'Upload com sucesso',
            simpleUploadStart: 'A enviar para o servidor',
            simpleStatus: 'Estado alterado com sucesso',
            simpleAdd: 'Adicionado com sucesso!',
            simpleRemove: 'Removido com sucesso!',
            addProfile: 'Perfil adicionado com sucesso!',
            simpleBlock: 'Bloqueado com sucesso!',
            askRemove: 'Tem a certeza que quer remover?',
            askConfirm: 'Tem a certeza que quer avançar?',
            askConfirmCustomer: 'Tem a certeza que deseja confirmar a publicação do frete?',
            askConfirmICargo: 'Tem a certeza que deseja confirmar a publicação do frete?',
            askDelete: 'Tem a certeza que quer apagar premanentemente?',
            askAprove: 'Tem a certeza que quer aprovar?',

            askReject: 'Tem a certeza que quer rejeitar?',
            askSubmit: 'Tem a certeza que quer submeter?',

            askBlockP: 'Tem a certeza que quer bloquear permanentemente?',
            askCancelarP: 'Tem a certeza que quer cancelar permanentemente?',
            askNegateCargo: 'Tem a certeza que deseja rejeitar a publicação deste frete e enviar para ajuste do solicitante?',
            askBlock: 'Tem a certeza que quer remover?',

            simpleDeactivate: `Tem a certeza que quer desativar?`,
            simpleActivate: `Tem a certeza que quer ativar?`,

            askDeactivate: `Tem a certeza que quer desativar?`,
            askTempDeactivate: `Tem a certeza que quer desativar temporariamente?`,
            askActivate: `Tem a certeza que quer ativar?`,

            askMoveToMaintenance: 'Tem a certeza que quer colocar manutenção?',
            askRemoveToMaintenance: 'Tem a certeza que quer retirar manutenção?',

            addressDelete: 'Endereço apagado com sucesso',
            calendarSetSize: 'Evento não alterado. Evento encontra-se fechado',
            calendarResize: 'Não pode ser eventos no passado',
            //          'calendarSetSame': 'Não pode ser criado num tempo ja ocupado',
            //          'calendarOnlyOneDay': 'Não pode ser mudado para mais de um dia',
            shedulingLock: 'Tem a certeza que quer reservar?',
            schedulingUnlock: 'Tem a certeza que quer liberar?',
            zeroResults: 'Sem resultados',
            mustAddUpTo100: 'Os fluxo repasse tem que totalizar 100',
            negate: 'Negado com sucesso!',
            confirmInfo: 'Valide os dados do seu frete e confirme a sua publicação. Apos publicado o seu frete sera disponibilizado as transportadoras e motoristas do ICargo',
            respond: 'Responder:',
            driverNotSelected: 'Motorista nao selecionado',
            selectDriver: 'Selecionar Motorista',
            noState: 'Serviço por Iniciar',
            notContracted: 'Não Contratado',
            noVatNumber: 'CPF nao encontrado',
            authOk: 'Venda aprovada',
            askApproveStatus: 'Ao aprovar ira tornar o provedor disponivel nos canais aplicacionais. Deseja realmente aprovar o provedor ?',
            askDenyApproveStatus: 'Tem a certeza que negar?',
            bidAutoMsg: 'Nova licitação feita no valor de',
            askForceEmission: 'Ao forçar a emissão ira colocar o pedido no estado Ativo sem validação do saldo do usuario. Deseja continuar ?',
            userNotFound: 'Usuario não encontrado.',
            passwordResetSuccess: 'Senha resetada com sucesso. Você recebera um email em breve.',
            removeProfileFromUser: 'Tem a certesa que deseja desassociar este user do perfil?',
            treePointsNedded: 'São nessesarios pelo menos três pontos para criar um Poligno',
            couldNotAssociateUser: 'O Utilizador foi criado mas não foi possivel assosia-lo, por favor contacte um administrador Numo!',
            generateArchives: 'A gerar arquivos',
            skipRegister: 'Ao saltar o cadastro o processo de calculo de MARGEM ira usar os dados atuais na plataforma.  Deseja realmente saltar o CADASTRO?',
            skipMargin: 'Ao saltar a margem o processo de calculo de Movimentos ira usar os dados atuais na plataforma.  Deseja realmente saltar o MARGEM?',
            askRemoveSheet: 'Deseja realmente eliminar o lacamento desta FOLHA?',

            notificationAskProcess: 'Ao processar estara a mudar o estado desta notificação para fila de espera, deseja processar?',

            initialBid: 'Licitação Inicial de $priceR$',
        },
        default: {
            emptyCalendarEvent: '-',
            hourFormat: '24',
            moneySymbol: 'R$',
        },
        batchesIdUUID: {
            '5edc7e99-ce5c-68aa-97e1-6f1699550c89': 'Cadrasto',
            '4f491300-f859-f49e-c8a0-db59764ea41c': 'Margem',
            '2b81a56a-780b-bf8f-b00d-038f01b88c1a': 'Movimentos',
            '4663be4a-3f28-e780-5275-9e9b29c9cef6': 'Folha',
        },
        batchesNote: {
            1: 'Carregue o arquivo de cadastro para que a folha deste mes seja processada com os dados atualizados. O carregamento da folha acrescenta e remove automaticamente os funcionários da plataforma.',
            5: 'Carregue os diversos arquivos de margem ate dia 17 referente mes para processamento. Pode carregar multiplos arquivos de movimentos.',
        },
        btt: {
            close: 'Fechar',
            yes: 'Sim',
            no: 'Não',
            schedule: 'Agendar',
            denyCall: 'Desligar',
            acceptCall: 'Atender',
            placeUnavailable: 'Colocar Indisponivel',
            placeAvailable: 'Colocar Disponivel',
            finish: 'Finalizar',
            preview: 'Preview',
            aprove: 'Aprovar',
            reject: 'Rejeitar',
            sale: 'Venda',
            delete: 'Apagar',
            error: 'Erro',
            validate: 'Validar',
            emitir: 'Emitir',
            advance: 'Avançar',
            uploadMarketing: 'Upload Marketing',
            save: 'Salvar',
            exit: 'Sair',
            copy: 'Copiar',
            create: 'Criar',
            accept: 'Aceitar',
            change: 'Alterar',
            deactivate: 'Desativar',
            cancel: 'Cancelar',
            negate: 'Negar',
            block: 'Bloquear',
            remove: 'Remover',
            respond: 'Responder',
            deny: 'Negar',
            moveMaintenance: 'Colocar manutenção',
            removeMaintenance: 'Retirar manutenção',
            activate: 'Ativar',
            deactivateTemp: 'Desativar Temporariamente',
            shedulingLock: 'Reservar',
            schedulingUnlock: 'Liberar',
            calendarEventToday: 'Hoje',
            automaticFiller: 'Agendamento automatico',
            generate: 'Gerar',
            next: 'Continuar',
            confirm: 'Confirmar',
            confirmPublication: 'Confirmar publicação',
            bid: 'Licitar',
            chat: 'Chat',
            select: 'Selecionar',
            upload: 'Upload',
            approve: 'Aprovar',
            forceEmission: 'Forçar Emissão',
            submit: 'Submter',
            login: 'Acessar',
            resetPassword: 'Resetar Senha',
            resetPin: 'Resetar Pin',
            changePassword: 'Alterar a password',
            incert: 'Inserir',
            add: 'Adicionar',
            saveMap: 'Salvar Mapa',
            editMap: 'Editar Mapa',
            addRegister: 'Adicionar Cadastro',
            skipRegister: 'Saltar Cadastro',
            addMargin: 'Adicionar Margem',
            skipMargin: 'Saltar Margem',
            addMovements: 'Adicionar Movimentos',
            genererateArchives: 'Gerar arquivos',
            addSheet: 'Adicionar Folha',
            skipMovements: 'Saltar Movimentos',
            eliminate: 'Eliminar',
            process: 'Processar',
        },
        dataTableGeneric: {
            state: 'Estado',
        },
        login: {
            preForgot: 'Esqueceu sua senha?',
            forgot: 'Clique aqui',
            goback: 'Voltar para Login',
        },
        reports: {
            'report-etl': 'Pedidos ETL',
            'report-xls': 'Pedidos Excel',
            'report-financier': 'Pedidos Financeiras',
            'report-indication': 'Relatorio de indicacao',
            'report-service': 'Relatorio de serviços',
            'report-driver': 'Relatorio por condutor',
            'report-asset': 'Relatorio por viatura',
            'report-provider': 'Relatorio por transportadora',
        },
        mask: {
            vatNumber: '999.999.999-99',
            vatNumberC: `99.999.999/9999-99`,
            phoneCommercial: '(99) 99999-?9999',
            phoneMobile: '(99) 99999-?9999',
            phoneHome: '(99) 99999-?9999',
            zipCode: '99999-999',
            panMaked: '9999 9999 9999? 9999',
            hours: '99:99',
        },
        generic: {
            reason: 'Motivo',
            normal: 'Normal',
            system: 'Sistema',

            type: 'Tipo',
            other: 'Outros',
            address: 'Endereço',
            position: 'Posição',

            bigPhoto: 'Foto grande',
            smallPhoto: 'Foto pequena',
            status: 'Estado',
        },
        contactType: {
            1: 'email',
            2: 'celular',
            3: 'home phone',
            4: 'work phone',
            5: 'other phone',
            6: 'fax',
        },
        campaignMode: {
            1: 'Direto',
            2: 'Limitado a quandidade de',
            3: 'Limitado ao valor de',
        },
        cardType: {
            1: 'Credito',
            2: 'Debito',
            3: 'Pre-pago',
        },
        clockingActionType: {
            0: 'Entrada',
            1: 'Saida',
        },
        clockingType: {
            1: 'Trabalho',
            2: 'Pausa',
        },

        _: {
            gestorialEntity: 'Entidade Gestora',
            promotor: 'Promotor',
            refreshDbConfigs: 'Refresh Db Configs',
            refreshFileConfigs: 'Refresh Files Configs',
            refreshLanguageConfigs: 'Refresh Language Configs',
            profile: 'Perfil',

            accessItemDescription: 'Descrição',
            profileDescription: 'Descrição',
            description: 'Descrição',
            extraDescription: 'Descrição',
            rattingCategoryDescription: 'Descrição',
            documentTypeDescription: 'Descrição',

            organizationName: 'Empresa',

            username: 'Usuário',
            creationUserDescription: 'Usuário',
            userFullname: 'Usuário',

            email: 'Email',
            phoneMobile: 'Celular',

            promotionStatus: 'Estado',
            status: 'Estado',
            contractTemplateFormStatus: 'Estado',

            contractTemplate: 'Template',

            passwordRetries: 'Logins Falhados',
            deviceBrand: 'Marca',

            paymentType: 'Tipo',
            cacheManagerType: 'Tipo',
            accessItemTypeDescription: 'Tipo',
            configType: 'Tipo',
            serviceType: 'Tipo de Serviço',
            deviceType: 'Tipo',
            businessHoursType: 'Tipo',
            cardType: 'Tipo',
            assetType: 'Tipo',
            contactType: 'Tipo',
            voucherType: 'Tipo',
            addressType: 'Tipo',
            providerType: 'Tipo',
            documentTypeId: 'Tipo',
            fieldType: 'Tipo',
            groupType: 'Tipo',
            marginType: 'Tipo',
            contractTemplateMarginType: 'Tipo',
            logTypeDescription: 'Tipo',
            saleTypeDescription: 'Tipo',
            productTypeDescription: 'Tipo',
            relationType: 'Tipo',
            contractTemplateFormType: 'Tipo',
            incidentTypeDescription: 'Tipo',

            truck_type_id: 'Tipo de Veículo',
            cargo_type_id: 'Tipo de carga',
            category_type_id: 'Tipo',
            searchAreaType: 'Tipo Pesquisa',
            promotionType: 'Tipo de Promoção',
            newsType: 'Tipo Noticia',
            contractType: 'Tipo',
            clockingType: 'Tipo',
            productType: 'Tipo',
            financierType: 'Tipo',
            profileType: 'Tipo de perfil',

            deviceId: 'Id',
            deviceModel: 'Modelo',
            appVersion: 'Versão Applicativo',
            month: 'Mes',
            year: 'Ano',
            checkDigit: 'Digito Verificador',
            batchLoadNumber: 'Lote',
            adutionalDescription: 'Descrição Adicional',
            additionalCode: 'Code Adicional',
            brandCard: 'Bandeira',
            panMasked: 'Nr. Cartão',
            referenceDescription: 'Entidade associada',
            cardDefault: 'Default',
            model: 'Modelo',
            plateId: 'Placa',

            campaignName: 'Nome',
            referenceName: 'Nome',
            groupName: 'Nome',
            fieldName: 'Nome',
            contactRelationshipName: 'Nome',
            contractFormModeValue: 'Nome',
            name: 'Nome',
            fullname: 'Nome',

            financierName: 'Financiador',

            visibility: 'Visiblidade',

            productMode: 'Modo',
            campaignModeDescription: 'Modo',
            campaignMode: 'Modo',

            minValue: 'Valor Minimo',
            maxValue: 'Valor Maximo',
            subscriptionValuePayed: 'Valor Pago',

            periodValue: 'Periodo',
            minPeriod: 'Periodo Minimo',
            maxPeriod: 'Periodo Maximo',

            stepPeriod: 'Passo',
            stepValue: 'Passo',

            contractActionText: 'Texto Adsão',
            priceContextTitle: 'Titulo Preço',

            assetCategory: 'Categoria',
            businessHoursCategory: 'Categoria',
            incidentCategoryDescription: 'Categoria',

            contact: 'Contacto',

            contactRelationshipTypeDescription: 'Relação',
            contactRelationshipType: 'Relação',

            principal: 'Principal',
            teaser: 'Teaser',
            contactStatus: 'Estado',
            idUUID: 'ID',
            totalClickNumber: 'Nr Clicks',
            price: 'Preço',
            showOnApp: 'Mostrar na App',
            showOnSite: 'Mostrar no Site',
            showOnFB: 'Mostrar no Facebook',
            showOnTweeter: 'Mostrar no Tweeter',
            showOnInstagram: 'Mostrar no Instagram',
            sendByEmail: 'Enviar por email',
            featuringMode: 'Destaque',
            promotion: 'Promoção',

            searchAreaCategory: 'Categoria Pesquisa',
            promotionCategory: 'Categoria de Promoção',
            newsCategory: 'Categoria Noticia',

            showOrder: 'Ordem',
            title: 'Titulo',

            consignatorFantasyName: 'Transportadora',
            consignatorName: 'Transportadora',

            providerName: 'Empresa',

            vatNumber: 'CPF',
            employeeId: 'Número de Empregado',
            referenceOwnerName: 'Empresa',
            parenGroupName: 'Hierarquia',
            fieldPosition: 'Posição',
            mandatory: 'Obrigatorio',

            contractTemplateMarginContext: 'Contexto',
            contextDescription: 'Valor Contexto',
            contextValue: 'Valor Contexto',

            contractTemplateMarginCategory: 'Categoria',

            zipCode: 'Codigo Postal',
            street: 'Logradouro',
            city: 'Cidade',
            state: 'Estado',
            countryName: 'Pais',

            country: 'Pais',

            chatMode: 'Chat',
            schedulingMode: 'Agendamento',
            tag: 'Tag',
            languageId: 'Linguagem',
            calendarEventContract: 'Contrato',
            yes: 'Sim',
            no: 'Não',
            schedulingSyncStatus: 'Síncrono',
            latitude: 'Latitude',
            longitude: 'Longitude',
            periodOneStart: 'Inicio periodo 1',
            periodOneEnd: 'Fim periodo 1',
            periodTwoStart: 'Inicio periodo 2',
            periodTwoEnd: 'Fim periodo 2',
            message: 'Mensagem',
            context: 'Contexto',
            weekday: 'Dia',
            streetZone: 'Bairro',
            port: 'Numero',
            complement: 'Complemento',

            shortDescription: 'Descrição Curta',
            longDescription: 'Descrição Longa',

            parentIdUUID: 'Parente',
            parameterValue: 'Valor',
            value: 'Valor',
            priceValue: 'Valor',
            marginValueControl: 'Valor',
            voucherValue: 'Valor',
            cargo_insurace_value: 'Valor',
            fieldValue: 'Valor',

            quantity: 'Quantidade',

            rate: 'Avaliação',
            ratting: 'Avaliação',

            filenameOriginal: 'Ficheiro',
            originName: 'Utente',
            callRecordStatusDescription: 'Estado',
            originalFilename: 'Nome Original',

            fieldMaxSize: 'Max',
            fieldHeight: 'Altura',
            fieldWidth: 'Largura',
            terms: 'Termos',
            text: 'Texto',
            info: 'Informação',
            contractInfo: 'Informação',
            fantasyName: 'Nome Fantasia',
            fantasyNameSimple: 'Nome',
            fantasyfieldNameName: 'Nome Fantasia',

            searchAreaServiceType: 'Serviço',
            condicionalValue: 'Valor Condição',
            afterCondicionalValue: 'Valor apos limite',
            afterCancelationValue: 'Valor apos cancelamento ou nao pagamento',
            page: 'Página',
            help: 'Ajuda',
            contractTemplateFormMode: 'Modo',

            category_id: 'Categoria',
            cte: 'Documento transporte electronico',
            origin_address: 'Origem',
            destination_address: 'Destino',
            product_segment_id: 'Produto',
            cargo_weight: 'Peso(Kg)',
            quotation_type_id: 'Modo cotação',
            cargo_price: 'Preço',
            cargo_priceMax: 'Preço Máximo',
            cargo_payment_flow_adjudication: 'Fluxo repasse Aceitcao',
            cargo_payment_flow_load: 'Carregamento',
            cargo_payment_flow_delivery: 'Entrega',
            voucher_id_uuid: 'Codigo promocional',
            cargo_insured_by_owner: 'Tem Seguro?',
            cargo_insurance_company: 'Seguradora',
            cargo_insurance_document_number: 'Nr. Apolice',
            //            'date_checkout_start': 'Data inicio da colecta',
            //            'date_checkout_end': 'Data fim de colecta',
            date_checkout: 'Datas colecta',
            hour_checkout_start: 'Horario de inicio de recolha',
            hour_checkout_end: 'Horario limite de recolha',
            date_delivery_limit: 'Data limite de entrega',
            cargo_shared_allow: 'Premite carga partilhada',
            service_take_photo: 'Coletar imagem',
            service_take_signature: 'Coletar Assinatura',
            service_track_simple: 'Rastreamento simpes',
            service_track_physical: 'Rastreamento fisico com auditoria',
            service_load: 'Carregamento',
            service_unload: 'Descarga',
            service_packging: 'Acondicionamento / Paletização',
            service_cargo_insurance: 'Seguro ICargo',
            obs: 'Descrição',
            cargo_payment_mode_id: 'Modo de pagamento',
            consigneeName: 'Motorista',

            duration: 'Duração',

            contractFormMode: 'Modo',
            campaignItemMode: 'Modo',

            cargo_calculated_distance: 'Distancia Calculada(km)',
            cargo_calculated_price: 'Preço Calculado(R$)',
            cargo_calculated_time: 'Tempo Calculado',

            saleValue: 'Sale',
            totalMargin: 'Saldo',

            code: 'Código ',
            destinationAddress: 'Destino',
            originAddress: 'Origem',
            licitationsCounter: 'Nr. Licitações',
            count: 'Quantidade',
            totalValue: 'Valor total',
            bidedValue: 'Valor Licitado',
            productName: 'Produto',
            workingEntityName: 'Medico',

            destinationName: 'Atendente',

            totalMarginEx: 'Margem Total:',
            mobileEnrollmentStatus: 'Emparelhamento',
            legalAcceptanceStatus: 'Aceito Dos Termos',
            requestUsername: 'Usuário',
            tId: 'TID',
            applicationId: 'Aplicação',
            versionapp: 'Versão applicativo',
            contractTotalValue: 'Valor Total',

            dateContractStart: 'Data de Inicio',
            dateContractEnd: 'Data de Fim',
            datePeriodStart: 'Inicio',
            datePeriodEnd: 'Fim',
            dateCreation: 'Data Criação',
            dateStart: 'Data Inicio',
            dateStartCall: 'Data Inicio',
            dateEndCall: 'Data Fim',
            dateEnd: 'Data Fim',
            dateExecution: 'Data Execução',
            dateStartNews: 'Data Inicio',
            dateEndNews: 'Data Fim',
            dateUpdate: 'Data Alteração',
            dateLastLogin: 'Ultimo Login',
            dateLastUserLock: 'Ultimo Bloqueio',
            dateLastPasswordChange: 'Alteração Password',
            dateManualRecording: 'Data Registro',
            dateSystem: 'Data Sistema',
            dateBirth: 'Data Nascimento',
            date: 'Data',

            jobStartDate: 'Inicio',

            vatNumberC: 'CNPJ',
            registerId: 'Doc.ID',
            employeeGovId: 'Carteira empregado',
            gender: 'Gênero',
            hablitation: 'Habilitações',
            civilStatus: 'Estado Civil',
            dependentNumber: 'Nr. Dependentes',
            motherName: 'Nome da Mãe',
            fatherName: 'Nome do Pai',
            phoneCommercial: 'Telefone Comercial',
            usedMargin: 'Margem Usada:',
            loyaltyPointsBalance: 'Pontos:',
            bankId: 'Banco',
            bankingBilletNumber: 'Nr Boleto',
            payerVatNumber: 'CPF Pagador',
            billetDisplayValue: 'Valor',
            biometricRecognitionStatus: 'Biometria',

            totalIncomigs: 'Valor Recebido',
            totalDiscounts: 'Valor Descontos',
            discounts: 'Descontos',
            incomigs: 'Proventos',

            changeMarignDialogValueUsed: 'Margem usada',
            changeMarignDialogValueTotal: 'Margem total',
            socialSecurity: 'Previdencia',
            baseFGTS: 'FGTS Base',
            FGTS: 'FGTS',
            baseIR: 'IR Base',
            iR: 'IR',
            extraHours: 'Horas Extra',
            marginValue: 'Valor Margem',
            salaryBase: 'Salario Base',
            salaryNet: 'Salario Liquido',
            salaryGross: 'Salario Bruto',
            jobName: 'Cargo',
            workLocation: 'Localização',
            forcedMargin: 'Margem Forçada',

            clockingMotiveType: 'Tipo Justificação',
            motiveInfo: 'Justificação',
            erpCode: 'Codigo ERP',
            consignatorPullMode: 'Modo de Cadastro',

            phoneHome: `Telefone`,
            verba: 'Verba',
            movementLayout: 'Layout Movimento',

            budgetCode: 'Codigo',
            budgetDescription: 'Descrição',
            movementValue: 'Valor',
            movementDiscountValue: 'Desconto',

            installmentNumber: 'Prestação',

            installmentValue: 'Valor',

            installmentTotal: 'Total Prestações',
            secondPassword: 'Repetir Password',
            newPassword: 'Nova Password',
            oldPassword: 'Password Antiga',

            interfaceName: 'Interface',
            parameterA: 'Parametro A',
            parameterB: 'Parametro B',
            parameterC: 'Parametro C',
            parameterD: 'Parametro D',
            parameterE: 'Parametro E',
            parameterF: 'Parametro F',
            parameter: 'Parámetro',

            notificationTypeDescription: 'Tipo',
            notificationType: 'Tipo',

            notificationCheckMode: 'Mode de Confirmação',

            notificationBroadcastType: 'Tipo de evento',

            shortDescriptionNotification: 'Titulo',

            longDescriptionNotification: 'Menssagem',

            dateViewed: 'Data de Visualização',
            dateChecked: 'Data de Confirmação',

            viewed: 'Visualizado',
            checked: 'Confirmado',

            consigneeFullname: 'Funcionário',
            consigneeVatNumber: 'CPF',

            contractTotalCapitalValue: 'Total de Capital',
            contractInterestsPrecentage: 'Juros',
            contractTotalInterestsValue: 'Total de Juros',

            capital: 'Capital',
            interests: 'Juros',

            dateInstallment: 'Data Parcela',
            datePayment: 'Data de Pagamento',
            datePayed: 'Data Pago',

            campaignMainImage: 'Foto Principal',
            campaignAggregatedImage: 'Foto Agregada',

            contractTemplateTypeDescription: 'Tipo',
            contractTemplateType: 'Tipo',

            contractSignatureMode: 'Modo Assinatura',
            templateVersion: 'Versão',

            workflowType: 'Workflow',

            billingToMode: 'Método cobrança',

            liquidationEntityType: 'Entidade de liquidação',

            contractDocumentBase: 'Template de Contrato',

            allowToreadeal: 'Permitir Renegociação',

            marginControl: 'Controlo de Margem',

            allowsSyncCall: 'Permite chamada sincrone',

            iofannual: 'IOFAnual',
            iofadditional: 'IOF Adicional',

            lenderTax: 'Prestamista',

            monthlyTax: 'Juros Mensal',

            consignatorMarginStartMode: 'Margem Entrada',
            consignatorMarginInstallmentMode: 'Margem Prestações',
            consignatorRevenueCloseMode: 'Margem Fecho',

            numoMarginStartMode: 'Margem Entrada',
            numoMarginInstallmentMode: 'Margem Prestações',
            numoMarginCloseMode: 'Margem Fecho',

            loyaltyAngariationType: 'Pontuação',

            productPackageValue: 'Valor',

            messageSubscription: 'Mensagem por adesao',
            messagePaymenyOverdue: 'Mensagem por nao pagamento',

            allowMultiplePackage: 'Permintir aderir com outros pacotes',

            accessItemTag: 'Tag',

            aclRead: 'Ler/Acesso',
            aclCreate: 'Criar',
            aclUpdate: 'Atualizar',
            aclDelete: 'Apagar',

            batchServiceType: 'Tipo Serviço Batch',

            monitoringDateTick: 'Ultimo Tick',
            monitoringLastExecution: 'Ultima Execução',
            monitoringStatus: 'Monitorização',

            serviceId: 'Service Id',

            folderIn: 'Pasta In',
            folderOut: 'Pasta Out',
            folderProcessed: 'Pasta Processed',

            logTickType: 'Modo Log',

            scheduleStartDelay: 'Atraso de Inicio Serviço',

            scheduleType: 'Modo de Agendamento',

            scheduleValue: 'Valor de Agendamento',
            referenceNumber: 'Codigo de Referencia',

            paymentLocation: 'Local de Pagamento',
            dateDue: 'Vencimento',
            recipientName: 'Beneficiário / CPF',
            recipientBranch: 'Agência/Código Beneficiário',
            installmentNumberBoleto: 'N.documento',
            bankingBilletDocumentTypePrefix: 'Espécie',
            dateCreationBoleto: 'Data do Documento',
            carteira: 'Carteira',
            especie: 'Espécie',
            billetValueFirst: 'Valor Documento',
            bodyRecipientDescription: 'Texto Beneficiário',
            bodyPayerDescription: 'Texto Pagador',
            discountsBoleto: '(-) Desconto / Abatimento',
            otherDeductions: '(-) Outras deduções',
            delayCosts: '(+) Mora / Multa',
            payerName: 'Pagador/CPF',
            otherCredits: '(+) Outros Acréscimos',
            payeeName: 'Sacador / Avalista',
            billetValue: '(=) Valor Cobrado',
            bankingBilletResponseCode: 'Retorno',
            smsMobilePhone: 'Celular',
            smsText: 'Texto',
            aceite: 'Aceite',

            merchantName: 'Estabelecimento',
            acquireAuthorizationCode: 'Codigo de Autorização',
            brandReturnCode: 'Codigo de Retorno',

            number: 'Numero',
            checkDigitVaucher: 'Digito verificador',
            controlNumber: 'Nr. Sorteio',
            serialNumber: 'Nr. Serie',
            additionalDescription: 'Nr. Sorteio',

            brand: 'Marca',

            consigneeFullnameP: 'Utente',

            fillerField1: 'Atribuido',

            contract: 'Contrato',
            contractTemplateTitle: 'Template',
            vehicle: 'Viatura',

            package: 'Pacote',
            cvv2: 'CVV',
            expData: 'Data de expiração',
            waitTime: 'Tempo de Espera',

            medicalRecord: 'Prontuário',
            ratting: 'Avaliação',
            addressState: 'Província',
        },
    };
    if (!selections[prefix]) prefix = '_';
    return selections[prefix][id] ? selections[prefix][id] : 'LabelInfenranceFailed';
}
