import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ConsignatorService from '../../service/ConsignatorService';
import ProductService from '../../service/ProductService';
import GSelector from '../generic/GSelector';
import {
    LS,
    StateIcon,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_inputArea,
    g_template_input,
    g_getData,
    g_dialogTemplate,
    g_getWraper,
    g_inputTemplate,
} from '../GenericFunctions';

export default class BatchMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                code: '',
                consignatorIdUUID: '',
                dateCreation: '',
                dateUpdate: '',
                description: '',
                idUUID: '',
                movementLayout: '',
                payrollBudgetStatus: '',
                productIdUUID: '',
                productName: '',
            },
            items: [{ label: LS('verba', 'tab') }],
            holderphoto: null,
            idUUID: null,
        };
    }

    updateStatusClick = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.idUUID,
            payrollBudgetStatus: this.state.data.payrollBudgetStatus === 1 ? 2 : 1,
        };
        let response = await ConsignatorService.payrollBudgetUpdateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.idUUID,
            payrollBudgetStatus: 3,
        };
        let response = await ConsignatorService.payrollBudgetUpdateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let cid = query.get('cid');
        this.setState({ cid: cid, idUUID: idUUID, mode: query.get('type') === '1' ? false : true }, this.getData);
    }

    getProducts = context => g_getData(context, ProductService.getProducts, 'products');

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
        };
        let response = await ConsignatorService.payrollBudgetList(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.payrollBudgets[0];
        this.setState({
            data: rdata,
            bname: rdata.productName,
        });
    };

    onSubmit = async e => {
        e.preventDefault();
        if (!this.state.data.consignatorIdUUID && !this.state.cid) return;
        if (this.state.idUUID) {
            let response = await ConsignatorService.payrollBudgetUpdate({ payrollBudget: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.getData();
        } else {
            let data = this.state.data;
            data.consignatorIdUUID = data.consignatorIdUUID ? data.consignatorIdUUID : this.state.cid;
            data = { payrollBudget: data };
            console.log(data);
            let response = await ConsignatorService.payrollBudgetAdd(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            window.location.href = `/#/payrollbudget?type=1&id=${response.payrollBudget.idUUID}`;
            window.location.reload();
        }
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-8 p-lg-8 p-fluid contact-form">
                                    <Panel>
                                        <GSelector
                                            label="Produto"
                                            ref={e => (this.selector1 = e)}
                                            getData={this.getProducts}
                                            tableBody={[
                                                { type: 'd', data: 'name', filter: true, sortable: true },
                                                {
                                                    type: 'btt',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector1.setState({ selected: raw.name, visible: false });
                                                        this.setState({ data: { ...this.state.data, productIdUUID: raw.idUUID } });
                                                    },
                                                },
                                            ]}
                                            labelwraper="p-col-2"
                                            inputtextwarper="p-col-5"
                                            disabled={this.state.mode}
                                            startV={this.state.bname}
                                        />
                                        {g_template_input(this, 'code', this.state.data.code, LS('verba'), this.state.mode, false, true, 'p-col', 'p-col-2')}
                                        {g_template_inputArea(this, 'description', this.state.data.description, LS('description'), this.state.mode, false, true, 'p-col-7', 'p-col-2', '', {
                                            minHeight: '7em',
                                        })}
                                        {g_inputTemplate(
                                            'movementLayout',
                                            this.state.data.movementLayout,
                                            LS('movementLayout'),
                                            this,
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col',
                                            'p-col-2'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.consignatorStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? `save` : `create`), ``, this.state.data.status === 3, null, this.state.mode, 'p-col-2')}
                                {g_dialogTemplate(
                                    LS(`ask${this.state.data.status === '1' ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'a1',
                                    this,
                                    this.updateStatusClick,
                                    LS(this.state.data.status === '1' ? 'deactivate' : 'activate', 'btt'),
                                    'Sim',
                                    'Não',
                                    '',
                                    '',
                                    !this.state.idUUID || this.state.data.status === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                                {g_dialogTemplate(
                                    LS('askBlockP', 'messages'),
                                    'a2',
                                    this,
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    'Sim',
                                    'Não',
                                    '',
                                    'p-button-danger',
                                    !this.state.idUUID || this.state.data.status === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                    ])}
                </div>
            );
        }, false);
    }
}
