import axios from 'axios';
import '../App.scss';
import Config from '../config/config';

class AxiosService{
    constructor(){
        const loggedUserN = JSON.parse(localStorage.getItem('loggedUserN')) || {}

        axios.interceptors.response.use(undefined, async err => {
            if(err && err.response){
                if(err.response.status === 401){
                    localStorage.removeItem('loggedUserN')
                    window.location = '#/login'
                    document.location.reload()
                }
            }
        })

        axios.defaults.baseURL = Config.API_URL
        axios.defaults.headers = {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "token": loggedUserN.token
        }
    }

    async request (method, url, data = null, headerParams = null) {
        let headers = {...axios.defaults.header, ...headerParams}
        const storage = await JSON.parse(localStorage.getItem('loggedUserN'))
        
        headers.token = storage ? storage.token : Config.TOKEN;

        const config = {
            method,
            url,
            data,
            headers
        }
        let res = null
        try{
            res = await axios(config)
            return res.data
        } catch(err) {
            console.log(res);
            console.log(err);
        }

    }
}

export default new AxiosService()
