import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { g_checkLoginACL, g_displayMessageError, g_getStateIcon, g_handleChange, g_template_button } from '../GenericFunctions';

export default class Chat extends React.Component {

    constructor(props) { super(props);
        this.state = {
            message: { text: '' },
            interval: null,
        };
    }

    componentDidMount() {
        this.getData();
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(this.getData, 30000);
    }

    componentWillUnmount() { if (this.interval)clearInterval(this.interval); }
    
    getData = async () => {
        let apiRequest = this.props.apiRequestList ? this.props.apiRequestList : () => {console.log('missing apiRequestAdd');return null;};
        let apiRequestRead = this.props.apiRequestRead ? this.props.apiRequestRead : () => {console.log('missing apiRequestRead');return null;};
        let data = { pageNumber: 1, pageSize: 200, orderBy: [{key:'dateCreation', value:'desc'}], };
        await apiRequestRead();
        let request = await apiRequest(data); 
        if (g_displayMessageError(this.props.context.messages, request)) return; 
        let rdata = [...request.chatMessages].reverse();
        let idfuuid = rdata[rdata.length - 1]?.idUUID;
        if (idfuuid === this.state.lastIdUUID) return;
        let getBorder = (v, i) => {
            if (i - 1 >= 0 && rdata[i - 1].messageFlow === v.messageFlow && rdata.length > i + 1 && rdata[i + 1].messageFlow === v.messageFlow) return '05px';
            if (i - 1 >= 0 && rdata[i - 1].messageFlow !== v.messageFlow && rdata.length > i + 1 && rdata[i + 1].messageFlow === v.messageFlow) return '25px 25px 05px 05px';
            if (rdata.length > i + 1 && rdata[i + 1].messageFlow === v.messageFlow && i === 0) return '25px 25px 05px 05px';
            if (i - 1 >= 0 && rdata[i - 1].messageFlow === v.messageFlow) return v.messageFlow === 1 ? '05px 05px 25px 05px' : '05px 05px 05px 25px';
            return v.messageFlow === 1 ? '25px 25px 25px 05px' : '25px 25px 05px 25px';
        };
        rdata = rdata.map((me, i) => [
            <tr key={me.idUUID}>
              {me.messageFlow !== 1 ? <td style={{width: '50%'}}></td> :null}
              <td key={me.idUUID} style={{width: '50%', margin: '0px'}}>
                <div style={{ paddingBottom: '5px', paddingTop: '5px', paddingLeft: '20px', float: me.messageFlow !== 1 ? 'right' : 'left', width: '80%', background: me.messageFlow !== 1 ? 'rgb(153, 191, 221)' : '#ffffff', borderRadius: getBorder(me, i)}}>
                  <b>{me.messageFlow !== 1 ? me.operatorEntityName : me.mainEntityName}</b>
                  <span style={{ float: 'right', marginRight: '25px'}}>{g_getStateIcon(me.messageRead, '1.2em', {0: {class: 'pi-check', color: 'grey'}, 1: {class: 'pi-check', color: 'green'}})}</span>
                  {me.messageType !== 2 ?
                   <p style={{margin: '0px', marginLeft: '.5em', marginRight: '.5em',  marginTop: '.5em', padding: '0px'}}>{me.messageContent.split(/\n/).map(v => [v.replace(/\*->\*/g, '\u2192 ' ).split(/\*([\w ]+)\*/).map((v, i) => i % 2 !== 0 ? <b key={v.idUUID+v+'bold'}>{v}</b> : v), <br key={v.idUUID+v}/>])}</p> :
                   <p style={{margin: '0px', marginLeft: '.5em', marginRight: '.5em',  marginTop: '.5em', padding: '0px'}}><div onClick={() => this.props.apiRequestOpenA ? this.props.apiRequestOpenA(me.filename) : () => console.log('Open Content Missing')}>{me.filenameOriginal}<span className="pi pi-paperclip"/></div></p>
                  }
                </div>
              </td>
            </tr>,
            <tr key={me.idUUID+"spacer"}style={{height: '5px'}}></tr>
        ]);
        this.setState({
            data: rdata,
            lastIdUUID: idfuuid,
        });
        let b = document.getElementById('chatBody');
        b.scrollTop = b.scrollHeight;
    }
    
    onSubmit = async e => {
        e.preventDefault();
        let apiRequest = this.props.apiRequestAdd ? this.props.apiRequestAdd : () => {console.log('missing apiRequestAdd');return null;};
        let request = await apiRequest(this.state.message.text); 
        if (g_displayMessageError(this.props.context.messages, request)) return;
        this.getData();
        this.setState({message: {text: ''}});
    }

    grender = e => {
        if (!this.state.mode && e.u !== 1) this.setState({mode: true});
        console.log(e);
        return (
            <div className="p-grid p-fluid p-col-12" style={{height: '87vh'}}>
              <div className="p-col-12" style={{height: '100%'}}>
                <div id="chatBody" style={{border: "1px solid grey", background:"#e5e3e3",height:'90%', width:'100%', overflowY: 'auto'}}>
                  <table style={{background:"#e5e3e3"}} width='100%'>
                    <tbody>
                      {this.state.data}
                    </tbody>
                  </table>
                </div>
                <form onSubmit={this.state.mode ? () => {} : this.onSubmit}>
                  <div className="p-grid p-fluid p-col-12 p-justify-end" style={{marginTop: '10px'}}>
                    <div className="p-col-6"><InputTextarea style={{borderRadius: '10px'}} rows={1} cols={30} value={this.state.message.text} id="text" onChange={e => g_handleChange(e, {data: 'message'}, this)} autoResize={true} disabled={this.state.mode} /></div>
                    {g_template_button('Enviar', '', 0, null, this.state.mode, 'p-col-1')}
                  </div>
                </form>
              </div>
            </div>);
    }
    
    render () { return g_checkLoginACL(this.grender, true, this.props.fakename);}
    
}
