import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ConfigService from '../../service/ConfigService';
import {
    LS,
    ls,
    g_buttonTemplate,
    g_checkLoginACL,
    g_dialogTemplate,
    g_displayMessageError,
    g_handleChange,
    g_inputAreaTemplate,
    g_inputTemplate,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
} from '../GenericFunctions';

export default class Multilanguage extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                id: 0,
                tag: '',
                value: '',
            },
            items: [{ label: LS('multilanguage', 'tab') }],
            id: null,
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ id: id, mode: mode }, this.getData);
    }

    getData = async () => {
        if (!this.state.id) return;
        let data = {
            filters: [{ key: 'id', value: this.state.id }],
            pageSize: 1,
            pageNumber: 1,
        };
        let response = await ConfigService.multilanguageList(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.languages[0]) {
            g_nullarrayresponse(this);
            return;
        }
        this.setState({ data: response.languages[0] });
    };

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.id) {
            let response = await ConfigService.multilanguageUpdate({ language: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.getData();
        } else {
            let response = await ConfigService.multilanguageAdd({ language: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.setState({ id: response.language.id }, this.getData);
        }
    };

    deleteM = async e => {
        e.preventDefault();
        if (!this.state.id) return;
        let response = await ConfigService.multilanguageDelete(this.state.id);
        if (g_displayMessageError(this.messages, response)) return;
        window.close();
    };

    validateJSON = () => {
        try {
            JSON.parse(this.state.data.value);
        } catch (e) {
            return false;
        }
        return true;
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        {g_inputTemplate(`tag`, this.state.data.tag, LS('tag'), this, true, this.state.mode, false, g_handleChange, {}, ``, `p-col-6`, `p-col-2`)}
                                        {g_inputAreaTemplate(`value`, this.state.data.value, LS('value'), this, true, this.state.mode, false, g_handleChange, {}, false, `p-col-9`, `p-col-9`, {
                                            minHeight: '10em',
                                        })}
                                        <div style={{ fontWeight: 'bold', fontSize: '2em', color: 'red' }} hidden={!(this.state.data.tag.endsWith('JSON') && !this.validateJSON())}>
                                            {ls('invalidJSON', 'text')}
                                        </div>
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_buttonTemplate(
                                    LS(this.state.id ? 'save' : 'create', 'btt'),
                                    '',
                                    ``,
                                    false,
                                    null,
                                    this.state.mode || (this.state.data.tag.endsWith('JSON') && !this.validateJSON()),
                                    `p-col-2`
                                )}
                                {g_dialogTemplate(
                                    LS('askBlockP', 'messages'),
                                    'delete',
                                    this,
                                    this.deleteM,
                                    LS('block', 'btt'),
                                    LS('yes'),
                                    LS('no'),
                                    '',
                                    'p-button-danger',
                                    !this.state.id,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                    ])}
                </div>
            );
        });
    }
}
