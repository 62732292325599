import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import BatchService from '../../service/BatchService';
import {
    lt,
    LS,
    StateIcon,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_wraper,
    g_template_copy,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class ServiceBatch extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                batchServiceStatus: 0,
                batchServiceType: '',
                dateCreation: '',
                dateUpdate: '',
                description: '',
                folderIn: '',
                folderOut: '',
                folderProcessed: '',
                id: '',
                idUUID: '',
                interfaceName: '',
                logTickType: '',
                monitoringDateTick: '',
                monitoringLastExecution: '',
                monitoringStatus: '',
                name: '',
                scheduleStartDelay: '',
                scheduleType: '',
                scheduleValue: '',
                serviceId: '',
            },
            idUUID: null,
            mode: false,
            items: [{ label: lt('serviceBatch') }],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, mode: mode }, this.getData);
    }

    getData = async () => {
        if (this.state.idUUID === null) return;
        let data = {
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
            pageSize: 1,
            pageNumber: 1,
        };
        let response = await BatchService.serviceList(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.batchServices[0];
        rdata.batchServiceType = String(rdata.batchServiceType);
        rdata.scheduleType = String(rdata.scheduleType);
        let items = [{ label: lt('serviceBatch') }, { label: lt('log'), icon: 'pi pi-list' }];
        if (g_checkAlc(4, 'extrainfo')) items.push({ icon: 'pi pi-info' });
        this.setState({
            data: rdata,
            items: items,
            activeItem: null,
            mode: this.state.data.batchServiceStatus === 3 ? true : this.state.mode,
        });
    };

    updateStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            batchServiceStatus: this.state.data.batchServiceStatus === 1 ? 2 : 1,
            idUUID: this.state.data.idUUID,
        };
        let response = await BatchService.updateServiceStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            batchServiceStatus: 3,
            idUUID: this.state.data.idUUID,
        };
        let response = await BatchService.updateServiceStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleBlock', 'messages') });
        this.getData();
    };

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.idUUID !== null) {
            let data = { batchService: this.state.data };
            let response = await BatchService.serviceUpdate(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let data = { batchService: this.state.data };
            let response = await BatchService.serviceAdd(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.setState({ idUUID: response.batchService.idUUID }, this.getData);
        }
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        {g_template_input(this, 'name', this.state.data.name, LS('name'), this.state.mode, false, true, 'p-col-7')}
                                        {g_template_input(this, 'description', this.state.data.description, LS('description'), this.state.mode, false, true, 'p-col-9')}
                                        {g_template_dropdown(
                                            this,
                                            'batchServiceType',
                                            this.state.data.batchServiceType,
                                            LS('batchServiceType'),
                                            [
                                                { label: 'task', value: '1' },
                                                { label: 'file', value: '2' },
                                                { label: 'ftp', value: '3' },
                                            ],
                                            this.state.mode
                                        )}
                                        {g_template_input(
                                            this,
                                            'serviceId',
                                            this.state.data.serviceId,
                                            LS('serviceId'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-2',
                                            'p-col-3',
                                            '0 aplica-se a todos, maior que 0 aplica-se a um serviço específico',
                                            { regex: '\\d*' }
                                        )}
                                        {g_template_input(this, 'folderIn', this.state.data.folderIn, LS('folderIn'), this.state.mode, false, false, 'p-col-9')}
                                        {g_template_input(this, 'folderOut', this.state.data.folderOut, LS('folderOut'), this.state.mode, false, false, 'p-col-9')}
                                        {g_template_input(this, 'folderProcessed', this.state.data.folderProcessed, LS('folderProcessed'), this.state.mode, false, false, 'p-col-9')}
                                        {g_template_input(this, 'interfaceName', this.state.data.interfaceName, LS('interfaceName'), this.state.mode, false, true, 'p-col-9')}
                                        {g_template_input(
                                            this,
                                            'logTickType',
                                            this.state.data.logTickType,
                                            LS('logTickType'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-2',
                                            'p-col-3',
                                            '0 - não log, 1 - log tudo, > 2 log de x em x',
                                            { regex: '\\d*' }
                                        )}
                                        {g_template_input(
                                            this,
                                            'scheduleStartDelay',
                                            this.state.data.scheduleStartDelay,
                                            LS('scheduleStartDelay'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-2',
                                            'p-col-3',
                                            'ms',
                                            { regex: '\\d{0,9}' }
                                        )}
                                        {g_template_dropdown(
                                            this,
                                            'scheduleType',
                                            this.state.data.scheduleType,
                                            LS('scheduleType'),
                                            [
                                                { label: LS('1', 'scheduleTypes'), value: '1' },
                                                { label: LS('1', 'scheduleTypes'), value: '2' },
                                                { label: LS('1', 'scheduleTypes'), value: '3' },
                                                { label: LS('4', 'scheduleTypes'), value: '4' },
                                            ],
                                            this.state.mode
                                        )}
                                        {g_template_input(this, 'scheduleValue', this.state.data.scheduleValue, LS('scheduleValue'), this.state.mode, false, true, 'p-col-2')}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_wraper(
                                            null,
                                            LS('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.batchServiceStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_wraper(
                                            null,
                                            LS('monitoringStatus'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.monitoringStatus}
                                                    small
                                                    custom={{ 0: { class: 'pi-circle-on', color: 'red' }, 1: { class: 'pi-check', color: 'green' }, 2: { class: 'pi-ban', color: 'red' } }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                        {g_wraper(null, LS('monitoringLastExecution'), g_getDateTemplate(this.state.data.monitoringLastExecution), !this.state.idUUID, 'p-col')}
                                        {g_wraper(null, LS('monitoringDateTick'), g_getDateTemplate(this.state.data.monitoringDateTick), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.batchServiceStatus === 3, null, 0, 'p-col-1')}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.batchServiceStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    'batchServiceStatus',
                                    this.updateStatus,
                                    LS(this.state.data.batchServiceStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                    !this.state.idUUID || this.state.data.batchServiceStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlockP', 'messages'),
                                    'batchServiceStatusBloquear',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    !this.state.idUUID || this.state.data.batchServiceStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                        <Log idUUID={this.state.idUUID} logContextType={13} tid={true} />,
                        ...(g_checkAlc(4, 'extrainfo')
                            ? [
                                  <div className="p-col">
                                      <div className="card p-grid p-fluid">
                                          <div className="p-col-12 p-md-8 p-lg-8 p-fluid contact-form">
                                              <Panel>
                                                  {g_template_copy(this, 'UUID', this.state.data.idUUID, 'idUUID', 0, 'p-col-4', 'p-col-2')}
                                                  {g_template_copy(this, 'ID', this.state.data.id, 'id', 0, 'p-col-4', 'p-col-2')}
                                              </Panel>
                                          </div>
                                      </div>
                                  </div>,
                              ]
                            : []),
                    ])}
                </div>
            );
        });
    }
}
