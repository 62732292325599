import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import NotificationService from '../../service/NotificationService';
import { pair, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class Events extends Component {
    constructor() {
        super();
        this.state = {
            ...g_genericRequired([{ key: 'destinationName', value: 'desc' }]),
            idUUID: null,
        };
    }

    componentDidMount() {
        g_hideSearch();
        this.setState({ idUUID: this.props.idUUID }, this.getData);
    }

    getData = () =>
        this.state.idUUID ? g_getData(this, NotificationService.getNotificationEvents, 'notificationEvents', { filters: [...this.state.filter, pair('notificationIdUUID', this.state.idUUID)] }) : null;

    render() {
        return (
            <div className="p-col card">
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, 0, 0, false),
                    [
                        { type: 'd', data: 'destinationName', sortable: true, filter: true },
                        { type: 'date', data: 'dateViewed', sortable: true, filter: true },
                        { type: 'date', data: 'dateChecked', sortable: true, filter: true },
                        { type: 'states', data: 'viewed', states: { 0: { class: '', color: '' }, 1: { class: 'pi-check', color: 'green' } } },
                        { type: 'states', data: 'checked', states: { 0: { class: '', color: '' }, 1: { class: 'pi-check', color: 'green' } } },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
