import { LS, g_entities, g_providers, g_consignators, g_checkAlc } from '../components/GenericFunctions';
import ConfigService from '../service/ConfigService';

function createItem(icon, windowLocation = null, items = null, costumCommandfunction = null, additives = null, id = '', prefix = '', acl = null) {
    let label = id ? LS(id, prefix + 'menu') : LS(windowLocation, prefix + 'menu');
    icon = 'pi pi-fw pi-' + icon;
    if (items !== null) return { label: label, icon: icon, items: items };
    if (windowLocation !== null && costumCommandfunction !== null)
        return { label: label, icon: icon, windowLocation: windowLocation, acl: acl ?? windowLocation ?? '', command: costumCommandfunction };
    if (windowLocation !== null)
        return {
            label,
            icon,
            windowLocation,
            acl: acl ?? windowLocation ?? '',
            command: () => {
                window.location = '/#/' + windowLocation + (additives ?? '');
            },
        };
    return { label: 'erro na criação do menuItem!;' };
}

function createMenuItem(label, icon, windowLocation = null, items = null, costumCommandfunction = null, additives = null) {
    if (items !== null) return { label: label, icon: icon, items: items };
    if (windowLocation !== null && costumCommandfunction !== null) return { label: label, icon: icon, windowLocation: windowLocation, acl: windowLocation ?? '', command: costumCommandfunction };
    if (windowLocation !== null)
        return {
            label: label,
            icon: icon,
            windowLocation,
            acl: windowLocation ?? '',
            command: () => {
                window.location = '/#/' + windowLocation + (additives ? additives : '');
            },
        };
    return { label: 'erro na criação do menuItem!;' };
}

let menu = (otherDashboard, reportsMenu, configToAdd) => [
    otherDashboard,
    ...(g_consignators().split(',').length === 1 && g_consignators() ? [createItem('ticket', 'consignator', null, null, `?id=${g_entities().split(',')[0]}`)] : []),
    ...(g_checkAlc(5, 'consignator-management#accessall') ? [createItem('globe', 'consignator-management')] : []),
    ...(g_providers().split(',').length === 1 && g_providers() ? [createItem('ticket', 'provider', null, null, `?id=${g_entities().split(',')[0]}`)] : [createItem('mobile', 'provider-management')]),

    ...(g_providers() && g_providers().split(',').length === 1 ? [createItem('plus', 'freight')] : []),
    createItem(
        'shopping-cart',
        null,
        [
            createItem('id-card', 'freight-management', null, null, '?t=2', 'creation'),
            createItem('id-card', 'freight-management', null, null, '?t=4', 'aprove'),
            createItem('id-card', 'freight-management', null, null, '?t=3', 'bid'),
            createItem('id-card', 'freight-management', null, null, '?t=1', 'active'),
            createItem('id-card', 'freight-management', null, null, '', 'all'),
        ],
        null,
        null,
        g_providers() && g_providers().split(',').length === 1 ? 'myfreight' : 'freightL'
    ),

    createItem('list', 'contractTemplate-management'),
    createItem(
        'briefcase',
        null,
        [createItem('shopping-cart', 'services-management', null, null, '?t=1', 'active'), createItem('shopping-cart', 'services-management', null, null, '', 'all')],
        null,
        null,
        'services'
    ),
    createItem(
        'briefcase',
        null,
        [createItem('shopping-cart', 'requests-management', null, null, '', 'active'), createItem('shopping-cart', 'requests-management', null, null, '?t=1', 'byme')],
        null,
        null,
        'requests'
    ),
    createItem(
        'money-bill',
        null,
        [createItem('money-bill', 'account-info', null, null, null), createItem('shopping-cart', 'account-transfer', null, null, ''), createItem('shopping-cart', 'account-pay', null, null, '')],
        null,
        null,
        'account'
    ),

    g_consignators().split(',').length === 1 && g_consignators() && !g_checkAlc('consignator-management#accessall', 5)
        ? createItem('id-card', 'consignee-management', null, null, '')
        : createItem(
              'id-card',
              null,
              [
                  createItem('minus', 'consignee-management', null, null, '?=1', 'active'),
                  createItem('minus', 'consignee-management', null, null, '', 'all'),
                  createItem('minus', 'staging-consignee-management'),
              ],
              null,
              null,
              'driver'
          ),
    createItem('id-card', null, [createItem('minus', 'assets-management', null, null, '?t=1', 'active'), createItem('minus', 'assets-management', null, null, '', 'all')], null, null, 'assets'),

    createItem(
        'bell',
        null,
        [
            createItem('upload', 'notification-management'),
            createItem('mobile', 'notification-management', null, null, '?app=1', 'notification-management#1'),
            createItem('bell', 'groups', null, null, '?t=2'),
        ],
        null,
        null,
        'notification'
    ),

    createItem(
        'shopping-cart',
        null,
        [
            createItem('mobile', 'campaign-management'),
            createItem('id-card', 'product-management', null, null, '?mode=1', 'productServices'),
            createItem('id-card', 'product-management', null, null, '?mode=2', 'productProduct'),
            createItem('id-card', 'campaignproduct-management'),
            createItem('folder', 'packageProduct-management'),
        ],
        null,
        null,
        'marketplace'
    ),

    createItem(
        'minus',
        null,
        [
            createItem(
                'ticket',
                null,
                [createItem('circle-off', 'promotions-active'), createItem('clock', 'promotions-past'), createItem('dollar', 'promotions-prices')],
                null,
                null,
                'segmentPrmotions'
            ),
            createItem(
                'search',
                null,
                [
                    createItem('circle-off', 'searcharea-active'),
                    createItem('clock', 'searcharea-past'),
                    createItem('dollar', 'searcharea-prices'),
                    createItem('cog', 'searcharea-services'),
                    createItem('cog', 'searcharea-categories'),
                ],
                null,
                null,
                'segmentSearch'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=1&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=1&a=0', 'all')],
                null,
                null,
                'segmentNews'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=2&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=2&a=0', 'all')],
                null,
                null,
                'segmentInformation'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=3&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=3&a=0', 'all')],
                null,
                null,
                'segmentWitness'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=4&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=4&a=0', 'all')],
                null,
                null,
                'segmentServices'
            ),
        ],
        null,
        null,
        'cms'
    ),

    reportsMenu,
    createItem(
        'sliders-h',
        null,
        [
            createItem(
                'unlock',
                null,
                [
                    createItem('mobile', 'admin-user-management-app'),
                    createItem('user', 'admin-user-management'),
                    createItem('globe', 'admin-user-management-serv'),
                    createItem('key', null, [createItem('th-large', 'control-item-management'), createItem('tags', 'profile-management')], null, null, 'acl'),
                ],
                null,
                null,
                'access'
            ),
            createItem(
                'cog',
                null,
                [
                    createItem('question', 'help-management'),
                    createItem('inbox', 'cache-management'),
                    createItem('home', 'zipcode-management'),
                    createItem('globe', 'multilanguage-management'),
                    createItem('th-large', 'product-type-management'),
                    createItem('key', null, [...configToAdd], null, null, 'systemVars'),
                    createMenuItem('bars', 'service-batch-management'),
                ],
                null,
                null,
                'configuration'
            ),

            createItem('bars', 'service-batch-management'),
        ],
        null,
        null,
        'administraion'
    ),
];
export async function getSideBarMenu(storage) {
    let profile = JSON.parse(storage).profileACLs;
    let aclData = profile.filter(v => v.type === 4 && v.r === 1).map(v => v.tag);
    let dashboards = aclData.filter(v => v.startsWith('dashboard-'));
    let reports = aclData.filter(v => v.startsWith('report-'));
    let configToAdd = [],
        otherDashboard = {};
    //TODO API SIDE
    let replaceD = {
        'dashboard-admin': 'Administração',
        'dashboard-financier': 'Financeiro',
        'dashboard-backoffice': 'Backoffice',
        'dashboard-consignator': 'Empresa',
        'dashboard-icargo-admin': 'ICargo Administração',
        'dashboard-icargo-operation': 'ICargo Operação',
    };
    if (dashboards.length > 1) {
        otherDashboard = createMenuItem('Dashboards', 'pi pi-fw pi-chart-line', null, [], null);
        otherDashboard.skipsAcl = true;
        for (let i = 0; i < dashboards.length; i++) {
            otherDashboard.items.push(createMenuItem(replaceD[dashboards[i]] ? replaceD[dashboards[i]] : dashboards[i], 'pi pi-fw pi-minus', i === 0 ? '/' : dashboards[i]));
        }
    }
    if (!otherDashboard.label)
        otherDashboard = {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-chart-line',
            command: () => {
                window.location = '#/';
            },
            skipsAcl: true,
        };
    let reportsMenu = {};
    if (reports.length > 0) {
        reportsMenu = createMenuItem('Relatórios', 'pi pi-fw pi-clone', null, [], null);
        reportsMenu.skipsAcl = true;
        for (let i = 0; i < reports.length; i++) {
            if (LS(reports[i], 'reports') !== 'LabelInfenranceFailed') reportsMenu.items.push(createMenuItem(LS(reports[i], 'reports'), 'pi pi-fw pi-minus', reports[i]));
        }
    }
    if (aclData.indexOf('config-contex-management') !== -1) {
        let response = await ConfigService.getConfigContextsList({ filters: [], orderBy: [{ key: 'description', value: 'asc' }] });
        if (response && response.code === 0) {
            let f = response.configContexts;
            for (let i = 0; i < f.length; i++) {
                configToAdd.push(createMenuItem(f[i].id, 'pi pi-fw pi-minus', 'config-contex-management', null, e => (window.location = '#/config-contex-management?id=' + e.item.label)));
            }
        }
    }
    //SelectMenu
    return menu(otherDashboard, reportsMenu, configToAdd);
}
