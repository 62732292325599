import axios from './AxiosService'

class UtilsService {
    
    getContactRelationshipTypes = data => axios.request('POST', 'utils/contactRelationshipTypes', data);

    getGender = data => axios.request('POST','/utils/genderTypes', data);

    async getMaritalStatus(data) {
        return await axios.request('POST','/utils/civilStatus', data);  
    }

    async getHabilitation(data){
        return await axios.request('POST','/utils/hablitations', data);
    }

    async getEntityType(data){
        return await axios.request('POST',`/utils/entityTypes`, data);
    }

    async getDocumentType(data){
        return await axios.request('POST',"/utils/documentTypes", data);
    }

    async zipCodeList(data){
        return await axios.request('POST',"/utils/zipCode/list", data);
    }

    async zipCodeAdd(data){
        return await axios.request('POST',"/utils/zipCode/add", data);
    }

    async zipCodeUpdate(data){
        return await axios.request('POST',"/utils/zipCode/update", data);
    }

    async getTimestamp(){
        return await axios.request('GET',"/utils/timestamp");
    }

    async getDocuments(data,service){
        return await axios.request('POST', `/${service}/document/list`, data);
    }

    async documentUpload(formData, documentTypeId, UUID,service){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/${service}/document/upload/${documentTypeId}/${UUID}`, formData, config)
    }


}

export default new UtilsService();
