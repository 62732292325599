import axios from './AxiosService';
import {g_getData, g_entityFilter} from '../components/GenericFunctions';

class ConsignatorService {
   
    processed = {
        list : (context, other={}) => g_getData(context, data => {
            let f = g_entityFilter(data.filters, 'idUUID', 'idUUIDs', 5, 'consignator-management#accessall');
            if (!f) return {code: 0, pageTotal: 0, recordsTotal: 0, consignators: []};
            data.filters = f[0];
            return this.listConsignator(data);
        }, 'consignators', other)
    };

    async listConsignator(data){return await axios.request('POST', `/consignator/list`, data);}

    async createConsignator(data){return await axios.request('POST', `/consignator/add`, data);}

    async getPullModes(data){return await axios.request('POST', `/consignator/consignatorPullModes`, data);}

    async updateConsignator(data){return await axios.request('PUT', `/consignator/update`, data);}

    async getLogs(data){return await axios.request('POST', `/consignator/log/list`, data);}

    async updateStatus(data){return await axios.request('PUT', `/consignator/update/status`, data);}

    async documentUpload(formData, documentTypeId, userUUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };
        return await axios.request('POST', `/consignator/document/upload/${documentTypeId}/${userUUID}`, formData, config);
    }

    //BUDGET

    async payrollBudgetAdd(data){return await axios.request('POST', `/consignator/payroll/budget/add`, data);}

    async payrollBudgetUpdate(data){return await axios.request('PUT', `/consignator/payroll/budget/update`, data);}

    async payrollBudgetList(data){return await axios.request('POST', `/consignator/payroll/budget/list`, data);}

    async payrollBudgetUpdateStatus(data){return await axios.request('PUT', `/consignator/payroll/budget/updateStatus`, data);}

    //Clocking
    
    async clockingAdd(data){return await axios.request('POST', `/consignator/clocking/location/add`, data);}

    async clockingUpdate(data){return await axios.request('PUT', `/consignator/clocking/location/update`, data);}

    async clockingList(data){return await axios.request('POST', `/consignator/clocking/location/list`, data);}

    async clockingGet(idUUID){return await axios.request('GET', `/consignator/clocking/location/${idUUID}`);}

    async clockingUpdateStatus(data){return await axios.request('PUT', `/consignator/clocking/location/updateStatus`, data);}

    //Parameters

    async parameterList(data){ return await axios.request('POST', `/consignee/parameter/list`, data) }
    async parameterPut(data){ return await axios.request('POST', `/consignee/parameter/put`, data) }
}

export default new ConsignatorService();
