import { Panel } from 'primereact/panel';
import React from 'react';
import ProfileService from '../../service/ProfileService';
import { LS, g_template_input, g_checkLoginACL, g_displayMessageError, g_tabViewRenderOptions, g_template_button, g_openWindowDialog } from '../GenericFunctions';

export default class CopyTemplate extends React.Component {
    constructor() {
        super();
        this.state = { description: '' };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1' || !idUUID;
        this.setState({ mode: mode, idUUID: idUUID }, this.getData);
    }

    handleSubmit = async event => {
        event.preventDefault();
        if (!this.state.idUUID || !this.state.description) return;
        let response = await ProfileService.copy({originProfileIdUUID: this.state.idUUID, description: this.state.description});
        if (g_displayMessageError(this.messages, response)) return;
        g_openWindowDialog(`#/profile?type=1&id=${response.profile?.idUUID}`, response.profile?.idUUID, 1200, 700);
        window.close();
    }

    render() {
        return g_checkLoginACL(e => {
        if (!this.state.mode && ( ( !e.c && this.state.idUUID === null) || ( !e.u && this.state.idUUID !== null))) this.setState({mode: true});
        return (
           <div className="card card-w-title">
             {g_tabViewRenderOptions(this, [
                 <form className='p-col' onSubmit={this.handleSubmit}>
                   <div className="p-grid p-fluid">
                     <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                       <Panel style={{ border: 'none !important', height: '80vh' }}>
                           {g_template_input(this, 'description', this.state.description, LS('description'), this.state.mode, false, true, 'p-col-7', 'p-col-2', '', {data: 'description'} )}
                       </Panel>
                     </div>
                   </div>
                     <div className="p-grid p-fluid"> {g_template_button(LS('copy', 'btt'), '', 0, null, this.state.mode, 'p-col-3')} </div>
                 </form>
               ])}
           </div>
        );
    });
    }

}
