import axios from './AxiosService';

class UtilsService {

    async list(data) { return await axios.request('POST','/voucher/list ', data); }
    async add(data) { return await axios.request('POST','/voucher/add ', data); }

    async documentUpload(formData, voucherType, consignatorIdUUID, productIdUUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

            return await axios.request('POST', `/voucher/upload/a3777dcc-a1da-00f0-5476-94b1c2d76291/${voucherType}/${consignatorIdUUID}/${productIdUUID} `, formData, config);
    }

}

export default new UtilsService();
