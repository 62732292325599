import { g_ChangeStatus } from '../components/GenericFunctions';
import axios from './AxiosService';

class SearchSegmentService {
    list = data => axios.request('POST', `/search/list`, data);
    updateContent = data => axios.request('PUT', '/search/update/content', data);
    add = data => axios.request('POST', '/search/add', data);
    update = data => axios.request('PUT', '/search/update', data);
    getSearchTypes = data => axios.request('POST', `/search/types`, data);
    getSearchCategories = data => axios.request('POST', `/search/categories`, data);
    getSearchImages = data => axios.request('POST', `/search/image/list`, data);
    deleteSearchImage = imageIdUUID => axios.request('DELETE', `/search/image/${imageIdUUID}`, '');
    updatePrice = data => axios.request('PUT', `/search/simulation/price`, data);
    updateStatusRaw = data => axios.request('PUT', `/search/update/status`, data);
    updateStatus = (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
        g_ChangeStatus(context, () => this.updateStatusRaw({ idUUID: idUUID, searchAreaStatus: status }), callback, message, messages, e);
    imageUpload = (formData, documentTypeId, productUUID) => {
        const config = { 'content-type': 'multipart/form-data', 'background-request': true };
        return axios.request('POST', `/search/image/upload/${documentTypeId}/${productUUID}`, formData, config);
    };

    serviceTypes = data => axios.request('POST', `/search/serviceTypes`, data);

    serviceType = {
        list: data => axios.request('POST', `/search/serviceTypes`, data),
        put: data => axios.request('POST', `/search/serviceType/put`, data),
        delete: id => axios.request('DELETE', `/search/serviceType/${id}`, {}),
    };

    category = {
        list: data => axios.request('POST', `/search/categories`, data),
        put: data => axios.request('POST', `/search/category/put`, data),
        delete: id => axios.request('DELETE', `/search/category/${id}`, {}),
        servicetype: {
            delete: (id, service) => axios.request('DELETE', `/search/category/${id}/${service}`, {}),
            list: data => axios.request('POST', `/search/categories/servicetypes`, data),
            add: data => axios.request('POST', `/search/category/serviceType/add`, data),
        },
    };
}

export default new SearchSegmentService();
