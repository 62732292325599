import React from 'react';
import { pair, LS, g_getDataFields, g_ApiContact, g_checkLoginACL, g_displayMessageError, g_tabViewRenderOptions, g_template_form } from '../GenericFunctions';
import UtilsService from '../../service/UtilsService';
import ConsigneeService from '../../service/ConsigneeService';

export default class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiContact(),
            idUUID: '',
            items: [{ label: 'Contato' }],
            contactType: [
                { value: '1', label: LS('1') },
                { value: '2', label: LS('2') },
                { value: '3', label: LS('3') },
                { value: '4', label: LS('4') },
                { value: '5', label: LS('5') },
                { value: '6', label: LS('6') },
            ],
            contactRelationships: [],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        g_getDataFields(this, UtilsService.getContactRelationshipTypes, 'contactRelationships', 'description', 'id', 'contactRelationships');
        this.setState({ idUUID: query.get('id'), cid: query.get('cid'), mode: query.get('type') !== '1', items: [{ label: 'contacto' }] }, this.getData);
    }

    async getData() {
        if (!this.state.idUUID) return;
        let response = await ConsigneeService.contactList({ filters: [pair('idUUID', this.state.idUUID)] });
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.contacts[0]) return;
        let rdata = response.contacts[0];
        rdata.contactRelationshipType = rdata.contactRelationshipType ? String(rdata.contactRelationshipType) : '';
        rdata.contactType = rdata.contactType ? String(rdata.contactType) : '';
        this.setState({ data: rdata, addresses: rdata.address });
    }

    submitForm = async e => {
        if (e) e.preventDefault();
        let data = this.state.data;
        if (this.state.idUUID) {
            let response = await ConsigneeService.contactUpdate({ contact: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.getData();
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            return;
        }
        if (!this.state.cid) return;
        data.refereceEntityIdUUID = this.state.cid;
        let response = await ConsigneeService.contactAdd({ contact: data });
        if (g_displayMessageError(this.messages, response)) return;
        window.location.href = `/#/contact?type=1&id=${response.contact.idUUID}`;
        window.location.reload();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                {
                                    t: 'array',
                                    dTypeF: 't',
                                    filter: ['contactType', 'contactRelationshipType', 'contact', 'contactRelationshipName', 'principal'],
                                    options: [this.state.contactType, this.state.contactRelationships],
                                    dTypeFs: { 0: 'drop', 1: 'drop', 4: 'check' },
                                    cc: { _: '4' },
                                    c: { 3: ['0', '1'].indexOf(this.state.data.contactRelationshipType) === -1 },
                                },
                            ],
                            mode => [{ t: 'b', click: null, c: mode, l: LS(this.state.idUUID ? 'save' : 'create'), class: 1 }],
                            () => [
                                [
                                    {
                                        cg: !this.state.idUUID,
                                        t: 'array',
                                        origin: this.state.data,
                                        filter: ['contactStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        class: 'l',
                                        states: { 0: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'Red', class: 'pi-ban' } } },
                                    },
                                ],
                            ],
                            this.submitForm,
                            2,
                            this.state.idUUID,
                            this.state.mode,
                            8,
                            4
                        ),
                    ])}
                </div>
            );
        });
    }
}
