import React from 'react';
import S from '../../service/Services';
import { LabelSelector as LS, g_getData, ListPage, g_entityFilter, g_consignators, g_providers, g_pair } from '../GenericFunctions';
import { states } from './freight';
import { g_getHeaderTemplateNull, TablePage } from '../generic/DataTable';

export const url = raw => `#/freight?type=1&id=${raw.idUUID}`;

const table = (context, e) => [
    { type: 'd', data: 'obs', filter: true, sortable: true },
    { type: 'd', data: 'consignatorName', sortable: true, filter: true },
    { type: 'd', data: 'providerName', sortable: true, filter: true },
    { type: 'date', data: 'dateCreation', sortable: true, filter: true },
    { type: 'state', data: 'contractStatus', states, sortable: true, filter: true },
    { type: 'url', url: url, width: 1400, height: 900, callback: context.getData, c: e.u === 1 || e.d === 1 },
];

const header = c => g_getHeaderTemplateNull(c, c.getData); // g_getHeaderTemplate(context, '/#/freight?type=1', 'new noticias', context.getData, 1400, 900, e.c && g_providers() && g_providers().split(",").length === 1)
const getData = (context, filter = []) =>
    g_getData(
        context,
        data => {
            let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess', g_consignators());
            let f1 = g_entityFilter(data.filters, 'providerIdUUID', 'providersIdUUID', 5, 'contract-management#totalaccess', g_providers());
            if (!f && !f1) return { code: 0, pageTotal: 0, recordsTotal: 0 };
            data.filters = [g_pair('joinType', '2')];
            if (f) data.filters = [...data.filters, ...f[0]];
            if (f1) data.filters = [...data.filters, ...f1[0]];
            return S.contract.list(data);
        },
        'contracts',
        { filters: [...filter, { key: 'workflowType', value: 8 }, ...context.state.filter] }
    );

const getDataBid = context =>
    g_getData(
        context,
        data => {
            let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess', g_consignators());
            let f1 = g_entityFilter(data.filters, 'providerIdUUID', 'providersIdUUID', 5, 'contract-management#totalaccess', g_providers());
            if (!f && !f1) return { code: 0, pageTotal: 0, recordsTotal: 0 };
            data.filters = [g_pair('joinType', '2'), g_pair('contractStatus', 422), g_pair('workflowType', 8), g_pair('countLicitations', 1)];
            if (f) data.filters = [...data.filters, ...f[0]];
            if (f1) data.filters = [...data.filters, ...f1[0]];
            return S.icargo.cargo.list(data);
        },
        'cargos'
    );

const bidFreightList = (
    <TablePage
        title={LS('bid', 'titles')}
        header={header}
        table={(context, e) => [
            { type: 'd', data: 'obs', filter: true, sortable: true },
            { type: 'd', data: 'licitationsCounter', filter: true, sortable: true },
            { type: 'di', di: raw => raw?.cargoAddress?.originAddress?.city ?? '', data: 'originAddress' },
            { type: 'di', di: raw => raw?.cargoAddress?.destinationAddress?.city ?? '', data: 'destinationAddress' },
            { type: 'date', ddi: raw => raw?.cargoPickUpData?.dateDeliveryLimit ?? '', title: LS('cargo_payment_flow_delivery') },
            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
            { type: 'state', data: 'contractStatus', states, sortable: true, filter: true },
            { type: 'url', url: url, width: 1400, height: 900, callback: context.getData, c: e.u === 1 || e.d === 1 },
        ]}
        getData={getDataBid}
        rg={[g_pair('dateUpdate', 'desc')]}
    />
);

export const freightList = t =>
    t === '3' ? (
        bidFreightList
    ) : (
        <ListPage
            title={t === '1' ? LS('active', 'titles') : t === '2' ? LS('creation', 'titles') : t === '4' ? LS('aproveICargo', 'titles') : LS('all', 'titles')}
            header={header}
            table={table}
            getData={context =>
                getData(
                    context,
                    t === '1'
                        ? [{ key: 'contractStatus', value: '422, 423, 424, 425, 426' }]
                        : t === '2'
                        ? [{ key: 'contractStatus', value: '400, 401, 402, 403, 404, 405, 406, 407, 408, 420' }]
                        : t === '4'
                        ? [{ key: 'contractStatus', value: '421' }]
                        : []
                )
            }
            rg={[g_pair('dateUpdate', 'desc')]}
            update={c => {
                if (c.state.oldt !== t) c.setState({ oldt: t, pageNumber: 1 }, c.getData);
            }}
        />
    );
