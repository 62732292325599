import React from 'react';
import { Messages } from 'primereact/messages';
import { S, LS, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import * as UA from '../appUsers/UserApp';

export default class UserManagementServ extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }

    componentDidMount() {
        this.getData();
        g_hideSearch();
    }

    getData = () => g_getData(this, S.user.list, 'users', { userType: 2 });

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('userserv', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/user-serv?type=1', 'addUserServ', this.getData, 1000, 400, e.c === 1),
                    [
                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                        { type: 'd', data: 'username', sortable: true, filter: true },
                        { type: 'd', data: 'email', sortable: true, filter: true },
                        { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                        { type: 'date', data: 'dateLastLogin', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'userStatus', states: UA.states },
                        { type: 'url', c: e.d === 1 || e.c === 1, url: raw => `#/user-serv?type=1&id=${raw.idUUID}`, width: 1000, height: 400, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
