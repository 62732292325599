import axios from './AxiosService';

class ConfigService {
    async getConfigContextsList(filter) {
        return await axios.request('POST', `/config/configContexts/list`, filter);
    }

    async getConfigList(data) {
        return await axios.request('POST', `/config/list`, data);
    }

    async add(data) {
        return await axios.request('POST', `/config/add`, data);
    }

    async update(data) {
        return await axios.request('PUT', `/config/update`, data);
    }

    async deleteConfig(idUUID) {
        return await axios.request('DELETE', `/config/${idUUID}`);
    }

    async multilanguageList(data) {
        return await axios.request('POST', `/config/language/list`, data);
    }

    async multilanguageAdd(data) {
        return await axios.request('POST', `/config/language/add`, data);
    }

    async multilanguageUpdate(data) {
        return await axios.request('PUT', `/config/language/update`, data);
    }

    async multilanguageDelete(idUUID) {
        return await axios.request('DELETE', `/config/language/${idUUID}`);
    }

    async getConfigLogList(data) {
        return await axios.request('POST', `/config/log/list`, data);
    }

    async cacheList(data) {
        return await axios.request('POST', `/config/cachemanager/list`, data);
    }

    async cacheAdd(data) {
        return await axios.request('POST', `/config/cachemanager/add`, data);
    }

    async cacheUpdate(data) {
        return await axios.request('PUT', `/config/cachemanager/update`, data);
    }
}

export default new ConfigService();
