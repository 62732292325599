import React, { Component } from 'react';
import { S, LS, g_getData, g_hideSearch, g_displayMessageError, g_genericRequired, g_checkLoginACL, g_template_dataTable, g_getHeaderTemplatecF } from '../GenericFunctions';
import CampaignService from '../../service/CampaignService';
import CampaignProductsList from './CampainProductsList';

export default class CampaignProductsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getProducts = c => g_getData(c, S.product.list, 'products');

    getData = async () => {
        if (!this.props.context.state.idUUID) return;
        let filters = {
            campaignIdUUID: this.props.context.state.idUUID,
            filters: this.state.filter,
            orderBy: this.state.orderBy,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
        };
        let response = await CampaignService.getCampaignItemList(filters);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal,
            data: response.campaignItens,
        });
    };

    addProductAssotiation = async (context, pId) => {
        const item = { campaignItem: { campaignIdUUID: this.props.context.state.idUUID, productIdUUID: pId.idUUID } };
        var response = await CampaignService.addCampaignItem(item);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.props.context.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
        context.props.getData(context);
    };

    deleteClick = async data => {
        let response = await CampaignService.removeCampaignItem({}, data.campaignItemIdUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.props.context.messages.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(
            () => (
                <div className="card p-col-12">
                    <CampaignProductsList close={this.getData} add={this.addProductAssotiation} getData={this.getProducts} ref={e => (this.productsSelector = e)} />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(
                            this,
                            () => {
                                this.productsSelector.setState({ visible: true });
                                this.productsSelector.props.getData(this.productsSelector);
                            },
                            this.getData
                        ),
                        [
                            { type: 'd', data: 'name', sortable: true, filter: true },
                            { type: 'd', data: 'shortDescription', sortable: true, filter: true },
                            { type: 'd', data: 'productTypeDescription', sortable: true, filter: true },
                            { type: 'btt', icon: 'pi pi-times', tooltip: LS('remove', 'btt'), extra: 'p-button-secondary', click: this.deleteClick },
                        ],
                        this.getData
                    )}
                </div>
            ),
            false,
            'campaign#productManagement'
        );
    }
}
