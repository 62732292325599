import axios from './AxiosService';

class BatchService {

    async batchList(data){return await axios.request('POST', `/batch/list`, data); }

    add = data => axios.request('POST', `/batch/add`, data);
    batchAdd = data => axios.request('POST', `/batch/add`, data);

    async logList(data){
        return await axios.request('PUT', `/batch/log/list`, data)
    }

    async getLogsProcessed(data){
        return await axios.request('POST', `/batch/log/process/list`, data)
    }

    async logServiceList(data){
        return await axios.request('POST', `/batch/service/log/list`, data)
    }

    async serviceList(data){
        return await axios.request('POST', `/batch/service/list`, data)
    }

    async serviceUpdate(data){
        return await axios.request('POST', `/batch/service/update`, data)
    }

    async serviceAdd(data){
        return await axios.request('POST', `/batch/service/add`, data)
    }

    async updateStatus(data){
        return await axios.request('PUT', `/user/update/status`, data)
    }

    async updateServiceStatus(data){
        return await axios.request('PUT', `/batch/service/update/status`, data)
    }

    async documentUpload(formData, serviceBatchIdUUID,referenceIDUUID, executeFillerA, executeFillerB, layoutId){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/batch/upload/${serviceBatchIdUUID}/${referenceIDUUID}/${executeFillerA}/${executeFillerB}/${layoutId}`, formData, config)
    }

    async getLogs(data){
        return await axios.request('POST', `/user/log/list`, data);
    }

}

export default new BatchService();
