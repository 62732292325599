import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Messages } from 'primereact/messages';
import { Redirect } from 'react-router-dom';
import UserService from '../../service/UserService';
import EncryptPassword from '../utils/EncryptPassword';
import { g_displayMessageError, ls } from '../GenericFunctions';
import './Login.css';

export default class Login extends Component {
    constructor() {
        super();
        this.state = {
            user: { username: '', password: '' },
            logged: localStorage.getItem('loggedUserN') ? true : false,
            redirect: false,
        };
        this.storage = {};
    }

    handleChange = event => {
        const user = { ...this.state.user };
        const { name, value } = event.target;
        user[name] = value;
        this.setState({ user });
    };

    handleSubmit = async event => {
        event.preventDefault();

        var ciphertext64 = await EncryptPassword.encrypt(this.state.user.password);

        const body = {
            userType: 1,
            tokenExpiration: 1440,
            username: this.state.user.username,
            password: ciphertext64,
        };
        const response = await UserService.login(body);

        if (response === undefined || response === null) {
            this.messages.show({ severity: 'error', summary: 'Erro ao acessar o servidor!' });
        } else if (response.code === 0) {
            let entities = '';
            let providers = '';
            let consignators = '';
            let financiers = '';
            response.user.associatedEntities.forEach(v => {
                entities += (entities !== '' ? ',' : '') + v.entityIdUUID;
                if (v.entityUserType === 1) consignators += (consignators !== '' ? ',' : '') + v.entityIdUUID;
                if (v.entityUserType === 2) financiers += (financiers !== '' ? ',' : '') + v.entityIdUUID;
                if (v.entityUserType === 3) providers += (providers !== '' ? ',' : '') + v.entityIdUUID;
            });
            this.storage = {
                token: response.token,
                userName: response.user.username,
                name: response.user.fullname,
                LastLoginDate: response.user.lastLoginDate,
                responseCode: response.code,
                profileACLs: response.user.profileACLs,
                uuid: response.user.idUUID,
                photo: response.user.photo,
                associatedEntities: entities,
                providers: providers,
                consignators: consignators,
                financiers: financiers,
            };
            localStorage.setItem('loggedUserN', JSON.stringify(this.storage));
            localStorage.removeItem('BASEMEDICALFORM');
            this.setState({ logged: true, redirect: true });
            this.props.handlerLogin(this.state.logged);
        } else if (response.code === 9100) {
            this.messages.show({ severity: 'error', summary: 'Password ou utilizador errado.' });
        } else if (response.code === 9101) {
            this.messages.show({ severity: 'error', summary: 'Utilizador bloqueado!' });
        } else {
            g_displayMessageError(this.messages, response);
        }
    };

    componentDidMount() {
        document.body.style.cssText += 'background-image: none !important;';
        document.body.style.cssText +=
            'background: #5098c8; background: -webkit-linear-gradient(-135deg, #5098c8, #19255e) !important; background: -o-linear-gradient(-135deg, #5098c8, #19255e)!important; background: -moz-linear-gradient(-135deg, #5098c8, #19255e)!important; background: linear-gradient(-135deg, #5098c8, #19255e)!important;';
    }
    componentDidUpdate() {
        document.body.style.cssText += 'background-image: none !important;';
        document.body.style.cssText +=
            'background: #5098c8; background: -webkit-linear-gradient(-135deg, #5098c8, #19255e) !important; background: -o-linear-gradient(-135deg, #5098c8, #19255e)!important; background: -moz-linear-gradient(-135deg, #5098c8, #19255e)!important; background: linear-gradient(-135deg, #5098c8, #19255e)!important;';
    }

    render() {
        const loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN !== null) {
            let query = new URLSearchParams(this.props?.location?.search ?? '');
            let red = query.get('redirect') ?? '/';
            if (red === 'freight') {
                let id = query.get('id') ?? null;
                red = `/freight${id ? `?id=${id}` : ''}`;
            }

            return <Redirect to={red} />;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                    <div className="login-div">
                        <div className="login-img-div">
                            <img src={'assets/images/logo.png'} width="100%" alt="" />
                        </div>
                        <Messages ref={el => (this.messages = el)} />
                        <div className="login-field-div">
                            <InputText className="login-field" placeholder="Usuario" name="username" onChange={this.handleChange} required />
                        </div>
                        <div className="login-field-div p-float-label">
                            <Password className="login-field" placeholder="Senha" name="password" feedback={false} onChange={this.handleChange} required />
                        </div>
                        <input type="submit" value="Acessar" className="btt" />

                        <div className="login-new-div">
                            {ls('preForgot', 'login')}{' '}
                            <a className="login-new-account" href="#/recover-password">
                                {ls('forgot', 'login')}
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}
