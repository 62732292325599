import {g_ChangeStatus} from '../components/GenericFunctions';
import axios from './AxiosService';

class NewsService {
    
    list = data => axios.request('POST', `/news/list`, data);
    updateContent = data => axios.request('PUT', '/news/update/content', data);
    add = data => axios.request('POST', '/news/add', data);
    update = data => axios.request('PUT', '/news/update', data);
    
    images = {
        list: data => axios.request('POST', `/news/image/list`, data),
        upload: (formData, documentTypeId, productUUID) => {
            const config = {'content-type': 'multipart/form-data', 'background-request': true};
            return axios.request('POST', `/news/image/upload/${documentTypeId}/${productUUID}`, formData, config);
        },
        delete: imageIdUUID => axios.request('DELETE', `/news/image/${imageIdUUID}`, ''),
    }

    types = data => axios.request('POST', `/news/newsTypes`, data);
    categories = data => axios.request('POST', `/news/newsCategories`, data);
    updateStatusRaw = data => axios.request('PUT', `/news/update/status`, data);
    updateStatus = (context, idUUID, status, callback= ()=> {}, message="", messages=context.messages, e) => g_ChangeStatus(context, () => this.updateStatusRaw({idUUID: idUUID, newsStatus: status}), callback, message, messages, e )

}

export default new NewsService();
