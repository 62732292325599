import axios from './AxiosService'

class NotificationService {

    async getNotifications(data) {
        return await axios.request('POST','/notification/list', data)
    }

    async addNotification(data) {
        return await axios.request('POST', `/notification/add`, data)
    }

    async getLogs(data){
        return await axios.request('POST', `/notification/log/list`, data);
    }

    async updateStatus(data){
        return await axios.request('PUT', `/notification/update/status`, data)
    }

    async getNotificationTypes(data){
        return await axios.request('POST', `/notification/notificationTypes`, data);
    }

    async updateNotification(data){
        return await axios.request('PUT', `/notification/update`, data)
    }

    async getNotificationCheckMode(data){
        return await axios.request('POST', `/notification/notificationCheckModes`, data);
    }

    async getNotificationBroadcastTypes(data){
        return await axios.request('POST', `/notification/notificationBroadcastTypes`, data);
    }

    async getNotificationEvents(data){return await axios.request('POST', `/notification/event/list`, data);}


    async documentUpload(formData, documentTypeId, UUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/notification/document/upload/${documentTypeId}/${UUID}`, formData, config)
    }
}

export default new NotificationService();
