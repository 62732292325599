import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import BatchService from '../../service/BatchService';
import { LS, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ConfigBatchManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired([{ key: 'name', value: 'asc' }]);
        this.interval = null;
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(this.getData, 120000);
    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }
    getData = () => g_getData(this, BatchService.serviceList, 'batchServices');

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('configBatchManagement', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/service-batch?type=1', 'Adicionar Batch', this.getData, 1200, 600, e.c === 1),
                    [
                        { type: 'd', data: 'name', sortable: true, filter: true },
                        { type: 'd', data: 'interfaceName', filter: true },
                        {
                            type: 'di',
                            data: 'batchServiceType',
                            align: 'left',
                            di: raw => (raw.batchServiceType === 1 ? 'task' : raw.batchServiceType === 2 ? 'file' : 'type'),
                        },
                        { type: 'date', data: 'monitoringDateTick', sortable: true },
                        { type: 'date', data: 'monitoringLastExecution', sortable: true },
                        {
                            type: 'state',
                            data: 'monitoringStatus',
                            states: { 0: { class: 'pi-circle-on', color: 'red' }, 1: { class: 'pi-check', color: 'green' }, 2: { class: 'pi-ban', color: 'red' } },
                        },
                        {
                            type: 'state',
                            data: 'batchServiceStatus',
                            states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                        },
                        { type: 'url', url: raw => '#/service-batch?type=1&id=' + raw.idUUID, width: 1200, height: 600, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
