import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Spinner } from 'primereact/spinner';
import { Dialog } from 'primereact/dialog';
import { EMPTY_ADDRESS, AddressDialog } from '../generic/Address';
import {
    pair,
    LS,
    ls,
    lb,
    StateIcon,
    g_treatNumric,
    g_treatNumricR,
    g_ApiConsignee,
    g_ChangeStatus,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_getDataFields,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dropdown,
    g_template_dialog,
    g_template_input,
    g_template_inputMask,
    g_template_numeric,
    g_wraper,
    g_genericRequired,
    g_hideSearch,
    g_getHeaderTemplate,
    g_treatDate,
    g_inputTemplate,
    g_getWraper,
} from '../GenericFunctions';
import * as address from '../generic/Address';
import { ListContracts, ListSubscriptions } from './lists';
import React from 'react';
import config from '../../config/config';
import ConsigneeService from '../../service/ConsigneeService';
import UserService from '../../service/UserService';
import UtilsService from '../../service/UtilsService';
import Attachment from '../attachment/Attachment';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import Log from '../logs/Log';
import ClockingManagement from './ClockingManagement';
import Payrool from './Payroll';
import { g_template_dataTable, g_getHeaderTemplatecF } from '../generic/DataTable';

export const states = { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } };

export default class Consignee extends React.Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiConsignee(),
            addresses: [],
            idUUID: '',
            items: [{ label: LS('consignee', 'tab') }],
            holderphoto: null,
            changeMarignDialog: false,
            changeMarignDialogValueUsed: '',
            changeMarignDialogValueTotal: '',
        };
    }

    updateStatus = (e, status) =>
        g_ChangeStatus(this, () => ConsigneeService.updateStatus({ idUUID: this.state.data.idUUID, consigneeStatus: status }), this.getData, LS('simpleStatus', 'messages'), this.messages, e);

    updateStatusClick = e => this.updateStatus(e, this.state.data.consigneeStatus === 1 ? 2 : 1);
    blockStatus = e => this.updateStatus(e, 3);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') !== '1';
        g_getDataFields(this, UtilsService.getEntityType, 'entityTypes', 'description', 'id', 'entityTypes');
        g_getDataFields(this, UtilsService.getGender, 'genders', 'description', 'id', 'genders');
        g_getDataFields(this, UtilsService.getHabilitation, 'hablitations', 'description', 'id', 'hablitations');
        g_getDataFields(this, UtilsService.getMaritalStatus, 'civilStatus', 'description', 'id', 'civilStatus');
        this.setState(
            {
                idUUID: id,
                mode: mode,
                items: [
                    { label: LS('consignee', 'tab') },
                    ...(id
                        ? [
                              { label: LS('addresses', 'tab') },
                              { label: LS('contacts', 'tab') },
                              ...(g_checkAlc(4, 'consignee#presence') ? [{ label: LS('schedulePuchIn', 'tab'), icon: 'pi pi-clock' }] : []),
                              ...(g_checkAlc(4, 'consignee#payroll') ? [{ label: LS('payroll', 'tab'), icon: 'pi pi-file' }] : []),
                              ...(g_checkAlc(4, 'consignee#contracts') ? [{ label: LS('contracts', 'tab'), icon: 'pi pi-briefcase' }] : []),
                              ...(g_checkAlc(4, 'consignee#subscriptions') ? [{ label: LS('subscriptions', 'tab'), icon: 'pi pi-calendar' }] : []),
                              { label: LS('parameters', 'tab'), icon: 'pi pi-cog' },
                              { label: LS('attachments', 'tab'), icon: 'pi pi-paperclip' },
                              { label: LS('log', 'tab'), icon: 'pi pi-list' },
                          ]
                        : []),
                ],
            },
            this.getData
        );
    }

    async getData() {
        if (!this.state.idUUID) return;
        const response = await ConsigneeService.get(this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.consignee;
        rdata.civilStatus = String(rdata.civilStatus);
        rdata.gender = String(rdata.gender);
        rdata.hablitation = String(rdata.hablitation);
        rdata.entityType = String(rdata.entityType);
        rdata.vatNumber = rdata.vatNumber ? String(rdata.vatNumber).padStart(11, '0') : '';
        this.setState({
            data: rdata,
            addresses: rdata.address.map(a => ({
                ...a,
                addressType: String(a.addressType),
                port: !a.port ? '' : String(a.port),
                longitude: g_treatNumricR(a.longitude),
                latitude: g_treatNumricR(a.latitude),
            })),
        });
    }

    saveMargin = async () => {
        if (this.state.changeMarignDialogValueUsed === '' || this.state.changeMarignDialogValueTotal === '') return;
        let toSend = {
            idUUID: this.state.idUUID,
            marginTotal: this.state.changeMarignDialogValueTotal,
            marginUsed: this.state.changeMarignDialogValueUsed,
        };
        const response = ConsigneeService.updateMargin(toSend);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleSave') });
        this.setState({ changeMarignDialog: false }, this.getData);
    };

    submitForm = async event => {
        if (event) event.preventDefault();
        if (!this.state.idUUID) return;
        let data = this.state.data;
        if (data.dateBirth) {
            let d = new Date(data.dateBirth);
            data.dateBirth = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000`;
        }
        data.phoneHome = data.phoneHome ? data.phoneHome.replace(/[^\d]/g, '') : '';
        data.phoneCommercial = data.phoneCommercial ? data.phoneCommercial.replace(/[^\d]/g, '') : '';
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : '';
        data.vatNumber = data.vatNumber ? data.vatNumber.replace(/[^\d]/g, '') : '';
        data.registerId = data.registerId ? data.registerId.replace(/[^\d]/g, '') : '';
        data.address = this.state.addresses.map(a => ({ ...a, latitude: g_treatNumric(a.latitude), longitude: g_treatNumric(a.longitude) }));
        data.dateBirth = g_treatDate(data.dateBirth);
        data = { consignee: data };
        let response = await ConsigneeService.updateConsignee(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleSave') });
        this.getData();
    };

    handlerResetClick = async e => {
        e.preventDefault();
        let input = { idUUID: this.state.data.consignee.userIdUUID, userType: 1, email: this.state.data.consignee.email };
        let response = await UserService.resetPassword(input);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('passwordResetSuccess') });
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) this.setState({ holderphoto: formData });
        else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'sucess', summary: LS('simpleUploadStart') });
        var response = await ConsigneeService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN.uuid === this.state.data.idUUID) {
            loggedUserN.photo = response.photo;
            localStorage.setItem('loggedUserN', JSON.stringify(loggedUserN));
        }
        window.location.reload();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.submitForm}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel style={{ border: 'none !important', overflowY: 'auto', height: '80vh' }}>
                                        {g_inputTemplate('fullname', this.state.data.fullname, LS('fullname'), this, true, this.state.mode, false, g_handleChange, {}, 'Editar', 'p-col-7', 'p-col-2')}
                                        {g_inputTemplate('email', this.state.data.email, LS('email'), this, false, this.state.mode, false, g_handleChange, {}, '', 'p-col-6', 'p-col-2', 'email')}
                                        {g_wraper(
                                            'vatNumber',
                                            LS('vatNumber'),
                                            [
                                                g_template_dropdown(this, 'entityType', this.state.data.entityType, null, this.state.entityTypes, this.state.mode, false, false, 'p-col-2'),
                                                g_template_inputMask(this, 'vatNumber', this.state.data.vatNumber, null, ls('vatNumber', 'mask'), true, this.state.mode, false, 'p-col-3'),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'registerId',
                                            LS('registerId'),
                                            [
                                                g_template_dropdown(this, null, '', null, [], true, false, false, 'p-col-2'),
                                                g_template_inputMask(this, 'registerId', this.state.data.registerId, null, '', false, this.state.mode, false, 'p-col-3', '', '', ''),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        <div className="p-grid p-fluid p-col-12" style={{ padding: '0px' }}>
                                            {g_wraper(
                                                'employeeGovId',
                                                LS('employeeGovId'),
                                                g_template_input(this, 'employeeGovId', this.state.data.employeeGovId, null, this.state.mode, false, false, 'p-col-3'),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                            <div className="p-col-1" />
                                            {g_wraper(
                                                'employeeId',
                                                LS('employeeGovId'),
                                                g_template_input(this, 'employeeId', this.state.data.employeeId, null, this.state.mode, false, false, 'p-col-3', '', '', {
                                                    regex: new RegExp('\\d*', 'g'),
                                                }),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                        </div>
                                        <div className="p-grid p-col-12 p-fluid" style={{ padding: '0px' }}>
                                            {g_wraper(
                                                'gender',
                                                LS('gender'),
                                                g_template_dropdown(this, 'gender', this.state.data.gender, null, this.state.genders, this.state.mode, false, false, 'p-col-2'),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                            <div className="p-col-2"></div>
                                            {g_wraper(
                                                'hablitation',
                                                LS('hablitation'),
                                                g_template_dropdown(this, 'hablitation', this.state.data.hablitation, null, this.state.hablitations, this.state.mode, false, false, 'p-col-2'),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                        </div>
                                        <h1 style={{ width: '25vw' }}> </h1>
                                        <div className="p-grid p-col-12 p-fluid" style={{ padding: '0px' }}>
                                            {g_wraper(
                                                'civilStatus',
                                                LS('civilStatus'),
                                                g_template_dropdown(this, 'civilStatus', this.state.data.civilStatus, null, this.state.civilStatus, this.state.mode, false, false, 'p-col-3'),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                            <div className="p-col-1"></div>
                                            {g_wraper(
                                                'dependentNumber',
                                                LS('dependentNumber'),
                                                <div className="p-col-2">
                                                    <Spinner
                                                        disabled={this.state.mode}
                                                        id="dependentNumber"
                                                        value={this.state.data.dependentNumber}
                                                        onChange={e => (!this.state.mode ? g_handleChange(e, {}, this) : () => {})}
                                                        min={0}
                                                        max={100}
                                                    />
                                                </div>,
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                        </div>
                                        {g_template_input(this, 'motherName', this.state.data.motherName, LS('motherName'), this.state.mode, false, false, 'p-col-7', 'p-col-2')}
                                        {g_template_input(this, 'fatherName', this.state.data.fatherName, LS('fatherName'), this.state.mode, false, false, 'p-col-7', 'p-col-2')}
                                        {g_wraper(
                                            'dateBirth',
                                            LS('dateBirth'),
                                            <div className="p-col-2">
                                                <Calendar
                                                    disabled={this.state.mode}
                                                    id="dateBirth"
                                                    dateFormat="dd-mm-yy"
                                                    value={new Date(this.state.data.dateBirth)}
                                                    onChange={e => (!this.state.mode ? g_handleChange(e, {}, this) : () => {})}
                                                />
                                            </div>,
                                            false,
                                            'p-col-2'
                                        )}
                                        <div className="p-grid p-col-12 p-fluid" style={{ padding: '0px' }}>
                                            {g_wraper(
                                                'phoneMobile',
                                                LS('phoneMobile'),
                                                g_template_inputMask(
                                                    this,
                                                    'phoneMobile',
                                                    this.state.data.phoneMobile,
                                                    null,
                                                    LS('phoneMobile', 'mask'),
                                                    false,
                                                    this.state.mode,
                                                    false,
                                                    'p-col-3',
                                                    '',
                                                    '',
                                                    LS('phoneMobile', 'placeholder')
                                                ),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                            <div className="p-col-1"></div>
                                            {g_wraper(
                                                'phoneHome',
                                                LS('phoneHome'),
                                                g_template_inputMask(
                                                    this,
                                                    'phoneHome',
                                                    this.state.data.phoneHome,
                                                    null,
                                                    LS('phoneHome', 'mask'),
                                                    false,
                                                    this.state.mode,
                                                    false,
                                                    'p-col-3',
                                                    '',
                                                    '',
                                                    LS('phoneHome', 'placeholder')
                                                ),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                        </div>
                                        {g_template_inputMask(
                                            this,
                                            'phoneCommercial',
                                            this.state.data.phoneCommercial,
                                            LS('phoneCommercial'),
                                            LS('phoneCommercial', 'mask'),
                                            false,
                                            this.state.mode,
                                            false,
                                            'p-col-3',
                                            'p-col-2',
                                            '',
                                            LS('phoneCommercial', 'placeholder')
                                        )}
                                    </Panel>
                                </div>

                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        <GPhotoUpload
                                            style={{ width: '80px', height: '80px' }}
                                            handlePhotoUpload={this.onUpload}
                                            photo={this.state.data.photo}
                                            documentTypeId={1}
                                            prefixPath={config.PHOTO_URL}
                                        />
                                        {g_getWraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon state={this.state.data.consigneeStatus} small custom={states} />
                                            </div>,
                                            this.state.idUUID == null,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID == null, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID == null, 'p-col')}
                                        <h1> </h1>
                                        {g_getWraper(null, LS('totalMarginEx'), <div className="p-col-1">{this.state.data.totalMargin}</div>, !this.state.idUUID, 'p-col-7')}
                                        {g_getWraper(null, LS('usedMargin'), <div className="p-col-1">{this.state.data.usedMargin}</div>, !this.state.idUUID, 'p-col-7')}
                                        <h1> </h1>
                                        {g_getWraper(null, LS('loyaltyPointsBalance'), <div className="p-col-1">{this.state.data.loyaltyPointsBalance}</div>, !this.state.idUUID, 'p-col-7')}
                                    </Panel>
                                </div>
                            </div>

                            <div className="p-grid p-fluid">
                                {g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', this.state.data.userStatus === 3, null, this.state.mode, 'p-col-2')}
                                {g_template_button(LS('resetPassword', 'btt'), '', !this.state.idUUID || this.state.data.consigneeStatus === 3, this.handlerResetClick, this.state.mode, 'p-col-2')}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.consigneeStatus === 1 ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'status',
                                    this.updateStatusClick,
                                    LS(this.state.data.consigneeStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                    this.state.data.consigneeStatus === 3 || !this.state.idUUID || this.state.mode,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-warning'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS(`askBlockP`, 'messages'),
                                    'status',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    this.state.data.consigneeStatus === 3 || !this.state.idUUID || this.state.mode,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-warning'
                                )}
                                {g_template_button(
                                    'Mudar margem',
                                    '',
                                    !this.state.idUUID && g_checkAlc(5, 'changeConsigneeMargin'),
                                    e => {
                                        e.preventDefault();
                                        this.setState({ changeMarignDialog: true });
                                    },
                                    this.state.mode,
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                        <AddressList context={this} gc={() => this} />,
                        <ContactList context={this} />,
                        ...(g_checkAlc(4, 'consignee#presence') ? [<ClockingManagement context={this} />] : []),
                        ...(g_checkAlc(4, 'consignee#payroll') ? [<Payrool context={this} />] : []),
                        ...(g_checkAlc(4, 'consignee#contracts') ? [<ListContracts context={this} />] : []),
                        ...(g_checkAlc(4, 'consignee#subscriptions') ? [<ListSubscriptions context={this} />] : []),
                        <Parameters context={this} />,
                        <Attachment idUUID={this.state.idUUID} documentContextType={2} service={'consignee'} />,
                        <Log idUUID={this.state.idUUID} logContextType={2} />,
                    ])}
                    <Dialog
                        visible={this.state.changeMarignDialog}
                        footer={g_template_button(LS('save', 'btt'), '', false, e => {
                            e.preventDefault();
                            this.saveMargin();
                        })}
                        onHide={() => this.setState({ changeMarignDialog: false })}
                    >
                        <div className="p-grid p-fluid p-col-12 contact-form">
                            {g_template_numeric(
                                this,
                                'changeMarignDialogValueUsed',
                                this.state.changeMarignDialogValueUsed,
                                LS('changeMarignDialogValueUsed'),
                                this.state.mode,
                                false,
                                false,
                                'p-col-5',
                                'p-col-4',
                                '',
                                {
                                    data: 'changeMarignDialogValueUsed',
                                }
                            )}
                            {g_template_numeric(
                                this,
                                'changeMarignDialogValueTotal',
                                this.state.changeMarignDialogValueTotal,
                                LS('changeMarignDialogValueUsed'),
                                this.state.mode,
                                false,
                                false,
                                'p-col-5',
                                'p-col-4',
                                '',
                                {
                                    data: 'changeMarignDialogValueTotal',
                                }
                            )}
                        </div>
                    </Dialog>
                </div>
            );
        });
    }
}

class ContactList extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => g_getData(this, ConsigneeService.contactList, 'contacts', { refereceEntityIdUUID: this.props.context.state.idUUID }, this.props.context.messages);
    remove = async idUUID => {
        let response = await ConsigneeService.addressDelete(idUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.getData();
        this.props.context.messages.show({ severity: 'sucess', summary: LS('simpleRemove') });
    };
    render() {
        return (
            <div className="p-col">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, `/#/contact?type=1&cid=${this.props.context.state.idUUID}`, 'Create new contact', this.getData, 1000, 500, true),
                    [
                        { type: 'd', data: 'contact', sortable: true, filter: true },
                        { type: 'd', data: 'contactRelationshipName', sortable: true, filter: true },
                        { type: 'di', data: 'contactType', align: 'left', di: raw => ({ 1: 'email', 2: 'celular', 3: 'home phone', 4: 'work phone', 5: 'other phone', 6: 'fax' }[raw.contactType]) },
                        { type: 'd', data: 'contactRelationshipTypeDescription' },
                        { type: 'state', data: 'principal', states: { 1: { color: 'green', class: 'pi-check' }, 0: 'none' } },
                        { type: 'state', data: 'contactStatus', states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi pi-trash' } } },
                        { type: 'url', url: raw => `#/contact?type=1&id=${raw.idUUID}`, width: 1000, height: 500, callBack: this.getData, icon: 'pi pi-pencil', class: 'pi p-button-warning' },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}

class AddressList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: { type: '' } };
        this.addsL = [];
    }
    handleSubmit = (e, d) => {
        e.preventDefault();
        let data = d;
        let addresses = this.props.gc().state.addresses;
        data.zipCode = data.zipCode ? String(data.zipCode).replace(/[^\d]]/g, '') : '';
        if (!data.idUUID) addresses.push(data);
        else addresses.map(a => (a.idUUID === d.idUUID ? d : a));
        this.props.context.setState({ addresses: addresses }, this.props.context.submitForm);
        this.addD.setState({ visible: false });
    };
    remove = async idUUID => {
        let response = await ConsigneeService.addressDelete(idUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.props.context.getData();
        this.props.context.messages.show({ severity: 'sucess', summary: LS('simpleRemove', 'messages') });
    };
    render() {
        return (
            <div className="p-col">
                <AddressDialog context={this.props.context} ref={e => (this.addD = e)} handleSubmit={this.handleSubmit} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.addD.open(EMPTY_ADDRESS), this.props.context.getData),
                    {
                        data: this.props.gc().state.addresses,
                        body: [
                            { type: 'map', data: 'addressType', objs: address.types },
                            { type: 'd', data: 'zipCode' },
                            { type: 'd', data: 'street' },
                            { type: 'd', data: 'city' },
                            { type: 'd', data: 'state' },
                            { type: 'button', icon: 'pi pi-pencil', click: raw => this.addD.open(raw), class: 'p-button-warning' },
                            { type: 'button', icon: 'pi pi-trash', click: raw => this.remove(raw.idUUID), class: 'p-button-danger' },
                        ],
                    },
                    this.getData
                )}
            </div>
        );
    }
}

class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), name: '', parameterValue: '' };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, ConsigneeService.parameterList, 'parameters', {
                  filters: [pair('referenceIdUUID', this.props.context.state.idUUID), ...this.state.filter],
              })
            : null;

    save = () => {
        if (!this.props.context.state.idUUID || !this.state.name || !this.state.parameterValue) return;
        const response = ConsigneeService.parameterPut({
            parameter: {
                logContextType: 0,
                name: this.state.name,
                parameterValue: this.state.parameterValue,
                referenceIdUUID: this.props.context.state.idUUID,
            },
        });
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({ name: '', parameterValue: '', changeDialog: false }, this.getData);
    };

    render() {
        return (
            <div className="p-col">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ changeDialog: true }), this.getData, this.props.context.state.idUUID),
                    [
                        { type: 'd', data: 'name' },
                        { type: 'd', data: 'parameterValue' },
                        { type: 'button', icon: 'pi pi-pencil', click: raw => this.setState({ name: raw.name, parameterValue: raw.parameterValue, changeDialog: true }) },
                    ],
                    this.getData
                )}
                <Dialog
                    visible={this.state.changeDialog}
                    footer={
                        <Button
                            label={lb('save')}
                            onClick={e => {
                                e.preventDefault();
                                this.save();
                            }}
                        />
                    }
                    onHide={() => this.setState({ changeDialog: false })}
                >
                    <div className="p-grid p-fluid p-col-12 contact-form">
                        {g_template_numeric(this, 'name', this.state.name, LS('name'), this.state.mode, false, false, 'p-col-5', 'p-col-4', '', { data: 'name' })}
                        {g_template_numeric(this, 'parameterValue', this.state.parameterValue, LS('parameterValue'), this.state.mode, false, false, 'p-col-5', 'p-col-4', '', {
                            data: 'parameterValue',
                        })}
                    </div>
                </Dialog>
            </div>
        );
    }
}
