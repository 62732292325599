import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import CampaignService from '../../service/CampaignService';
import { LS, g_entityFilter, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ProductsPackageManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorsidUUID', 'consignatorsidUUIDs', 5, 'consignator-management#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                data.filters = f[0];
                return CampaignService.getCampaigns(data);
            },
            'campaigns',
            { filters: [{ key: 'campaignType', value: 3 }, ...this.state.filter] }
        );
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('productsPackageManagement', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/packageProduct?type=1', 'campaignProduct', this.getData, 1000, 700, e.c === 1),
                    [
                        { type: 'd', data: 'name', sortable: true, filter: true },
                        { type: 'd', data: 'shortDescription', sortable: true, filter: true },
                        { type: 'd', data: 'campaignModeDescription', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'campaignStatus', states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } } },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/packageProduct?type=1&id=${raw.idUUID}`, width: 1000, height: 700, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
