import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ProfileService from '../../service/ProfileService';
import { ls, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ProfileManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => g_getData(this, ProfileService.list, 'profiles');
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>Perfis</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/profile?type=1', 'Criar novo perfil', this.getData, 1300, 600, e.c === 1),
                    [
                        { type: 'd', data: 'description', filter: true, sortable: true },
                        { type: 'di', data: 'profileType', align: 'rigth', di: raw => (raw.profileType === 1 ? ls('normal', 'generic') : ls('system', 'generic')) },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'profileStatus',
                            states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                        },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/profile?type=1&id=${raw.idUUID}`, width: 1300, height: 600, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
