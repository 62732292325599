import { Dialog } from 'primereact/dialog';
import { GMap } from 'primereact/gmap';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import BatchService from '../../service/BatchService';
import ConsignatorService from '../../service/ConsignatorService';
import Address from '../generic/Address';
import {
    LS,
    StateIcon,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_getDateTemplate,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_input,
    g_getWraper,
    g_dialogTemplate,
    g_buttonTemplate,
} from '../GenericFunctions';
import Log from '../logs/Log';

var google = window.google;

export default class ClockingLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                area: [],
                address: Address.EMPTY_ADDRESS,
                dateCreation: '',
                dateUpdate: '',
                clockingLocationStatus: 0,
            },
            idUUID: null,
            mode: false,
            overlays: null,
            items: [{ label: LS('location', 'tab') }],
            options: { center: { lat: -14.2400732, lng: -53.180501 }, zoom: 3 },
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, cid: query.get('cid'), mode: mode }, this.getData);
    }

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  () => ConsignatorService.clockingGet(this.state.idUUID),
                  response => this.setState({ data: response.clockingLocations[0] }),
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }], orderBy: [], pageSize: 1, pageNumber: 1 }
              )
            : null;

    updateStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            clockingLocationStatus: this.state.data.batchServiceStatus === 1 ? 2 : 1,
            idUUID: this.state.data.idUUID,
        };
        let response = await BatchService.updateServiceStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            clockingLocationStatus: 3,
            idUUID: this.state.data.idUUID,
        };
        let response = await BatchService.updateServiceStatus(data);
        if (g_displayMessageError(this.message, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.editMap) {
            this.messages.show({ severity: 'warn', summary: LS('saveMap', 'messages') });
            return;
        }
        let data = this.state.data;
        data.address = this.addr.state.data;
        data.area = [];
        let baseAreaItem = {
            accuracy: 0,
            altitude: 0,
            altitudeAccuracy: 0,
            heading: 0,
            latitude: 0,
            longitude: 0,
            mocked: false,
            pointOrder: 0,
            speed: 0,
        };
        let overlays = this.state.overlays;
        if (overlays[0] && overlays[0].latLngs) {
            let paths = overlays[0].latLngs.i[0];
            paths.forEach((e, _) => {
                let areaItem = { ...baseAreaItem };
                areaItem.latitude = e.lat();
                areaItem.longitude = e.lng();
            });
        }
        data = { clockingLocation: data };
        if (this.state.idUUID) {
            let response = await ConsignatorService.clockingUpdate(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            if (this.state.cid) return;
            data.clockingLocation.consignatorIdUUID = this.state.cid;
            let response = await ConsignatorService.clockingAdd(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            window.location.href = `#/clockinglocation?type=1&id=${response.clocking.idUUID}`;
            window.location.reload();
        }
    };

    mapClick = e => {
        if (!this.state.editMap) return;
        this.setState({
            newLocation: true,
            newLat: e.latLng.lat(),
            newLng: e.latLng.lng(),
        });
    };

    addMarker = _ => {
        if (!this.state.editMap) return;
        if (!(Number(this.state.newLng) && Number(this.state.newLat))) return;
        this.setState({
            overlays: [
                ...this.state.overlays,
                new google.maps.Marker({
                    draggable: false,
                    position: { lat: Number(this.state.newLat), lng: Number(this.state.newLng) },
                    title: `${LS('position', 'generic')} ${this.state.overlays.length + 1}`,
                }),
            ],
            newLng: '',
            newLat: '',
            newLocation: false,
        });
    };

    mapReady = _ => {
        if (this.state.data.area.length <= 2) {
            this.setState({ overlays: [] });
            return;
        }
        let poly = new google.maps.Polygon({
            paths: this.state.data.area.map(v => ({ lat: v.latitude, lng: v.longitude })),
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#1976D2',
            fillOpacity: 0.35,
        });
        this.setState({ overlays: [poly], editMap: false });
    };

    saveMap = _ => {
        if (!this.state.editMap) {
            let overlays = this.state.overlays;
            if (overlays[0] && overlays[0].latLngs) {
                let paths = overlays[0].latLngs.i[0];
                overlays = [];
                paths.forEach((e, i) => {
                    overlays.push(new google.maps.Marker({ draggable: false, position: { lat: e.lat(), lng: e.lng() }, title: `${LS('position', 'generic')} ${i + 1}` }));
                });
            }
            this.setState({ editMap: true, overlays: overlays });
            return;
        }
        let paths = [];
        this.state.overlays.forEach(v => (paths = [...paths, { lat: v.position.lat(), lng: v.position.lng() }]));
        if (paths.length <= 2) {
            this.messages.show({ severity: 'warn', summary: LS('treePointsNedded', 'messages') });
            return;
        }
        let poly = new google.maps.Polygon({ paths: paths, strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35 });
        this.setState({ overlays: [poly], editMap: false });
    };

    overlayClick = e => {
        if (!e.overlay.title) return;
        this.setState({ deleteD: true, newLat: e.overlay.getPosition().lat(), newLng: e.overlay.getPosition().lng() });
    };

    deleteMarker = () => {
        let overlays = this.state.overlays;
        let noverlays = overlays
            .filter(v => !(Number(this.state.newLat) === Number(v.position.lat()) && Number(this.state.newLng) === Number(v.position.lng())))
            .map((v, i) => {
                v.title = `${LS('position', 'generic')} ${i + 1}`;
                return v;
            });
        this.setState({ overlays: noverlays, deleteD: false, newLat: '', newLng: '' });
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        <Address vValue={this.state.data.address} context={this} ref={e => (this.addr = e)} />
                                        <GMap
                                            onMapReady={this.mapReady}
                                            overlays={this.state.overlays}
                                            onMapClick={this.mapClick}
                                            options={this.state.options}
                                            style={{ width: '100%', minHeight: '50vh' }}
                                        />
                                        <Dialog header="new Location" visible={this.state.newLocation} onHide={_ => this.setState({ newLocation: false })}>
                                            {g_template_input(this, 'lat', this.state.newLat, LS('latitude'), false, false, true, 'p-col-12', 'p-col-2', '', { data: 'lat' })}
                                            {g_template_input(this, 'lng', this.state.newLng, LS('longitude'), false, false, true, 'p-col-12', 'p-col-2', '', { data: 'lng' })}
                                            {g_template_button(
                                                LS('add', 'btt'),
                                                '',
                                                false,
                                                e => {
                                                    e.preventDefault();
                                                    this.addMarker();
                                                },
                                                false,
                                                'p-col-12'
                                            )}
                                        </Dialog>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.clockingLocationStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            this.state.idUUID == null,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID == null, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID == null, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_buttonTemplate(
                                    LS(this.state.editMap ? 'saveMap' : 'editMap', 'btt'),
                                    '',
                                    '',
                                    this.state.data.clockingLocationStatus === 3,
                                    e => {
                                        e.preventDefault();
                                        this.saveMap();
                                    },
                                    this.state.mode,
                                    'p-col-2'
                                )}
                                {g_buttonTemplate(LS(this.state.editMap ? 'save' : 'create', 'btt'), '', '', this.state.data.clockingLocationStatus === 3, null, this.state.mode, 'p-col-1')}
                                {g_dialogTemplate(
                                    LS(`ask${this.state.data.clockingLocationStatus === 1 ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'batchServiceStatus',
                                    this,
                                    this.updateStatus,
                                    LS(this.state.data.clockingLocationStatus === 2 ? 'activate' : 'deativate', 'btt'),
                                    'Sim',
                                    'Não',
                                    '',
                                    '',
                                    this.state.idUUID == null || this.state.data.clockingLocationStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                                {g_dialogTemplate(
                                    LS('askBlockP', 'messages'),
                                    'batchServiceStatusBloquear',
                                    this,
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    'Sim',
                                    'Não',
                                    '',
                                    'p-button-danger',
                                    this.state.idUUID == null || this.state.data.clockingLocationStatus === 3,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                        <Log idUUID={this.state.idUUID} logContextType={13} />,
                    ])}
                </div>
            );
        });
    }
}
