import axios from './AxiosService'

class ProviderService {

    getProviders = data => axios.request('POST','/provider/list', data)
    addProvider = data => axios.request('POST', `/provider/add`, data)
    getLogs = data => axios.request('POST', `/provider/log/list`, data);
    updateStatus = data => axios.request('PUT', `/provider/update/status`, data)
    updateProvider = data => axios.request('PUT', `/provider/update`, data)
    getProviderTypes = data => axios.request('POST', `/provider/providerTypes`, data)

    documentUpload(formData, documentTypeId, userUUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };
        return axios.request('POST', `/provider/document/upload/${documentTypeId}/${userUUID}`, formData, config)
    }

    //Service Types
    deleteSearchAreaServiceType = (providerUUID, searchAreaServiceType) => axios.request('DELETE',`/provider/searchAreaServiceType/${providerUUID}/${searchAreaServiceType}`, {})
    addSearchAreaServiceType = data => axios.request('POST',`/provider/searchAreaServiceType/add`, data)
    searchAreaServiceType = data => axios.request('POST',`/provider/searchAreaServiceTypes`, data)
}

export default new ProviderService();
