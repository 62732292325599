import React from 'react';
import VoucherService from '../../service/VoucherService';
import ProductService from '../../service/ProductService';
import GSelector from '../generic/GSelector';
import {
    ls,
    lt,
    lm,
    lb,
    S,
    g_ApiVoucher,
    g_ChangeStatus,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_tabViewRenderOptions,
    g_template_form,
    g_treatNumric,
    g_nullarrayresponse,
    g_treatNumricR,
} from '../GenericFunctions';
import Log from '../logs/Log';

export const states = {
    0: { class: 'pi pi-step-forward', color: 'orange' },
    1: { class: 'pi pi-check', color: 'green' },
    2: { class: 'pi pi-spin pi-spinner', color: 'green' },
    3: { class: 'pi pi-times', color: 'red' },
    4: { class: 'pi pi-clock', color: 'red' },
    5: { class: 'pi pi-circle-on', color: 'red' },
};

export default class Voucher extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiVoucher(), items: [{ label: lt('voucher') }], idUUID: null, mode: true };
    }
    onCreate = e => {
        e.preventDefault();
        if (!this.state.data.consignatorIdUUID) return;
        document.getElementById('file').click();
    };

    updateStatus = (e, status, message) => g_ChangeStatus(this, () => null, this.getData, message);

    getProducts = context => g_getData(context, ProductService.getProducts, 'products');

    onCreate = e => {
        e.preventDefault();
        document.getElementById('file').click();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        let items = this.state.items;
        if (!idUUID) items.push({ label: lt('forArchive') });
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  () => VoucherService.list({ idUUID: this.state.idUUID }),
                  response => {
                      let rdata = response.vouchers[0];
                      if (!rdata) {
                          g_nullarrayresponse(this);
                          return;
                      }
                      rdata.voucherValue = g_treatNumricR(rdata.voucherValue);
                      this.setState({ data: response.vouchers[0], items: [{ label: lt('voucher') }, { label: lt('log'), icons: 'pi pi-list' }] });
                  }
              )
            : null;

    submitForm = () => {
        if (this.state.idUUID || !this.state.data.consignatorIdUUID) return;
        let data = this.state.data;
        data.voucherValue = g_treatNumric(data.voucherValue);
        let response = VoucherService.add({ voucher: this.state.data });
        if (g_displayMessageError(this.messages, response)) return;
        window.location.href = `/#/voucher?id=${response.voucher.idUUID}&type=1`;
        window.location.reload();
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadHandler = async file => {
        const formData = new FormData();
        formData.append('file', file);
        if (!this.state.data.description || !this.state.data.voucherType || !this.state.data.consignatorIdUUID) return;
        var response = VoucherService.documentUpload(formData, this.state.data.voucherType, this.state.data.consignatorIdUUID, this.state.data.description);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('uploadSuccessfulVoucher'), detail: lm('autoClose') });
        setTimeout(window.close(), 5000);
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadHandler = async file => {
        if (!this.state.batch_idUUID) return;
        const formData = new FormData();
        formData.append('file', file);
        var response = await VoucherService.documentUpload(formData, this.state.data.voucherType, this.state.data.consignatorIdUUID, this.state.data.productIdUUID);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleUpload') });
        this.setState({ idUUID: response.batch.idUUID }, this.getData);
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    <input id="file" type="file" accept=".csv,.dat,.xls,.xlsx,.txt" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                <GSelector
                                    labelwraper="p-col-2"
                                    label={ls('consignatorName')}
                                    ref={e => (this.selector0 = e)}
                                    getData={S.consignator.processed.list}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector0.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    extrafunctionicon="pi pi-list"
                                    extrafunction={e => {
                                        e.preventDefault();
                                        this.selector0.setState({ selected: ls('all', 'text'), visible: false });
                                        this.setState({ data: { ...this.state.data, consignatorIdUUID: '-' } });
                                    }}
                                    inputtextwarper="p-col-5"
                                    disabled={this.state.mode}
                                    startV={this.state.data.consignatorName}
                                />,

                                <GSelector
                                    label={ls('productName')}
                                    ref={e => (this.selector3 = e)}
                                    getData={this.getProducts}
                                    tableBody={[
                                        { type: 'd', data: 'name', title: 'Nome', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector3.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, productIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    extrafunctionicon="pi pi-list"
                                    extrafunction={e => {
                                        e.preventDefault();
                                        this.selector3.setState({ selected: 'Todos', visible: false });
                                        this.setState({ data: { ...this.state.data, consignatorIdUUID: '-' } });
                                    }}
                                    inputtextwarper="p-col-5"
                                    labelwraper="p-col-2"
                                    disabled={mode}
                                    startV={this.state.data.productName}
                                />,

                                {
                                    t: 'array',
                                    dTypeF: this.state.idUUID ? 'view' : 't',
                                    cc: 4,
                                    req: true,
                                    dTypeFs: { 4: this.state.idUUID ? 'view' : 'numeric' },
                                    filter: ['number', 'checkDigit', 'serialNumber', 'batchLoadNumber', 'voucherValue', 'controlNumber', 'additionalDescription', 'additionalCode'],
                                },
                            ],
                            () => [
                                { t: 'b', l: lb('save'), c: this.state.idUUID },

                                {
                                    t: 'dil',
                                    c: !this.state.idUUID || (this.state.voucherStatus !== 1 && this.state.voucherStatus !== 5),
                                    text: ls(`ask${this.state.data.voucherStatus === 5 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    id: 'voucherStatus',
                                    onConfirm: e => this.updateStatus(e, this.state.data.voucherStatus === 1 ? 5 : 1),
                                    l: ls(this.state.data.userStatus === 5 ? 'activate' : 'deactivate', 'btt'),
                                },
                            ],
                            () => [
                                [
                                    {
                                        t: 'array',
                                        origin: this.state.data,
                                        filter: ['vaucherStatus', 'dateCreation', 'dateUpdate', 'dateExecution'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        ddless: true,
                                        class: 'l',
                                        states: { 0: states },
                                    },
                                ],
                            ],
                            this.submitForm,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),

                        //=====================
                        //Second Page
                        //=====================

                        ...(!this.state.idUUID
                            ? [
                                  <>
                                      <input id="file" type="file" accept=".csv,.dat,.xls,.xlsx,.txt" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                                      {g_template_form(
                                          this,
                                          mode => [
                                              { t: 'drop', data: this.state.data.voucherType, id: 'voucherType', options: [{ label: 'Numo', value: '0' }] },
                                              <GSelector
                                                  labelwraper="p-col-2"
                                                  label={ls('consignatorName')}
                                                  ref={e => (this.selector1 = e)}
                                                  getData={S.consignator.processed.list}
                                                  tableBody={[
                                                      { type: 'd', data: 'name', sortable: true, filter: true },
                                                      {
                                                          type: 'button',
                                                          icon: 'pi pi-plus',
                                                          click: (raw, e) => {
                                                              e.preventDefault();
                                                              this.selector1.setState({ selected: raw.name, visible: false });
                                                              this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                                          },
                                                      },
                                                  ]}
                                                  extrafunctionicon="pi pi-list"
                                                  extrafunction={e => {
                                                      e.preventDefault();
                                                      this.selector1.setState({ selected: ls('all', 'text'), visible: false });
                                                      this.setState({ data: { ...this.state.data, consignatorIdUUID: '-' } });
                                                  }}
                                                  inputtextwarper="p-col-5"
                                                  disabled={this.state.mode}
                                                  startV={''}
                                              />,

                                              <GSelector
                                                  label={ls('productName')}
                                                  ref={e => (this.selector4 = e)}
                                                  getData={this.getProducts}
                                                  tableBody={[
                                                      { type: 'd', data: 'name', sortable: true, filter: true },
                                                      {
                                                          type: 'button',
                                                          icon: 'pi pi-plus',
                                                          click: (raw, e) => {
                                                              e.preventDefault();
                                                              this.selector4.setState({ selected: raw.name, visible: false });
                                                              this.setState({ data: { ...this.state.data, productIdUUID: raw.idUUID } });
                                                          },
                                                      },
                                                  ]}
                                                  extrafunctionicon="pi pi-list"
                                                  extrafunction={e => {
                                                      e.preventDefault();
                                                      this.selector4.setState({ selected: ls('all', 'text'), visible: false });
                                                      this.setState({ data: { ...this.state.data, consignatorIdUUID: '-' } });
                                                  }}
                                                  inputtextwarper="p-col-5"
                                                  labelwraper="p-col-2"
                                                  disabled={mode}
                                                  startV={this.state.data.productName}
                                              />,
                                          ],
                                          () => [{ t: 'b', icon: 'pi pi-upload', click: this.uploadClick, c: this.state.idUUID }],
                                          () => [[]],
                                          this.onCreate,
                                          2,
                                          true,
                                          this.state.mode,
                                          12,
                                          -1,
                                          '70vh'
                                      )}
                                  </>,
                              ]
                            : []),
                        <Log idUUID={this.state.idUUID} logContextType={24} />,
                    ])}
                </div>
            );
        });
    }
}
