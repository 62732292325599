import React from 'react';
import SearchSegmentsService from '../../service/searchSegmentService';
import { Messages } from 'primereact/messages';
import { S, ls, ListPage, g_entityFilter, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import { searchAreaStates } from './search';

export const SearchAreaServiceCategories = (
    <ListPage
        title={ls('categoriesList', 'titles')}
        getData={context => g_getData(context, SearchSegmentsService.category.list, 'searchAreaCategories')}
        header={(context, e) => g_getHeaderTemplate(context, '/#/searchAreaCategory?type=1', 'new', context.getData, 1200, 600, e.c)}
        table={(context, e) => [
            { type: 'd', data: 'description', filter: true },
            { type: 'url', url: raw => `/#/searchAreaCategory?type=1&id=${raw.id}`, width: 1200, height: 600, c: e.u || e.d, callback: context.getData },
        ]}
    />
);

export const SearchAreaServiceTypes = (
    <ListPage
        title={ls('serviceList', 'titles')}
        getData={context => g_getData(context, S.search.serviceType.list, 'searchAreaServiceTypes')}
        header={(context, e) => g_getHeaderTemplate(context, '/#/searcharea-service?type=1', 'new', context.getData, 1200, 600, e.c)}
        table={(context, e) => [
            { type: 'd', data: 'description', filter: true },
            { type: 'url', url: raw => `/#/searcharea-service?type=1&id=${raw.idUUID}`, width: 1200, height: 600, c: e.u || e.d, callback: context.getData },
        ]}
    />
);

//export default const searchAreaActive = <ListPage title="Segementos de Pesquisa: Ativos" getData={
//c => g_getData(c, data => {
//let f = g_entityFilter(data.filters, 'idUUID', 'idUUID', 5, 'searcharea#accessall');
//if (!f) return {code: 0, pageTotal: 0, recordsTotal: 0, consignators: []};
//data.filters = [ ...f[0], {key: 'notsearchAreasStatus', value: '6,100,101'} ];
//return SearchSegmentsService.list(data);
//}, 'searchAreas' )
//} />;

export class SearchAreaActive extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired([{ key: 'showorder', value: 'desc' }]);
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'searcharea#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                data.filters = [...f[0], { key: 'notsearchAreasStatus', value: '6,100,101' }];
                return SearchSegmentsService.list(data);
            },
            'searchAreas'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{ls('segmentsActive', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '/#/searcharea?type=1', 'newSearchSegment', this.getData, 1400, 700, e.c),
                    [
                        { type: 'd', data: 'consignatorName', sortable: true, filter: true },
                        { type: 'd', data: 'description', sortable: true, filter: true },
                        { type: 'd', data: 'totalClickNumber', sortable: true, filter: true },
                        { type: 'date', data: 'dateStart', sortable: true, filter: true },
                        { type: 'date', data: 'dateEnd', sortable: true, filter: true },
                        { type: 'd', data: 'showOrder', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'searchAreaStatus', states: searchAreaStates },
                        { type: 'url', url: raw => `#/searcharea?type=1&id=${raw.idUUID}`, width: 1400, height: 700, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
