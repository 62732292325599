import React from 'react';
import Services from '../../service/Services';
import {
    g_ApiScheduling,
    g_checkLoginACL,
    g_checkMode,
    g_displayMessageError,
    g_getData,
    g_openWindowDialog,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_form,
    LS,
    pair,
} from '../GenericFunctions';
import { contractManagement } from '../contract/ContractManagement';
import Log from '../logs/Log';

export const calendarEvent = {
    size: [1500, 400],
    url: id => `#/calendar-event?type=1${id ? '&id=' + id : ''}`,
};

export class CalendarEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiScheduling(), items: [{ label: LS('calendarEvent', 'tab') }], idUUID: null, ctid: null, mode: true };
    }
    onCancel = e =>
        Services.scheduling.processed.status(
            this,
            this.state.data.idUUID,
            [1, 2].indexOf(this.state.data.schedulingStatus) !== -1 ? 3 : 1,
            this.getData,
            LS('simpleStatus', 'messages'),
            this.messages,
            e
        );
    onULock = e => Services.scheduling.processed.status(this, this.state.data.idUUID, this.state.data.schedulingStatus === 1 ? 2 : 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  Services.scheduling.list,
                  response => {
                      let rdata = response.schedulings[0];
                      this.setState({ data: rdata });
                  },
                  { filters: [pair('idUUID', this.state.idUUID)] }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        if (!idUUID) {
            window.close();
            return;
        }
        let mode = query.get('type') !== '1';
        let items = [{ label: LS('calendarEvent', 'tab') }];
        if (idUUID) items.push({ label: LS('log', 'tab'), icon: 'pi pi-list' });
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (!this.state.idUUID) {
            window.close();
            return;
        }
        const response = await Services.scheduling.update({ scheduling: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                { t: 'array', dTypeF: 't', filter: ['description'] },
                                g_template_button(
                                    LS('calendarEventContract', 'btt'),
                                    '',
                                    !this.state.data.contractIdUUID,
                                    e => {
                                        e.preventDefault();
                                        g_openWindowDialog(contractManagement.url(this.state.data.contractIdUUID), '', contractManagement.size[0], contractManagement.size[1]);
                                    },
                                    mode,
                                    'p-col-2'
                                ),
                            ],
                            mode => [
                                g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.schedulingStatus === 3, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    LS(this.state.data.schedulingStatus === 1 ? 'shedulingLock' : 'schedulingUnlock', 'messages'),
                                    'unlock',
                                    this.onULock,
                                    LS(this.state.data.schedulingStatus === 1 ? 'shedulingLock' : 'schedulingUnlock', 'btt'),
                                    this.state.data.schedulingStatus === 3 || !this.state.data.idUUID,
                                    this.state.mode,
                                    '',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                                g_template_dialog(
                                    this,
                                    LS([1, 2].indexOf(this.state.data.schedulingStatus) !== -1 ? 'simpleDeactivate' : 'simpleActivate', 'messages'),
                                    'cancel',
                                    this.onCancel,
                                    LS([1, 2].indexOf(this.state.data.schedulingStatus) !== -1 ? 'deactivate' : 'activate', 'btt'),
                                    !this.state.data.idUUID,
                                    this.state.mode,
                                    '',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                            ],
                            () => [
                                [
                                    {
                                        t: 'array',
                                        filter: ['schedulingStatus', 'schedulingSyncStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: ['state'],
                                        class: 'l',
                                        states: [schedulingStates],
                                    },
                                ],
                            ],
                            this.submit,
                            2,
                            true,
                            this.state.mode || this.state.data.schedulingStatus === 3,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            ))
        );
    }
}

export const schedulingStates = { 1: { color: 'green', class: 'pi-lock-open' }, 2: { color: 'orange', class: 'pi-lock' }, 3: { color: 'red', class: 'pi-times' } };
