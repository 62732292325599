import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import { g_getHeaderTemplate, g_template_dataTable } from '../GenericFunctions';

export default class GAddNew extends Component {

    constructor() {
        super();
        this.state = {
            filter: [],
            pageNumber: 1,
            pageSize: 6,
            pageTotal: 0,
            recordsTotal: 0,
            multiSortMeta: [],
            data: [],
            first: 0,
            idUUID: null,
            doNotRender: false,
            tableFilter: {},
            tableDate: [],
            addnewWindow: null,
        };
    }

    componentDidMount() {
        if (!(this.props.getData && this.props.tableBody)) { this.setState({doNotRender: true}); return}
        let d = document.getElementsByClassName("p-filter-column");
        for (let i = 0; i < d.length;i++) { d[i].style.setProperty('display', 'none'); }
        if (this.props.addnewWindow && this.props.addnewWindowWidth && this.props.addnewWindowHeight) { this.setState({addnewWindow: this.props.addnewWindow})}
        this.setState({
            tableFilter: this.props.tableFilter ? this.props.tableFilter : {},
            tableDate: this.props.tableDate ? this.props.tableDate : [],
        }, () => this.props.getData(this));
    }

    render() {
        if (this.state.doNotRender) return <span></span>;
        return (
            <div className='card' >
              <Messages ref={e => this.messages = e}/>
              {g_template_dataTable(this, g_getHeaderTemplate(this, this.props.addnewWindow, 'addnew', () => this.props.getData(this), this.props.addnewWindowWidth, this.props.addnewWindowHeight, this.state.addnewWindow, this.props.windowAction), this.props.tableBody, () => this.props.getData(this), this.state.tableFilter, this.state.tableDate, {}, {})}
            </div>
        );
    }

}
