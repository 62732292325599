import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ProfileService from '../../service/ProfileService';
import { LS, g_displayMessageError, g_genericRequired, g_getData, g_getHeaderTemplatecF, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import AddNew from './AddNew';

export default class Associciation extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.idUUID !== null) this.getData();
    }

    getData = () => g_getData(this, ProfileService.aclList, 'profileACLs', { filters: [{ key: 'profileIdUUID', value: this.props.idUUID }, ...this.state.filter] });

    change = async (e, raw) => {
        let value = e.target.id;
        let data = {
            profileACL: {
                accessItemIdUUID: raw.accessItemIdUUID,
                aclCreate: raw.aclCreate,
                aclDelete: raw.aclDelete,
                aclRead: raw.aclRead,
                aclUpdate: raw.aclUpdate,
                profileIdUUID: raw.profileIdUUID,
            },
        };
        data.profileACL[value] = data.profileACL[value] === 1 ? 0 : 1;
        let response = await ProfileService.aclSet(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    addAll = async raw => {
        let data = {
            profileACL: {
                accessItemIdUUID: raw.accessItemIdUUID,
                aclCreate: 1,
                aclDelete: 1,
                aclRead: 1,
                aclUpdate: 1,
                profileIdUUID: raw.profileIdUUID,
            },
        };
        let response = await ProfileService.aclSet(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    removeAll = async raw => {
        let response = await ProfileService.aclSet({
            profileACL: {
                accessItemIdUUID: raw.accessItemIdUUID,
                aclCreate: 0,
                aclDelete: 0,
                aclRead: 0,
                aclUpdate: 0,
                profileIdUUID: raw.profileIdUUID,
            },
        });
        if (!g_displayMessageError(this.messages, response)) this.getData();
    };

    allChecked = raw => raw.aclRead === 1 && raw.aclDelete === 1 && raw.aclUpdate === 1 && raw.aclCreate === 1;

    render() {
        if (this.props.idUUID === null) return <span>Error</span>;
        return (
            <div className="card">
                <Messages ref={e => (this.messages = e)} />
                <Dialog header={LS('addNewOne', 'titles')} style={{ maxWidth: '99%' }} onHide={() => this.setState({ showNewOne: false })} visible={this.state.showNewOne}>
                    <AddNew idUUID={this.props.idUUID} callBackData={this.getData} />
                </Dialog>
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ showNewOne: !this.state.showNewOne }), this.getData),
                    [
                        { type: 'd', data: 'accessItemDescription', filter: true },
                        { type: 'di', align: 'center', data: 'aclRead', di: raw => <Checkbox onChange={e => this.change(e, raw)} id="aclRead" checked={raw.aclRead === 1} /> },
                        { type: 'di', align: 'center', data: 'aclCreate', di: raw => <Checkbox onChange={e => this.change(e, raw)} id="aclCreate" checked={raw.aclCreate === 1} /> },
                        { type: 'di', align: 'center', data: 'aclUpdate', di: raw => <Checkbox onChange={e => this.change(e, raw)} id="aclUpdate" checked={raw.aclUpdate === 1} /> },
                        { type: 'di', align: 'center', data: 'aclDelete', di: raw => <Checkbox onChange={e => this.change(e, raw)} id="aclDelete" checked={raw.aclDelete === 1} /> },
                        { type: 'btt', title: LS('addAll', 'titles'), icon: 'pi pi-plus', class: 'p-button-success', click: this.addAll },
                        { type: 'btt', title: LS('deleteAll', 'titles'), icon: 'pi pi-trash', class: 'p-button-danger', click: this.removeAll },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
