import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import Config from '../../config/config';
import UserService from '../../service/UserService';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import {
    LS,
    pair,
    StateIcon,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_input,
    g_template_inputMask,
    g_wraper,
    g_ApiUserResponse,
} from '../GenericFunctions';
import Log from '../logs/Log';
import AssociationProfile from '../admin/backUsers/AssosiationProfile';
import * as UA from '../appUsers/UserApp';

export default class UserServ extends Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiUserResponse(2),
            idUUID: '',
            mode: false,
            items: [{ label: LS('user', 'tab') }],
            holderphoto: null,
        };
    }

    handlerResetClick = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.data.idUUID,
            userType: 2,
            email: this.state.data.email,
        };
        let response = await UserService.resetPassword(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('passwordResetSuccess', 'messages') });
    };

    changeStatus = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.data.idUUID,
            userStatus: this.state.data.userStatus === 1 ? 2 : 1,
            userType: 2,
        };
        const response = await UserService.updateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.data.idUUID,
            userStatus: 3,
            userType: 2,
        };
        const response = await UserService.updateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        this.setState({ idUUID: idUUID, mode: mode }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let filters = {
            pageSize: 1,
            pageNumber: 1,
            userType: 2,
            filters: [pair('idUUID', this.state.idUUID)],
        };
        let response = await UserService.listUsers(filters);
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({
            data: response.users[0],
            items: [
                { label: LS('user', 'tab') },
                ...(g_checkAlc(4, `user-serv#associationprofileuser`) ? [{ label: LS('profile', 'tab'), icon: 'pi pi-tags' }] : []),
                { label: LS('log', 'tab'), icon: 'pi pi-list' },
            ],
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        let data = { ...this.state.data };
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : '';
        data = { user: data };
        if (this.state.idUUID !== null) {
            let data = { user: this.state.data };
            let response = await UserService.updateUser(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let data = { user: this.state.data };
            let response = await UserService.createUser(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            if (this.state.holderphoto !== null) {
                await this.upload(this.state.holderphoto, 1, response.user.idUUID);
                this.setState({ holderphoto: null });
            }
            this.setState({ idUUID: response.user.idUUID }, this.getData);
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) {
            this.setState({ holderphoto: formData });
            return;
        } else {
            this.upload(formData, data.documentTypeId, this.state.idUUID);
        }
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'sucess', summary: LS('simpleUploadStart', 'messages') });
        var response = await UserService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        window.location.reload();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            return (
                <div className="card">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.handleSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-sm-7 p-lx-7 p-lg-7 p-md-7 p-fluid contact-form">
                                    <Panel>
                                        {g_template_input(this, 'fullname', this.state.data.fullname, LS('fullname'), this.state.mode, 0, 1, 'p-col', 'p-col-2')}
                                        {g_template_input(this, 'username', this.state.data.username, LS('username'), this.state.mode, 0, 1, 'p-col', 'p-col-2')}
                                        {g_template_input(this, 'email', this.state.data.email, LS('email'), this.state.mode, 0, 1, 'p-col', 'p-col-2', '', {}, 'email')}
                                        {g_template_inputMask(
                                            this,
                                            'phoneMobile',
                                            this.state.data.phoneMobile,
                                            LS('phoneMobile'),
                                            LS('phoneMobile', 'mask'),
                                            LS('phoneMobile', 'placeholder'),
                                            this.state.mode,
                                            0,
                                            'p-col-3',
                                            'p-col-2'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: '1px solid lightGrey', height: '100%' }}>
                                        {g_wraper(
                                            '',
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon state={this.state.data.userStatus} small custom={UA.states} />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_wraper('', LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_wraper('', LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                        {g_wraper('', LS('dateLastLogin'), g_getDateTemplate(this.state.data.dateLastLogin), !this.state.idUUID, 'p-col')}
                                        {g_wraper('', LS('dateLastUserLock'), g_getDateTemplate(this.state.data.dateLastUserLock), !this.state.idUUID, 'p-col')}
                                        {g_wraper('', LS('dateLastPasswordChange'), g_getDateTemplate(this.state.data.dateLastPasswordChange), !this.state.idUUID, 'p-col')}
                                        {g_wraper('', LS('passwordRetries'), <span className="p-col-1">{this.state.data.passwordRetries}</span>, !this.state.idUUID, 'p-col-7')}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-2 p-md-2 p-lg-2">
                                    <GPhotoUpload
                                        style={{ width: '80px', height: '80px' }}
                                        handlePhotoUpload={this.onUpload}
                                        photo={this.state.data.photo}
                                        documentTypeId={1}
                                        prefixPath={Config.PHOTO_URL}
                                    />
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.userStatus === 3, null, this.state.mode, 'p-col-1')}
                                {g_template_button(LS('changePassword', 'btt'), '', !this.state.idUUID || this.state.data.userStatus === 3, this.handlerResetClick, this.state.mode, 'p-col-2')}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.userStatus === '1' ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'bq',
                                    this.changeStatus,
                                    LS(this.state.data.userStatus === '1' ? 'deactivate' : 'activate', 'btt'),
                                    this.state.data.userStatus === 3 || !this.state.idUUID,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-warning'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlockP', 'messages'),
                                    'block',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    this.state.data.userStatus === 3 || !this.state.idUUID,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                        ...(g_checkAlc(4, `user-serv#associationprofileuser`) ? [<AssociationProfile userType={2} idUUID={this.state.idUUID} />] : []),
                        <Log idUUID={this.state.idUUID} logContextType={1} />,
                    ])}
                </div>
            );
        });
    }
}
