import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import config from '../../config/config';
import ProductService from '../../service/ProductService';
import { LS, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ProductTypeManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.load();
    }
    load = () => g_getData(this, ProductService.getProductTypes, 'productTypes');

    //TODO: CRUD
    render() {
        return g_checkLoginACL(() => (
            <div className="card">
                <h1>{LS('productType')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.load, 0, 0, false),
                    [
                        { type: 'd', data: 'description', sortable: true, filter: true },
                        { type: 'di', title: '', di: raw => <img style={{ height: '2em' }} src={raw.photoShortcut ? config.PHOTO_PRODUCT_URL + raw.photoShortcut : ''} alt="" />, align: 'center' },
                    ],
                    this.load
                )}
            </div>
        ));
    }
}
