import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import {
    S,
    LS,
    StateIcon,
    g_getDataFields,
    g_ApiCampaign,
    g_template_button,
    g_tabViewRenderOptions,
    g_getDateTemplate,
    g_checkLoginACL,
    g_template_dropdown,
    g_template_input,
    g_template_inputArea,
    g_wraper,
    g_handleChange,
    g_template_dialog,
    g_displayMessageError,
    g_openWindowDialog,
    g_getWraper,
} from '../GenericFunctions';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import GSelector from '../generic/GSelector';

import CampaignService from '../../service/CampaignService';
import CampaignProductsManagement from '../campaignProduct/CampaignProductsManagement';
import Log from '../logs/Log';
import config from '../../config/config';

export default class CampaignProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: g_ApiCampaign(1),
            mode: false,
            campaignModes: [],
            consignators: [],
            items: [{ label: LS('campaignProduct', 'tab') }],
            idUUID: null,
            holderPhotoBig: null,
            holderPhotoSmall: null,
        };
    }

    updateStatusClick = async e => {
        e.preventDefault();
        let input = {
            idUUID: this.state.data.idUUID,
            campaignStatus: this.state.data.campaignStatus === 1 ? 2 : 1,
        };
        let response = await CampaignService.updateStatus(input);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            campaignStatus: 3,
            idUUID: this.state.data.idUUID,
        };
        let response = await CampaignService.updateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleBlock') });
        this.getData();
    };

    async componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        this.getCampaignModes();
        this.setState({ mode: mode, idUUID: idUUID }, this.getData);
    }

    async getData() {
        if (!this.state.idUUID) return;
        const response = await CampaignService.getCampaign({}, this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.campaign;
        rdata.campaignMode = rdata.campaignMode ? String(rdata.campaignMode) : '';
        this.setState({
            data: rdata,
            items: [{ label: LS('campaignProduct', 'tab') }, { label: LS('products', 'tab') }, { label: LS('log', 'tab'), icon: 'pi pi-list' }],
        });
    }

    getCampaignModes = g_getDataFields(this, CampaignService.getCampaignModes, 'campaignModes', 'description', 'id', 'campaignModes');

    handleSubmit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (data.dateStart) {
            let d = new Date(data.dateStart);
            data.dateStart = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (data.dateEnd) {
            let d = new Date(data.dateEnd);
            data.dateEnd = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (this.state.idUUID) {
            let response = await CampaignService.updateCampaign({ campaign: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            let response = await CampaignService.addCampaign({ campaign: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            if (this.state.holderPhotoBig !== null) {
                await this.upload(this.state.holderPhotoBig, 302, response.campaign.idUUID);
                this.setState({ holderPhotoBig: null });
            }
            if (this.state.holderPhotoSmall !== null) {
                await this.upload(this.state.holderPhotoSmall, 301, response.campaign.idUUID);
                this.setState({ holderPhotoSmall: null });
            }
            this.props.history.push(`${window.location.href.substr(window.location.href.indexOf('?'))}&id=${response.campaign.idUUID}`);
            this.setState({ idUUID: response.campaign.idUUID }, this.getData);
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (!this.state.idUUID) {
            if (data.documentTypeId === 302) {
                this.setState({ holderPhotoBig: formData });
                return;
            } else {
                this.setState({ holderPhotoSmall: formData });
                return;
            }
        } else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await CampaignService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
    };

    getConsignators = S.consignator.processed.list;

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form id="1" className="p-col" onSubmit={this.handleSubmit}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel className="p-fluid">
                                        <GSelector
                                            labelwraper="p-col-2"
                                            label={LS('consignatorName')}
                                            ref={e => (this.selector0 = e)}
                                            getData={this.getConsignators}
                                            tableBody={[
                                                { type: 'd', data: 'name', sortable: true, filter: true },
                                                {
                                                    type: 'btt',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector0.setState({ selected: raw.name, visible: false });
                                                        this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                                    },
                                                },
                                            ]}
                                            inputtextwarper="p-col-5"
                                            disabled={this.state.mode}
                                            startV={this.state.data.consignatorName}
                                        />
                                        {g_template_dropdown(
                                            this,
                                            'campaignMode',
                                            this.state.data.campaignMode,
                                            LS('campaignMode'),
                                            this.state.campaignModes,
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-6',
                                            'p-col-2'
                                        )}
                                        {g_template_input(this, 'name', this.state.data.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                        {g_template_input(this, 'shortDescription', this.state.data.shortDescription, LS('shortDescription'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                        {g_template_inputArea(this, 'longDescription', this.state.data.longDescription, LS('longDescription'), this.state.mode, false, true, 'p-col-8', 'p-col-2', '', {
                                            minHeight: '10em',
                                        })}
                                        {g_wraper(
                                            'dateStart',
                                            LS('dateStart'),
                                            [
                                                <div className="p-col-2">
                                                    <Calendar
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        yearRange={`${new Date().getFullYear() - 2}:${new Date().getFullYear() + 10}`}
                                                        id="dateStart"
                                                        dateFormat="dd-mm-yy"
                                                        value={new Date(this.state.data.dateStart)}
                                                        onChange={e => g_handleChange(e, {}, this)}
                                                    />
                                                </div>,
                                                g_wraper(
                                                    'dateEnd',
                                                    LS('dateEnd'),
                                                    <div className="p-col-2">
                                                        <Calendar
                                                            id="dateEnd"
                                                            yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateFormat="dd-mm-yy"
                                                            value={new Date(this.state.data.dateEnd)}
                                                            onChange={e => g_handleChange(e, {}, this)}
                                                        />
                                                    </div>,
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            LS('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.campaignStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(
                                            null,
                                            LS('campaignMainImage'),
                                            <div className="p-col-12 p-fluid p-grid">
                                                <div></div>
                                                <GPhotoUpload
                                                    style={{ height: '95px', width: '164px' }}
                                                    handlePhotoUpload={this.onUpload}
                                                    photo={this.state.data.campaignMainImage}
                                                    documentTypeId={301}
                                                    prefixPath={config.PHOTO_PRODUCT_URL}
                                                    alt={window.location.origin + '/assets/images/maincampaign.png'}
                                                    disabled={this.state.mode}
                                                />
                                            </div>,
                                            false,
                                            'p-col-12'
                                        )}
                                        {g_getWraper(
                                            null,
                                            LS('campaignAggregatedImage'),
                                            <div className="p-col-12 p-fluid p-grid">
                                                <div></div>
                                                <GPhotoUpload
                                                    style={{ height: '113px', width: '164px' }}
                                                    handlePhotoUpload={this.onUpload}
                                                    photo={this.state.data.campaignAggregatedImage}
                                                    documentTypeId={302}
                                                    prefixPath={config.PHOTO_PRODUCT_URL}
                                                    alt={window.location.origin + '/assets/images/agregadacampaign.png'}
                                                    disabled={this.state.mode}
                                                />
                                            </div>,
                                            false,
                                            'p-col-12'
                                        )}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.campaignStatus === 3, null, this.state.mode, 'p-col-2')}
                                {g_template_button(
                                    LS('copy', 'btt'),
                                    '',
                                    !this.state.idUUID,
                                    e => {
                                        e.preventDefault();
                                        g_openWindowDialog(`#/copyCampaign?type=1&id=${this.state.data.idUUID}`, `copy_${this.state.idUUID}`, 700, 500);
                                    },
                                    this.state.mode && e.c !== 1,
                                    'p-col-1',
                                    'p-button-warning'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.campaignStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    'campaignStatus',
                                    this.updateStatusClick,
                                    LS(this.state.data.campaignStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                    !this.state.idUUID || this.state.data.campaign === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlockP', 'messages'),
                                    'campaignStatusBloquear',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    !this.state.idUUID || this.state.data.campaign === 3,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                        <CampaignProductsManagement context={this} />,
                        <Log idUUID={this.state.idUUID} logContextType={10} />,
                    ])}
                </div>
            );
        });
    }
}
