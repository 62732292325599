import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import UserService from '../../../service/UserService';
import { LabelSelector as LS, g_checkLoginACL, g_entityFilter, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../../GenericFunctions';
import * as UA from '../../appUsers/UserApp';

export default class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = async () => {
        let f = g_entityFilter(this.state.filter, 'entitiesIdUUID', 'entitiesIdUUID', 5, 'admin-user-management#accessall');
        if (!f) return;
        g_getData(
            this,
            UserService.listUsers,
            response => {
                this.setState(
                    {
                        data: response.users,
                        pageTotal: response.pageTotal,
                        recordsTotal: response.recordsTotal,
                        showmore: f[1],
                    },
                    () => {
                        if (this.state.showmore !== this.state.oldshowmore) {
                            this.setState({ oldshowmore: this.state.showmore }, g_hideSearch);
                        }
                    }
                );
            },
            { userType: 1, filters: f[0] }
        );
    };

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('userback', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/user?type=1', 'Criar novo user BO', this.getData, 1300, 500, e.c === 1),
                    [
                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                        { type: 'd', data: 'username', sortable: true, filter: true },
                        { type: 'd', data: 'email', sortable: true, filter: true },
                        { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                        { type: 'date', data: 'dateLastLogin', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'userStatus', states: UA.states },
                        { type: 'url', c: e.d === 1 || e.u === 1, url: raw => `#/user?type=1&id=${raw.idUUID}`, width: 1300, height: 500, callback: this.getData },
                    ],
                    this.getData,
                    { 0: 'fullname', 1: 'username', 2: 'email', 3: 'phoneMobile', 5: 'dateCreation', 4: 'dateLastLogin' },
                    [4, 5],
                    {},
                    { 3: '^\\d*$' }
                )}
            </div>
        ));
    }
}
