import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ConfigService from '../../service/ConfigService';
import { ls, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class CacheManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => g_getData(this, ConfigService.cacheList, 'cacheManagers');
    render() {
        return g_checkLoginACL(e => {
            let states = { 0: { class: 'pi-circle-off', color: 'grey' }, 1: { class: 'pi-circle-on', color: 'grey' } };
            return (
                <div className="card">
                    <h1>{ls('cache', 'titles')}</h1>
                    <Messages ref={e => (this.messages = e)} />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(this, '#/cache?type=1', 'createnewcache', this.getData, 1000, 500, e.c === 1),
                        [
                            { type: 'd', title: 'Serviço', data: 'serviceId', align: 'center' },
                            { type: 'state', data: 'refreshDbConfigs', states: states, stateSize: '1em' },
                            { type: 'state', data: 'refreshFileConfigs', states: states, stateSize: '1em' },
                            { type: 'state', data: 'refreshLanguageConfigs', states: states, stateSize: '1em' },
                            { type: 'date', data: 'dateCreation', filter: true, sortable: true },
                            {
                                type: 'state',
                                data: 'cacheManagerStatus',
                                states: { 0: { class: 'pi-spin pi-spinner', color: 'grey' }, 1: { class: 'pi-check', color: 'green' }, 2: { class: 'pi-cross', color: 'red' } },
                            },
                            { type: 'url', c: e.u === 1 || e.d === 1, url: raw => '#/cache?type=1&id=' + raw.idUUID, width: 1000, height: 450, callback: this.getData },
                        ],
                        this.getData
                    )}
                </div>
            );
        });
    }
}
