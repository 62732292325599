import React, { Component } from 'react';
import './GenericPhotoUpload.css';

export default class GPhotoUpload extends Component {
    constructor(props) {
      super(props);
      this.state = {
        file: [],
        imagePreviewUrl: '',
        style: {},
        prefixPath: '',
        disabled: false,
      };
      this._handleImageChange = this._handleImageChange.bind(this);
    }
  
    _handleImageChange(documentTypeId,e) {
        e.preventDefault();
        let reader      = new FileReader();
        let readerArray = new FileReader();
        let file        = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.props.handlePhotoUpload({
                file: file,
                documentTypeId: documentTypeId
            });
        };
        readerArray.readAsArrayBuffer(file)
    }
    
    componentDidMount() { 
        if (this.props.style) this.setState({style: this.props.style});
        if (this.props.alt) this.setState({alt: this.props.alt}); else this.setState({alt: window.location.origin+"/assets/images/person.png"});
        if (this.props.prefixPath) this.setState({prefixPath: this.props.prefixPath});
    }

    render() {
        let $imagePreview = (<img style={{cursor:'pointer',...this.state.style}} src={this.props.photo === null||this.props.photo === ""||this.props.photo === undefined ? this.state.imagePreviewUrl !== '' ? this.state.imagePreviewUrl : this.state.alt : this.state.prefixPath +this.props.photo} alt=''/>);
        let input = <input disabled={this.props.disabled} style={{'cursor': 'pointer', 'display': 'none', ...this.state.style}} id={this.props.documentTypeId} type="file" onChange={(e) => this._handleImageChange(this.props.documentTypeId, e)}/>;
        return (
            <div className="p-grid p-justify-center">
            <div className="p-col">
            <div style={{'height':parseInt(this.state.style.height)+2+'px','width':parseInt(this.state.style.width)+2+'px'}} className="imgPreview3">
                <label htmlFor={this.props.documentTypeId}>
                {$imagePreview}
                </label>
                {input}
                </div>
                </div>
            </div>
        );
    }
  
  }
