import React, { Component } from 'react';
import { g_checkLoginACL, g_template_dataTable, g_getHeaderTemplatecF, g_genericRequired } from '../GenericFunctions';
import {Messages} from 'primereact/messages';

export default class Reportsetl extends Component {

    constructor() { super(); this.state = g_genericRequired(); }
    render() {return g_checkLoginACL(this.grender);}

    grender = () => {
       return (
            <div className="card" >
                <h1>Relatorio ETL</h1>
                <Messages ref={e => this.messages = e}/>
                {g_template_dataTable(this, g_getHeaderTemplatecF(this, () => {}, this.getData, false), [])}
            </div>
       );
    }
}
