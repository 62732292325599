import {g_ChangeStatus} from '../components/GenericFunctions';
import axios from './AxiosService';

class PromotionsService {

    list = data => axios.request('POST', `/promotion/list`, data);
    updateContent = data => axios.request('PUT', '/promotion/update/content', data);
    add = data => axios.request('POST', '/promotion/add', data);
    update = data => axios.request('PUT', '/promotion/update', data);
    getPromotionImages = data => axios.request('POST', `/promotion/image/list`, data);
    getPromotionTypes = data => axios.request('POST', `/promotion/promotionTypes`, data);
    getPromotionCategories = data => axios.request('POST', `/promotion/promotionCategories`, data);
    deletePromotionImage = imageIdUUID => axios.request('DELETE', `/promotion/image/${imageIdUUID}`, '');
    updatePrice = data => axios.request('PUT', `/promotion/simulation/price`, data);
    updateStatusRaw = data => axios.request('PUT', `/promotion/update/status`, data);
    updateStatus = (context, idUUID, status, callback= ()=> {}, message="", messages=context.messages, e) => g_ChangeStatus(context, () => this.updateStatusRaw({idUUID: idUUID, promotionStatus: status}), callback, message, messages, e )
    imageUpload = (formData, documentTypeId, productUUID) => {
        const config = {'content-type': 'multipart/form-data', 'background-request': true};
        return axios.request('POST', `/promotion/image/upload/${documentTypeId}/${productUUID}`, formData, config);
    }

}

export default new PromotionsService();
