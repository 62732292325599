import DashboardService from '../../service/DashboardService';
import React from 'react';
import { Chart } from 'primereact/chart';
import {g_getData, g_entityFilter} from '../GenericFunctions';

export function getContratsData(context, filterCheck, lineMonth, lineYear, lineWeek, isQueued, isAprove, filterTimelessCheck){
    g_getData(context, filterCheck, response => {
        let activeC = 0;
        let closedC = 0;
        let total = 0;
        let activeCValue = 0;
        let closedCValue = 0;
        let toAprove = 0;
        let negated = 0;
        let queued = 0;
        let pieDataLabels = [];
        let pieDataValues = [];
        let pieDataValuesNClosed = [];
        let pieDataColors = [];
        console.log(response.contractGroupeds);
        for (let a of response.contractGroupeds) {
            total += a.contractCounts;
            closedC += a.contractStatus === 100 ? a.contractCounts : 0;
            negated += a.contractStatus === 200 ? a.contractCounts : 0;
            activeC += a.contractStatus === 1 ? a.contractCounts : 0;
            toAprove += isAprove(a) ? a.contractCounts : 0;
            closedCValue += a.contractStatus === 100 ? a.contractValues : 0;
            activeCValue += a.contractStatus === 1 ? a.contractValues : 0;
            queued  += isQueued(a) ? a.contractCounts : 0;
            if (a.contractCounts === 0 ) continue;
            let index = pieDataLabels.indexOf(a.productName);
            if (index === -1) {
                pieDataLabels.push(a.productName);
                pieDataValues.push(a.contractStatus === 1 || a.contractStatus === 100 ? a.contractCounts : 0);
                pieDataValuesNClosed.push(!(a.contractStatus === 1 || a.contractStatus === 100) ? a.contractCounts : 0);
                pieDataColors.push(randomColor());
            } else {
                pieDataValues[index] = pieDataValues[index] + (a.contractStatus === 1 || a.contractStatus === 100 ? a.contractCounts : 0);
                pieDataValuesNClosed[index] = pieDataValuesNClosed[index] + (!(a.contractStatus === 1 || a.contractStatus === 100) ? a.contractCounts : 0);
            }
        }
        if (pieDataLabels.length > 10) {
            let av = 0;
            pieDataValuesNClosed.forEach(a => av += a);
            av = av / pieDataValuesNClosed.length;
            let other = 0;
            pieDataValuesNClosed = pieDataValuesNClosed.map(a => {if(a - av/3 > 0) return a; other += a;  return 0;});
            pieDataValuesNClosed.push(other);
            av = 0;
            pieDataValues.forEach(a => av += a);
            av = av / pieDataValues.length;
            other = 0;
            pieDataValues = pieDataValues.map(a => {if(a - av/3 > 0) return a; other += a; return 0;});
            pieDataValues.push(other);
            pieDataColors.push(randomColor());
            pieDataLabels.push('Outros');
            let npeilabels = [];
            let npeivalues = [];
            let npeivaluesN = [];
            for (let i = 0; i < pieDataValues.length; i++) {
                if (pieDataValues[i] !== 0 || pieDataValuesNClosed[i] !== 0) {
                    npeilabels.push(pieDataLabels[i]);
                    npeivalues.push(pieDataValues[i]);
                    npeivaluesN.push(pieDataValuesNClosed[i]);
                }
            }
            pieDataLabels = npeilabels;
            pieDataValues = npeivalues;
            pieDataValuesNClosed = npeivaluesN;
        }
        let piedata = {
            labels: pieDataLabels,
            datasets: [{label: "Ativos e Terminados",data: pieDataValues,backgroundColor: pieDataColors,hoverBackgroundColor: pieDataColors,}, {label: 'Outros(Exepção Negagos)', data: pieDataValuesNClosed,backgroundColor: pieDataColors,hoverBackgroundColor: pieDataColors,}]
        };
        context.setState({pieData: piedata, contractsTotal: total, activeCContracts: (activeC + closedC), fullValue: (closedCValue + activeCValue), contractsForAproval: toAprove, negatedContracts: negated, queued: queued});
    });
    //Line
    let getDataLine = (ndays , nvalues, nvaluesNegados, nvaluesTerminados) => { return {
        labels: ndays,
        datasets: [
            {label: 'Ativos',data: nvalues,fill: true, borderColor: '#20d077', backgroundColor: '#20d07744'},
            {label: 'Terminados',data: nvaluesTerminados,fill: true,borderColor: '#00448f'},
            {label: 'Negados',data: nvaluesNegados,fill: true,borderColor: '#ef6262', backgroundColor: '#ef626244'},
        ] };};
    if (context.state.smainsec === '2') {
        g_getData(context, lineMonth
                  , response => {
                      let days = [];
                      let daysvalues = [];
                      for(let a of response.contractGroupeds) {days.push(String(a.periodValue).substr(6,2));daysvalues.push(a);}
                      let ndays = [];
                      let nvalues = [];
                      let nvaluesNegados = [];
                      let nvaluesTerminados = [];
                      console.log(daysvalues.filter(a => a.contractStatus === 200));
                      let d = new Date();
                      for (let i = 1; i <= new Date(d.getFullYear(), Number(context.state.ssecsecv)+ 1, 0).getDate(); i++) {
                          ndays.push(i);
                          let toAdd = 0;
                          days.map((a,e) => [a,e]).filter((a, e) => Number(a[0]) === i && (daysvalues[e].contractStatus === 1)).forEach(a => toAdd += daysvalues[a[1]].contractCounts );
                          nvalues.push(toAdd);
                          toAdd = 0;
                          days.map((a,e) => [a,e]).filter((a, e) => Number(a[0]) === i && (daysvalues[e].contractStatus === 200)).forEach(a => toAdd += daysvalues[a[1]].contractCounts );
                          nvaluesNegados.push(toAdd);
                          toAdd = 0;
                          days.map((a,e) => [a,e]).filter((a, e) => Number(a[0]) === i && (daysvalues[e].contractStatus === 100)).forEach(a => toAdd += daysvalues[a[1]].contractCounts );
                          nvaluesTerminados.push(toAdd);
                      }
                      context.setState({lineData: getDataLine(ndays, nvalues, nvaluesNegados, nvaluesTerminados) });
                  });
    } else if (context.state.smainsec === '3') {
        g_getData(context, lineYear, response => {
            let days = [];
            let daysvalues = [];
            for(let a of response.contractGroupeds) { days.push(String(a.periodValue).substr(4,2)); daysvalues.push(a); }
            let ndays = [];
            let nvalues = [];
            let nvaluesNegados = [];
            let nvaluesTerminados = [];
            for (let i = 1; i <= 12; i++) {
                ndays.push(i);
                let toAdd = 0;
                days.map((a,e) => [a,e]).filter((a, e) => Number(a[0]) === i && (daysvalues[e].contractStatus === 1)).forEach(a => toAdd += daysvalues[a[1]].contractCounts );
                nvalues.push(toAdd);
                toAdd = 0;
                days.map((a,e) => [a,e]).filter((a, e) => Number(a[0]) === i && (daysvalues[e].contractStatus === 200)).forEach(a => toAdd += daysvalues[a[1]].contractCounts );
                nvaluesNegados.push(toAdd);
                toAdd = 0;
                days.map((a,e) => [a,e]).filter((a, e) => Number(a[0]) === i && (daysvalues[e].contractStatus === 100)).forEach(a => toAdd += daysvalues[a[1]].contractCounts );
                nvaluesTerminados.push(toAdd);
            }
            context.setState({lineData: getDataLine(context.state.months, nvalues, nvaluesNegados, nvaluesTerminados) });
        });
    } else if (context.state.smainsec === '1') {
        var curr = new Date();
        var first = curr.getDate() - curr.getDay();
        var last = first + 6;
        var firstday = new Date(curr.setDate(first));
        var lastday = new Date(curr.setDate(last));
        g_getData(context, data => lineWeek(data, firstday, lastday), response => {
            let days = [];
            let daysvalues = [];
            for(let a of response.contractGroupeds) {
                days.push(String(a.periodValue).substr(6,2));
                daysvalues.push(a);
            }
            let ndays = [];
            let nvalues = [];
            let nvaluesNegados = [];
            let nvaluesTerminados = [];
            for (let i = 0; i < 7; i++) {
                let d = new Date(curr.setDate(first + i)).getDate();
                ndays.push(d);
                let toAdd = 0;
                days.filter((a, e) => Number(a) === i && (daysvalues[e].contractStatus === 1)).forEach((a, e) => toAdd += daysvalues[e].contractCounts );
                nvalues.push(toAdd);
                toAdd = 0;
                days.filter((a, e) => Number(a) === i && (daysvalues[e].contractStatus === 200)).forEach((a, e) => toAdd += daysvalues[e].contractCounts );
                nvaluesNegados.push(toAdd);
                toAdd = 0;
                days.filter((a, e) => Number(a) === i && (daysvalues[e].contractStatus === 100)).forEach((a, e) => toAdd += daysvalues[e].contractCounts );
                nvaluesTerminados.push(toAdd);
            }
            context.setState({lineData: getDataLine(ndays, nvalues, nvaluesNegados, nvaluesTerminados) });
        });
    } else {
        context.setState({lineData: {}});
    }

    //Timeless
    
    g_getData(context, filterTimelessCheck, response => {
        let totalValue = 0;
        let total = 0;
        for (let a of response.contractGroupeds) {
            total += a.contractCounts;
            totalValue += a.contractStatus === 100 || a.contractStatus === 1 ? a.contractValues : 0; 
        }
        context.setState({ contractsTotalTimeless: total, fullValueTimeless: totalValue, });
    });
}


/**
* @function
*/
export function getContractsDataConsignator(context) {
    getContratsData(context, data => {
        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        console.log(data);
        data.filters = [...f[0],{key:'groupingType', value: context.state.requestType[context.state.smainsec].typer}, {key: 'periodValue', value: context.state.requestType[context.state.smainsec].processData()} ];
        return DashboardService.contractList(data);
    }, data => {
        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        let d = new Date();
        data.filters = [...f[0],{key:'groupingType', value: '1'}, {key: 'periodValueStart', value: `${d.getFullYear()}${gSize(Number(context.state.ssecsecv) + 1)}01`}, {key: 'periodValueEnd', value: `${d.getFullYear()}${gSize(Number(context.state.ssecsecv) + 1)}31`}  ];
        return DashboardService.contractList(data);
    }, data => {
        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: '3'}, {key: 'periodValueStart', value: `${context.state.ssecsecv}01`}, {key: 'periodValueEnd', value: `${context.state.ssecsecv}12`}  ];
        return DashboardService.contractList(data);
    }, (data, firstday, lastday) => {
        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: '1'}, {key: 'periodValueStart', value: `${firstday.getFullYear()}${gSize(firstday.getMonth() + 1)}${gSize(firstday.getDate())}`}, {key: 'periodValueEnd', value: `${lastday.getFullYear()}${gSize(lastday.getMonth() + 1)}${gSize(lastday.getDate())}`}  ];
        return DashboardService.contractList(data);
    }, a => !(a.contractStatus === 1 || a.contractStatus === 100 || a.contractStatus === 200), a => false
    , data => {
        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: '4'} ];
        return DashboardService.contractList(data);
    });
}

/**
* @function
*/
export function getContractsDataFinancier(context) {
    getContratsData(context, data => {
        let f = g_entityFilter(data.filters, 'financierIdUUID', 'financiersIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: context.state.requestType[context.state.smainsec].typer}, {key: 'periodValue', value: context.state.requestType[context.state.smainsec].processData()} ];
        return DashboardService.contractList(data);
    }, data => {
        let f = g_entityFilter(data.filters, 'financierIdUUID', 'financiersIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        let d = new Date();
        data.filters = [...f[0],{key:'groupingType', value: '1'}, {key: 'periodValueStart', value: `${d.getFullYear()}${gSize(Number(context.state.ssecsecv) + 1)}01`}, {key: 'periodValueEnd', value: `${d.getFullYear()}${gSize(Number(context.state.ssecsecv) + 1)}31`}  ];
        return DashboardService.contractList(data);
    }, data => {
        let f = g_entityFilter(data.filters, 'financierIdUUID', 'financiersIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: '3'}, {key: 'periodValueStart', value: `${context.state.ssecsecv}01`}, {key: 'periodValueEnd', value: `${context.state.ssecsecv}12`}  ];
        return DashboardService.contractList(data);
    }, (data, firstday, lastday) => {
        let f = g_entityFilter(data.filters, 'financierIdUUID', 'financiersIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: '1'}, {key: 'periodValueStart', value: `${firstday.getFullYear()}${gSize(firstday.getMonth() + 1)}${gSize(firstday.getDate())}`}, {key: 'periodValueEnd', value: `${lastday.getFullYear()}${gSize(lastday.getMonth() + 1)}${gSize(lastday.getDate())}`}  ];
        return DashboardService.contractList(data);
    }, a => !(a.contractStatus === 8 || a.contractStatus === 9 || a.contractStatus === 1 || a.contractStatus === 100 || a.contractStatus === 200), a => a.contractStatus === 8 || a.contractStatus === 9
    ,data => {
        let f = g_entityFilter(data.filters, 'financierIdUUID', 'financiersIdUUID', 5, 'dashboards#totalaccess');
        if (!f) return {code: 0, contractGroupeds: [], pageTotal: 0, recordsTotal: 0};
        data.filters = [...f[0],{key:'groupingType', value: '4'} ];
        return DashboardService.contractList(data);
    });
}

//templates

export function contractRenderTimeless(context) {
    return [ 
        <div key="c1" className="p-col-12 p-lg-5">
          <div className="card summary">
            <span className="title">Total Pedidos</span>
            <span className="detail"></span>
            <span className="count purchases">{processNumber(context.state.contractsTotalTimeless)}</span>
          </div>
        </div>,
        <div key="c2" className="p-col-12 p-lg-5">
          <div className="card summary">
            <span className="title">Valor Contradados</span>
            <span className="detail"></span>
            <span className="count purchases">R${processNumber(context.state.fullValueTimeless)}</span>
          </div>
        </div>];
}

export function processNumber(n) {
    //let r = '';
    //n = String(n);
    //while (n.length > 3) {
        //r = n.substr(-3) + (r ? ',' + r : '');
        //n = n.substr(0, n.length-3)
    //}
    //r = n + (r ? ',' + r : '');
    //return r && r !== "undefined" ? r : '0';
    return String(n).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function contractRenderPart1(context) {
    return [ 
        <div key="c1" className="p-col-12 p-lg-5">
          <div className="card summary">
            <span className="title">Total Pedidos</span>
            <span className="detail"></span>
            <span className="count purchases">{processNumber(context.state.contractsTotal)}</span>
          </div>
        </div>,
        <div key="c2" className="p-col-12 p-lg-5">
          <div className="card summary">
            <span className="title">Valor Contradados</span>
            <span className="detail"></span>
            <span className="count purchases">R${processNumber(context.state.fullValue)}</span>
          </div>
        </div>];
}

export function contractRenderPartGraphs(context) {
    return [
        <div className="p-col-12 p-md-6 p-xl-3">
          <div className="highlight-box">
            <div className="initials" style={{backgroundColor:'#007be5',color:'#00448f'}}><span>F</span></div>
            <div className="highlight-details ">
              <span>Fila</span>
              <span className="count">{context.state.queued}</span>
            </div>
          </div>
        </div>,
        <div className="p-col-12 p-md-6 p-xl-3">
          <div className="highlight-box">
            <div className="initials" style={{backgroundColor:'#f9c851',color:'#b58c2b'}}><span>PA</span></div>
            <div className="highlight-details ">
              <span>Para Aprovação</span>
              <span className="count">{context.state.contractsForAproval}</span>
            </div>
          </div>
        </div>,
        <div className="p-col-12 p-md-6 p-xl-3">
          <div className="highlight-box">
            <div className="initials" style={{backgroundColor:'#20d077',color:'#038d4a'}}><span>A</span></div>
            <div className="highlight-details ">
              <span>Aprovados</span>
              <span className="count">{context.state.activeCContracts}</span>
            </div>
          </div>
        </div>,
        <div className="p-col-12 p-md-6 p-xl-3">
          <div className="highlight-box">
            <div className="initials" style={{backgroundColor:'#ef6262',color:'#a83d3b'}}><span>N</span></div>
            <div className="highlight-details ">
              <span>Negados</span>
              <span className="count">{context.state.negatedContracts}</span>
            </div>
          </div>
        </div>,
        context.state.smainsec === '0' ?
         (<div className="p-col-12 p-lg-3"></div>)
         :
         (<div className="p-col-12 p-lg-6">
             <div className="card">
               <Chart type="line" data={context.state.lineData}/>
             </div>
          </div>)
        ,
        <div className="p-col-12 p-lg-6">
          <div className="card">
            <Chart type="pie" data={context.state.pieData}/>
          </div>
        </div>
    ];
}

export function gSize (a) {return String(a).padStart(2,'0');} 

export function week(year,month,day) {
    function serial(days) { return 86400000*days; }
    function dateserial(year,month,day) { return (new Date(year,month-1,day).valueOf()); }
    function weekday(date) { return (new Date(date)).getDay()+1; }
    function yearserial(date) { return (new Date(date)).getFullYear(); }
    var date = year instanceof Date ? year.valueOf() : typeof year === "string" ? new Date(year).valueOf() : dateserial(year,month,day), 
        date2 = dateserial(yearserial(date - serial(weekday(date-serial(1))) + serial(4)),1,3);
    return ~~((date - date2 + serial(weekday(date2) + 5))/ serial(7));
}

function randomColor () {
    let a = '0123456789ABCDEF';
    let s = '';
    for (let i = 0; i < 6; i++) {s += a[Math.floor(Math.random() * a.length)];}
    return '#' + s;
} 

export function contractRequired (context) { return {
    months: [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    requestType: [{label: 'Diario', value: '0', typer:'1', processData: () => {let d = new Date(); return `${d.getFullYear()}${gSize(d.getMonth() + 1)}${gSize(d.getDate())}`;}}, {label: 'Semanal', value: '1', typer:'2', processData: () => {let d = new Date(); return week(d.getFullYear(), d.getMonth() + 1, d.getDate());}}, {label: 'Mensal', value: '2', typer: '3', processData: () => {let d = new Date(); return `${d.getFullYear()}${gSize(Number(context.state.ssecsecv) + 1)}`;}}, {label: 'Anual', value: '3', typer:4, processData: () => context.state.ssecsecv}],
    activeContracts: 0,
    fullValue: 0,
    contractsTotal: 0,
    contractsForAproval: 0,
    negatedContracts: 0,
    monthDataMonth: 0,
    queued: 0,
    contractsTotalTimeless: 0,
    fullValueTimeless: 0,
}; }
