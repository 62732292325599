import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ConfigService from '../../service/ConfigService';
import { LS, g_checkLoginACL, g_pair, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export class MultilanguageManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired([{ key: 'tag', value: 'desc' }]);
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => g_getData(this, ConfigService.multilanguageList, 'languages');
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('multilanguage', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, `#/multilanguage?type=1`, `addnewmultilingua`, this.getData, 1000, 400, e.c === 1),
                    [
                        { type: 'd', data: 'tag', filter: true, sortable: true },
                        { type: 'd', data: 'value', filter: true, sortable: true },
                        { type: 'url', icon: 'pi pi-external-link', class: '', url: raw => `#/multilanguage-management-preview?type=1&id=${raw.id}`, width: 1000, height: 400 },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/multilanguage?type=1&id=${raw.id}`, width: 1000, height: 400, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}

export class HTMLPreview extends React.Component {
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        if (!id) {
            window.close();
            return;
        }
        this.setState({ idUUID: query.get('id') }, this.getData);
    }
    getData = () => {
        return g_getData(
            this,
            ConfigService.multilanguageList,
            r => {
                let data = (r?.languages ?? [])[0] ?? {};
                this.setState({ value: data.value ?? '' });
            },
            { filters: [g_pair('id', this.state.idUUID ?? '')] }
        );
    };
    render() {
        return <div style={{ width: '100vw', height: 'auto', minHeight: '100vh', backgroundColor: '#fff' }} dangerouslySetInnerHTML={{ __html: this.state?.value ?? '' }}></div>;
    }
}
