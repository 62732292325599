import UtilsService from '../../service/UtilsService';
import Config from '../../config/config';

class EncryptPassword {
    async encrypt(password) {
        var ciphertext64;

        const resp = await UtilsService.getTimestamp();

        if (resp) {
            var forge = require('node-forge');

            var pki = forge.pki;

            var publicKey = pki.publicKeyFromPem(Config.KEY);

            var text = password + '{ts:' + String(resp.timestamp) + '}';

            var encrypted = publicKey.encrypt(text, 'RSA-OAEP', {
                md: forge.md.sha1.create(),
                mgf: forge.mgf.mgf1.create(forge.md.sha1.create()),
            });

            ciphertext64 = forge.util.encode64(encrypted);
        }

        return ciphertext64;
    }

    async encryptGen(f) {
        var ciphertext64;

        const resp = await UtilsService.getTimestamp();

        if (resp) {
            var forge = require('node-forge');

            var pki = forge.pki;

            var publicKey = pki.publicKeyFromPem(Config.KEY);

            var text = f(String(resp.timestamp));

            var encrypted = publicKey.encrypt(text, 'RSA-OAEP', {
                md: forge.md.sha1.create(),
                mgf: forge.mgf.mgf1.create(forge.md.sha1.create()),
            });

            ciphertext64 = forge.util.encode64(encrypted);
        }

        return ciphertext64;
    }
}
export default new EncryptPassword();
