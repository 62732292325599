import axios from './AxiosService';

class ContractService {

    list = (data, back=false) => axios.request('POST','/contract/list', data, back ? {'background-request': true} : {})
    add = (data, back=false) => axios.request('POST','/contract/add', data, back ? {'background-request': true} : {})
    getContracts = filter => axios.request('POST','/contract/list', filter);
    getContract = (idUUID, back=false) => axios.request('GET', `/contract/${idUUID}`, {}, back ? {'background-request': true} : {});
    getTemplateByTag = (tag, back=false) => axios.request('GET', `contract/template/tag/${tag}`, {}, back ? {'background-request': true} : {});
    addContract = data => axios.request('POST', `/contract/add`, data);
    fieldPut = (data, back=false) => axios.request('POST', `/contract/field/put`, data, back ? {'background-request': true} : {});
    getInstallments = data => axios.request('POST', `/contract/installments/list`, data);
    getContractTemplate = data => axios.request('POST', `/contract/template/list`, data);
    
    installments = {
        updateStatus: data => axios.request('PUT', `/contract/installments/update/status`, data),
    }

    //updates
    updateContract = (data, back=false) => axios.request('PUT', `/contract/update`, data, back ? {'background-request': true} : {});
    updateWorkingEntity = (data, back=false) => axios.request('PUT', `/contract/update/workingEntity`, data, back ? {'background-request': true} : {});
    updateContractObservation = (data, back=false) => axios.request('PUT', `contract/update/observation`, data, back ? {'background-request': true} : {});
    updateStatus = data => axios.request('PUT', `/contract/update/status`, data);

    async getContractTypes(data){
        return await axios.request('POST', `/contract/type/list`, data);
    }

    async getDocuments(data){
        return await axios.request('POST', `/contract/document/list`, data);
    }

    async documentUpload(formData, documentTypeId, UUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/contract/document/upload/${documentTypeId}/${UUID}`, formData, config)
    }
}

export default new ContractService();
