import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import NotificationService from '../../service/NotificationService';
import { LS, g_checkLoginACL, g_entityFilter, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import { states } from './Notification';

export default class NotificationManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        let query = new URLSearchParams(this.props.location.search);
        this.setState({ app: query.get('app') === '1' }, this.getData);
    }
    componentDidUpdate() {
        g_hideSearch();
        let query = new URLSearchParams(this.props.location.search);
        if ((query.get('app') === '1') !== this.state.app) this.setState({ app: query.get('app') === '1' }, this.getData);
    }
    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(this.state.filter, 'companiesUUID', 'companiesUUID', 5, 'notification-management#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, notifications: 0 };
                data.filters = [...f[0], { key: (this.state.app ? '' : 'not') + 'notificationBroadcastType', value: '6' }];
                return NotificationService.getNotifications(data);
            },
            'notifications'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('notification', 'tables')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/notification?type=1', 'addNotification', this.getData, 1000, 600, e.c === 1),
                    [
                        { type: 'd', data: 'description', filter: true },
                        { type: 'd', data: 'notificationTypeDescription' },
                        { type: 'd', data: 'userFullname' },
                        { type: 'date', data: 'dateCreation', filter: true, sortable: true },
                        { type: 'state', data: 'notificationStatus', states },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/notification?type=1&id=${raw.idUUID}`, width: 1000, height: 600, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
