import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import PropTypes from 'prop-types';
import Config from '../../config/config';
import UtilsService from '../../service/UtilsService';
import {
    LS,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_genericRequired,
    g_hideSearch,
    g_getData,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDataFields,
    g_template_button,
    g_template_dropdown,
    g_pair,
    g_wraper,
} from '../GenericFunctions';

export default class attachment extends React.Component {
    static defaultProps = { idUUID: null, documentContextType: null, service: null, documentTypeId: -1 };
    static propTypes = { idUUID: PropTypes.string, documentContextType: PropTypes.number, service: PropTypes.string, documentTypeId: PropTypes.number };
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), documentTypeId: props.documentTypeId, displayDialog: false };
    }
    addNew = e => {
        e.preventDefault();
        this.setState({ displayDialog: true });
    };
    getData = () =>
        g_getData(this, data => UtilsService.getDocuments(data, this.props.service), 'documents', {
            documentContextType: this.props.documentContextType,
            filters: [...this.state.filter, g_pair('documentStatus', 1), g_pair('referenceIdUUID', this.props.idUUID)],
        });
    HandleBrowseClick(e) {
        e.preventDefault();
        document.getElementById('file').click();
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
        g_getDataFields(this, UtilsService.getDocumentType, 'documentTypes', 'description', 'id', 'documentTypes', { filters: [g_pair('documentContextType', this.props.documentContextType)] });
    }

    componentDidUpdate() {
        if (this.state.activeItem !== this.props.update) {
            this.setState({ activeItem: this.props.update, documentTypeId: this.props.documentTypeId });
            this.getData();
            g_getDataFields(this, UtilsService.getDocumentType, 'documentTypes', 'description', 'id', 'documentTypes', { filters: [g_pair('documentContextType', this.props.documentContextType)] });
            return;
        }
    }

    handleChange = e => {
        e.preventDefault();
        const { value, name } = e.target;
        this.setState({ ...this.state, [name]: value });
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadPhotoHandler(file, this.state.documentTypeId);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadPhotoHandler = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        var response = await UtilsService.documentUpload(formData, type, this.props.idUUID, this.props.service);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleUpload', 'messages') });
        this.setState({ displayDialog: false }, this.getData);
    };

    render() {
        return g_checkLoginACL(
            () => (
                <div className="card">
                    <input id="file" type="file" accept=".jpg, .png, .doc, .pdf" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                    <form id="2" onSubmit={this.HandleBrowseClick}>
                        <Messages ref={e => (this.messages = e)} />
                        {g_template_dataTable(
                            this,
                            g_getHeaderTemplatecF(this, this.addNew, this.getData, this.props.add ?? true),
                            [
                                { type: 'd', data: 'description', sortable: true, filter: true },
                                { type: 'd', data: 'documentTypeDescription', sortable: true, filter: true },
                                { type: 'd', data: 'filenameOriginal', sortable: true, filter: true },
                                { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                                { type: 'url', url: raw => Config.DOCUMENT_URL + this.props.service + '/' + raw.filename, width: 1000, heigth: 600, callback: this.getData },
                            ],
                            this.getData
                        )}
                        <Dialog visible={this.state.displayDialog} style={{ width: '45vw' }} header="Anexo" modal={true} onHide={() => this.setState({ displayDialog: false })}>
                            <div className="p-col-2" style={{ padding: '2em' }}></div>
                            <div className="p-grid p-fluid">
                                {g_wraper(
                                    'documentTypeId',
                                    LS('documentTypeId'),
                                    g_template_dropdown(this, 'documentTypeId', this.state.documentTypeId, '', this.state.documentTypes, false, false, true, 'p-col-6', '', false, '', {
                                        data: 'documentTypeId',
                                    }),
                                    false,
                                    'p-col-2',
                                    true
                                )}
                                {g_template_button(LS('', 'btt'), 'pi pi-download', false, null, false, 'p-col-3')}
                            </div>
                            <div className="p-col-2" style={{ padding: '2em' }}></div>
                        </Dialog>
                    </form>
                </div>
            ),
            false
        );
    }
}
