import React from 'react';
import Services from '../../service/Services';
import { g_ApiRatting, g_checkLoginACL, g_checkMode, g_displayMessageError, g_getData, g_tabViewRenderOptions, g_template_button, g_template_dialog, g_template_form, LS } from '../GenericFunctions';

export class Rating extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiRatting(), items: [{ label: 'Rate' }], idUUID: null, ctid: null, mode: true };
    }
    onCancel = e => Services.utils.ratting.processed.status(this, this.state.data.idUUID, this.state.data.status === 1 ? 2 : 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);
    getData = () =>
        this.state.idUUID
            ? g_getData(this, Services.utils.ratting.list, response => this.setState({ data: response.rattingCategories[0] ?? g_ApiRatting() }), {
                  filters: [{ key: 'idUUID', value: this.state.idUUID }],
              })
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id'),
            ctid = query.get('ctid');
        if (!idUUID && !ctid) {
            window.close();
            return;
        }
        let items = [{ label: LS('ratting', 'tab') }];
        this.setState({ mode: query.get('type') !== '1', idUUID: idUUID, ctid: ctid, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (!this.state.idUUID && !this.state.ctid) {
            window.close();
            return;
        }
        if (!this.state.idUUID) {
            const response = await Services.utils.ratting.add({ rattingCategory: { ...data, contractTemplateIdUUID: this.state.ctid } });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/ratting?type=1&id=${response.rattingCategory.idUUID}`;
            window.location.reload();
            return;
        }
        const response = await Services.utils.ratting.update({ contractTemplateMargin: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
    };

    render() {
        return g_checkLoginACL(
            e =>
                g_checkMode(this, e, this.state.idUUID, () => (
                    <div className="card card-w-title">
                        {g_tabViewRenderOptions(this, [
                            g_template_form(
                                this,
                                () => [
                                    {
                                        t: 'array',
                                        dTypeF: 't',
                                        cc: 3,
                                        req: { _: true },
                                        filter: ['description'],
                                    },
                                ],
                                mode => [
                                    g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.status === 2, null, mode, 'p-col-2'),
                                    g_template_dialog(
                                        this,
                                        LS(this.state.data.status === 1 ? 'simpleDeactivate' : 'simpleActivate', 'messages'),
                                        'cancel ',
                                        this.onCancel,
                                        LS(this.state.data.status === 1 ? 'deactivate' : 'activate', 'btt'),
                                        !this.state.data.idUUID,
                                        this.state.mode,
                                        '',
                                        null,
                                        null,
                                        null,
                                        'p-col-2',
                                        'p-button-warning'
                                    ),
                                ],
                                () => [[{ t: 'array', filter: ['status', 'dateCreation', 'dateUpdate'], dTypeF: 'view', dTypeFs: { 0: 'state' }, class: 'l', states: { 0: rattingStates } }]],
                                this.submit,
                                2,
                                true,
                                this.state.mode || this.state.data.status === 2,
                                this.state.idUUID ? 9 : 12,
                                this.state.idUUID ? 3 : -1,
                                '70vh'
                            ),
                        ])}
                    </div>
                )),
            true,
            'contractTemplate'
        );
    }
}

export const rattingStates = { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' } };
