import axios from './AxiosService'

class ContractTemplateFieldService {

    async getContractTemplatesFields(filter) {
        return await axios.request('POST',`/contract/template/field/list`, filter)
    }

    async getContractTemplateField(filter,contractTemplateFieldIdUUID) {
        return await axios.request('GET',`/contract/template/field/${contractTemplateFieldIdUUID}`, filter)
    }

    async addContractTemplateField(data) {
        return await axios.request('POST', `/contract/template/field/add`, data)
    }

    async updateContractTemplateField(data){
        return await axios.request('PUT', `/contract/template/field/update`, data)
    }

    async getContractTemplateFieldTypes(data) {
        return await axios.request('POST', `/contract/template/field/type/list`, data)
    }

}

export default new ContractTemplateFieldService();