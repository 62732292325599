import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ConfigService from '../../service/ConfigService';
import {
    lt,
    lm,
    ls,
    lb,
    StateIcon,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_dropdown,
    g_template_input,
    g_wraper,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class CacheEdit extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                cacheManagerStatus: 0,
                cacheManagerType: 0,
                dateCreation: '',
                dateUpdate: '',
                id: 0,
                parentId: 0,
                refreshDbConfigs: 0,
                refreshFileConfigs: 0,
                refreshLanguageConfigs: 0,
                serviceId: '',
                idUUID: '',
            },
            items: [{ label: lt('cache') }],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, mode: mode }, this.getData);
    }

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.idUUID) return;
        let data = this.state.data;
        let response = await ConfigService.cacheAdd({ cacheManager: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleCreate') });
        this.setState({ idUUID: response.cacheManager.idUUID }, this.getData);
    };

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
        };
        let response = await ConfigService.cacheList(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.cacheManagers[0]) {
            g_nullarrayresponse(this);
            return;
        }
        let rdata = response.cacheManagers[0];
        rdata.cacheManagerType = String(rdata.cacheManagerType);
        this.setState({
            data: response.cacheManagers[0],
            items: [{ label: lt('cache') }, { label: lt('log'), icon: 'pi-list pi' }],
        });
    };

    //FIXME: log
    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null) || this.state.idUUID)) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        {g_template_dropdown(
                                            this,
                                            'cacheManagerType',
                                            this.state.data.cacheManagerType,
                                            ls('cacheManagerType'),
                                            [{ label: ls('1', 'cacheManagerType'), value: '1' }],
                                            this.state.mode,
                                            false,
                                            true
                                        )}
                                        {g_template_input(
                                            this,
                                            'serviceId',
                                            this.state.data.serviceId,
                                            ls('serviceId'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-2',
                                            'p-col-3',
                                            '0 aplica-se a todos, maior que 0 aplica-se a um serviço específico',
                                            { regex: new RegExp('\\d*') }
                                        )}
                                        {g_template_checkBox(this, 'refreshDbConfigs', this.state.data.refreshDbConfigs === 1, ls('refreshDbConfigs'), this.state.mode)}
                                        {g_template_checkBox(this, 'refreshFileConfigs', this.state.data.refreshFileConfigs === 1, ls('refreshFileConfigs'), this.state.mode)}
                                        {g_template_checkBox(this, 'refreshLanguageConfigs', this.state.data.refreshLanguageConfigs === 1, ls('refreshLanguageConfigs'), this.state.mode)}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_wraper(
                                            null,
                                            ls('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.cacheManagerStatus}
                                                    small
                                                    custom={{
                                                        0: { class: 'pi-spin pi-spinner', color: 'grey' },
                                                        1: { class: 'pi-check', color: 'green' },
                                                        2: { class: 'pi-cross', color: 'red' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_wraper(null, ls('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_wraper(null, ls('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">{g_template_button(lb('process'), '', this.state.idUUID, null, this.state.mode)}</div>
                        </form>,
                        <Log idUUID={this.state.idUUID} logContextType={19} />,
                    ])}
                </div>
            );
        });
    }
}
