import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ProfileService from '../../service/ProfileService';
import { LS, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ControlItemManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => g_getData(this, ProfileService.accessItensList, 'accessItems');
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('aclItem', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/control-item?type=1', 'Criar Novo Item de Controlo', this.getData, 1000, 275, e.c === 1),
                    [
                        { type: 'd', data: 'description', filter: true },
                        { type: 'd', data: 'accessItemTag', filter: true },
                        { type: 'd', data: 'accessItemTypeDescription' },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'accessItemStatus',
                            states: () => ({ 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } }),
                        },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/control-item?type=1&id=${raw.idUUID}`, width: 1000, height: 275, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
