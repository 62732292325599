import React, { MouseEvent, useState } from 'react';
import type { QueryParam } from './GenericType';

export function pair<T>(key: string, value: T) {
    return { key, value };
}

export function pairF<T>(key: string, value: T, filter: boolean = false): QueryParam<T>[] {
    if (filter) return [{ key, value }];
    return [];
}

export function StateIcon(props: {
    custom: { [key: number]: { class: string; color?: string; icon?: string; tooltip?: string; text?: string } };
    state: number;
    style?: React.CSSProperties;
    small?: boolean;
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
}) {
    let [tooltip, setTooltip] = useState(false),
        { custom, state, onClick } = props,
        style = { fontSize: props.small ? '1.4em' : '1.87em', ...(props.style ?? {}) };

    if (custom && custom[state]) {
        let icon = custom[state];
        style = { ...style, color: icon.color ?? 'black', ...(onClick ? { cursor: 'pointer' } : {}) };
        return (
            <span onMouseOver={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)}>
                <div
                    style={{
                        display: tooltip && icon.tooltip ? 'block' : '',
                        marginTop: '0.5em',
                        backgroundColor: '#222d',
                        color: '#fff',
                        maxWidth: '5em',
                    }}
                    className="p-tooltip"
                >
                    {icon.tooltip}
                </div>
                <span onClick={onClick} className={'pi ' + icon.class} style={style}>
                    {icon.text ?? ''}
                </span>
            </span>
        );
    }
    return <span onClick={onClick} className="pi pi-question" style={{ color: 'grey', fontSize: '1.83em' }} />;
}
