import React, { useRef } from 'react';
import {
    S,
    LS,
    g_consignators,
    pair,
    g_dataTable,
    g_getHeaderTemplate,
    ListPage,
    g_genericRequired,
    g_hideSearch,
    g_checkAlc,
    g_getData,
    g_entityFilter,
    g_checkLoginACL,
    g_template_dataTable,
} from '../GenericFunctions';
import { states } from './Consignee';
import { states as contractStates } from '../contract/Contract';
import { statgingData } from '../staging/consignee/StagingConsigneeManagement';

const urlConsignee = id => `#/consignee?type=1${id ? `&id=${id}` : ''}`;
const sizeConsignee = [1200, 600];

function ConsigneeManagementRender() {
    const baseT = useRef(null);

    if (g_consignators().split(',').length === 1 && g_consignators() && !g_checkAlc('consignator-management#accessall', 5))
        return (
            <div className="card">
                <ListPage
                    ref={baseT}
                    title={LS('driverManagementAprove', 'titles')}
                    header={context => g_getHeaderTemplate(context, '', '', context.getData, 0, 0, false)}
                    table={(context, e) => [
                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                        { type: 'd', data: 'username', sortable: true, filter: true },
                        { type: 'd', data: 'email', sortable: true, filter: true },
                        { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                        { type: 'd', data: 'vatNumber', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'consigneeStatus', states: states },
                        { type: 'url', c: e.d === 1 || e.u === 1, url: raw => statgingData.url(raw.idUUID), width: sizeConsignee[0], height: sizeConsignee[1], callback: context.getData },
                    ]}
                    //AddNotInconsignee, 1 flag
                    getData={c =>
                        g_getData(
                            c,
                            S.consignee.staging.list,
                            { p: 'consignees', c: g_hideSearch },
                            { filters: [...c.state.filter, pair('notinconsigneebyconsignator', g_consignators()), pair('consignatoriduuid', g_consignators())] }
                        )
                    }
                    hidden={c => c.state.data.length === 0}
                />

                <ListPage
                    title={LS('driverManagement', 'titles')}
                    header={(context, e) =>
                        g_getHeaderTemplate(
                            context,
                            statgingData.url(),
                            'create new consignee',
                            () => {
                                context.getData();
                                baseT.current.getData();
                            },
                            statgingData.size[0],
                            statgingData.size[1],
                            e.c
                        )
                    }
                    table={(context, e) => [
                        { type: 'd', data: 'consignatorFantasyName', filter: true, c: context.state.showMore },
                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                        { type: 'd', data: 'username', sortable: true, filter: true },
                        { type: 'd', data: 'email', sortable: true, filter: true },
                        { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                        { type: 'd', data: 'vatNumber', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'consigneeStatus', states: states },
                        { type: 'url', c: e.d === 1 || e.u === 1, url: raw => urlConsignee(raw.idUUID), width: sizeConsignee[0], height: sizeConsignee[1], callback: context.getData },
                    ]}
                    getData={S.consignee.processed.list}
                />
            </div>
        );
    else
        return (
            <ListPage
                title={LS('driverManagement', 'titles')}
                header={context => g_getHeaderTemplate(context, '', '', context.getData, 0, 0, false)}
                table={(context, e) => [
                    { type: 'd', data: 'consignatorFantasyName', filter: true, c: context.state.showMore },
                    { type: 'd', data: 'fullname', sortable: true, filter: true },
                    { type: 'd', data: 'username', sortable: true, filter: true },
                    { type: 'd', data: 'email', sortable: true, filter: true },
                    { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                    { type: 'd', data: 'vatNumber', sortable: true, filter: true },
                    { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                    { type: 'state', data: 'consigneeStatus', states: states },
                    { type: 'url', c: e.d === 1 || e.u === 1, url: raw => urlConsignee(raw.idUUID), width: sizeConsignee[0], height: sizeConsignee[1], callback: context.getData },
                ]}
                getData={S.consignee.processed.list}
            />
        );
}

export const consigneeManagement = {
    render: () => <ConsigneeManagementRender />,
    url: urlConsignee,
    size: sizeConsignee,
};

export class ListContracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_checkAlc(5, 'contract-management#totalaccess')
                ? g_getData(this, S.contract.list, 'contracts', { filters: [...this.state.filter, pair('consigneeIdUUID', this.props.context.state.idUUID)] })
                : g_getData(
                      this,
                      data => {
                          let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                          if (!f) return { code: 0, contracts: [], pageTotal: 0, recordsTotal: 0 };
                          data.filters = [...f[0], pair('filterBehaviour', g_checkAlc(5, 'contract-management#financier') ? 1 : 2), pair('consigneeIdUUID', this.props.context.state.idUUID)];
                          return S.contract.list(data);
                      },
                      'contracts'
                  )
            : null;

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_dataTable(
                        this,
                        null,
                        [
                            { type: 'd', data: 'productName', sortable: true, filter: true },
                            { type: 'd', data: 'contractTotalValue', sortable: true, filter: true },
                            { type: 'd', data: 'installmentTotal', sortable: true, filter: true },
                            { type: 'date', data: 'dateContractStart', sortable: true },
                            { type: 'date', data: 'dateContractEnd', sortable: true },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                            { type: 'state', data: 'contractStatus', filter: true, states: contractStates },
                            {
                                type: 'url',
                                url: raw => `#/contract${raw.workflowType === 5 || raw.workflowType === 6 ? 'P' : ''}?type=1&id=${raw.idUUID}`,
                                width: 1400,
                                height: 500,
                                callback: this.getData,
                                c: e.u === 1 || e.d === 1,
                            },
                        ],
                        this.getData,
                        { enabled: true }
                    )}
                </div>
            ),
            false,
            'consignee#contracts'
        );
    }
}

export class ListSubscriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => (this.props.context.state.idUUID ? g_getData(this, S.subscription.list, 'subscriptions', { referenceIdUUID: this.props.context.state.idUUID }) : null);
    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(
                            this,
                            `/#/subscribe?type=1&cid=${this.props.context.state.idUUID}`,
                            'create new subscribe',
                            this.getData,
                            1000,
                            500,
                            this.props.context.state.idUUID && e.c === 1
                        ),
                        [
                            { type: 'd', data: 'campaignName' },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                            {
                                type: 'state',
                                data: 'campaignStatus',
                                states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                            },
                            { type: 'url', url: raw => `/#/subscribe?type=1&cid=${this.props.context.state.idUUID}&id=${raw.idUUID}`, width: 1000, height: 500 },
                        ],
                        this.getData
                    )}
                </div>
            ),
            false,
            'consignee#subscriptions'
        );
    }
}
