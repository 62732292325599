import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import { Spinner } from 'primereact/spinner';
import React from 'react';
import config from '../../../config/config';
import GPhotoUpload from '../../file/G_photoFileUpload/PhotoUpload';
import Address from '../../generic/Address';
import {
    lm,
    lt,
    ls,
    lb,
    pair,
    StateIcon,
    S,
    g_checkLoginACL,
    g_dialogTemplate,
    g_displayMessageError,
    g_getDateTemplate,
    g_getWraper,
    g_getDataFields,
    g_consignators,
    g_treatNumric,
    g_treatDate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_dropdown,
    g_template_input,
    g_template_inputMask,
    g_wraper,
} from '../../GenericFunctions';
import { statgingData } from './StagingConsigneeManagement';

export default class StagingConsignee extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                dateCreation: '',
                dateLastLogin: '',
                dateLegalAcceptance: '',
                dateMobileEnrollment: '',
                dateUpdate: '',
                email: '',
                fullname: '',
                idUUID: '',
                legalAcceptanceStatus: 0,
                mobileEnrollmentStatus: 0,
                passwordRetries: 0,
                phoneMobile: '',
                photo: '',
                profile: 0,
                consigneeStatus: 0,
                consigneeType: 0,
                username: '',
                vatNumber: '',
                dateBirth: '',
                civilStatus: 0,
                consignatorIdUUID: '',
                dependentNumber: 0,
                employeeId: '',
                entityType: 0,
                fatherName: '',
                gender: 0,
                hablitation: 0,
                motherName: '',
                phoneCommercial: '',
                phoneHome: '',
                registerId: '',
                totalMargin: 0,
                usedMargin: 0,
                loyaltyPointsBalance: 0,
                employeeGovId: '',
            },
            addresses: [],
            mainAddress: { ...Address.EMPTY_ADDRESS },
            idUUID: '',
            items: [{ label: lt('consignee') }],
            holderphoto: null,
        };
    }

    blockStatus = async e => {
        e.preventDefault();
        return;
        const input = { idUUID: this.state.data.idUUID, consigneeStatus: 3 };
        let response = await S.consignee.status(input);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleStatus') });
        this.getData();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        g_getDataFields(this, S.utils.listEntityType, 'entityTypes', 'description', 'id', 'entityTypes');
        g_getDataFields(this, S.utils.listGender, 'genders', 'description', 'id', 'genders');
        g_getDataFields(this, S.utils.listHabilitation, 'hablitations', 'description', 'id', 'hablitations');
        g_getDataFields(this, S.utils.listMaritalStatus, 'civilStatus', 'description', 'id', 'civilStatus');
        this.setState({ idUUID: query.get('id'), wmode: query.get('type') !== '1', mode: query.get('type') !== '1' || query.get('id'), modew: query.get('type') !== '1' }, this.getData);
    }

    handleSubmit = async e => {
        if (e.preventDefault) e.preventDefault();
        if (this.state.idUUID || (!g_consignators() && g_consignators().split(',').length !== 1)) return;
        let data = this.state.data;
        data.consignatorIdUUID = g_consignators().split(',')[0];
        data.entityType = 1;
        data.phoneHome = data.phoneHome ? data.phoneHome.replace(/[^\d]/g, '') : '';
        data.phoneCommercial = data.phoneCommercial ? data.phoneCommercial.replace(/[^\d]/g, '') : '';
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : '';
        data.vatNumber = data.vatNumber ? data.vatNumber.replace(/[^\d]/g, '') : '';
        data.registerId = data.registerId ? data.registerId.replace(/[^\d]/g, '') : '';
        data.address = this.state.addresses.map(a => ({ ...a, latitude: g_treatNumric(a.latitude), longitude: g_treatNumric(a.longitude) }));
        data.dateBirth = g_treatDate(data.dateBirth);
        data = { consignee: data };
        let response = await S.consignee.staging.add(data);
        if (g_displayMessageError(this.messages, response)) return;
        window.location.href = statgingData.url(response.consignees[0].idUUID);
        window.location.reload();
    };

    async getData() {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [pair('idUUID', this.state.idUUID)],
        };
        let response = await S.consignee.staging.list(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.consignees[0];
        if (!rdata) return;
        rdata.civilStatus = String(rdata.civilStatus);
        rdata.gender = String(rdata.gender);
        rdata.hablitation = String(rdata.hablitation);
        rdata.dateBirth = new Date(rdata.dateBirth);
        rdata.entityType = String(rdata.entityType);
        rdata.vatNumber = rdata.vatNumber ? String(rdata.vatNumber).padStart(11, '0') : '';
        let madd = this.state.mainAddress;
        let add = [];
        if (rdata.address)
            rdata.address.forEach(a => {
                if (a.addressType === 0) madd = a;
                else add.push(a);
            });
        this.setState({ data: rdata, addresses: add, mainAddress: madd });
    }

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) this.setState({ holderphoto: formData });
        else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: lm('simpleUploadStart') });
        var response = await S.consignee.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN.uuid === this.state.data.idUUID) {
            loggedUserN.photo = response.photo;
            localStorage.setItem('loggedUserN', JSON.stringify(loggedUserN));
        }
        window.location.reload();
    };

    render() {
        return g_checkLoginACL(e => (
            <div className="card card-w-title">
                {g_tabViewRenderOptions(this, [
                    <form className="p-col">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                <Panel style={{ border: 'none !important', overflowY: 'auto', height: '80vh' }}>
                                    {g_template_input(this, 'fullname', this.state.data.fullname, ls('fullname'), this.state.mode, 0, 1, 'p-col-7', 'p-col-2')}
                                    {g_template_input(this, 'email', this.state.data.email, ls('email'), this.state.mode, 0, 0, 'p-col-6', 'p-col-2', '', {}, 'email')}
                                    {g_wraper(
                                        'vatNumber',
                                        ls('vatNumber'),
                                        [
                                            g_template_dropdown(this, 'entityType', this.state.data.entityType, null, this.state.entityTypes, this.state.mode, false, false, 'p-col-2'),
                                            g_template_inputMask(this, 'vatNumber', this.state.data.vatNumber, null, ls('vatNumber', 'mask'), true, this.state.mode, false, 'p-col-3'),
                                        ],
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_wraper(
                                        'registerId',
                                        ls('registerId'),
                                        [
                                            g_template_dropdown(this, null, '', null, [], true, false, false, 'p-col-2'),
                                            g_template_inputMask(this, 'registerId', this.state.data.registerId, null, '', false, this.state.mode, false, 'p-col-3', '', '', ''),
                                        ],
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_wraper(
                                        'employeeGovId',
                                        ls('employeeGovId'),
                                        [
                                            g_template_input(this, 'employeeGovId', this.state.data.employeeGovId, null, this.state.mode, false, false, 'p-col-3'),
                                            <div className="p-col-1" />,
                                            g_wraper(
                                                'employeeId',
                                                ls('employeeId'),
                                                g_template_input(this, 'employeeId', this.state.data.employeeId, null, this.state.mode, false, false, 'p-col-3', '', '', {
                                                    regex: new RegExp('\\d*', 'g'),
                                                }),
                                                false,
                                                'p-col-2',
                                                true
                                            ),
                                        ],
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_wraper(
                                        'gender',
                                        ls('gender'),
                                        [
                                            g_template_dropdown(this, 'gender', this.state.data.gender, null, this.state.genders, this.state.mode, false, false, 'p-col-2'),
                                            <div className="p-col-2"></div>,
                                            g_wraper(
                                                'hablitation',
                                                ls('hablitation'),
                                                g_template_dropdown(this, 'hablitation', this.state.data.hablitation, null, this.state.hablitations, this.state.mode, false, false, 'p-col-2'),
                                                false,
                                                'p-col-2',
                                                true
                                            ),
                                        ],
                                        false,
                                        'p-col-2'
                                    )}
                                    <h1 style={{ width: '25vw' }}> </h1>
                                    {g_wraper(
                                        'civilStatus',
                                        ls('civilStatus'),
                                        [
                                            g_template_dropdown(this, 'civilStatus', this.state.data.civilStatus, null, this.state.civilStatus, this.state.mode, false, false, 'p-col-3'),
                                            <div className="p-col-1"></div>,
                                            g_wraper(
                                                'dependentNumber',
                                                ls('dependentNumber'),
                                                <div className="p-col-2">
                                                    <Spinner
                                                        disabled={this.state.mode}
                                                        id="dependentNumber"
                                                        value={this.state.data.dependentNumber}
                                                        onChange={e => (!this.state.mode ? g_handleChange(e, {}, this) : () => {})}
                                                        min={0}
                                                        max={100}
                                                    />
                                                </div>,
                                                false,
                                                'p-col-2',
                                                true
                                            ),
                                        ],
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_template_input(this, 'motherName', this.state.data.motherName, ls('motherName'), this.state.mode, false, false, 'p-col-7', 'p-col-2')}
                                    {g_template_input(this, 'fatherName', this.state.data.fatherName, ls('fatherName'), this.state.mode, false, false, 'p-col-7', 'p-col-2')}
                                    {g_wraper(
                                        'dateBirth',
                                        ls('dateBirth'),
                                        <div className="p-col-2">
                                            <Calendar
                                                disabled={this.state.mode}
                                                id="dateBirth"
                                                dateFormat="dd-mm-yy"
                                                value={new Date(this.state.data.dateBirth)}
                                                onChange={e => (!this.state.mode ? g_handleChange(e, {}, this) : () => {})}
                                                yearNavigator
                                                monthNavigator
                                                yearRange={`1900:${new Date().getFullYear() + 10}`}
                                            />
                                        </div>,
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_wraper(
                                        'phoneMobile',
                                        ls('phoneMobile'),
                                        [
                                            g_template_inputMask(
                                                this,
                                                'phoneMobile',
                                                this.state.data.phoneMobile,
                                                null,
                                                ls('phoneMobile', 'mask'),
                                                false,
                                                this.state.mode,
                                                false,
                                                'p-col-3',
                                                '',
                                                '',
                                                ls('phoneMobile', 'placeholder')
                                            ),
                                            <div className="p-col-1"></div>,
                                            g_wraper(
                                                'phoneHome',
                                                ls('phoneHome'),
                                                g_template_inputMask(
                                                    this,
                                                    'phoneHome',
                                                    this.state.data.phoneHome,
                                                    null,
                                                    ls('phoneHome', 'mask'),
                                                    false,
                                                    this.state.mode,
                                                    false,
                                                    'p-col-3',
                                                    '',
                                                    '',
                                                    ls('phoneHome', 'placeholder')
                                                ),
                                                false,
                                                'p-col-2',
                                                true
                                            ),
                                        ],
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_template_inputMask(
                                        this,
                                        'phoneCommercial',
                                        this.state.data.phoneCommercial,
                                        ls('phoneCommercial'),
                                        ls('phoneCommercial', 'mask'),
                                        false,
                                        this.state.mode,
                                        false,
                                        'p-col-3',
                                        'p-col-2',
                                        '',
                                        ls('phoneCommercial', 'placeholder')
                                    )}
                                    <h1 style={{ width: '25vw' }}> </h1>
                                    <Address vValue={this.state.data.mainAddress} context={this} ref={e => (this.addr = e)} />
                                </Panel>
                            </div>
                            <div className="p-col-12 p-md-3 p-lg-3">
                                <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                    <GPhotoUpload
                                        style={{ width: '80px', height: '80px' }}
                                        handlePhotoUpload={this.onUpload}
                                        photo={this.state.data.photo}
                                        documentTypeId={1}
                                        prefixPath={config.PHOTO_URL}
                                    />
                                    {g_getWraper(
                                        null,
                                        ls('status', 'generic'),
                                        <div className="p-col">
                                            <StateIcon
                                                state={this.state.data.consigneeStatus}
                                                small
                                                custom={{
                                                    1: { color: 'green', class: 'pi-check' },
                                                    2: { color: 'red', class: 'pi-times' },
                                                    3: { color: 'red', class: 'pi-ban' },
                                                }}
                                            />
                                        </div>,
                                        this.state.idUUID == null,
                                        'p-col-7'
                                    )}
                                    {g_getWraper(null, ls('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID == null, 'p-col')}
                                    {g_getWraper(null, ls('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID == null, 'p-col')}
                                    <h1> </h1>
                                    {g_getWraper(null, ls('totalMargin'), this.state.data.totalMargin, !this.state.idUUID, 'p-col-7')}
                                    {g_getWraper(null, ls('usedMargin'), this.state.data.usedMargin, !this.state.idUUID, 'p-col-7')}
                                    <h1> </h1>
                                    {g_getWraper(null, ls('loyaltyPointsBalance'), this.state.data.loyaltyPointsBalance, !this.state.idUUID, 'p-col-7')}
                                </Panel>
                            </div>
                        </div>
                        <div className="p-grid p-fluid">
                            {g_dialogTemplate(
                                lm('baseRemove'),
                                'campaignStatusBloquear',
                                this,
                                this.blockStatus,
                                lb('eliminate'),
                                ls('yes'),
                                ls('no'),
                                '',
                                'p-button-danger',
                                !this.state.idUUID || e.d !== 1,
                                this.state.modew && e.d !== 1,
                                'p-button-danger',
                                '',
                                '',
                                '',
                                'auto',
                                'p-col-2'
                            )}
                        </div>
                    </form>,
                ])}
            </div>
        ));
    }
}
