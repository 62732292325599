import axios from './AxiosService';
import {g_hideSearch, g_getData, g_entityFilter } from '../components/GenericFunctions';

class ConsigneeService {

	processed = {
		list: (context, overide) => g_getData(context, data => {
			let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall');
			if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
			data.filters = f[0];
			context.setState({ showMore: f[1] }, () => { if (context.state.showMore !== context.state.oldShowMore) { context.setState({ oldShowMore: context.state.showMore }, g_hideSearch); } });
			return this.list(data);
		}, 'consignees', overide),
	}

	list = data => axios.request('POST', `/consignee/list`, data);

    async listConsignee(data){return await axios.request('POST', `/consignee/list`, data);}

    get = (consigneeIdUUID, loadAddress=1, loadContacts=1, back=false) => axios.request('GET', `/consignee/${consigneeIdUUID}/${loadAddress}/${loadContacts}/0`, {}, back ? {'background-request': true} : {});

    async listStaging(data) { return await axios.request('POST', `/consignee/staging/list`, data); }

    async marginMovementList(data) { return await axios.request('POST', `/consignee/margin/movement/list`, data); }

    async addressDelete(idUUID) { return await axios.request('DELETE', `/consignee/address/${idUUID}`); }

    async createConsignee(data){
        return await axios.request('POST', `/consignee/add`, data)
    }

    async updateConsignee(data){ return await axios.request('PUT', `/consignee/update`, data); }
    async updateMargin(data){ return await axios.request('PUT', `/consignee/update/margin`, data); }

    async getLogs(data){
        return await axios.request('POST', `/consignee/log/list`, data);
    }

    async updateStatus(data){
        return await axios.request('PUT', `/consignee/update/status`, data)
    }

    async documentUpload(formData, documentTypeId, userUUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/consignee/document/upload/${documentTypeId}/${userUUID}`, formData, config)
    }
    
    //Contact
    contactList = data => axios.request('POST', '/consignee/contact/list', data);
    contactAdd = data => axios.request('POST', '/consignee/contact/add', data);
    contactUpdate = data => axios.request('PUT', '/consignee/contact/update', data);
    contactDelete = idUUID => axios.request('PUT', `/consignee/contact/${idUUID}`);
    //SUBSCRIPTION
    async subscriptionAdd(data){return await axios.request('POST', `/subscription/add`, data);}
    async subscriptionUpdateStatus(data){return await axios.request('POST', `/subscription/update/status`, data);}
    subscriptionList = (data) => axios.request('POST', `/consignee/subscriptions`, data);
    //PAYROLL
    payrollList = data => axios.request('POST', `/consignee/payroll/list`, data);
    payrollGet = (year, month, consigneeIdUUID) => axios.request('GET', `/consignee/payroll/${year}/${month}/${consigneeIdUUID}`, {})
    //Clocking
    
    async clockingAdd(data){
        return await axios.request('POST', `/consignee/clocking/add`, data)
    }

    async clockingUpdate(data){
        return await axios.request('PUT', `/consignee/clocking/update`, data)
    }

    async clockingList(data){
        return await axios.request('POST', `/consignee/clocking/list`, data)
    }

    async clockingUpdateStatus(data){
        return await axios.request('PUT', `/consignee/clocking/updateStatus`, data)
    }

    //Parameters

    async parameterList(data){ return await axios.request('POST', `/consignee/parameter/list`, data) }
    async parameterPut(data){ return await axios.request('POST', `/consignee/parameter/put`, data) }

}

export default new ConsigneeService();
