import axios from './AxiosService'

class FinancierService {
    getFinanciers = data => axios.request('POST','/financier/list', data);
    addFinancier = data => axios.request('POST', `/financier/add`, data);
    getLogs = data => axios.request('POST', `/financier/log/list`, data);
    updateStatus = data => axios.request('PUT', `/financier/update/status`, data);
    updateFinancier = data => axios.request('PUT', `/financier/update`, data);
    getFinancierTypes = data => axios.request('POST', `/financier/financierTypes`, data);

    parameterList = data => axios.request('POST', `/consignee/parameter/list`, data);
    parameterPut = data => axios.request('POST', `/consignee/parameter/put`, data);

    documentUpload(formData, documentTypeId, financierUUID){
        const config = { 'content-type': 'multipart/form-data', 'background-request': true };
        return axios.request('POST', `/financier/document/upload/${documentTypeId}/${financierUUID}`, formData, config)
    }
}
export default new FinancierService();
