import axios from './AxiosService';

class ConsigneeService {

    list = data => axios.request('POST', `/payroll/list`, data);
    get = idUUID => axios.request('GET', `/payroll/${idUUID}`);

    async controlList(data){ return await axios.request('POST', `/payroll/control/list`, data); }

    async getControl(idUUID, back){ return await axios.request('GET', `/payroll/control/${idUUID}`, {}, back ? {'background-request': true} : {}); }

    async controlUpdate(data){ return await axios.request('PUT', `/payroll/control/update/status`, data); }

    async controlAdd(data){ return await axios.request('POST', `/payroll/control/add`, data); }

    async controlAddBatch(formData, batchServiceIDUUID, payrollControlIDUUID, productIDUUID, layoutId){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/payroll/upload/${batchServiceIDUUID}/${payrollControlIDUUID}/${productIDUUID}/${layoutId}`, formData, config);
    }

}

export default new ConsigneeService();
