import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react';
import config from '../../config/config';
import FinancierService from '../../service/FinancierService';
import Attachment from '../attachment/Attachment';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import {
    ls,
    LS,
    pair,
    StateIcon,
    g_template_button,
    g_genericRequired,
    g_hideSearch,
    g_getData,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_template_input,
    g_template_inputArea,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDataFields,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_ApiFinancier,
    g_dialogTemplate,
    g_dropdownTemplate,
    g_inputAreaTemplate,
    g_inputMaskTemplate,
    g_inputTemplate,
    g_getWraper,
} from '../GenericFunctions';
import Log from '../logs/Log';
import FinancierAssociation from './FinancierAssociation';

export default class Financier extends Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiFinancier(),
            idUUID: null,
            financierTypes: [],
            items: [{ label: LS('financier', 'tab') }],
            holderphoto: null,
        };
    }

    blockStatus = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.data.idUUID,
            financierStatus: 3,
        };
        let response = await FinancierService.updateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
        this.messages.show({ severity: 'success', summary: LS('simpleBlock', 'tab') });
    };

    updateStatusClick = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.data.idUUID,
            financierStatus: this.state.data.financierStatus === 1 ? 2 : 1,
        };
        let response = await FinancierService.updateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
    };

    async componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        g_getDataFields(this, FinancierService.getFinancierTypes, 'financierTypes', 'description', 'id', 'financierTypes');
        let items = [{ label: LS('financier', 'tab') }];
        if (g_checkAlc(4, 'financier#association')) items.push({ label: LS('backoffice', 'tab'), icon: 'pi pi-sitemap' });
        if (g_checkAlc(4, 'financier#parameters')) items.push({ label: LS('parameters', 'tab'), icon: 'pi pi-cog' });
        items.push({ label: LS('attachments', 'tab'), icon: 'pi pi-paperclip' });
        items.push({ label: LS('log', 'tab'), icon: 'pi pi-list' });
        this.setState({ idUUID: id, mode: mode, items: items }, this.getData);
    }

    async getData() {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
        };
        let response = await FinancierService.getFinanciers(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.financiers[0];
        rdata.financierType = String(rdata.financierType);
        this.setState({ data: rdata });
    }

    onSubmit = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.phoneHome = data.phoneHome ? data.phoneHome.replace(/[^\d]/g, '') : '';
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, ``) : ``;
        data.vatNumber = data.vatNumber ? data.vatNumber.replace(/[^\d]/g, ``) : ``;
        if (this.state.idUUID) {
            let response = await FinancierService.updateFinancier({ financier: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let response = await FinancierService.addFinancier({ financier: data });
            if (g_displayMessageError(this.messages, response)) return;
            if (this.state.holderphoto) {
                await this.upload(this.state.holderphoto, 1, this.state.idUUID);
                this.setState({ holderphoto: null });
            }
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.setState({ idUUID: response.financier.idUUID }, this.getData);
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) this.setState({ holderphoto: formData });
        else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await FinancierService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel>
                                        {g_dropdownTemplate(
                                            `financierType`,
                                            this.state.data.financierType,
                                            LS('financierType'),
                                            this,
                                            this.state.financierTypes,
                                            ``,
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            `p-col-4`,
                                            `p-col-3`
                                        )}
                                        {g_inputTemplate(`name`, this.state.data.name, LS('name'), this, true, this.state.mode, false, g_handleChange, {}, '', `p-col`, `p-col-3`)}
                                        {g_inputTemplate(`fantasyName`, this.state.data.fantasyName, LS('fantasyName'), this, true, this.state.mode, false, g_handleChange, {}, '', `p-col`, `p-col-3`)}
                                        {g_inputTemplate(
                                            `shortDescription`,
                                            this.state.data.shortDescription,
                                            LS('shortDescription'),
                                            this,
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            `p-col-8`,
                                            `p-col-3`
                                        )}
                                        {g_inputAreaTemplate(
                                            `longDescription`,
                                            this.state.data.longDescription,
                                            LS('longDescription'),
                                            this,
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-12',
                                            'p-col-3',
                                            { minHeight: '5em' }
                                        )}
                                        {g_inputMaskTemplate(
                                            `vatNumber`,
                                            this.state.data.vatNumber,
                                            LS('vatNumberC'),
                                            this,
                                            ls('vatNumberC', 'mask'),
                                            ``,
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            ``,
                                            `p-col-4`,
                                            `p-col-3`
                                        )}
                                        {g_inputTemplate(`email`, this.state.data.email, LS('email'), this, false, this.state.mode, false, g_handleChange, {}, ``, `p-col-6`, `p-col-3`, `email`)}
                                        <div className="p-grid p-fluid p-col-12" style={{ padding: '0px' }}>
                                            {g_inputMaskTemplate(
                                                `phoneMobile`,
                                                this.state.data.phoneMobile,
                                                LS(`phoneMobile`),
                                                this,
                                                ls('phoneMobile', 'mask'),
                                                ls('phoneMobile', 'placeholder'),
                                                false,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                ``,
                                                `p-col-3`,
                                                `p-col-3`,
                                                ``
                                            )}
                                            {g_inputMaskTemplate(
                                                `phoneHome`,
                                                this.state.data.phoneHome,
                                                LS(`phoneHome`),
                                                this,
                                                ls('phoneHome', 'mask'),
                                                ls('phoneHome', 'placeholder'),
                                                false,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                ``,
                                                `p-col-3`,
                                                `p-col-3`,
                                                ``
                                            )}
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        <GPhotoUpload
                                            style={{ width: '80px', height: '80px' }}
                                            handlePhotoUpload={this.onUpload}
                                            photo={this.state.data.photo}
                                            disabled={this.state.mode}
                                            documentTypeId={1}
                                            prefixPath={config.PHOTO_URL}
                                            alt={window.location.origin + '/assets/images/logotemplate.png'}
                                        />
                                        {g_getWraper(
                                            null,
                                            LS('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.financierStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', false, null, this.state.mode)}
                                {g_dialogTemplate(
                                    LS(`ask${this.state.data.financierStatus === 2 ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'financierStatus',
                                    this,
                                    this.updateStatusClick,
                                    LS(this.state.data.financierStatus === 2 ? 'deactivate' : 'activate', 'btt'),
                                    LS('yes'),
                                    LS('no'),
                                    '',
                                    '',
                                    !this.state.idUUID || this.state.data.financierStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                                {g_dialogTemplate(
                                    LS('askBlockP'),
                                    'financierStatusBloquear',
                                    this,
                                    this.blockStatus,
                                    LS('block'),
                                    LS('yes'),
                                    LS('no'),
                                    '',
                                    'p-button-danger',
                                    this.state.idUUID == null || this.state.data.financierStatus === 3,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                        ...(g_checkAlc(4, 'financier#association') ? [<FinancierAssociation idUUID={this.state.idUUID} />] : []),
                        ...(g_checkAlc(4, 'financier#parameters') ? [<Parameters context={this} />] : []),
                        <Attachment idUUID={this.state.idUUID} documentContextType={8} service={'financier'} />,
                        <Log idUUID={this.state.idUUID} logContextType={8} />,
                    ])}
                </div>
            );
        });
    }
}

class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), name: '', parameterValue: '' };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, FinancierService.parameterList, 'parameters', { filters: [...this.state.filter, pair('referenceIdUUID', this.props.context.state.idUUID)] })
            : null;

    save = async () => {
        if (!this.props.context.state.idUUID || !this.state.name || !this.state.parameterValue) return;
        const response = await FinancierService.parameterPut({
            parameter: {
                logContextType: 0,
                name: this.state.name,
                parameterValue: this.state.parameterValue,
                referenceIdUUID: this.props.context.state.idUUID,
            },
        });
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({ name: '', parameterValue: '', changeDialog: false }, this.getData);
    };

    render() {
        return (
            <div className="p-col">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ changeDialog: true }), this.getData, this.props.context.state.idUUID),
                    [
                        { type: 'd', data: 'name' },
                        { type: 'd', data: 'parameterValue' },
                        { type: 'button', icon: 'pi pi-pencil', click: raw => this.setState({ name: raw.name, parameterValue: raw.parameterValue, changeDialog: true }) },
                    ],
                    this.getData
                )}
                <Dialog
                    visible={this.state.changeDialog}
                    footer={
                        <Button
                            label={LS('save', 'btt')}
                            onClick={e => {
                                e.preventDefault();
                                this.save();
                            }}
                        />
                    }
                    onHide={() => this.setState({ changeDialog: false })}
                >
                    <div className="card p-grid p-fluid contact-form" style={{ height: '50vh', width: '50vw' }}>
                        <div className="p-fluid" style={{ height: '50vh', width: '50vw' }}>
                            {g_template_input(this, 'name', this.state.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-4', '', { data: 'name' })}
                            {g_template_inputArea(
                                this,
                                'parameterValue',
                                this.state.parameterValue,
                                LS('parameterValue'),
                                this.state.mode,
                                false,
                                true,
                                'p-col-12',
                                'p-col-4',
                                '',
                                { minHeight: '5em' },
                                { data: 'parameterValue' }
                            )}
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
