import React from 'react';
import Services from '../../../service/Services';
import { g_getHeaderTemplate, ListPage, LabelSelector, g_getData } from '../../GenericFunctions';

let size = [1000, 600];
let url = (iduuid, ctiduuid) => `#/margin-control?type=1${iduuid ? `&id=${iduuid}` : ''}${ctiduuid ? `&ctid=${ctiduuid}` : ''}`;
export const contractTemplateMarginList = {
    render: ctiduuid => (
        <ListPage
            title=""
            header={(c, e) => g_getHeaderTemplate(c, url(null, ctiduuid), 'new margin', c.getData, size[0], size[1], e.c)}
            getData={c => g_getData(c, Services.contract.template.margin.list, 'contractTemplateMargins', { filters: [...c.state.filter, { key: 'contractTemplateIdUUID', value: ctiduuid }] })}
            table={(c, e) => [
                { type: 'map', data: 'contractTemplateMarginType', objs: types, sortable: true },
                { type: 'map', data: 'contractTemplateMarginContext', objs: contexts, sortable: true },
                { type: 'd', data: 'contextDescription', sortable: true },
                { type: 'map', data: 'contractTemplateMarginCategory', objs: category, sortable: true },
                { type: 'd', data: 'marginValueControl', sortable: true, filter: true },
                { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                { type: 'url', url: raw => url(raw.idUUID, null), width: size[0], height: size[1], c: e.c || e.d, callback: c.getData },
            ]}
        />
    ),
    url: url,
    size: size,
};
export const types = { 1: LabelSelector('type1', 'ctemplatemargin'), 2: LabelSelector('type2', 'ctemplatemargin') };
export const contexts = { 1: LabelSelector('context1', 'ctemplatemargin'), 2: LabelSelector('context2', 'ctemplatemargin'), 3: LabelSelector('context3', 'ctemplatemargin') };
export const category = { 1: LabelSelector('category1', 'ctemplatemargin'), 2: LabelSelector('category2', 'ctemplatemargin'), 3: LabelSelector('category3', 'ctemplatemargin') };
