import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import S from '../../../service/Services';
import { ls, g_checkLoginACL, g_genericRequired, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../../GenericFunctions';

export const statgingData = {
    size: [1000, 500],
    url: id => `#/staging-consignee?type=1${id ? '&id=' + id : ''}`,
};

export default class StagingConsigneeManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => S.consignee.processed.staging.list(this);
    render() {
        return g_checkLoginACL(e => {
            let collumAdd = this.state.showMore ? { type: 'd', data: 'consignatorFantasyName', filter: true } : null;
            return (
                <div className="card">
                    <h1>{ls('stagingConsigneeManagement', 'titles')}</h1>
                    <Messages ref={el => (this.messages = el)} />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(this, statgingData.url(), 'new statging', this.getData, statgingData.size[0], statgingData.size[1], e.c),
                        [
                            collumAdd,
                            { type: 'd', data: 'fullname', sortable: true, filter: true },
                            { type: 'd', data: 'email', sortable: true, filter: true },
                            { type: 'd', align: 'right', data: 'phoneMobile', sortable: true, filter: true },
                            { type: 'd', align: 'right', data: 'vatNumber', sortable: true, filter: true },
                            { type: 'd', align: 'right', data: 'employeeId', sortable: true, filter: true },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                            {
                                type: 'state',
                                data: 'consigneeStatus',
                                states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                            },
                            { type: 'url', c: e.d === 1 || e.u === 1, url: raw => `#/staging-consignee?type=1&id=${raw.idUUID}`, width: 1000, heigth: 500, callback: this.getData },
                        ],
                        this.getData
                    )}
                </div>
            );
        });
    }
}
