import axios from './AxiosService'

class ContractTemplateService {

    async getContractTemplates(filter) {
        return await axios.request('POST',`/contract/template/list`, filter)
    }

    async copy(data) { return await axios.request('PUT',`/contract/template/copy`, data); }

    async getContractTemplate(filter, contractTemplateIdUUID) {
        return await axios.request('GET',`/contract/template/${contractTemplateIdUUID}`, filter)
    }

    async addContractTemplate(data) {
        return await axios.request('POST', `/contract/template/add`, data)
    }

    async getRevenueModes(data){
        return await axios.request('POST', `/contract/template/revenueMode/list`, data);
    }

    async getSignatureModes(data){
        return await axios.request('POST', `/contract/template/signatureMode/list`, data)
    }

    async updateContractTemplate(data){
        return await axios.request('PUT', `/contract/template/update`, data)
    }

    async getContractTemplateTypes(data){
        return await axios.request('POST', `/contract/template/type/list`, data);
    }

    async updateContractTemplateForm(data){
        return await axios.request('PUT', `/contract/template/form/update`, data)
    }

    async addContractTemplateForm(data) {
        return await axios.request('POST', `/contract/template/form/add`, data)
    }

    async getContractTemplateFormList(data) {
        return await axios.request('POST', `/contract/template/form/list`, data)
    }

    async updateContractUpdateStatus(data) {
        return await axios.request('PUT', `/contract/template/update/status`, data)
    }

}

export default new ContractTemplateService();
