import React, { Component } from 'react';
import { Messages } from 'primereact/messages';
import CampaignService from '../../service/CampaignService';
import ProductService from '../../service/ProductService';
import CampaignProductsList from '../campaign/CampainProductsList';
import { LS, g_checkLoginACL, g_displayMessageError, g_genericRequired, g_getData, g_getHeaderTemplatecF, g_hideSearch, g_template_dataTable, g_treatNumric } from '../GenericFunctions';

export default class ProductsPackagesProductsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getProducts = context => g_getData(context, ProductService.getProducts, 'products');
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, CampaignService.getCampaignItemList, 'campaignItens', { campaignIdUUID: this.props.context.state.idUUID }, this.props.context.messages)
            : null;

    addProductAssotiation = async (context, pId) => {
        let data = context.state.dataa;
        if (
            (data.campaignItemMode ?? false) === false ||
            (data.condicionalValue ?? false) === false ||
            (data.afterCondicionalValue ?? false) === false ||
            (data.afterCancelationValue ?? false) === false
        )
            return;
        let condicionalValue = g_treatNumric(data.condicionalValue);
        let afterCondicionalValue = g_treatNumric(data.afterCondicionalValue);
        const item = {
            campaignItem: {
                campaignIdUUID: this.props.context.state.idUUID,
                productIdUUID: pId.idUUID ?? '',
                campaignItemMode: data.campaignItemMode,
                searchAreaServiceType: data.searchAreaServiceType ?? '',
                condicionalValue: condicionalValue,
                afterCondicionalValue: afterCondicionalValue,
            },
        };
        var response = await CampaignService.addCampaignItem(item);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
        context.props.getData(context);
        this.productsSelector.setState({ visible: false });
    };

    deleteClick = async data => {
        let response = await CampaignService.removeCampaignItem({}, data.campaignItemIdUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    <Messages ref={el => (this.messages = el)} />
                    <CampaignProductsList showAll={true} close={this.getData} add={this.addProductAssotiation} getData={this.getProducts} ref={e => (this.productsSelector = e)} />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(
                            this,
                            () => {
                                this.productsSelector.setState({ visible: true });
                                this.productsSelector.props.getData(this.productsSelector);
                            },
                            this.getData,
                            e.c === 1
                        ),
                        [
                            { type: 'd', data: 'name', filter: true, sortable: true },
                            { type: 'd', data: 'shortDescription', filter: true, sortable: true },
                            { type: 'd', data: 'productTypeDescription', filter: true, sortable: true },
                            {
                                type: 'di',
                                title: 'Modo',
                                di: raw => LS(raw.campaignItemMode, 'campaignMode'),
                            },
                            { type: 'd', data: 'condicionalValue' },
                            { type: 'd', data: 'afterCondicionalValue' },
                            { type: 'btt', icon: 'pi pi-trash', tooltip: LS('delete', 'tooltip'), click: this.deleteClick },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'campaignProduct#products'
        );
    }
}
