import React from 'react';
import Services from '../../service/Services';
import {
    g_ApiBusinessHours,
    g_checkLoginACL,
    g_checkMode,
    g_convertList,
    g_convertResponse,
    g_displayMessageError,
    g_getData,
    g_getDataFields,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_form,
    LS,
    ls,
} from '../GenericFunctions';
import Log from '../logs/Log';

export const businessHours = {
    size: [1500, 400],
    url: (id, provider) => `#/business-hours?type=1${id ? '&id=' + id : ''}${provider ? '&pid=' + provider : ''}`,
    weekDaysArray: [
        LS('sunday', 'weekdays'),
        LS('monday', 'weekdays'),
        LS('tuesday', 'weekdays'),
        LS('wednesday', 'weekdays'),
        LS('thursday', 'weekdays'),
        LS('friday', 'weekdays'),
        LS('saturday', 'weekdays'),
        LS('weekdays', 'weekdays'),
        LS('weekends', 'weekdays'),
        LS('alldays', 'weekdays'),
    ],
};

export class BusinessHours extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiBusinessHours(), items: [{ label: LS('businessHours', 'tab') }], idUUID: null, ctid: null, mode: true, types: [], categories: [] };
    }
    onCancel = () =>
        g_getData(
            this,
            () => Services.businessHours.delete(this.state.idUUID),
            () => window.close()
        );

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  Services.businessHours.list,
                  response => {
                      let rdata = response.businessHourss[0];
                      if (!rdata) return;
                      rdata = g_convertResponse(['weekday', 'businessHoursType', 'businessHoursCategory', 'periodOneEnd', 'periodOneStart', 'periodTwoEnd', 'periodTwoStart'], rdata);
                      rdata.periodOneEnd = rdata.periodOneEnd.padStart(4, '0');
                      rdata.periodOneStart = rdata.periodOneStart.padStart(4, '0');
                      rdata.periodTwoEnd = rdata.periodTwoEnd.padStart(4, '0');
                      rdata.periodTwoStart = rdata.periodTwoStart.padStart(4, '0');
                      this.setState({ data: rdata });
                  },
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }] }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let pid = query.get('pid');
        if (!idUUID && !pid) {
            window.close();
            return;
        }
        let mode = query.get('type') !== '1';
        let items = [{ label: LS('businessHours', 'tab') }];
        if (idUUID) items.push({ label: LS('log', 'tab'), icon: 'pi pi-list' });
        g_getDataFields(this, Services.businessHours.types, 'businessHoursTypes', 'description', 'id', d => this.setState({ types: d }));
        g_getDataFields(this, Services.businessHours.categories, 'businessHoursCategories', 'description', 'id', d => this.setState({ categories: d }));
        this.setState({ mode: mode, idUUID: idUUID, pid: pid, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        if (!this.state.idUUID && !this.state.pid) {
            window.close();
            return;
        }
        let data = this.state.data;
        data.periodOneEnd = data.periodOneEnd.replace(':', '');
        data.periodOneStart = data.periodOneStart.replace(':', '');
        data.periodTwoEnd = data.periodTwoEnd.replace(':', '');
        data.periodTwoStart = data.periodTwoStart.replace(':', '');
        if (!this.state.idUUID) {
            data.providerIdUUID = this.state.pid;
            const response = await Services.businessHours.add({ businessHours: data });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = businessHours.url(response.businessHours.idUUID);
            window.location.reload();
            return;
        }
        const response = await Services.businessHours.update({ businessHours: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                {
                                    t: 'array',
                                    dTypeFs: { 3: 't', _: 'mask', 0: 'drop', 1: 'drop', 2: 'drop', 6: 't' },
                                    cc: { _: 2, 3: 4, 4: { _: 1 }, 5: { _: 1 }, 6: 2 },
                                    mask: { _: ls('hours', 'mask') },
                                    handleExtraData: { _: { regex: new RegExp('(0\\d|1\\d|2[0-3]):[0-5]\\d') }, 0: {}, 1: {}, 2: {}, 3: {}, 6: { regex: new RegExp('\\d*') } },
                                    c: {
                                        5: this.state.data.businessHoursCategory !== '2' || this.state.data.businessHoursType === '2',
                                        1: this.state.data.businessHoursType === '2',
                                        4: this.state.data.businessHoursType === '2' || this.state.data.businessHoursCategory === '3',
                                    },
                                    req: { _: true },
                                    options: [this.state.types, this.state.categories, g_convertList(businessHours.weekDaysArray)],
                                    filter: [
                                        'businessHoursType',
                                        'businessHoursCategory',
                                        'weekday',
                                        'extraDescription',
                                        ['periodOneStart', 'periodOneEnd'],
                                        ['periodTwoStart', 'periodTwoEnd'],
                                        'showOrder',
                                    ],
                                },
                            ],
                            mode => [
                                g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', false, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    LS('askRemove', 'messages'),
                                    'cancel',
                                    this.onCancel,
                                    LS('remove', 'btt'),
                                    !this.state.data.idUUID,
                                    this.state.mode || e.d !== 1,
                                    '',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                            ],
                            () => [[{ t: 'array', filter: ['dateCreation', 'dateUpdate'], dTypeF: 'view', class: 'l' }]],
                            this.submit,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            ))
        );
    }
}
