import React, { Component } from 'react';
import classNames from 'classnames';
import Config from './config/config';

export class AppProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false,
            redirect: false,
            logged: true,
            loggedUserN: localStorage.getItem('loggedUserN')
        };
        this.onClick = this.onClick.bind(this);
    }

    async componentDidMount(){
        const loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'))
        this.setState({loggedUserN})
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    handleLogoutClick = async () => {
        await localStorage.removeItem('loggedUserN')
        this.setState({redirect: true, logged: false}, () => { this.props.handlerLogin(this.state.logged); window.location="#/login";});
    }

    handleUser = () => {
        window.location = "#/LUser";
    }

    render() {
        const loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'))
        let photo;

        if(loggedUserN === null)
        {
           photo = <img src="assets/images/user.svg" alt="" />
        }
        else
        {
           photo = <img style={{width:"105px", height:"105px"}} src={loggedUserN.photo ? Config.PHOTO_URL+loggedUserN.photo : window.location.origin+"/assets/images/person.png"} alt="" />
        }



        return  (
            <div className="layout-profile">
                <div>
                {photo}
                </div>
                <button className="p-link layout-profile-link" onClick={this.onClick}>
        <span className="username">{this.state.loggedUserN.name}</span>
                    <i className="pi pi-fw pi-cog"/>
                </button>
                <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                    <li><button className="p-link" onClick={this.handleUser}><i className="pi pi-fw pi-user"/><span>Conta</span></button></li>
                    {/* <li><button className="p-link"><i className="pi pi-fw pi-inbox"/><span>Notifications</span><span className="menuitem-badge">2</span></button></li> */}
                    <li><button className="p-link" onClick={this.handleLogoutClick}><i className="pi pi-fw pi-power-off"/><span>Logout</span></button></li>
                </ul>
            </div>
        );
    }
}
