import React, { Component } from 'react';
import {g_checkLoginACL} from './GenericFunctions';

export class Dashboard extends Component {

    componentDidMount() {  }

    grender() {
         return (
              <div style={{verticalAlign: 'center', textAlign: 'center'}}>
         {/* <img style={{width: '50%'}} src="/assets/images/numo.png" /> */}
              </div>
         )
    }

    render() { return g_checkLoginACL(this.grender, false) }  
}
