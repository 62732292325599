import { g_checkAlc, g_entityFilter, g_hideSearch, g_getData, g_getDataFields, g_ChangeStatus, g_getDataHook, g_consignators, g_providers, g_pair } from '../components/GenericFunctions';
import axios from './AxiosService';

class Services {
    card = {
        add: data => axios.request('POST', '/card/add', data),
        list: data => axios.request('POST', '/card/list', data),
        update: data => axios.request('PUT', '/card/update', data),
        status: data => axios.request('PUT', '/card/update/status', data),
        payments: data => axios.request('POST', '/card/payment/list', data),
    };

    config = {
        language: {
            list: data => axios.request('POST', `/config/language/list`, data),
            add: data => axios.request('POST', `/config/language/add`, data),
            update: data => axios.request('PUT', `/config/language/update`, data),
            delete: idUUID => axios.request('DELETE', `/config/language/${idUUID}`),
        },
    };

    boleto = {
        list: data => axios.request('POST', `/bankingbillet/list`, data),
        barcode: barcode => axios.request('GET', `/bankingbillet/barcode/${barcode}`),
    };

    group = {
        processed: {
            list: (context, overide) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'referenceOwerIdUUID', 'referenceOwersIdUUID', 5, 'groups#acessall');
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
                        data.filters = f[0];
                        context.setState({ showMore: f[1] }, () => {
                            if (context.state.showMore !== context.state.oldShowMore) {
                                context.setState({ oldShowMore: context.state.showMore }, g_hideSearch);
                            }
                        });
                        return this.group.list(data);
                    },
                    'groups',
                    overide
                ),
            types: (context, process = () => {}) => g_getDataFields(context, this.group.types, 'groupTypes', 'description', 'id', process),
            status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                g_ChangeStatus(context, () => this.group.status({ idUUID: idUUID, groupStatus: status }), callback, message, messages, e),
        },
        add: data => axios.request('POST', `/group/add`, data),
        types: data => axios.request('POST', `/group/groupTypes`, data),
        list: data => axios.request('POST', `/group/list`, data),
        get: (iduuid, loadEntities) => axios.request('GET', `/group/${iduuid}/${loadEntities}`, {}),
        element: (iduuid, data) => axios.request('POST', `/group/element/list/${iduuid}`, data),
        update: data => axios.request('PUT', `/group/update`, data),
        status: data => axios.request('PUT', `/group/update/status`, data),
    };

    product = {
        processed: {
            status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                g_ChangeStatus(context, () => this.product.status({ idUUID: idUUID, productStatus: status }), callback, message, messages, e),
        },
        list: data => axios.request('POST', '/product/list', data),
        status: data => axios.request('PUT', `/product/update/status`, data),
    };

    consignee = {
        processed: {
            list: (context, overide) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall', g_consignators());
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
                        data.filters = f[0];
                        context.setState({ showMore: f[1] }, () => {
                            if (context.state.showMore !== context.state.oldShowMore) {
                                context.setState({ oldShowMore: context.state.showMore }, g_hideSearch);
                            }
                        });
                        return this.consignee.list(data);
                    },
                    'consignees',
                    overide
                ),
            staging: {
                list: (c, overide) =>
                    g_getData(
                        c,
                        data => {
                            let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall', g_consignators());
                            if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
                            data.filters = f[0];
                            c.setState({ showMore: f[1] }, () => {
                                if (c.state.showMore !== c.state.oldShowMore) {
                                    c.setState({ oldShowMore: c.state.showMore }, g_hideSearch);
                                }
                            });
                            return this.consignee.staging.list(data);
                        },
                        'consignees',
                        overide
                    ),
            },
        },
        list: data => axios.request('POST', `/consignee/list`, data),
        status: data => axios.request('PUT', `/consignee/update/status`, data),
        documentUpload: (formData, documentTypeId, userUUID) => {
            const config = {
                'content-type': 'multipart/form-data',
                'background-request': true,
            };

            return axios.request('POST', `/consignee/document/upload/${documentTypeId}/${userUUID}`, formData, config);
        },
        staging: {
            list: data => axios.request('POST', `/consignee/staging/list`, data),
            add: data => axios.request('POST', `/consignee/staging/add`, data),
        },
        group: {
            add: (consigneeIdUUID, groupIdUUID) => axios.request('POST', `/consignee/group/${consigneeIdUUID}/${groupIdUUID}`, {}),
            remove: (consigneeIdUUID, groupIdUUID) => axios.request('DELETE', `/consignee/group/${consigneeIdUUID}/${groupIdUUID}`, {}),
            list: (consigneeIdUUID, data) => axios.request('POST', `/consignee/group/${consigneeIdUUID}`, data),
        },
        payroll: {
            list: data => axios.request('POST', `/consignee/payroll/list`, data),
            get: (year, month, consigneeIdUUID) => axios.request('GET', `/consignee/payroll/${year}/${month}/${consigneeIdUUID}`, {}),
        },
    };

    contract = {
        template: {
            form: {
                list: data => axios.request('POST', `/contract/template/form/list`, data),
            },
            margin: {
                processed: {
                    status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                        g_ChangeStatus(context, () => this.contract.template.margin.status({ idUUID: idUUID, contractTemplateMarginStatus: status }), callback, message, messages, e),
                },
                list: data => axios.request('POST', `/contract/template/margin/list`, data),
                add: data => axios.request('POST', `/contract/template/margin/add`, data),
                update: data => axios.request('PUT', `/contract/template/margin/update`, data),
                status: data => axios.request('PUT', `/contract/template/margin/update/status`, data),
            },
            processed: {
                list: (c, extra = {}) =>
                    g_checkAlc(5, 'contract-management#totalaccess')
                        ? g_getData(c, this.contract.template.list, 'contractTemplates', extra)
                        : g_getData(
                              c,
                              data => {
                                  let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess');
                                  if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                                  data.filters = f[0];
                                  return this.contract.template.list(data);
                              },
                              'contractTemplates',
                              extra
                          ),
            },
            list: data => axios.request('POST', `/contract/template/list`, data),
            get: iduuid => axios.request('GET', `/contract/template/${iduuid}`),
        },
        field: {
            put: (data, back = false) => axios.request('POST', `/contract/field/put`, data, back ? { 'background-request': true } : {}),
        },
        add: (data, back = false) => axios.request('POST', '/contract/add', data, back ? { 'background-request': true } : {}),
        update: (data, back = false) => axios.request('PUT', `/contract/update`, data, back ? { 'background-request': true } : {}),
        get: (idUUID, back = false) => axios.request('GET', `/contract/${idUUID}/2`, {}, back ? { 'background-request': true } : {}),
        status: data => axios.request('PUT', `/contract/update/status`, data),
        groupByContractTemplate: data => axios.request('POST', `/contract/groupByContractTemplate/list`, data),
        groupByContractProductType: data => axios.request('POST', `/contract/groupByProductType/list`, data),
        list: (data, back = false) => axios.request('POST', '/contract/list', data, back ? { 'background-request': true } : {}),
    };

    provider = {
        processed: {
            list: (context, other = {}) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'organizationiduuid', 'organizationsiduuid', 5, 'provider#accessall', g_consignators());
                        let f1 = g_entityFilter(data.filters, 'iduuid', 'iduuids', 5, 'provider#accessall', g_providers());
                        if (!f && !f1) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                        data.filters = [];
                        if (f) data.filters = f[0];
                        if (f1) data.filters = [...data.filters, ...f1[0]];
                        return this.provider.list(data);
                    },
                    'providers',
                    other
                ),
            listhook: (state, other = {}) =>
                g_getDataHook(
                    state,
                    data => {
                        let f = g_entityFilter(data.filters, 'organizationiduuid', 'organizationsiduuid', 5, 'provider#accessall', g_consignators());
                        let f1 = g_entityFilter(data.filters, 'iduuid', 'iduuids', 5, 'provider#accessall', g_providers());
                        if (!f && !f1) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                        if (f) data.filters = f[0];
                        if (f1) data.filters = [...data.filters, ...f1[0]];
                        return this.provider.list(data);
                    },
                    'providers',
                    other
                ),
            status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                g_ChangeStatus(context, () => this.provider.status({ idUUID: idUUID, providerStatus: status }), callback, message, messages, e),
        },
        address: {
            delete: idUUID => axios.request('DELETE', `/provider/address/${idUUID}`),
        },
        searchAreaServiceType: {
            list: data => axios.request('POST', `/provider/searchAreaServiceTypes`, data),
            delete: (providerUUID, searchAreaServiceType) => axios.request('DELETE', `/provider/searchAreaServiceType/${providerUUID}/${searchAreaServiceType}`, {}),
            add: data => axios.request('POST', `/provider/searchAreaServiceType/add`, data),
        },
        get: (idUUID, loadAddress = 0) => axios.request('GET', `/provider/${idUUID}/${loadAddress}/0/0`),
        list: data => axios.request('POST', '/provider/list', data),
        status: data => axios.request('PUT', `/provider/update/status`, data),
        add: data => axios.request('POST', `/provider/add`, data),
        listLogs: data => axios.request('POST', `/provider/log/list`, data),
        update: data => axios.request('PUT', `/provider/update`, data),
        listTypes: data => axios.request('POST', `/provider/providerTypes`, data),
        documentUpload: (formData, documentTypeId, userUUID) => {
            const config = {
                'content-type': 'multipart/form-data',
                'background-request': true,
            };
            return axios.request('POST', `/provider/document/upload/${documentTypeId}/${userUUID}`, formData, config);
        },
    };

    user = {
        resetPassword: data => axios.request('POST', `/user/reset`, data),
        list: data => axios.request('POST', `/user/list`, data),
    };

    search = {
        serviceType: {
            entityProduct: {
                remove: id => axios.request('DELETE', `/search/serviceType/entity/product/${id}`),
                add: (id, ctid = null, cid = null) =>
                    axios.request('POST', '/search/serviceType/entity/product/add', {
                        ...(ctid ? { contractTemplateIdUUID: ctid } : {}),
                        ...(cid ? { campaignIdUUID: cid } : {}),
                        entitySearchAreaServiceTypeIdUUID: id,
                    }),
            },
            list: data => axios.request('POST', `/search/serviceTypes`, data),
            put: data => axios.request('POST', `/search/serviceType/put`, data),
            delete: id => axios.request('DELETE', `/search/serviceType/${id}`, {}),
        },
    };

    scheduling = {
        processed: {
            status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                g_ChangeStatus(context, () => this.scheduling.status({ idUUID: idUUID, schedulingStatus: status }), callback, message, messages, e),
        },
        add: data => axios.request('POST', `/scheduling/add`, data),
        update: (data, hidden = false) => axios.request('PUT', `/scheduling/update`, data, { 'background-request': hidden }),
        status: data => axios.request('PUT', `/scheduling/update/status`, data),
        list: (data, hidden = false) => axios.request('POST', `/scheduling/list`, data, { 'background-request': hidden }),
        autoGenerate: data => axios.request('POST', `/scheduling/autogeneration`, data),
    };

    businessHours = {
        add: data => axios.request('POST', `/businessHours/add`, data),
        list: data => axios.request('POST', `/businessHours/list`, data),
        types: data => axios.request('POST', `/businessHours/businessHoursTypes`, data),
        categories: data => axios.request('POST', `/businessHours/businessHoursCategories`, data),
        update: data => axios.request('PUT', `/businessHours/update`, data),
        delete: id => axios.request('DELETE', `/businessHours/${id}`),
    };

    parameter = {
        list: data => axios.request('POST', `/consignee/parameter/list`, data),
        put: data => axios.request('POST', `/consignee/parameter/put`, data),
    };

    consignator = {
        processed: {
            list: (context, other = {}) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'idUUID', 'idUUIDs', 5, 'consignator-management#accessall');
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                        data.filters = f[0];
                        return this.consignator.list(data);
                    },
                    'consignators',
                    other
                ),
            status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                g_ChangeStatus(context, () => this.consignator.status({ idUUID: idUUID, consignatorStatus: status }), callback, message, messages, e),
        },
        clocking: {
            add: data => axios.request('POST', `/consignator/clocking/location/add`, data),
            update: data => axios.request('PUT', `/consignator/clocking/location/update`, data),
            list: data => axios.request('POST', `/consignator/clocking/location/list`, data),
            get: idUUID => axios.request('GET', `/consignator/clocking/location/${idUUID}`),
            status: data => axios.request('PUT', `/consignator/clocking/location/updateStatus`, data),
        },
        list: data => axios.request('POST', `/consignator/list`, data),
        status: data => axios.request('PUT', `/consignator/update/status`, data),
    };

    subscription = {
        list: data => axios.request('POST', '/subscription/list', data),
        listCycles: data => axios.request('POST', '/subscription/cycle/list', data),
        add: data => axios.request('POST', '/subscription/add', data),
    };

    loyalty = {
        authorize: (data, provider) => axios.request('PUT', `/loyalty/card/authorize/${provider}`, data),
    };

    campaign = {
        processed: {
            list: (c, campaignType, filter = c.state.filter) =>
                g_getData(
                    c,
                    d => {
                        let f = g_entityFilter(d.filters, 'consignatorsidUUID', 'consignatorsidUUIDs', 5, 'consignator-management#accessall');
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                        d.filters = f[0];
                        return this.campaign.list(d);
                    },
                    'campaigns',
                    { filters: [{ key: 'campaignType', value: campaignType }, ...filter] }
                ),
        },
        list: data => axios.request('POST', '/campaign/list', data),
    };

    icargo = {
        categories: data => axios.request('POST', '/icargo/categories', data),
        categoryTypes: data => axios.request('POST', '/icargo/categoryTypes', data),
        truckType: data => axios.request('POST', '/icargo/truckTypes', data),
        cargoTypes: data => axios.request('POST', '/icargo/cargoTypes', data),
        cargoPaymentMode: data => axios.request('POST', '/icargo/cargoPaymentModes', data),
        cargoQuationType: data => axios.request('POST', '/icargo/cargoQuotationTypes', data),
        executionStatus: data => axios.request('POST', '/icargo/executionStatus', data),
        products: data => axios.request('POST', '/icargo/productSegments', data),
        gps: {
            list: data => axios.request('POST', '/icargo/gps/list', data),
        },
        cargo: {
            list: data => axios.request('POST', '/icargo/cargo/list', data),
        },
    };

    incident = {
        list: data => axios.request('POST', '/incident/list', data),
    };

    utils = {
        getContactRelationshipTypes: data => axios.request('POST', 'utils/contactRelationshipTypes', data),
        listGender: data => axios.request('POST', '/utils/genderTypes', data),
        listMaritalStatus: data => axios.request('POST', '/utils/civilStatus', data),
        listHabilitation: data => axios.request('POST', '/utils/hablitations', data),
        listEntityType: data => axios.request('POST', `/utils/entityTypes`, data),
        listDocumentType: data => axios.request('POST', '/utils/documentTypes', data),
        getTimestamp: () => axios.request('GET', '/utils/timestamp'),
        country: () => axios.request('POST', '/utils/country/list'),
        zipCode: {
            list: data => axios.request('POST', '/utils/zipCode/list', data),
            add: data => axios.request('POST', '/utils/zipCode/add', data),
            update: data => axios.request('POST', '/utils/zipCode/update', data),
        },

        ratting: {
            processed: {
                status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                    g_ChangeStatus(context, () => this.utils.ratting.status({ idUUID: idUUID, rattingCategoryStatus: status }), callback, message, messages, e),
            },
            list: data => axios.request('POST', `/utils/rattingCategory/list`, data),
            status: data => axios.request('PUT', `/utils/rattingCategory/update/status`, data),
            add: data => axios.request('POST', `/utils/rattingCategory/add`, data),
            update: data => axios.request('PUT', `/utils/rattingCategory/update`, data),
        },

        getDocuments: (data, service) => axios.request('POST', `/${service}/document/list`, data),
    };

    listDocuments = (data, service) => axios.request('POST', `/${service}/document/list`, data);

    listLogs = data => axios.request('POST', '/log/log/list', data);

    help = {
        processed: {
            list: (context, other = {}) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorIdUUIDS', 5, 'consignator-management#accessall', g_consignators());
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                        data.filters = f[0];
                        if (g_checkAlc(5, 'consignator-management#accessall')) data.filters.push(g_pair('showAll', true));
                        if (f[1]) context.setState({ showConsignator: true });
                        return this.help.list(data);
                    },
                    'helps',
                    other
                ),
        },
        list: data => axios.request('POST', `/help/list`, data),
    };

    payroll = {
        processed: {
            status: (context, idUUID, status, callback = () => {}, message = '', messages = context.messages, e) =>
                g_ChangeStatus(context, () => this.payroll.status({ idUUID: idUUID, payrollStatus: status }), callback, message, messages, e),
        },
        file: {
            status: (batchIdUUID, newStatusId) => axios.request('PUT', `/payroll/file/${batchIdUUID}/update/status/${newStatusId}`),
        },
        status: data => axios.request('PUT', `/payroll/update/status`, data),
        list: data => axios.request('POST', `/payroll/list`, data),
        get: idUUID => axios.request('GET', `/payroll/${idUUID}`),
        control: {
            list: data => axios.request('POST', `/payroll/control/list`, data),
            get: (idUUID, back) => axios.request('GET', `/payroll/control/${idUUID}`, {}, back ? { 'background-request': true } : {}),
            update: data => axios.request('PUT', `/payroll/control/update/status`, data),
            add: data => axios.request('POST', `/payroll/control/add`, data),
            controlAddBatch: (formData, batchServiceIDUUID, payrollControlIDUUID, productIDUUID, layoutId) => {
                const config = {
                    'content-type': 'multipart/form-data',
                    'background-request': true,
                };

                return axios.request('POST', `/payroll/upload/${batchServiceIDUUID}/${payrollControlIDUUID}/${productIDUUID}/${layoutId}`, formData, config);
            },
        },
    };
}

export default new Services();
