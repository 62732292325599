import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React from 'react';
import S from '../../service/Services';
import GSelector from '../generic/GSelector';
import { LS, g_getData, g_genericRequired, g_getHeaderTemplate, g_template_dataTable, g_formMaper } from '../GenericFunctions';

export default class CampaignProductsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), visible: false, dataa: { campaignItemMode: '0', condicionalValue: '', afterCancelationValue: '', afterCondicionalValue: '' } };
    }
    modeOptions = [
        { label: LS('1', 'campaignMode'), value: '1' },
        { label: LS('2', 'campaignMode'), value: '2' },
        { label: LS('3', 'campaignMode'), value: '3' },
    ];
    render() {
        return (
            <Dialog style={{ height: '80vh', width: '80vw' }} visible={this.state.visible} onHide={() => this.setState({ visible: false }, this.props.close)}>
                <Messages ref={e => (this.messages = e)} />
                <div className="p-grid">
                    <div style={{ overflowX: 'none' }} className="p-col p-fluid contact-form">
                        {g_formMaper(
                            this,
                            [
                                {
                                    t: 'array',
                                    origin: this.state.dataa,
                                    handleExtraData: { _: { data: 'dataa' } },
                                    dTypeFs: { _: 'numeric', 0: { 0: 'drop', _: 'numeric' } },
                                    options: [[this.modeOptions]],
                                    filter: [['campaignItemMode', 'condicionalValue', 'afterCondicionalValue'], 'afterCancelationValue'],
                                    cc: [[4, 1, 1], 1],
                                },
                            ],
                            this.state.mode,
                            2
                        )}
                        <GSelector
                            labelwraper="p-col-2"
                            label={LS('searchAreaServiceType')}
                            ref={e => (this.selector0 = e)}
                            getData={c => g_getData(c, S.search.serviceType.list, 'searchAreaServiceTypes')}
                            tableBody={[
                                { type: 'd', data: 'description', sortable: true, filter: true },
                                {
                                    type: 'button',
                                    icon: 'pi pi-plus',
                                    click: (raw, e) => {
                                        e.preventDefault();
                                        this.selector0.setState({ selected: raw.description, visible: false });
                                        this.setState({ dataa: { ...this.state.dataa, searchAreaServiceType: raw.id } });
                                        this.props.getData(this);
                                    },
                                },
                            ]}
                            inputtextwarper="p-col-5"
                            disabled={this.state.mode}
                            startV={this.state.data.searchAreaServiceTypeDescription}
                        />
                    </div>
                    <div className="p-col-12">
                        {g_template_dataTable(
                            this,
                            g_getHeaderTemplate(this, '', '', () => this.props.getData(this), 0, 0, false),
                            [
                                { type: 'd', data: 'name', filter: true },
                                { type: 'button', icon: 'pi pi-plus', click: raw => this.props.add(this, raw), extra: 'p-button-warning' },
                            ],
                            () => this.props.getData(this)
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
}
