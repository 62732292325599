import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import React, { Component } from 'react';
import config from '../../config/config';
import ConsignatorService from '../../service/ConsignatorService';
import NotificationService from '../../service/NotificationService';
import UserService from '../../service/UserService';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import GSelector from '../generic/GSelector';
import {
    S,
    LS,
    StateIcon,
    g_ApiNotification,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_entities,
    g_entityFilter,
    g_getData,
    g_getDataFields,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_button,
    g_dialogTemplate,
    g_dropdownTemplate,
    g_getWraper,
    g_inputAreaTemplate,
    g_inputTemplate,
} from '../GenericFunctions';
import Log from '../logs/Log';
import Events from './Events';

export default class Notification extends Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiNotification(),
            mode: false,
            steps: {
                steps: [{ label: LS('0', 'notificationSteps') }, { label: LS('1', 'notificationSteps') }, { label: LS('2', 'notificationSteps') }, { label: LS('3', 'notificationSteps') }],
                stepIndex: 0,
            },
            items: [{ label: LS('notification', 'tab') }],
            photoSmall: null,
            photoBig: null,
            notificationBroadcastTypes: [],
            notificationCheckModes: [],
            idUUID: null,
            holderPhotoSmall: null,
            holderPhotoBig: null,
            search: '',
            searchResult: null,
            bname: '',
        };
    }

    updateStatus = async e => {
        e.preventDefault();
        let data = {
            idUUID: this.state.data.idUUID,
            notificationStatus: this.state.data.notificationStatus === '0' ? 5 : -1,
        };
        let response = await NotificationService.updateStatus(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    process = async e => {
        e.preventDefault();
        if (!this.f.checkValidity()) return;
        let data = {
            idUUID: this.state.data.idUUID,
            notificationStatus: 0,
        };
        let response = await NotificationService.updateStatus(data);
        if (g_displayMessageError(this.message, response)) return;
        this.messages.show({ severity: 'success', summary: LS('inWaitList', 'messages') });
        this.getData();
    };

    handleItems(idUUID = null) {
        let id = idUUID ?? this.state.idUUID;
        if (id !== null) {
            let items = [{ label: LS('notification', 'tab') }];
            if (g_checkAlc(4, 'notification#events') !== null) items = [...items, { label: LS('events', 'tab'), icon: 'pi pi-envelope' }];
            items.push({ label: LS('log', 'tab'), icon: 'pi pi-list' });
            this.setState({ items: items });
        }
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        this.handleItems(idUUID);
        this.getNotificationTypes();
        this.getNotificationCheckMode();
        this.getNotificationBroadcastTypes(g_checkAlc(6, 'notification#accessall'));
        this.setState({ mode: mode, idUUID: idUUID }, this.getData);
    }

    getData = async () => {
        if (this.state.idUUID === null) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
        };
        let response = await NotificationService.getNotifications(data);
        if (g_displayMessageError(this.message, response)) return;
        let rdata = response.notifications[0];
        let bname = '';
        rdata.notificationType = String(rdata.notificationType);
        rdata.notificationBroadcastType = String(rdata.notificationBroadcastType);
        rdata.notificationCheckMode = String(rdata.notificationCheckMode);
        rdata.notificationStatus = String(rdata.notificationStatus);
        if (rdata.destinationIDUUID && rdata.destinationIDUUID !== this.state.data.destinationIDUUID) {
            if (rdata.notificationBroadcastTypes === 2) {
                data.filters = [{ key: 'idUUID', value: this.state.destinationIDUUID }];
                response = await ConsignatorService.listConsignator(data);
                if (g_displayMessageError(this.message, response)) return;
                bname = response.consignators[0].fantasyName;
            } else if (rdata.notificationBroadcastTypes === 3) {
                data.filters = [{ key: 'idUUID', value: this.state.destinationIDUUID }];
                data.userType = 0;
                response = await UserService.listUsers(data);
                if (g_displayMessageError(this.message, response)) return;
                bname = response.consignators[0].fullname;
            }
        }
        this.setState({
            data: rdata,
            steps: {
                ...this.state.steps,
                stepIndex: this.setStep(rdata.notificationStatus),
            },
            bname: bname,
            deactivate: ['0', '1', '2', '3', '4', '5', '6'].indexOf(rdata.notificationStatus) !== -1 ? true : this.state.mode,
        });
    };

    getNotificationTypes = () => g_getDataFields(this, NotificationService.getNotificationTypes, 'notificationTypes', 'description', 'id', d => this.setState({ notificationTypes: d }));
    getNotificationCheckMode = () =>
        g_getDataFields(this, NotificationService.getNotificationCheckMode, 'notificationCheckModes', 'description', 'id', d => this.setState({ notificationCheckModes: d }));
    getNotificationBroadcastTypes = (ac = 0) =>
        g_getDataFields(
            this,
            NotificationService.getNotificationBroadcastTypes,
            'notificationBroadcastTypes',
            'description',
            'id',
            d => this.setState({ notificationBroadcastTypes: d }),
            ac !== null ? {} : { filters: [{ key: 'accesscontext', value: 1 }] }
        );

    getUsers = context =>
        g_getData(
            context,
            data => {
                let f = g_entityFilter(data.filters, 'entitiesIdUUID', 'entitiesIdUUID', 6, 'notification#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, users: [] };
                data.filters = f[0];
                data.userType = 0;
                return UserService.listUsers(data);
            },
            'users'
        );

    cancel = async e => {
        e.preventDefault();
        let response = await NotificationService.updateStatus({
            idUUID: this.state.data.idUUID,
            notificationStatus: 6,
        });
        if (g_displayMessageError(this.message, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleBlock', 'messages') });
        this.getData();
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) {
            if (data.documentTypeId === 202) {
                this.setState({ holderPhotoBig: formData });
                return;
            } else {
                //data.documentTypeId === 201 small
                this.setState({ holderPhotoSmall: formData });
                return;
            }
        } else {
            this.upload(formData, data.documentTypeId, this.state.idUUID);
        }
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await NotificationService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.message, response)) return;
    };

    setStep = param => ({ 0: 1, 2: 2, 1: 3 }[param] ?? 0);

    onSubmit = async event => {
        event.preventDefault();
        if (this.state.idUUID !== null) {
            let response = await NotificationService.updateNotification({ notification: this.state.data });
            if (g_displayMessageError(this.message, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            console.log('2');
            let response = await NotificationService.addNotification({ notification: this.state.data });
            if (g_displayMessageError(this.message, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            if (this.state.holderPhotoBig !== null) {
                await this.upload(this.state.holderPhotoBig, 202, response.notification.idUUID);
                this.setState({ holderPhotoBig: null });
            }
            if (this.state.holderPhotoSmall !== null) {
                await this.upload(this.state.holderPhotoSmall, 201, response.notification.idUUID);
                this.setState({ holderPhotoSmall: null });
            }
            window.location.href = `#/notification?type=1&id=${response.notification.idUUID}`;
            window.location.reload();
        }
    };

    specificSelector = () => {
        let a = 'p-col-3';
        if (this.state.data.notificationBroadcastType !== this.state.old) {
            this.setState({ search: '', old: this.state.data.notificationBroadcastType });
            return null;
        }
        if (this.state.data.notificationBroadcastType === '3') {
            if (g_checkAlc(6, 'notification#accessall') !== null || g_entities().indexOf(',') !== -1)
                return (
                    <GSelector
                        ref={e => (this.selector0 = e)}
                        getData={S.consignator.processed.list}
                        tableBody={[
                            { type: 'd', data: 'name', filter: true, sortable: true },
                            {
                                type: 'btt',
                                icon: 'pi pi-plus',
                                click: (raw, e) => {
                                    e.preventDefault();
                                    this.selector0.setState({ selected: raw.name, visible: false });
                                    this.setState({ data: { ...this.state.data, destinationIDUUID: raw.idUUID } });
                                },
                            },
                        ]}
                        inputtextwarper="p-col-5"
                        disabled={this.state.mode || this.state.deactivate}
                        startV={this.state.bname}
                    />
                );
            else {
                if (this.state.data.destinationIDUUID !== g_entities()) {
                    this.setState({ data: { ...this.state.data, destinationIDUUID: g_entities() } });
                }
                return null;
            }
        } else if (this.state.data.notificationBroadcastType === '4') {
            return (
                <GSelector
                    ref={e => (this.selector1 = e)}
                    getData={this.getUsers}
                    tableBody={[
                        { type: 'd', data: 'name', filter: true, sortable: true },
                        {
                            type: 'btt',
                            icon: 'pi pi-plus',
                            click: (raw, e) => {
                                e.preventDefault();
                                this.selector1.setState({ selected: raw.fullname, visible: false });
                                this.setState({ data: { ...this.state.data, destinationIDUUID: raw.idUUID } });
                            },
                        },
                    ]}
                    inputtextwarper="p-col-5"
                    disabled={this.state.mode || this.state.deactivate}
                    startV={this.state.bname}
                />
            );
        } else if (this.state.data.notificationBroadcastType === '5') {
            return g_inputTemplate('', '', '', this, false, true, false, null, {}, '', a, a);
        } else if (this.state.data.notificationBroadcastType === '6') {
            return (
                <GSelector
                    ref={e => (this.selector2 = e)}
                    getData={S.consignee.processed.list}
                    tableBody={[
                        { type: 'd', data: 'name', filter: true, sortable: true },
                        {
                            type: 'btt',
                            icon: 'pi pi-plus',
                            click: (raw, e) => {
                                e.preventDefault();
                                this.selector2.setState({ selected: raw.fullname, visible: false });
                                this.setState({ data: { ...this.state.data, destinationIDUUID: raw.idUUID } });
                            },
                        },
                    ]}
                    inputtextwarper="p-col-5"
                    disabled={this.state.mode || this.state.deactivate}
                    startV={this.state.bname}
                />
            );
        }
        return null;
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            let simple = ['1', '2'].indexOf(this.state.data.notificationType) !== -1;
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <div className="p-col">
                            <form ref={e => (this.f = e)} onSubmit={this.onSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                        {g_inputTemplate(
                                            'description',
                                            this.state.data.description,
                                            LS('description'),
                                            this,
                                            true,
                                            this.state.mode || this.state.deactivate,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-4',
                                            'p-col-3'
                                        )}
                                        {g_dropdownTemplate(
                                            'notificationBroadcastType',
                                            this.state.data.notificationBroadcastType,
                                            LS('notificationBroadcastType'),
                                            this,
                                            this.state.notificationBroadcastTypes,
                                            '',
                                            true,
                                            this.state.mode || this.state.deactivate,
                                            false,
                                            g_handleChange,
                                            {},
                                            'p-col-5',
                                            'p-col-3'
                                        )}
                                        {this.specificSelector()}
                                        {g_dropdownTemplate(
                                            'notificationType',
                                            this.state.data.notificationType,
                                            LS('notificationType'),
                                            this,
                                            this.state.notificationTypes,
                                            '',
                                            true,
                                            this.state.mode || this.state.deactivate,
                                            false,
                                            g_handleChange,
                                            {},
                                            'p-col-4',
                                            'p-col-3'
                                        )}
                                        {g_dropdownTemplate(
                                            'notificationCheckMode',
                                            this.state.data.notificationCheckMode,
                                            LS('notificationCheckMode'),
                                            this,
                                            this.state.notificationCheckModes,
                                            '',
                                            !simple,
                                            this.state.mode || this.state.deactivate,
                                            simple,
                                            g_handleChange,
                                            {},
                                            'p-col-4',
                                            'p-col-3'
                                        )}
                                        {g_inputTemplate(
                                            'shortDescription',
                                            this.state.data.shortDescription,
                                            LS('shortDescriptionNotification'),
                                            this,
                                            !(simple && this.state.data.notificationType !== '1'),
                                            this.state.mode || this.state.deactivate,
                                            simple && this.state.data.notificationType !== '1',
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-4',
                                            'p-col-3'
                                        )}
                                        {g_inputAreaTemplate(
                                            'longDescription',
                                            this.state.data.longDescription,
                                            LS('shortDescriptionNotification'),
                                            this,
                                            true,
                                            this.state.mode || this.state.deactivate,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-11',
                                            'p-col-2',
                                            { minHeight: '7em' }
                                        )}
                                    </div>
                                    <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                        <Panel style={{ borderLeft: '1px solid lightGrey', height: '100%' }}>
                                            {g_getWraper(
                                                null,
                                                LS('state'),
                                                <div className="p-col">
                                                    <StateIcon state={this.state.data.notificationStatus} custom={states} small />
                                                </div>,
                                                this.state.idUUID == null,
                                                'p-col-7'
                                            )}
                                            {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID == null, 'p-col')}
                                            {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID == null, 'p-col')}
                                            {g_getWraper(
                                                null,
                                                LS('bigPhoto', 'generic'),
                                                <div className="p-col-12 p-fluid p-grid">
                                                    <div className="p-col-"></div>
                                                    <GPhotoUpload
                                                        style={{ height: '74px', width: '164px' }}
                                                        handlePhotoUpload={this.onUpload}
                                                        photo={this.state.data.photoBig}
                                                        documentTypeId={202}
                                                        prefixPath={config.PHOTO_NOTIFICATION_URL}
                                                        alt={window.location.origin + '/assets/images/notificationbig.png'}
                                                        disabled={this.state.mode || this.state.deactivate}
                                                    />
                                                </div>,
                                                false,
                                                'p-col-12'
                                            )}
                                            {g_getWraper(
                                                null,
                                                LS('smallPhoto', 'generic'),
                                                <div className="p-col-12 p-fluid p-grid">
                                                    <div className="p-col-3"></div>
                                                    <GPhotoUpload
                                                        style={{ height: '64px', width: '82px' }}
                                                        handlePhotoUpload={this.onUpload}
                                                        photo={this.state.data.photoSmall}
                                                        documentTypeId={201}
                                                        prefixPath={config.PHOTO_NOTIFICATION_URL}
                                                        alt={window.location.origin + '/assets/images/notificationsmall.png'}
                                                        disabled={this.state.mode || this.state.deactivate}
                                                    />
                                                </div>,
                                                false,
                                                'p-col-12'
                                            )}
                                        </Panel>
                                    </div>
                                </div>
                                <div className="p-grid p-fluid">
                                    {g_template_button(LS(this.state.idUUID ? 'save' : 'create'), '', 0, null, this.state.mode || this.state.deactivate, 'p-col-2')}
                                    {g_dialogTemplate(
                                        LS('notificationAskProcess', 'messages'),
                                        'process',
                                        this,
                                        this.process,
                                        LS('process', 'btt'),
                                        'Sim',
                                        'Não',
                                        '',
                                        'p-button-warning',
                                        !this.state.idUUID || this.state.data.notificationStatus !== '-1',
                                        this.state.mode,
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'auto',
                                        'p-col-2'
                                    )}
                                    {g_dialogTemplate(
                                        LS(`ask${this.state.data.notificationStatus === 5 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                        'notificationStatusCancel',
                                        this,
                                        this.updateStatus,
                                        LS(this.state.data.notificationStatus !== 5 ? 'deactivate' : 'activate', 'btt'),
                                        'Sim',
                                        'Não',
                                        '',
                                        '',
                                        this.state.idUUID == null || this.state.mode,
                                        this.state.mode || (this.state.deactivate && this.state.data.notificationStatus !== '5' && this.state.data.notificationStatus !== '0'),
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'auto',
                                        'p-col-2'
                                    )}
                                    {g_dialogTemplate(
                                        LS('askBlockP', 'messages'),
                                        'notificationStatusBloquear',
                                        this,
                                        this.cancel,
                                        LS('block', 'btt'),
                                        'Sim',
                                        'Não',
                                        '',
                                        'p-button-danger',
                                        this.state.idUUID == null || this.state.mode,
                                        this.state.mode || e.d !== 1 || (this.state.deactivate && this.state.data.notificationStatus !== '5'),
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'auto',
                                        'p-col-2'
                                    )}
                                </div>
                            </form>

                            <Steps
                                style={{ display: ['3', '4', '5', '6'].indexOf(this.state.data.notificationStatus) === -1 ? '' : 'none' }}
                                model={this.state.steps.steps}
                                activeIndex={this.state.steps.stepIndex}
                                className="steps-custom"
                                readOnly={false}
                            />
                        </div>,
                        ...(this.state.items.length === 3 ? [<Events idUUID={this.state.idUUID} />] : []),
                        <Log idUUID={this.state.idUUID} logContextType={9} />,
                    ])}
                </div>
            );
        });
    }
}

export const states = {
    '-1': { color: 'orange', class: 'pi-step-forward', tooltip: LS('creating') },
    0: { color: 'grey', class: 'pi-spinner pi-spin', tooltip: LS('inList') },
    2: { color: 'green', class: 'pi-spinner pi-spin', tooltip: LS('processing') },
    1: { color: 'green', class: 'pi-check', tooltip: LS('processed') },
    3: { color: 'red', class: 'pi-circle-on', tooltip: LS('error') },
    4: { color: 'red', class: 'pi-clock', tooltip: LS('timeout') },
    5: { color: 'red', class: 'pi-times', tooltip: LS('disabled') },
    6: { color: 'red', class: 'pi-ban', tooltip: LS('deleted') },
};
