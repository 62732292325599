import React from 'react';
import { Steps } from 'primereact/steps';
import { GMap } from 'primereact/gmap';
import { Dialog } from 'primereact/dialog';
import Config from '../../config/config';
import { providerList } from '../provider/lists';
import { consignatorManagement } from '../consignator/ConsignatorManagement';
import { consigneeManagement } from '../consignee/lists';
import Log from '../logs/Log';
import { bidData } from './bid';
import * as GF from '../GenericFunctions';
import { lb, ls, S } from '../GenericFunctions';
import { AdvancedAddress } from '../generic/Address';
import { GSelectorAdvanced } from '../generic/GSelector';

//Footer for the confirmations dialog
const footer = ctx => (
    <div>
        {GF.g_template_button(lb('yes'), '', 0, null, 0, '', 'p-button-danger')}
        {GF.g_template_button(
            lb('no'),
            '',
            0,
            e => {
                e.preventDefault();
                ctx.setState({
                    dialog_state_stepclick: false,
                    dialog_state_negateStatus: false,
                    dialog_state_respondStatus: false,
                    dialog_state_addrrErorr: false,
                    toSelected: '',
                    toSelectedMessage: '',
                });
            },
            0,
            '',
            'p-button-danger'
        )}
    </div>
);

//Table that shows the bids
//Both on the tab and on the mainpage
const bidTable = ctx =>
    (!GF.g_checkAlc(5, 'freight#bid') || GF.g_checkAlc(5, 'freight#aprove')) && (ctx.state.data.contractStatus >= 422 || ctx.state.data.contractStatus === 2)
        ? [
              <GF.TablePage
                  title={''}
                  getData={c =>
                      GF.g_getData(c, S.contract.list, 'contracts', {
                          filters: [GF.g_pair('joinType', '2'), GF.g_pair('parentContractIdUUID', ctx.state.idUUID), ...c.state.filter],
                      })
                  }
                  table={c => [
                      { type: 'd', data: 'consignatorName', sortable: true, filter: true },
                      {
                          type: 'di',
                          di: raw => [
                              ''
                                  .padStart(5 - raw.ratting, '0')
                                  .split('')
                                  .map(() => <i style={{ fontSize: '1.87em', color: 'gold' }} class="pi pi-star-o" />),
                              ''
                                  .padStart(raw.ratting, '0')
                                  .split('')
                                  .map(() => <i style={{ fontSize: '1.87em', color: 'gold' }} class="pi pi-star" />),
                          ],
                          data: 'ratting',
                          sortable: true,
                          filter: true,
                      },
                      { type: 'd', title: GF.LS('value'), data: 'contractTotalValue', sortable: true, filter: true },
                      { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                      { type: 'state', data: 'contractStatus', states, sortable: true, filter: true },
                      {
                          type: 'btt',
                          tooltip: GF.LS('accept', 'btt'),
                          icon: 'pi pi-thumbs-up',
                          class: 'p-button-success',
                          show: r => r.contractStatus !== 451,
                          click: raw =>
                              GF.g_ChangeStatus(
                                  c,
                                  () => S.contract.status({ idUUID: raw.idUUID, contractStatus: 453 }),
                                  () => {
                                      c.getData();
                                      ctx.getData();
                                  },
                                  GF.LS('simpleStatus', 'messages'),
                                  null
                              ),
                      },
                      {
                          type: 'btt',
                          tooltip: GF.LS('respond', 'btt'),
                          icon: 'pi pi-comment',
                          class: 'p-button-warning',
                          show: r => r.contractStatus !== 451,
                          click: raw => c.setState({ dialog_state_respondStatus: true, id: raw.idUUID }),
                      },
                      {
                          type: 'btt',
                          tooltip: GF.LS('negate', 'btt'),
                          icon: 'pi pi-thumbs-down',
                          class: 'p-button-danger',
                          show: r => r.contractStatus !== 451,
                          click: raw => c.setState({ dialog_state_negateStatus: true, id: raw.idUUID }),
                      },
                      { type: 'url', url: r => bidData.url(r.idUUID), width: 1400, height: 700, callback: c.getData },
                  ]}
                  addon={c => (
                      <>
                          <form
                              onSubmit={e =>
                                  GF.g_ChangeStatus(
                                      c,
                                      () =>
                                          S.contract.status({
                                              idUUID: c.state.id,
                                              contractStatus: 454,
                                              contractStatusMessage: c.state.negationMessage,
                                              extraLogEntry: 'BID',
                                          }),
                                      () => c.setState({ negationMessage: '', dialog_state_negateStatus: false, id: '' }, c.getData),
                                      GF.LabelSelector('negate', 'messages'),
                                      c.state.messages,
                                      e
                                  )
                              }
                          >
                              <Dialog
                                  visible={c.state.dialog_state_negateStatus}
                                  onHide={() => c.setState({ dialog_state_negateStatus: false })}
                                  footer={footer(c)}
                                  style={{ width: '50vw', overflowX: 'none' }}
                                  header="Confirmar"
                              >
                                  <div className="p-grid p-fluid p-col-12" style={{ overflowX: 'none' }}>
                                      <div className="p-col-12 p-fluid">
                                          <Log pageSize={3} simple filter={[GF.g_pair('context', 'BID')]} idUUID={c.state.id} logContextType={4} />
                                      </div>
                                      <div className="p-col-12">Tem a certeza que quer Negar?</div>
                                      {GF.g_template_inputArea(
                                          c,
                                          'negationMessage',
                                          c.state.negationMessage,
                                          'Motivio',
                                          0,
                                          0,
                                          1,
                                          'p-col-12',
                                          'p-col-12',
                                          '',
                                          { width: '48vw' },
                                          { data: 'negationMessage' }
                                      )}
                                  </div>
                              </Dialog>
                          </form>

                          <form
                              onSubmit={e =>
                                  GF.g_ChangeStatus(
                                      c,
                                      () =>
                                          S.contract.status({
                                              idUUID: c.state.id,
                                              contractStatus: 452,
                                              contractStatusMessage: c.state.negationMessage,
                                              extraLogEntry: 'BID',
                                          }),
                                      () => c.setState({ negationMessage: '', dialog_state_respondStatus: false, id: '' }, c.getData),
                                      GF.LabelSelector('simpleStatus', 'messages'),
                                      c.state.messages,
                                      e
                                  )
                              }
                          >
                              <Dialog
                                  visible={c.state.dialog_state_respondStatus}
                                  onHide={() => c.setState({ dialog_state_respondStatus: false, negationMessage: '' })}
                                  footer={footer(c)}
                                  style={{ width: '50vw', overflowX: 'none' }}
                                  header="Confirmar"
                              >
                                  <div className="p-grid p-fluid p-col-12" style={{ overflowX: 'none' }}>
                                      <div className="p-col-12 p-fluid">
                                          <Log pageSize={3} simple filter={[GF.g_pair('context', 'BID')]} idUUID={c.state.id} logContextType={4} />
                                      </div>
                                      {GF.g_template_inputArea(
                                          c,
                                          'negationMessage',
                                          c.state.negationMessage,
                                          GF.LabelSelector('respond', 'messages'),
                                          0,
                                          0,
                                          1,
                                          'p-col-12',
                                          'p-col-12',
                                          '',
                                          { width: '48vw' },
                                          { data: 'negationMessage' }
                                      )}
                                  </div>
                              </Dialog>
                          </form>
                      </>
                  )}
              />,
          ]
        : [];

export default class Freight extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                ...GF.g_ApiContract(),
                contractType: 1,
                contractStatus: 400,
                providerIdUUID: GF.g_providers().split(',')[0] ?? '',

                //TODO: Change idUUID for prod
                contractTemplateIdUUID: 'ff59ae90-050a-4f26-a304-6e10f61d1d4d',
            },
            itemsb: [{ label: 'Frete' }],
            mode: false,
            idUUID: null,
            holderPhotoBig: null,
            holderPhotoSmall: null,
            scroll: 0,
            forms: { service_track_simple: '1', cargo_weight: '0' },
            categories: [],
            categoryTypes: [],
            truckType: [],
            cargoTypes: [],
            products: [],
            modes: [],
            quotationTypes: [],
            executionStatus: [],
            boxes: { 0: false, 1: false },
            negationMessage: '',
            oldStatus: 0,
            gps: [],
            incidents: false,
            overlays: [],
            options: {
                center: { lat: 0, lng: 0 },
                zoom: 8,
            },

            showPhotosDialog: false,
        };
    }

    componentDidUpdate() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        if (idUUID != this.state.idUUID && this.state.idUUID && !idUUID) {
            window.location.reload();
        }
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let items = this.state.itemsb;
        items =
            idUUID && !GF.g_checkAlc(5, 'freight#bid')
                ? [
                      ...items,
                      ...(GF.g_checkAlc(5, 'freight#aprove') && this.state.data.contractStatus >= 422 ? [{ label: GF.LabelSelector('bid', 'tab'), icon: 'pi pi-list' }] : []),
                      { label: GF.LabelSelector('log', 'tab'), icon: 'pi pi-list' },
                  ]
                : items;
        this.setState({ idUUID: idUUID, items: items, scroll: 1 }, this.getData);
        GF.g_getDataFields(this, S.icargo.categories, 'categories', 'description', 'id', 'categories');
        GF.g_getDataFields(this, S.icargo.categoryTypes, 'categoryTypes', 'description', 'id', 'categoryTypes');
        GF.g_getDataFields(this, S.icargo.truckType, 'truckTypes', 'description', 'id', 'truckType');
        GF.g_getDataFields(this, S.icargo.cargoTypes, 'cargoTypes', 'description', 'id', 'cargoTypes');
        GF.g_getDataFields(this, S.icargo.products, 'productSegments', 'description', 'id', 'products');
        GF.g_getDataFields(this, S.icargo.cargoPaymentMode, 'cargoPaymentModes', 'description', 'id', 'modes');
        GF.g_getDataFields(this, S.icargo.cargoQuationType, 'cargoQuotationTypes', 'description', 'id', 'quotationTypes');
        GF.g_getDataFields(this, S.icargo.executionStatus, 'executionStatus', 'description', 'id', 'executionStatus');
    }

    componentWillUnmount() {
        if (this.mapReadyTimeOut) clearTimeout(this.mapReadyTimeOut);
    }

    approveStatus = e => GF.g_ChangeStatus(this, () => S.contract.status({ idUUID: this.state.data.idUUID, contractStatus: 422 }), this.getData, GF.LabelSelector('simpleStatus', 'messages'), null, e);
    negateStatus = e =>
        GF.g_ChangeStatus(
            this,
            () =>
                S.contract.status({
                    idUUID: this.state.data.idUUID,
                    contractStatus: 420,
                    contractStatusMessage: this.state.negationMessage,
                }),
            () => this.setState({ negationMessage: '', dialog_state_negateStatus: false }, this.getData),
            GF.LabelSelector('negate', 'messages'),
            this.state.messages,
            e
        );
    cancelStatus = e =>
        GF.g_ChangeStatus(
            this,
            () =>
                S.contract.status({
                    idUUID: this.state.data.idUUID,
                    contractStatus: 2,
                    contractStatusMessage: this.state.negationMessage,
                }),
            this.getData,
            GF.LabelSelector('simpleStatus', 'messages'),
            this.state.messages,
            e
        );

    getData = () =>
        this.state.idUUID
            ? GF.g_getData(
                  this,
                  () => S.contract.get(this.state.idUUID),
                  r => {
                      let contract = r.contract;
                      console.log(contract);

                      //If its active check for gps locations, incidents and photos
                      if (contract.contractStatus >= 426) {
                          GF.g_getData(
                              this,
                              S.icargo.gps.list,
                              r => {
                                  this.setState({ gps: r.cargoGPSs, scroll: 2 });
                              },
                              {
                                  pageSize: 100000,
                                  pageNumber: 1,
                                  filters: [GF.g_pair('contractIdUUID', contract.idUUID)],
                              }
                          );

                          // this only checks if incidents exist does not get the data for the table
                          GF.g_getData(
                              this,
                              S.incident.list,
                              r => {
                                  this.setState({ incidents: r.incidents.length > 0 });
                              },
                              { pageSize: 1, pageNumber: 1, filters: [GF.g_pair('contractIdUUID', contract.idUUID)] }
                          );

                          //Check if we have images
                          GF.g_getData(
                              this,
                              data => S.utils.getDocuments(data, 'contract'),
                              r => {
                                  console.log(r);
                              },
                              {
                                  documentContextType: 4,
                                  filters: [GF.g_pair('documentStatus', 1), GF.g_pair('referenceIdUUID', this.state.idUUID)],
                              }
                          );

                          //Check if we have signatures
                          GF.g_getData(
                              this,
                              data => S.utils.getDocuments(data, 'contract'),
                              r => {
                                  console.log(r);
                              },
                              {
                                  documentContextType: 4,
                                  filters: [GF.g_pair('documentStatus', 1), GF.g_pair('referenceIdUUID', this.state.idUUID)],
                              }
                          );
                      }

                      let fieldstoset = { date_checkout: [] };

                      if (contract.contractStatus >= 404) fieldstoset['date_delivery_limit'] = new Date();

                      let fields = (contract.contractComplementaryFields ?? []).map(e => {
                          let name = e.fieldName;
                          let value = e.fieldValue;
                          let toSet = {};
                          if (name === 'origin_address') {
                              let t = value => {
                                  if (this.o) this.o.setState({ data: value });
                                  else setTimeout(() => t(value), 500);
                              };
                              try {
                                  value = JSON.parse(value);
                                  setTimeout(() => t(value), 500);
                              } catch (_) {}
                              toSet[name] = value;
                          } else if (name === 'destination_address') {
                              let t = value => {
                                  if (this.d) this.d.setState({ data: value });
                                  else setTimeout(() => t(value), 500);
                              };
                              try {
                                  value = JSON.parse(value);
                                  setTimeout(() => t(value), 500);
                              } catch (_) {}
                              toSet[name] = value;
                          } else if (name === 'date_checkout_start') {
                              fieldstoset.date_checkout[0] = new Date(value);
                          } else if (name === 'date_checkout_end') {
                              fieldstoset.date_checkout[1] = new Date(value);
                          } else if (name === 'hour_checkout_start' || name === 'hour_checkout_end') {
                              value = String(value).padStart(4, '0');
                              value = value.substr(0, 2) + ':' + value.substr(2, 2);
                              fieldstoset[name] = new Date(`2000-01-01T${value}:00.000`);
                          } else if (name === 'date_delivery_limit' && value === '') return toSet;
                          else if (
                              name === 'cargo_weight' ||
                              name === 'cargo_price' ||
                              name === 'cargo_payment_flow_load' ||
                              name === 'cargo_payment_flow_adjudication' ||
                              name === 'cargo_payment_flow_delivery' ||
                              name === 'cargo_insurace_value'
                          )
                              toSet[name] = GF.g_treatNumricR(value);
                          else toSet[name] = value;
                          return toSet;
                      });
                      if (fields.length > 0) fields = { ...fieldstoset, ...fields.reduce((a, b) => ({ ...a, ...b })) };
                      else fields = fieldstoset;
                      let additional = {};
                      if (
                          (contract.contractStatus >= 422 || contract.contractStatus === 2) &&
                          !(GF.g_checkAlc(5, 'freight#bid') && !GF.g_checkAlc(5, 'freight#aprove')) &&
                          this.state.items.length !== 3
                      )
                          additional = {
                              items: [
                                  ...this.state.itemsb,
                                  ...(!GF.g_checkAlc(5, 'freight#bid') || GF.g_checkAlc(5, 'freight#aprove') ? [{ label: GF.LabelSelector('bid', 'tab'), icon: 'pi pi-list' }] : []),
                                  { label: GF.LabelSelector('log', 'tab'), icon: 'pi pi-list' },
                              ],
                          };
                      if (contract.contractStatus === 2) {
                          additional = { mode: true, scroll: 2, ...additional };
                      }
                      this.setState({ data: contract, scroll: 2, forms: fields, ...additional });
                  }
              )
            : null;

    handleSubmit = async (e, cS = true) => {
        if (e.preventDefault) e.preventDefault();
        let forms = this.state.forms;
        // Checks if p a d add up to 100
        let p = forms['cargo_payment_flow_load'];
        let a = forms['cargo_payment_flow_adjudication'];
        let d = forms['cargo_payment_flow_delivery'];
        if ((p === '' || a === '' || d === '') && this.state.data.contractStatus >= 406) return this.formRef.reportValidity();
        p = Number(GF.g_treatNumric(p));
        a = Number(GF.g_treatNumric(a));
        d = Number(GF.g_treatNumric(d));
        if (p + a + d !== 100 && this.state.data.contractStatus >= 406) {
            this.messages.show({
                life: 10000,
                summary: GF.LabelSelector('mustAddUpTo100', 'messages'),
                severity: 'error',
            });
            return;
        }
        forms['cargo_payment_flow_load'] = GF.g_treatNumricR(p);
        forms['cargo_payment_flow_adjudication'] = GF.g_treatNumricR(a);
        forms['cargo_payment_flow_delivery'] = GF.g_treatNumricR(d);

        // process most of the fileds from the form
        let dataObjs = Object.keys(forms)
            .filter(name => name !== 'destination_address' && name !== 'origin_address' && name !== 'date_checkout' && name !== 'date_checkout_end' && name !== 'date_checkout_start')
            .map(name => {
                if (name === 'hour_checkout_start' || name === 'hour_checkout_end') {
                    let treat = a => {
                        let date = new Date(a);
                        return String(date.getHours()).padStart(2, '0') + String(date.getMinutes()).padStart(2, '0');
                    };
                    return { fieldName: name, fieldValue: treat(this.state.forms[name]) };
                }
                if (name === 'date_delivery_limit') {
                    return { fieldName: name, fieldValue: GF.g_treatDate(this.state.forms[name]) };
                } else if (
                    name === 'cargo_weight' ||
                    name === 'cargo_price' ||
                    name === 'cargo_payment_flow_load' ||
                    name === 'cargo_payment_flow_adjudication' ||
                    name === 'cargo_payment_flow_delivery' ||
                    name === 'cargo_insurace_value'
                ) {
                    return { fieldName: name, fieldValue: GF.g_treatNumric(this.state.forms[name], true) };
                } else {
                    return { fieldName: name, fieldValue: this.state.forms[name] };
                }
            });

        let addrrError = false;

        // Process the destination
        if (this.d) {
            let data = this.d.state.data;
            if (this.state.data.contractStatus === 401 && Number(data.latitude) === 0 && Number(data.latitude) === 0) {
                let r = await this.d.update(2);
                if (r === 'laterror') {
                    addrrError = true;
                } else {
                    data.latitude = r.latitude;
                    data.longitude = r.longitude;
                }
            }
            data = this.d.state.data;
            data.zipCode = data.zipCode ? String(data.zipCode).replace(/[^\d]/g, '') : '';
            data.addressType = 1;
            dataObjs = [...dataObjs, { fieldName: 'destination_address', fieldValue: JSON.stringify(data) }];
        }

        // Process the origin
        if (this.o) {
            let data = this.o.state.data;
            if (this.state.data.contractStatus === 401 && Number(data.latitude) === 0 && Number(data.latitude) === 0) {
                let r = await this.o.update(2);
                if (r === 'laterror') {
                    addrrError = true;
                } else {
                    data.latitude = r.latitude;
                    data.longitude = r.longitude;
                }
            }
            data.zipCode = data.zipCode ? String(data.zipCode).replace(/[^\d]/g, '') : '';
            data.addressType = 1;
            dataObjs = [...dataObjs, { fieldName: 'origin_address', fieldValue: JSON.stringify(data) }];
        }

        if (!this.state.addrrError && addrrError) {
            this.setState({ addrrError: true, dialog_state_addrrErorr: true });
            return;
        }

        // Process the date checkout
        if (forms.date_checkout && this.state.data.contractStatus >= 404) {
            if (forms.date_checkout.length !== 2) {
                this.setState({ forms: { ...this.state.forms, date_checkout: [] } }, () => this.formRef.reportValidity());
                return;
            }
            dataObjs = [...dataObjs, { fieldName: 'date_checkout_start', fieldValue: GF.g_treatDate(this.state.forms.date_checkout[0]) }];
            dataObjs = [...dataObjs, { fieldName: 'date_checkout_end', fieldValue: GF.g_treatDate(this.state.forms.date_checkout[1]) }];
        }

        //Check if it exists or is a new one
        if (this.state.idUUID) {
            let response = await S.contract.update({ contract: this.state.data });
            if (GF.g_displayMessageError(this.messages, response)) return;
            response = await S.contract.field.put({
                contractComplementaryFields: dataObjs,
                contractIdUUID: this.state.idUUID,
            });
            if (GF.g_displayMessageError(this.messages, response)) return;
            if (this.state.data.contractStatus < 420)
                response = await S.contract.status({
                    idUUID: this.state.data.idUUID,
                    contractStatus: this.state.data.contractStatus === 406 ? 420 : this.state.data.contractStatus + 1,
                });
            if (this.state.data.contractStatus === 420 && cS) response = await S.contract.status({ idUUID: this.state.data.idUUID, contractStatus: 421 });

            if (GF.g_displayMessageError(this.messages, response)) return;
            this.setState({ scroll: 2 }, this.getData);
        } else {
            //Create a new one and then create it and then add the fileds to the api
            GF.g_getData(
                this,
                S.contract.add,
                async r => {
                    await S.contract.field.put({
                        contractComplementaryFields: dataObjs,
                        contractIdUUID: r.contract.idUUID,
                    });
                    let query = new URLSearchParams(this.props.location.search);
                    window.location.href = `#/freight?id=${r.contract.idUUID}${query.get('type') === '1' ? '&type=1' : ''}`;
                    window.location.reload();
                },
                {
                    contract: this.state.data,
                    syncCall: 0,
                }
            );
        }
    };

    update = _ => null;

    updatea = e => {
        let p = this.state.forms['cargo_payment_flow_load'];
        let a = this.state.forms['cargo_payment_flow_adjudication'];
        let d = this.state.forms['cargo_payment_flow_delivery'];
        if (e === 'cargo_payment_flow_adjudication') {
            if (a !== '') {
                if (p === '' && d !== '') {
                    let pn = Number(GF.g_treatNumric(p));
                    let an = Number(GF.g_treatNumric(a));
                    let r = 100 - pn - an;
                    let toSet = this.state.forms;
                    toSet['cargo_payment_flow_delivery'] = GF.g_treatNumricR(String(r));
                    this.setState({ forms: toSet });
                } else if (p !== '' && d === '') {
                    let pn = Number(GF.g_treatNumric(d));
                    let an = Number(GF.g_treatNumric(a));
                    let r = 100 - pn - an;
                    let toSet = this.state.forms;
                    toSet['cargo_payment_flow_adjudication'] = GF.g_treatNumricR(String(r));
                    this.setState({ forms: toSet });
                }
            }
        } else if (e === 'cargo_payment_flow_load') {
            if (p !== '') {
                if (a === '' && d !== '') {
                    let pn = Number(GF.g_treatNumric(a));
                    let an = Number(GF.g_treatNumric(p));
                    let r = 100 - pn - an;
                    let toSet = this.state.forms;
                    toSet['cargo_payment_flow_adjudication'] = GF.g_treatNumricR(String(r));
                    this.setState({ forms: toSet });
                } else if (a !== '' && d === '') {
                    let pn = Number(GF.g_treatNumric(d));
                    let an = Number(GF.g_treatNumric(p));
                    let r = 100 - pn - an;
                    let toSet = this.state.forms;
                    toSet['cargo_payment_flow_adjudication'] = GF.g_treatNumricR(String(r));
                    this.setState({ forms: toSet });
                }
            }
        } else if (e === 'cargo_payment_flow_delivery') {
            if (d !== '') {
                if (a === '' && p !== '') {
                } else if (a !== '' && p === '') {
                }
            }
        }
    };

    map = null;

    //TODO: CHAT BTT
    //TODO: PHOTOS BTT
    //TODO: SIGNATURES BTT
    mapSection = _mode => {
        if (this.state.data.contractStatus > 425 || (this.state.data.contractStatus === 2 && this.state.forms.execution_status_id !== '0')) {
            let executionStatusMap = [
                ...this.state.executionStatus.map(a => {
                    let toSet = {};
                    toSet[a.value] = a.label;
                    return toSet;
                }),
                {},
            ].reduce((a, b) => ({ ...a, ...b }));
            let gps = this.state.gps;
            let mapReady = map => {
                gps = this.state.gps;
                let google = window.google;
                if (this.mapReadyTimeOut) {
                    clearTimeout(this.mapReadyTimeOut);
                    this.mapReadyTimeOut = null;
                }
                if (google && gps.length > 0) {
                    this.map = map;
                    let cords = gps.map(a => ({ lat: Number(a.latitude), lng: Number(a.longitude) }));

                    const path = new google.maps.Polyline({
                        path: cords,
                        geodesic: true,
                        strokeColor: '#FF0000',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    });
                    let r = [path];

                    if (this.state.forms.origin_address) {
                        let data = this.state.forms.origin_address;
                        const m1 = new google.maps.Marker({
                            position: { lat: Number(data.latitude), lng: Number(data.longitude) },
                            title: GF.LabelSelector('origin_address'),
                        });
                        r = [...r, m1];
                    }

                    if (this.state.forms.destination_address) {
                        let data = this.state.forms.destination_address;
                        const m1 = new google.maps.Marker({
                            position: { lat: Number(data.latitude) ?? 0, lng: Number(data.longitude) ?? 0 },
                            title: GF.LabelSelector('destination_address'),
                        });
                        r = [...r, m1];
                    }

                    this.setState({ overlays: r });

                    map.map.setCenter(this.state.options.center);
                } else this.mapReadyTimeOut = setTimeout(() => mapReady(map), 1000);
            };
            let center = { lat: 0, lng: 0 };
            if (gps.length !== 0) {
                center = { lat: Number(gps[gps.length - 1].latitude), lng: Number(gps[gps.length - 1].longitude) };
            } else if (this.state.forms.origin_address) {
                let data = this.state.forms.origin_address;
                center = { lat: Number(data.latitude), lng: Number(data.longitude) };
            }

            if (this.state.options.center.lat === 0 && this.state.options.center.lng === 0 && center.lat !== 0 && center.lng !== 0) this.setState({ options: { center, zoom: 8 } });

            let verticalSteps = () => {
                let r = [];
                for (let a of this.state.executionStatus) {
                    console.log(a);
                    r.push(
                        <div
                            key={a.value}
                            style={{
                                display: 'grid',
                                placeItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <div
                                style={{
                                    background: a.value === this.state.forms.execution_status_id ? '#007ad9' : 'none',
                                    color: a.value === this.state.forms.execution_status_id ? 'white' : 'black',
                                    opacity: a.value === this.state.forms.execution_status_id ? '1' : '0.5',
                                    minWidth: '3em',
                                    width: '3em',
                                    minHeight: '3em',
                                    borderRadius: '50%',
                                    display: 'grid',
                                    placeItems: 'center',
                                    font: '1.4em',
                                    border: '1px solid grey',
                                }}
                            >
                                {a.value}
                            </div>
                            {a.label}
                        </div>
                    );
                }
                return r;
            };

            //BODY

            return (
                <>
                    <div
                        ref={a => {
                            if (this.state.scroll === 0 || this.state.data.contractStatus < 426) return;
                            this.setState({ scroll: this.state.scroll - 1 });
                            if (a) a.scrollIntoView();
                        }}
                        className="p-grid p-fluid"
                        style={{ ...this.divStyle }}
                    >
                        <div className="p-col-4" style={{ display: 'grid', placeItems: 'center' }}>
                            <span style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: '2em' }}>
                                {executionStatusMap[this.state.forms.execution_status_id] ?? this.state.forms.execution_status_id ?? GF.LabelSelector('noState', 'messages')}
                            </span>
                            <div style={{ display: 'grid', width: '100%', placeItems: 'center' }}>
                                <img
                                    alt=""
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        cursor: GF.g_checkAlc(5, 'consignee-management#acessall') ? 'pointer' : '',
                                    }}
                                    src={this.state.data.consigneePhoto ? Config.PHOTO_URL + this.state.data.consigneePhoto : window.location.origin + '/assets/images/person.png'}
                                    onClick={e => {
                                        e.preventDefault();
                                        if (GF.g_checkAlc(5, 'consignee-management#acessall'))
                                            GF.g_openWindowDialog(
                                                consigneeManagement.url(this.state.data.consigneeIdUUID),
                                                this.state.data.consigneeIdUUID,
                                                consigneeManagement.size[0],
                                                consigneeManagement.size[1]
                                            );
                                    }}
                                />
                                <p>{this.state.data.consigneeFullname ?? ''}</p>
                                {GF.g_template_button(GF.LabelSelector('chat', 'btt'), '', false, _ => null, false, 'p-col-12')}
                            </div>
                            <div className="p-grid" style={{ minWidth: '100%' }}>
                                {GF.g_template_checkBox(
                                    this,
                                    '',
                                    0,
                                    '',
                                    true,
                                    false,
                                    '',
                                    'p-col-1',
                                    {},
                                    undefined,
                                    false,
                                    this.state.forms.service_take_photo ? '' : GF.LabelSelector('notContracted', 'messages')
                                )}
                                <div className="p-col-5"> Fotos </div>
                            </div>
                            <div className="p-grid" style={{ minWidth: '100%' }}>
                                {GF.g_template_checkBox(
                                    this,
                                    '',
                                    0,
                                    '',
                                    true,
                                    false,
                                    '',
                                    'p-col-1',
                                    {},
                                    undefined,
                                    false,
                                    this.state.forms.service_take_signature ? '' : GF.LabelSelector('notContracted', 'messages')
                                )}
                                <div className="p-col-5"> Assianturas </div>
                            </div>

                            {this.state.incidents ? (
                                <p style={{ color: 'red' }}> {GF.LabelSelector('withIncidents', 'text')} </p>
                            ) : (
                                <p style={{ color: 'green' }}> {GF.LabelSelector('withoutIncidents', 'text')} </p>
                            )}
                        </div>

                        <div className="p-col-7" style={{ height: '70vh' }}>
                            <GMap style={{ width: '100%', height: '100%' }} onMapReady={mapReady} options={this.state.options} overlays={this.state.overlays} />
                        </div>

                        <div
                            className="p-col-1"
                            style={{
                                height: '70vh',
                                alignContent: 'space-evenly',
                                display: 'grid',
                            }}
                        >
                            {verticalSteps()}
                        </div>

                        <div hidden={!this.state.incidents} className={`p-col-6`}>
                            <GF.TablePage
                                table={_ => [
                                    { t: 'd', data: 'creationUserDescription', filter: true },
                                    { t: 'd', data: 'description', filter: true },
                                    { t: 'd', data: 'incidentTypeDescription', filter: true },
                                    { t: 'd', data: 'incidentCategoryDescription', filter: true },
                                    {
                                        t: 'btt',
                                        icon: 'pi pi-external-link',
                                        click: (raw, e) => {
                                            e.preventDefault();
                                            let center = {
                                                lat: raw.latitude,
                                                lng: raw.longitude,
                                            };
                                            if (this.map.map) this.map.map.setCenter(center);
                                            this.setState({ options: { center, ...this.state.options } });
                                        },
                                        disabled: e => e.latitude === 0 && e.longitude === 0,
                                    },
                                    { t: 'date', data: 'dateCreation', sortable: true },
                                ]}
                                header={c => GF.g_getHeaderTemplateNull(c, c.getData, <p style={{ float: 'left', padding: '0px', margin: '0px' }}> {GF.LabelSelector('withIncidents', 'text')} </p>)}
                                getData={c =>
                                    GF.g_getData(c, S.incident.list, 'incidents', {
                                        filters: [...c.state.filter, GF.g_pair('contractIdUUID', this.state.idUUID)],
                                    })
                                }
                            />
                        </div>
                        <div className={`p-col-${this.state.incidents ? '6' : '12'}`}>
                            <Log
                                idUUID={this.state.idUUID}
                                logContextType={4}
                                overideData={c => ({
                                    filters: [...c.state.filter, GF.g_pair('context', 'CARGO')],
                                    pageSize: 3,
                                })}
                            />
                        </div>
                    </div>

                    <Dialog visible={this.state.showPhotosDialog} onHide={() => this.setState({ showPhotosDialog: false })}></Dialog>
                </>
            );
        }
        return null;
    };

    getCStep = () => {
        if (this.state.data.contractStatus === 420) return 7;
        if (this.state.data.contractStatus > 420) return this.state.data.contractStatus - 420;
        return this.state.data.contractStatus - 400;
    };

    getSteps = () => {
        if (this.state.data.contractStatus >= 421)
            return [
                { label: GF.LabelSelector('creation', 'titles'), id: 0 },
                { label: GF.LabelSelector('aproveByICargo', 'titles'), id: 4 },
                { label: GF.LabelSelector('auction', 'titles'), id: 4 },
                { label: GF.LabelSelector('approvedForContract', 'titles'), id: 4 },
                { label: GF.LabelSelector('scheduledService', 'titles'), id: 4 },
                { label: GF.LabelSelector('gotDriver', 'titles'), id: 4 },
                { label: GF.LabelSelector('inExecution', 'titles'), id: 4 },
                { label: GF.LabelSelector('ended', 'titles'), id: 4 },
            ];
        return [
            { label: GF.LabelSelector('feigthData', 'titles'), id: 0 },
            { label: GF.LabelSelector('addresses', 'titles'), id: 1 },
            { label: GF.LabelSelector('feigthInfo', 'titles'), id: 2 },
            { label: GF.LabelSelector('otherServices', 'titles'), id: 3 },
            { label: GF.LabelSelector('dates', 'titles'), id: 4 },
            { label: GF.LabelSelector('waranty', 'titles'), id: 5 },
            { label: GF.LabelSelector('paymentMethods', 'titles'), id: 6 },
            { label: GF.LabelSelector('confirm', 'titles') },
        ];
    };

    status = 0;

    open = () => {
        GF.g_getData(
            this,
            data => {
                let f = GF.g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess', GF.g_consignators());
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                if (f) data.filters = [...data.filters, ...f[0]];
                return S.contract.list(data);
            },
            r => {
                let contracts = r.contracts;
                if (contracts.length >= 1) {
                    GF.g_openWindowDialog(bidData.url(contracts[0].idUUID, this.state.data.idUUID), this.state.data.idUUID + 'BID', bidData.size[0], bidData.size[1]);
                } else {
                    GF.g_openWindowDialog(bidData.url(null, this.state.data.idUUID), this.state.data.idUUID + 'BID', bidData.size[0], bidData.size[1]);
                }
            },
            { filters: [GF.g_pair('joinType', '2'), GF.g_pair('parentContractIdUUID', this.state.idUUID)] }
        );
    };

    divStyle = {
        border: '1px #d3dbe6 solid',
        background: '#eaedf2',
        padding: '8px',
        marginTop: '2em',
        borderRadius: '10px',
    };

    canShowOnCancel = other => {
        return other && this.state.data.contractStatus < other && 2 !== this.state.data.contractStatus;
    };

    render() {
        return GF.g_checkLoginACL(e =>
            GF.g_checkMode(this, e, this.state.idUUID, () => {
                return (
                    <div className="card card-w-title">
                        {GF.g_tabViewRenderOptions(
                            this,
                            [
                                GF.g_template_form(
                                    this,
                                    mode => [
                                        <div
                                            ref={a => {
                                                if (this.state.scroll === 0 || (this.state.data.contractStatus !== 420 && this.state.data.contractStatus !== 2)) return;
                                                this.setState({ scroll: this.state.scroll - 1 });
                                                if (a) a.scrollIntoView();
                                            }}
                                        ></div>,

                                        //Add Message on confirmacao
                                        {
                                            t: 'array',
                                            divStyle: { ...this.divStyle, backgroundColor: '#bde2c4' },
                                            dTypeF: 'text',
                                            cc: [11],
                                            filter: [GF.LabelSelector('confirmInfo', 'messages')],
                                            nshow: this.state.data.contractStatus !== 420,
                                            style: [{ textAlign: 'center', fontSize: '1.5em' }],
                                        },

                                        /* Cargo base data */

                                        <div className="p-grid p-fluid" style={{ ...this.divStyle }}>
                                            <div className="p-col-8">
                                                {GF.g_formMaper(
                                                    this,
                                                    [
                                                        {
                                                            t: 'array',
                                                            req: { _: true, 4: false },
                                                            dTypeFs: {
                                                                _: 't',
                                                                1: 'area',
                                                                2: 'drop',
                                                                0: 'text',
                                                                3: 'drop',
                                                            },
                                                            handleExtraData: { _: { data: 'forms' }, 1: {} },
                                                            origin: { ...this.state.forms, ...this.state.data },
                                                            filter: [GF.LabelSelector('feigthData', 'titles'), 'obs', ['category_id', 'category_type_id'], 'truck_type_id', 'cte'],
                                                            cc: { 1: 9, 2: [3, 3], 4: 3, 3: 5 },
                                                            class: { 2: [2, 1] },
                                                            options: {
                                                                2: [this.state.categories, this.state.categoryTypes],
                                                                3: this.state.truckType,
                                                            },
                                                            enableFilter: { 3: true },
                                                            style: { 0: { fontWeight: 'bold', fontSize: '1.5em' } },
                                                        },
                                                    ],
                                                    mode,
                                                    2
                                                )}
                                            </div>
                                            <div className="p-col-4" style={{ borderLeft: '2px #d3dbe6 solid' }}>
                                                {GF.g_formMaper(
                                                    this,
                                                    [
                                                        {
                                                            t: 'array',
                                                            dTypeFs: { 1: 'view', 2: 'view', 0: 'state', _: 'dElm' },
                                                            filter: ['contractStatus', 'dateCreation', 'dateUpdate', '', '', ''],
                                                            nshow: this.state.data.contractStatus < 401 && !GF.g_checkAlc(5, 'contract-management#totalaccess'),
                                                            states: {
                                                                0: states,
                                                            },
                                                            small: {
                                                                0: true,
                                                            },
                                                            dElm: {
                                                                3: GF.g_wraper(
                                                                    'providerPhoto',
                                                                    GF.LabelSelector('providerName'),
                                                                    <div className="p-col-5">
                                                                        <img
                                                                            alt=""
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                cursor: GF.g_checkAlc(5, 'provider#accessall') ? 'pointer' : '',
                                                                            }}
                                                                            src={
                                                                                this.state.data.providerPhoto
                                                                                    ? Config.PHOTO_URL + this.state.data.providerPhoto
                                                                                    : window.location.origin + '/assets/images/logotemplate.png'
                                                                            }
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (GF.g_checkAlc(5, 'provider#accessall'))
                                                                                    GF.g_openWindowDialog(
                                                                                        providerList.url(this.state.data.providerIdUUID),
                                                                                        this.state.data.providerIdUUID,
                                                                                        providerList.size[0],
                                                                                        providerList.size[1]
                                                                                    );
                                                                            }}
                                                                        />
                                                                        <p>{this.state.data.providerName ?? ''}</p>
                                                                    </div>,
                                                                    false,
                                                                    'p-col-7',
                                                                    false
                                                                ),
                                                                4: GF.g_wraper(
                                                                    'consignatorPhoto',
                                                                    GF.LabelSelector('consignatorName'),
                                                                    <div className="p-col-5">
                                                                        <img
                                                                            alt=""
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                cursor: GF.g_checkAlc(5, 'consignator-management#accessall') ? 'pointer' : '',
                                                                            }}
                                                                            src={
                                                                                this.state.data.consignatorPhoto
                                                                                    ? Config.PHOTO_URL + this.state.data.consignatorPhoto
                                                                                    : window.location.origin + '/assets/images/logotemplate.png'
                                                                            }
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (GF.g_checkAlc(5, 'consignator-management#accessall'))
                                                                                    GF.g_openWindowDialog(
                                                                                        consignatorManagement.url(this.state.data.consignatorIdUUID),
                                                                                        this.state.data.consignatorIdUUID,
                                                                                        consignatorManagement.size[0],
                                                                                        consignatorManagement.size[1]
                                                                                    );
                                                                            }}
                                                                        />
                                                                        <p>{this.state.data.consignatorName ?? ''}</p>
                                                                    </div>,
                                                                    false,
                                                                    'p-col-7'
                                                                ),
                                                                5: GF.g_wraper(
                                                                    'consignatorPhoto',
                                                                    GF.LabelSelector('consigneeName'),
                                                                    <div className="p-col-5">
                                                                        <img
                                                                            alt=""
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                cursor: GF.g_checkAlc(5, 'consignee-management#acessall') ? 'pointer' : '',
                                                                            }}
                                                                            src={
                                                                                this.state.data.consigneePhoto
                                                                                    ? Config.PHOTO_URL + this.state.data.consigneePhoto
                                                                                    : window.location.origin + '/assets/images/person.png'
                                                                            }
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (GF.g_checkAlc(5, 'consignee-management#acessall'))
                                                                                    GF.g_openWindowDialog(
                                                                                        consigneeManagement.url(this.state.data.consigneeIdUUID),
                                                                                        this.state.data.consigneeIdUUID,
                                                                                        consigneeManagement.size[0],
                                                                                        consigneeManagement.size[1]
                                                                                    );
                                                                            }}
                                                                        />
                                                                        <p>{this.state.data.consigneeFullname ?? ''}</p>
                                                                    </div>,
                                                                    false,
                                                                    'p-col-7'
                                                                ),
                                                            },
                                                            class: { 1: '7', 2: '7' },
                                                            c: {
                                                                3: !this.state.data.providerIdUUID,
                                                                4: !this.state.data.consignatorIdUUID,
                                                                5: !this.state.data.consigneeIdUUID,
                                                            },
                                                        },
                                                    ],
                                                    mode,
                                                    2
                                                )}
                                            </div>
                                        </div>,

                                        this.mapSection(mode),
                                        ...(this.state.data.contractStatus === 422 ? bidTable(this) : []),

                                        /* Consignee selector provider */
                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            dTypeFs: { _: 'dElm', 0: 'text' },
                                            nshow: this.state.data.contractStatus < 424 || this.state.data.contractStatus > 425 || GF.g_checkAlc(5, 'freight#bid'),
                                            c: [false, !this.state.data.consigneeIdUUID],
                                            cc: [12],
                                            style: {
                                                0: {
                                                    fontSize: '2em',
                                                    textAlign: this.state.data.consigneeIdUUID ? 'unset' : 'center',
                                                },
                                            },
                                            filter: [this.state.data.consigneeIdUUID ? GF.LabelSelector('driver', 'menu') : GF.LabelSelector('driverNotSelected', 'messages'), ''],
                                            dElm: {
                                                1: (
                                                    <div className="p-col-12" style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ height: '80px', width: '80px' }}
                                                            src={
                                                                !this.state.data.consigneePhoto
                                                                    ? window.location.origin + '/assets/images/person.png'
                                                                    : Config.PHOTO_URL + this.state.data.consigneePhoto
                                                            }
                                                            alt=""
                                                        />
                                                        <p>{this.state.data.consigneeFullname ?? ''}</p>
                                                    </div>
                                                ),
                                            },
                                        },

                                        /* Consignee selector consignator */
                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            dTypeFs: { _: 'dElm', 0: 'text' },
                                            style: { 0: { textAlign: 'center', fontSize: '1.25em' } },
                                            c: [this.state.data.consigneeIdUUID],
                                            nshow: this.state.data.contractStatus < 424 || this.state.data.contractStatus > 425 || !GF.g_checkAlc(5, 'freight#bid'),
                                            filter: [GF.LabelSelector('selectDriver', 'messages'), ''],
                                            dElm: {
                                                1: (
                                                    <GSelectorAdvanced
                                                        addon={c => (
                                                            <div className="p-col-12" style={{ display: 'grid', placeItems: 'center' }}>
                                                                <img
                                                                    onClick={c.toggleDialog}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        height: '80px',
                                                                        width: '80px',
                                                                    }}
                                                                    src={
                                                                        !this.state.data.consigneePhoto
                                                                            ? window.location.origin + '/assets/images/person.png'
                                                                            : Config.PHOTO_URL + this.state.data.consigneePhoto
                                                                    }
                                                                    alt=""
                                                                />
                                                                {GF.g_template_button(
                                                                    this.state.data.consigneeIdUUID ? GF.LabelSelector('change', 'btt') : GF.LabelSelector('select', 'btt'),
                                                                    '',
                                                                    false,
                                                                    c.toggleDialog
                                                                )}
                                                                {this.state.data.consigneeName ?? ''}
                                                            </div>
                                                        )}
                                                        ref={e => (this.selector10 = e)}
                                                        tableBody={[
                                                            {
                                                                type: 'd',
                                                                data: 'fullname',
                                                                sortable: true,
                                                                filter: true,
                                                            },
                                                            { type: 'd', data: 'email', sortable: true, filter: true },
                                                            {
                                                                type: 'd',
                                                                data: 'phoneMobile',
                                                                sortable: true,
                                                                filter: true,
                                                            },
                                                            {
                                                                type: 'date',
                                                                data: 'dateCreation',
                                                                sortable: true,
                                                                filter: true,
                                                            },
                                                            {
                                                                type: 'btt',
                                                                icon: 'pi pi-plus',
                                                                click: (raw, ev) => {
                                                                    if (ev?.preventDefault) ev.preventDefault();
                                                                    this.setState(
                                                                        {
                                                                            data: {
                                                                                ...this.state.data,
                                                                                consigneeIdUUID: raw.idUUID,
                                                                                consigneePhoto: raw.photo,
                                                                            },
                                                                        },
                                                                        async () => {
                                                                            await this.handleSubmit(ev);
                                                                            await S.contract.status({
                                                                                idUUID: this.state.data.idUUID,
                                                                                contractStatus: 425,
                                                                            });
                                                                            this.getData();
                                                                            this.selector10.setState({
                                                                                visible: false,
                                                                            });
                                                                        }
                                                                    );
                                                                },
                                                            },
                                                        ]}
                                                        getData={S.consignee.processed.list}
                                                    />
                                                ),
                                            },
                                        },

                                        /* Cargo adresses */

                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            req: true,
                                            c: {
                                                0: [0, this.state.data.contractStatus < 422],
                                                _: this.state.data.contractStatus >= 422 && !this.state.boxes[0],
                                            },
                                            dTypeFs: { _: 'text', 1: 'process' },
                                            cc: [[11, 1]],
                                            filter: [
                                                [GF.LabelSelector('addresses', 'titles'), this.state.boxes[0] ? '\u25B2' : '\u25BC'],
                                                {
                                                    t: 'array',
                                                    dTypeFs: { _: 'process' },
                                                    divCc: 'p-col p-grid',
                                                    cc: [6, 6],
                                                    filter: [
                                                        {
                                                            t: 'array',
                                                            dTypeFs: ['text', 'dElm'],
                                                            divStyle: { borderRight: '1px solid lightgrey', paddingRight: '5px' },
                                                            filter: [GF.LabelSelector('origin_address'), 'dElm'],
                                                            style: [{ fontWeight: 'bold' }],
                                                            dElm: [
                                                                null,
                                                                <AdvancedAddress
                                                                    small
                                                                    ref={e => (this.o = e)}
                                                                    context={this}
                                                                    mode={mode}
                                                                    c={{ 0: true }}
                                                                    req={{ _: true, 4: false, 3: [true, false], 2: [true, true, false] }}
                                                                />,
                                                            ],
                                                        },
                                                        {
                                                            t: 'array',
                                                            dTypeFs: ['text', 'dElm'],
                                                            filter: [GF.LabelSelector('destination_address'), 'dElm'],
                                                            style: [{ fontWeight: 'bold' }],
                                                            dElm: [
                                                                null,
                                                                <AdvancedAddress
                                                                    small
                                                                    ref={e => (this.d = e)}
                                                                    context={this}
                                                                    mode={mode}
                                                                    c={{ 0: true }}
                                                                    req={{ _: true, 4: false, 3: [true, false], 2: [true, true, false] }}
                                                                />,
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                            nshow: this.canShowOnCancel(401),
                                            style: {
                                                0: [
                                                    { fontWeight: 'bold', fontSize: '1.5em' },
                                                    { textAlign: 'center', fontSize: '1.5em', cursor: 'pointer' },
                                                ],
                                            },
                                            onClick: [
                                                [
                                                    _ => {},
                                                    _ =>
                                                        this.setState({
                                                            boxes: { ...this.state.boxes, 0: !this.state.boxes[0] },
                                                        }),
                                                ],
                                            ],
                                        },

                                        /* Cargo */

                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            dTypeFs: { _: 't', 0: 'text', 1: 'drop', 2: 'numeric' },
                                            handleExtraData: { _: { data: 'forms' } },
                                            origin: this.state.forms,
                                            filter: [[GF.LabelSelector('feigthInfo', 'titles'), this.state.boxes[1] ? '\u25B2' : '\u25BC'], ['cargo_type_id', 'product_segment_id'], 'cargo_weight'],
                                            nshow: this.canShowOnCancel(402),
                                            req: true,
                                            cc: { 2: 1, 1: [3, 3], 0: [11, 1] },
                                            class: { 1: [2, 1] },
                                            options: { 1: [this.state.cargoTypes, this.state.products] },
                                            c: {
                                                0: [0, this.state.data.contractStatus < 422],
                                                _: this.state.data.contractStatus >= 422 && !this.state.boxes[1],
                                            },
                                            style: {
                                                0: [
                                                    { fontWeight: 'bold', fontSize: '1.5em' },
                                                    { textAlign: 'center', fontSize: '1.5em', cursor: 'pointer' },
                                                ],
                                            },
                                            enableFilter: true,
                                            onClick: [
                                                [
                                                    _ => {},
                                                    _ =>
                                                        this.setState({
                                                            boxes: { ...this.state.boxes, 1: !this.state.boxes[1] },
                                                        }),
                                                ],
                                            ],
                                        },

                                        /* Other Services */

                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            dTypeFs: { _: 'switch', 0: 'text' },
                                            handleExtraData: { _: { data: 'forms' } },
                                            origin: this.state.forms,
                                            disabled: { 5: true, 6: true, 7: true, 8: true },
                                            filter: [
                                                [GF.LabelSelector('otherServices', 'titles'), this.state.boxes[2] ? '\u25B2' : '\u25BC'],
                                                'service_track_simple',
                                                'service_take_photo',
                                                'service_take_signature',
                                                'cargo_shared_allow',
                                                'service_track_physical',
                                                'service_load',
                                                'service_unload',
                                                'service_packging',
                                            ],
                                            nshow: this.canShowOnCancel(403),
                                            cc: [[11, 1]],
                                            c: {
                                                0: [0, this.state.data.contractStatus < 422],
                                                _: this.state.data.contractStatus >= 422 && !this.state.boxes[2],
                                            },
                                            style: {
                                                0: [
                                                    { fontWeight: 'bold', fontSize: '1.5em' },
                                                    { textAlign: 'center', fontSize: '1.5em', cursor: 'pointer' },
                                                ],
                                            },
                                            onClick: [
                                                [
                                                    _ => {},
                                                    _ =>
                                                        this.setState({
                                                            boxes: { ...this.state.boxes, 2: !this.state.boxes[2] },
                                                        }),
                                                ],
                                            ],
                                        },

                                        /* Dates */

                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            cc: { 0: [11, 1], _: 3 },
                                            origin: this.state.forms,
                                            showTime: { 2: true, 3: true },
                                            timeOnly: { 2: true },
                                            dTypeFs: { _: 'date', 0: 'text' },
                                            handleExtraData: {
                                                _: { data: 'forms' },
                                                1: { data: 'forms', multiple: 1 },
                                            },
                                            filter: [
                                                [GF.LabelSelector('dates', 'titles'), this.state.boxes[3] ? '\u25B2' : '\u25BC'],
                                                'date_checkout',
                                                ['hour_checkout_start', 'hour_checkout_end'],
                                                'date_delivery_limit',
                                            ],
                                            nshow: this.canShowOnCancel(404),
                                            selectionMode: { 1: 'range' },
                                            c: {
                                                0: [0, this.state.data.contractStatus < 422],
                                                _: this.state.data.contractStatus >= 422 && !this.state.boxes[3],
                                            },
                                            style: {
                                                0: [
                                                    { fontWeight: 'bold', fontSize: '1.5em' },
                                                    { textAlign: 'center', fontSize: '1.5em', cursor: 'pointer' },
                                                ],
                                            },
                                            onClick: [
                                                [
                                                    _ => {},
                                                    _ =>
                                                        this.setState({
                                                            boxes: { ...this.state.boxes, 3: !this.state.boxes[3] },
                                                        }),
                                                ],
                                            ],
                                        },

                                        /* Waranty */

                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            dTypeFs: { _: 't', 0: 'text', 1: 'switch', 4: 'switch' },
                                            handleExtraData: { _: { data: 'forms' } },
                                            origin: this.state.forms,
                                            c: {
                                                0: [0, this.state.data.contractStatus < 422],
                                                _: this.state.data.contractStatus >= 422 && !this.state.boxes[4],
                                                1: this.state.forms.service_cargo_insurance === '1' || (this.state.data.contractStatus >= 422 && !this.state.boxes[4]),
                                                2: this.state.forms.cargo_insured_by_owner !== '1' || (this.state.data.contractStatus >= 422 && !this.state.boxes[4]),
                                                3: this.state.forms.cargo_insured_by_owner !== '1' || (this.state.data.contractStatus >= 422 && !this.state.boxes[4]),
                                                4: this.state.forms.cargo_insured_by_owner === '1' || (this.state.data.contractStatus >= 422 && !this.state.boxes[4]),
                                                5:
                                                    (this.state.forms.cargo_insured_by_owner !== '1' && this.state.forms.service_cargo_insurance !== '1') ||
                                                    (this.state.data.contractStatus >= 422 && !this.state.boxes[4]),
                                            },

                                            filter: [
                                                [GF.LabelSelector('waranty', 'titles'), this.state.boxes[4] ? '\u25B2' : '\u25BC'],
                                                'cargo_insured_by_owner',
                                                'cargo_insurance_company',
                                                'cargo_insurance_document_number',
                                                'service_cargo_insurance',
                                                'cargo_insurace_value',
                                            ],
                                            nshow: this.canShowOnCancel(405),
                                            cc: { _: 2, 0: [11, 1], 2: 3, 5: 1 },
                                            style: {
                                                0: [
                                                    { fontWeight: 'bold', fontSize: '1.5em' },
                                                    { textAlign: 'center', fontSize: '1.5em', cursor: 'pointer' },
                                                ],
                                            },
                                            onClick: [[_ => {}, _ => this.setState({ boxes: { ...this.state.boxes, 4: !this.state.boxes[4] } }, () => console.log('teste'))]],
                                        },

                                        /* Payment */

                                        {
                                            t: 'array',
                                            divStyle: this.divStyle,
                                            dTypeFs: { 0: 'text', _: 'process' },
                                            cc: [
                                                [11, 1],
                                                [9, 3],
                                            ],
                                            nshow: this.canShowOnCancel(406),
                                            c: {
                                                0: [0, this.state.data.contractStatus < 422],
                                                _: this.state.data.contractStatus >= 422 && !this.state.boxes[5],
                                            },
                                            style: [
                                                [
                                                    { fontWeight: 'bold', fontSize: '1.5em' },
                                                    { textAlign: 'center', fontSize: '1.5em', cursor: 'pointer' },
                                                ],
                                            ],
                                            onClick: [
                                                [
                                                    _ => {},
                                                    _ =>
                                                        this.setState({
                                                            boxes: { ...this.state.boxes, 5: !this.state.boxes[5] },
                                                        }),
                                                ],
                                            ],
                                            filter: [
                                                [GF.LabelSelector('paymentMethods', 'titles'), this.state.boxes[5] ? '\u25B2' : '\u25BC'],

                                                [
                                                    {
                                                        t: 'array',
                                                        l: {
                                                            1: this.state.forms.quotation_type_id === '1' ? GF.LabelSelector('cargo_price') : GF.LabelSelector('cargo_priceMax'),
                                                        },
                                                        dTypeFs: { _: 't', 0: 'drop', 3: 'drop', 1: 'numeric' },
                                                        req: { 0: true, 3: true },
                                                        cc: { _: 2, 0: 4, 3: 4 },
                                                        handleExtraData: {
                                                            _: { data: 'forms' },
                                                            2: {
                                                                0: {
                                                                    callback: () => this.update('cargo_payment_flow_adjudication'),
                                                                    regex: /100|\d{0,2}/g,
                                                                    data: 'forms',
                                                                },
                                                                1: {
                                                                    callback: () => this.update('cargo_payment_flow_load'),
                                                                    regex: /100|\d{0,2}/g,
                                                                    data: 'forms',
                                                                },
                                                                2: {
                                                                    callback: () => this.update('cargo_payment_flow_delivery'),
                                                                    regex: /100|\d{0,2}/g,
                                                                    data: 'forms',
                                                                },
                                                            },
                                                        },
                                                        tooltip: { 2: { _: '%' } },
                                                        filter: [
                                                            'quotation_type_id',
                                                            'cargo_price',
                                                            ['cargo_payment_flow_adjudication', 'cargo_payment_flow_load', 'cargo_payment_flow_delivery'],
                                                            'cargo_payment_mode_id',
                                                            'voucher_id_uuid',
                                                        ],
                                                        origin: this.state.forms,
                                                        options: { 0: this.state.quotationTypes, 3: this.state.modes },
                                                        class: { 2: [2, 1, 1] },
                                                        req: { _: true, 4: false },
                                                    },

                                                    {
                                                        t: 'array',
                                                        dTypeFs: { _: 'view' },
                                                        dataMap: {
                                                            2: a => {
                                                                a = Math.floor(Number(a));
                                                                a = isNaN(a) ? 0 : a;
                                                                let hours = Math.floor(a / 60);
                                                                let days = Math.floor(hours / 24);
                                                                if (hours > 0) a = a - hours * 60;
                                                                if (days > 0) hours = hours - days * 24;
                                                                a = a !== 0 ? `${a} ${GF.LabelSelector('minutes', 'text')}` : '';
                                                                hours = hours !== 0 ? `${hours} ${GF.LabelSelector('hours', 'text')} ` : '';
                                                                days = days !== 0 ? `${days} ${GF.LabelSelector('days', 'text')} ` : '';
                                                                return days + hours + a;
                                                            },
                                                        },
                                                        class: 5,
                                                        filter: ['cargo_calculated_distance', 'cargo_calculated_price', 'cargo_calculated_time'],
                                                        origin: this.state.forms,
                                                    },
                                                ],
                                            ],
                                        },

                                        <div style={{ minHeight: '30vh' }}></div>,
                                        <div
                                            ref={a => {
                                                if (this.state.scroll === 0 || this.state.data.contractStatus >= 420 || this.state.data.contractStatus === 2) return;
                                                this.setState({ scroll: this.state.scroll - 1 });
                                                if (a) a.scrollIntoView();
                                            }}
                                        ></div>,
                                    ],
                                    () => [
                                        { t: 'b', l: GF.LabelSelector('next', 'btt'), c: !this.canShowOnCancel(419) },

                                        {
                                            t: 'b',
                                            l: GF.LabelSelector('save', 'btt'),
                                            c: this.state.data.contractStatus !== 420,
                                            click: e => {
                                                e.preventDefault();
                                                if (this.formRef.reportValidity()) this.handleSubmit(e, false);
                                            },
                                        },

                                        {
                                            t: 'dil',
                                            bttclass: 'p-button-success',
                                            l: GF.LabelSelector('confirmPublication', 'btt'),
                                            c: this.state.data.contractStatus !== 420,
                                            text: GF.LabelSelector('askConfirmCustomer', 'messages'),
                                            onConfirm: e => (this.formRef.reportValidity() ? this.handleSubmit(e) : ''),
                                        },

                                        {
                                            t: 'dil',
                                            bttclass: 'p-button-danger',
                                            disabled: false,
                                            l: GF.LabelSelector('cancel', 'btt'),
                                            text: GF.LabelSelector('askCancelP', 'messages'),
                                            onConfirm: this.cancelStatus,
                                            c:
                                                !(
                                                    (this.state.data.contractStatus <= 422 && !GF.g_checkAlc(5, 'freight#bid')) ||
                                                    (this.state.data.contractStatus > 422 && GF.g_checkAlc(5, 'freight#bid')) ||
                                                    GF.g_checkAlc(5, 'freight#aprove')
                                                ) || !this.canShowOnCancel(),
                                        },

                                        {
                                            t: 'dil',
                                            bttclass: 'p-button-success',
                                            l: GF.LabelSelector('confirm', 'btt'),
                                            c: this.state.data.contractStatus !== 421 || !GF.g_checkAlc(5, 'freight#aprove'),
                                            disabled: this.state.mode,
                                            text: GF.LabelSelector('askConfirmICargo', 'messages'),
                                            onConfirm: this.approveStatus,
                                        },

                                        {
                                            t: 'b',
                                            l: GF.LabelSelector('bid', 'btt'),
                                            c: this.state.data.contractStatus !== 422 || !GF.g_checkAlc(5, 'freight#bid') || GF.g_consignators() === '' || GF.g_consignators().split(',').length !== 1,
                                            disabled: false,
                                            click: e => {
                                                e.preventDefault();
                                                this.open();
                                            },
                                        },

                                        {
                                            t: 'b',
                                            extra: 'p-button-danger',
                                            l: GF.LabelSelector('negate', 'btt'),
                                            c: this.state.data.contractStatus !== 421 || !GF.g_checkAlc(5, 'freight#aprove'),
                                            disabled: this.state.mode,
                                            click: e => {
                                                e.preventDefault();
                                                this.setState({ dialog_state_negateStatus: true });
                                            },
                                        },
                                    ],
                                    () => [[], [<Steps style={{ width: '100%' }} model={this.getSteps()} activeIndex={this.getCStep()} className="steps-custom" readOnly={true} />]],
                                    this.handleSubmit,
                                    2,
                                    false,
                                    this.state.mode || this.state.data.contractStatus >= 421,
                                    12,
                                    0,
                                    '68vh',
                                    e => (this.formRef = e)
                                ),

                                //BID List
                                ...bidTable(this),

                                //Log List
                                <Log idUUID={this.state.idUUID} logContextType={4} />,
                            ],
                            null
                        )}
                        <form onSubmit={this.negateStatus}>
                            <Dialog
                                visible={this.state['dialog_state_negateStatus']}
                                onHide={() => {
                                    let toSet = {};
                                    toSet['dialog_state_negateStatus'] = false;
                                    this.setState(toSet);
                                }}
                                footer={footer(this)}
                                style={{ width: '50vw', overflowX: 'none' }}
                                header={ls('confirm', 'titles')}
                            >
                                <div className="p-grid p-fluid" style={{ overflowX: 'none', width: '40vw' }}>
                                    <div className="p-col-12">{GF.LabelSelector('askNegateCargo', 'messages')}</div>
                                    {GF.g_template_inputArea(
                                        this,
                                        'negationMessage',
                                        this.state.negationMessage,
                                        'Motivio',
                                        0,
                                        0,
                                        1,
                                        'p-col-12',
                                        'p-col-12',
                                        '',
                                        { width: '48vw' },
                                        { data: 'negationMessage' }
                                    )}
                                </div>
                            </Dialog>
                        </form>
                        <Dialog
                            visible={this.state.dialog_state_addrrErorr}
                            onHide={() => {
                                this.setState({ dialog_state_addrrErorr: false });
                            }}
                            footer={
                                <div>
                                    {GF.g_template_button(
                                        lb('close'),
                                        '',
                                        0,
                                        e => {
                                            e.preventDefault();
                                            this.setState({
                                                dialog_state_addrrErorr: false,
                                            });
                                        },
                                        0,
                                        '',
                                        'p-button-danger'
                                    )}
                                </div>
                            }
                            style={{ width: '50vw', overflowX: 'none' }}
                            header={ls('confirm', 'titles')}
                        >
                            {GF.LabelSelector('addrrError', 'messages')}
                        </Dialog>
                    </div>
                );
            })
        );
    }
}

let t = n => GF.LabelSelector(n, 'tooltip');
let stepf = { color: 'blue', class: 'pi-step-forward', tooltip: t('creation') };
export const states = {
    2: { color: 'red', class: 'pi-times', tooltip: t('canceled') },
    400: stepf,
    401: stepf,
    402: stepf,
    403: stepf,
    404: stepf,
    405: stepf,
    406: stepf,
    407: stepf,
    420: { color: 'orange', class: 'pi-step-forward', tooltip: t('confirmPublication') },
    421: { color: 'orange', class: 'pi-spinner pi-spin', tooltip: t('aproveByICargo') },
    422: { color: 'green', class: 'pi-spinner pi-spin', tooltip: t('bid') },
    423: { color: 'green', class: 'pi-thumbs-up', tooltip: t('approvedForContract') },
    424: { color: 'green', class: 'pi-calendar', tooltip: t('scheduledService') },
    425: { color: 'green', class: 'pi-user', tooltip: t('gotDriver') },
    426: { color: 'green', class: 'pi-play', tooltip: t('inExecution') },
    427: { color: 'green', class: 'pi-check', tooltip: t('ended') },
    450: stepf,
    451: { color: 'orange', class: 'pi-spinner pi-spin', tooltip: t('bidByTrasnporter') },
    452: { color: 'green', class: 'pi-spinner pi-spin', tooltip: t('answerdByProvider') },
    453: { color: 'green', class: 'pi-thumbs-up', tooltip: t('acceptedByProvider') },
    454: { color: 'red', class: 'pi-thumbs-down', tooltip: t('rejectedByProvider') },
};
