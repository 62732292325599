import { Messages } from 'primereact/messages';
import React from 'react';
import { ls, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable, g_entityFilter } from '../GenericFunctions';
import { states } from './Voucher';
import VoucherService from '../../service/VoucherService';

export default class VoucherManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignator-management#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                data.filters = f[0];
                return VoucherService.list(data);
            },
            'vouchers'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{ls('vouchers', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/voucher?type=1', 'newBatchService', this.getData, 1000, 450, e.c === 1),
                    [
                        { type: 'd', data: 'number', sortable: true, filter: true },
                        { type: 'd', data: 'checkDigitVaucher', sortable: true, filter: true },
                        { type: 'd', data: 'controlNumber', sortable: true, filter: true },
                        { type: 'd', data: 'batchLoadNumber', sortable: true, filter: true },
                        { type: 'd', data: 'voucherValue', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'batchStatus', states: () => states },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/voucher?id=${raw.idUUID}&type=1`, width: 1000, height: 450, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
