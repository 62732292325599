import { Dialog } from 'primereact/dialog';
import React from 'react';
import ConsignatorService from '../../service/ConsignatorService';
import ConsigneeService from '../../service/ConsigneeService';
import Services from '../../service/Services';
import { consignatorManagement } from '../consignator/ConsignatorManagement';
import {
    lt,
    ls,
    g_ApiGroup,
    g_checkAlc,
    g_checkLoginACL,
    g_checkMode,
    g_displayMessageError,
    g_genericRequired,
    g_getData,
    g_getHeaderTemplatecF,
    g_hideSearch,
    g_openWindowDialog,
    g_pair,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dataTable,
    g_template_dialog,
    g_template_form,
    ListPage,
} from '../GenericFunctions';
import Log from '../logs/Log';
import { groups } from './lists';

export class Group extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiGroup(), items: [{ label: lt('group') }], idUUID: null, mode: true };
    }
    onCancel = e => Services.group.processed.status(this, this.state.data.idUUID, this.state.data.groupStatus === 1 ? 2 : 1, this.getData, ls('simpleStatus', 'messages'), this.messages, e);

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  Services.group.list,
                  response => {
                      let rdata = response.groups[0];
                      rdata.groupType = String(rdata.groupType);
                      this.setState({ data: rdata });
                      this.selector1.setState({ selected: rdata.referenceOwnerName });
                      this.selector2.setState({ selected: rdata.parentGroupName });
                  },
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }] }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id'),
            t = query.get('t');
        let items = [{ label: ls('group', 'tab') }];
        if (idUUID) {
            items.push({ label: ls('groupconsignee', 'tab') });
            items.push({ label: ls('log', 'tab'), icon: 'pi pi-list' });
        }
        Services.group.processed.types(this, gs => this.setState({ groupTypes: gs.filter(a => (t ? a.value === t : true)) }));
        this.setState({ mode: query.get('type') !== '1', idUUID: idUUID, items: items, data: { ...this.state.data, groupType: t ? t : 0 }, t: t }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (!this.state.idUUID) {
            const response = await Services.group.add({ group: data });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/group?type=1&id=${response.group.idUUID}`;
            window.location.reload();
            return;
        }
        const response = await Services.group.update({ group: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: ls('simpleSave', 'messages') });
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                {
                                    t: 'array',
                                    options: { 1: this.state.groupTypes },
                                    req: { _: true, 2: false },
                                    cc: { _: 5, 4: 12 },
                                    filter: ['referenceOwnerName', 'groupType', 'parenGroupName', 'name', 'description'],
                                    dTypeF: 't',
                                    dTypeFs: { 0: 'selector', 2: 'selector', 1: 'drop', 4: 'area' },
                                    ref: [ev => (this.selector1 = ev), 0, ev => (this.selector2 = ev)],
                                    disabled: { 2: mode | !this.state.data.referenceOwnerIdUUID | !this.state.data.groupType, 1: this.state.t },
                                    getData: [
                                        c => ConsignatorService.processed.list(c, { pageSize: 5 }),
                                        0,
                                        c =>
                                            Services.group.processed.list(c, {
                                                pageSize: 5,
                                                filters: [
                                                    ...c.state.filter,
                                                    { key: 'groupStatus', value: '1' },
                                                    ...g_pair('notiduuid', this.state.idUUID, this.state.idUUID ?? false),
                                                    g_pair('groupType', this.state.data.groupType),
                                                    g_pair('referenceOwnerIdUUID', this.state.data.referenceOwnerIdUUID),
                                                ],
                                            }),
                                    ],
                                    tableBody: [
                                        [
                                            { type: 'd', data: 'name', sortable: true, filter: true },
                                            {
                                                type: 'button',
                                                icon: 'pi pi-plus',
                                                click: (raw, e) => {
                                                    e.preventDefault();
                                                    this.selector1.setState({ selected: raw.name, visible: false });
                                                    this.setState({ data: { ...this.state.data, referenceOwnerIdUUID: raw.idUUID } });
                                                },
                                            },
                                        ],
                                        0,
                                        [
                                            { type: 'd', data: 'name', sortable: true, filter: true },
                                            {
                                                type: 'button',
                                                icon: 'pi pi-plus',
                                                click: (raw, e) => {
                                                    e.preventDefault();
                                                    if (this.state.idUUID === raw.idUUID) return;
                                                    this.selector2.setState({ selected: raw.name, visible: false });
                                                    this.setState({ data: { ...this.state.data, parentGroupIdUUID: raw.idUUID } });
                                                },
                                            },
                                        ],
                                    ],
                                    startV: [this.state.data.referenceOwnerName, 0, this.state.data.parentGroupName],
                                    gobtt: [
                                        g_checkAlc(4, 'consignator') && this.state.data.referenceOwnerIdUUID ? true : false,
                                        0,
                                        g_checkAlc(4, 'group') && this.state.data.parentGroupIdUUID ? true : false,
                                    ],
                                    jump: [
                                        () =>
                                            g_openWindowDialog(
                                                consignatorManagement.url(this.state.data.referenceOwnerIdUUID),
                                                this.state.data.referenceOwnerIdUUID,
                                                consignatorManagement.size[0],
                                                consignatorManagement.size[1]
                                            ),
                                        0,
                                        () => g_openWindowDialog(groups.url(this.state.data.parentGroupIdUUID), this.state.data.parentGroupIdUUID, groups.size[0], groups.size[1]),
                                    ],
                                },
                            ],
                            mode => [
                                g_template_button(ls(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.groupStatus === 2, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    ls(this.state.data.groupStatus === 1 ? 'simpleDeactivate' : 'simpleActivate', 'messages'),
                                    'cancel ',
                                    this.onCancel,
                                    ls(this.state.data.groupStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                    !this.state.data.idUUID,
                                    this.state.mode,
                                    '',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                            ],
                            () => [[{ t: 'array', filter: ['groupStatus', 'dateCreation', 'dateUpdate'], dTypeF: 'view', dTypeFs: { 0: 'state' }, class: 'l', states: { 0: groupsStates } }]],
                            this.submit,
                            2,
                            true,
                            this.state.mode || this.state.data.groupStatus === 2,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <Members context={() => this} />,
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            ))
        );
    }
}

export const groupsStates = { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' } };

class Members extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.context().state.idUUID !== null) this.getData();
    }
    getData = () => (this.props.context().state.idUUID ? g_getData(this, data => Services.group.element(this.props.context().state.idUUID, data), 'groupElements') : null);
    remove = async raw => {
        if (!this.props.context().state.idUUID) return;
        let response = await Services.consignee.group.remove(raw.referenceIdUUID, this.props.context().state.idUUID);
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.context().messages.show({ severity: 'success', summary: ls('simpleRemove', 'messages') });
        this.getData();
    };
    render() {
        return (
            <div className="card">
                <Dialog header={ls('addNewOne', 'titles')} onHide={() => this.setState({ showNewOne: false })} visible={this.state.showNewOne}>
                    <div style={{ minWidth: '50vw' }}>{addNew(this.props.context().state.idUUID, this.getData)}</div>
                </Dialog>
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ showNewOne: !this.state.showNewOne }), this.getData),
                    [
                        { type: 'd', data: 'referenceName', filter: true },
                        { type: 'dil', text: ls('askRemove', 'messages'), icon: 'pi pi-trash', buttonClassName: 'p-button-danger', id: raw => raw.idUUID, onConfirm: this.remove },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}

const addNew = (idUUID, callBackData) =>
    idUUID && callBackData ? (
        <ListPage
            title={''}
            header={context => g_getHeaderTemplatecF(context, null, context.getData, false)}
            table={(context, e) => [
                { type: 'd', data: 'fullname', filter: true },
                {
                    type: 'button',
                    icon: 'pi pi-plus',
                    click: async e => {
                        const response = await Services.consignee.group.add(e.idUUID, idUUID);
                        if (g_displayMessageError(context.messages, response)) return;
                        context.messages.show({ severity: 'success', summary: ls('simpleAdd', 'messages') });
                        callBackData();
                        context.getData(context);
                    },
                },
            ]}
            getData={context => ConsigneeService.processed.list(context, { pageSize: 5, filters: [...context.state.filter, { key: 'notingroup', value: idUUID }] })}
        />
    ) : null;
