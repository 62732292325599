import React, { Component } from 'react';
import { S, pair, StateIcon, g_getData, g_genericRequired, g_checkLoginACL, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class ClockingManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired([pair('dateRegister', 'desc')]);
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () => (this.props.context.state.idUUID ? g_getData(this, S.consignator.clocking.list, 'clockings') : null);

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(this, `#/clocking?type=1&cid=${this.props.context.state.idUUID}`, 'Create New Clocking', this.getData, 1000, 450, e.c === 1),
                        [
                            {
                                type: 'di',
                                data: 'clockingType',
                                di: raw => (
                                    <div>
                                        <StateIcon state={raw.clockingActionType} custom={{ 1: { class: 'pi-arrow-right', color: 'grey' }, 2: { class: 'pi-arrow-left', color: 'grey' } }} />{' '}
                                        <StateIcon state={raw.clockingType} custom={{ 1: { class: 'pi-home', color: 'grey' }, 2: { class: 'pi-pause', color: 'grey' } }} />{' '}
                                    </div>
                                ),
                            },
                            { type: 'date', data: 'dateManualRecording' },
                            { type: 'date', data: 'dateSystem' },
                            { type: 'date', data: 'dateCreation' },
                            {
                                type: 'state',
                                data: 'biometricRecognitionStatus',
                                states: {
                                    0: { class: 'pi-minus', color: 'red' },
                                    1: { class: 'pi-user', color: 'green' },
                                    2: { class: 'pi-volume-up', color: 'green' },
                                    3: { class: '', color: 'green', text: '\u261D' },
                                    101: { class: 'pi-user', color: 'red' },
                                    102: { class: 'pi-volume-off', color: 'red' },
                                    103: { class: '', color: 'red', text: '\u261D' },
                                },
                            },
                            {
                                type: 'state',
                                data: 'clockingStatus',
                                states: {
                                    1: { class: 'pi-check', color: 'green' },
                                    2: { class: 'pi-step-forward', color: 'orange' },
                                    3: { class: 'pi-ban', color: 'red' },
                                },
                            },
                            { type: 'url', c: e.d === 1 || e.u === 1, url: raw => `#/clocking?type=1&id=${raw.idUUID}`, width: 1000, height: 450, callback: this.getData },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'consignee#presence'
        );
    }
}
