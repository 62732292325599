import React from 'react';
import Services from '../../service/Services';
import { g_getHeaderTemplate, ListPage, LabelSelector } from '../GenericFunctions';
import { groupsStates } from './Group';

const uG = (id, t) => `#/group?type=1${id ? `&id=${id}` : ''}${t ? `&t=${t}` : ''}`;
const sG = [1200, 600];
export const groups = {
    render: t => (
        <ListPage
            title={LabelSelector('groups' + t, 'titles') === 'LabelInfenranceFailed' ? LabelSelector('groups', 'titles') : LabelSelector('groups' + t, 'titles')}
            header={(c, e) => g_getHeaderTemplate(c, uG(null, t), 'new groups', c.getData, sG[0], sG[1], e.c)}
            table={(c, e) => [
                { type: 'd', data: 'referenceOwnerName', filter: true, c: c.state.showMore },
                { type: 'd', data: 'name', sortable: true, filter: true },
                { type: 'd', data: 'parenGroupName', filter: true, c: c.state.showMore },
                { type: 'd', data: 'groupType', sortable: true, filter: true },
                { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                { type: 'state', data: 'groupStatus', states: groupsStates },
                { type: 'url', c: e.d === 1 || e.u === 1, url: r => uG(r.idUUID, t), width: sG[0], height: sG[1], callback: c.getData },
            ]}
            getData={c => Services.group.processed.list(c, { filters: [...c.state.filter, ...(t ? [{ key: 'groupType', value: t }] : [])] })}
            update={c => (c.state.old === t ? null : c.setState({ old: t }, c.getData))}
            stateE={{ old: t }}
        />
    ),
    url: uG,
    size: sG,
};
