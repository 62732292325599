import React, { Component } from 'react';
import { Messages } from 'primereact/messages';
import { LS, g_getData, g_displayMessageError, g_checkLoginACL, g_getHeaderTemplatecF, g_template_dataTable, g_hideSearch, g_genericRequired } from '../GenericFunctions';
import CampaignProductsList from '../campaign/CampainProductsList';
import ProductService from '../../service/ProductService';
import CampaignService from '../../service/CampaignService';

export default class CampaignProductsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getProducts = c => g_getData(c, ProductService.getProducts, 'products');

    getData = () => (!this.props.context.state.idUUID ? null : g_getData(this, CampaignService.getCampaignItemList, 'campaignItens', { campaignIdUUID: this.props.context.state.idUUID }));

    addProductAssotiation = async (context, pId) => {
        console.log([this.props.context.state.idUUID, pId.idUUID]);
        const item = { campaignItem: { campaignIdUUID: this.props.context.state.idUUID, productIdUUID: pId.idUUID } };
        var response = await CampaignService.addCampaignItem(item);
        console.log([response, item]);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
        context.props.getData(context);
    };

    deleteClick = async data => {
        let response = await CampaignService.removeCampaignItem({}, data.campaignItemIdUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(
            e => (
                <div class="p-col">
                    <Messages ref={el => (this.messages = el)} />
                    <CampaignProductsList close={this.getData} add={this.addProductAssotiation} getData={this.getProducts} ref={e => (this.productsSelector = e)} />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(
                            this,
                            () => {
                                this.productsSelector.setState({ visible: true });
                                this.productsSelector.props.getData(this.productsSelector);
                            },
                            this.getData,
                            e.c === 1
                        ),
                        [
                            { type: 'd', data: 'name', sortable: true, filter: true },
                            { type: 'd', data: 'shortDescription', sortable: true, filter: true },
                            { type: 'd', data: 'productTypeDescription', sortable: true, filter: true },
                            { type: 'btt', extra: 'p-button-secondary', click: this.deleteClick, icon: 'pi pi-trash', tooltip: LS('delete', 'tooltip') },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'campaignProduct#products'
        );
    }
}
