import React, { Component } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { Route } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import Login from './components/login/Login';
import RecoverPassword from './components/recoverPassword/RecoverPassword';
import SidebarMenu from './AppSidebarMenu';
import { AppProfile } from './AppProfile';
import config from './config/config';

import UserManagement from './components/admin/backUsers/UserManagement';
import User from './components/admin/backUsers/User';
import LUser from './components/admin/backUsers/LUser';

import UserManagementApp from './components/appUsers/UserManagementApp';
import UserApp from './components/appUsers/UserApp';

import UserManagementServ from './components/serviceUsers/UserManagementServ';
import UserServ from './components/serviceUsers/UserServ';

import { consigneeManagement } from './components/consignee/lists';
import Consignee from './components/consignee/Consignee';
import Subscribe from './components/consignee/Subscribe';
import PayrollDetail from './components/consignee/PayrollDetail';
import Contact from './components/consignee/Contact';

import { consignatorManagement } from './components/consignator/ConsignatorManagement';
import Consignator from './components/consignator/Consignator';
import BatchMoney from './components/consignator/BatchMoney';
import ClockingLocation from './components/consignator/ClockingLocation';

import ChangePassword from './components/changePassword/ChangePassword';

import { productManagement } from './components/product/ProductManagement';
import { Stock, Product } from './components/product/Product';

import ProductsPackage from './components/productpackages/ProductsPackage';
import ProductsPackageManagement from './components/productpackages/ProductsPackageManagement';

import NotificationManagement from './components/notification/NotificationManagement';
import Notification from './components/notification/Notification';

import { ContractManagement, ServicesManagement, RequestsManagement, contractGroupByTemplate, contractGroupByProductType } from './components/contract/ContractManagement';
import Contract from './components/contract/Contract';

import { providerList } from './components/provider/lists';
import { Provider, AssociationServiceContractTemplate } from './components/provider/Provider';
import { CalendarEvent } from './components/provider/CalendarEvent';
import { BusinessHours } from './components/provider/BusinessHours';
import ProviderCreateUser from './components/provider/ProviderCreateUser';

import FinancierManagement from './components/financier/FinancierManagement';
import Financier from './components/financier/Financier';

import CampaignManagement from './components/campaign/CampaignManagement';
import Campaign from './components/campaign/Campaign';
import CopyCampaign from './components/campaign/CopyCampaign';

import ContractTemplateManagement from './components/contractTemplate/ContractTemplateManagement';
import ContractTemplate from './components/contractTemplate/ContractTemplate';
import CopyTemplate from './components/contractTemplate/CopyTemplate';
import { Margin } from './components/contractTemplate/margin/Margin';
import { Rating } from './components/contractTemplate/Rating';

import ContractTemplateFieldManagement from './components/contractTemplate/ContractTemplateFieldManagement';
import ContractTemplateField from './components/contractTemplate/ContractTemplateField';

import CampaignProductManagement from './components/campaignProduct/CampaignProductManagement';
import CampaignProduct from './components/campaignProduct/CampaignProduct';

import ProductTypeManagement from './components/productTypeManagement/productTypeManagement';

import ConfigContextManagement from './components/configContexts/ConfigContextManagement';
import ConfigContext from './components/configContexts/ConfigContext';

import ProfileManagement from './components/profile/ProfileManagement';
import Profile from './components/profile/Profile';
import ControlItemManagement from './components/profile/ControlItemManagement';
import ControlItem from './components/profile/ControlItem';
import CopyProfile from './components/profile/CopyProfile';

import ServiceBatchManagement from './components/serviceBatch/ServiceBatchManagement';
import ServiceBatch from './components/serviceBatch/ServiceBatch';

import { MultilanguageManagement, HTMLPreview } from './components/multilanguage/MultilanguageManagement';
import Multilanguage from './components/multilanguage/Multilanguage';

import ZipCodeManagement from './components/zipCode/ZipCodeManagement';
import ZipCode from './components/zipCode/ZipCode';

import CacheManagement from './components/cache/CacheManagement';
import CacheEdit from './components/cache/CacheEdit';

import { g_checkAlc, g_getDashbords, g_getReports } from './components/GenericFunctions';
import FinancierDashboard from './components/dashboards/financierDashboard';
import ConsignatorDashboard from './components/dashboards/consignatorDashboard';

import UserCreateConsignator from './components/consignator/UserCreateConsignator';
import UserCreateFinancier from './components/financier/UserCreateFinancier';

import Reportsetl from './components/reports/Reportsetl';

import StagingConsigneeManagement from './components/staging/consignee/StagingConsigneeManagement';
import StagingConsignee from './components/staging/consignee/StagingConsignee';

import Clocking from './components/consignee/Clocking';

import VoucherManagement from './components/voucher/VoucherManagement';
import Voucher from './components/voucher/Voucher';

import PromotionsActive from './components/promotions/promotions-active';
import PromotionsPast from './components/promotions/promotions-past';
import Promotion from './components/promotions/promotion';

import { SearchAreaActive, SearchAreaServiceCategories, SearchAreaServiceTypes } from './components/searchSegment/search-active';
import SearchAreaPast from './components/searchSegment/search-past';
import { SearchArea, SearchCategory, SearchService } from './components/searchSegment/search';
import { segment } from './components/segment/lists';
import { News } from './components/segment/segment';

import { groups } from './components/groups/lists';
import { Group } from './components/groups/Group';

import { mapPage } from './components/generic/Map';

//ICargo
import { freightList } from './components/freight/lists';
import Freight from './components/freight/freight';
import Bid from './components/freight/bid';

import { S, pair } from './components/GenericFunctions';

const ComponentTagMath = { 'dashboard-financier': FinancierDashboard, 'dashboard-consignator': ConsignatorDashboard, 'report-etl': Reportsetl };

const SockJS = window.SockJS;
const Stomp = window.Stomp;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            noMenu: false,
            userLogged: localStorage.getItem('loggedUserN') ? true : false,
        };
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.isMenuOpen = this.isMenuOpen.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.requestCounter = 0;
    }

    componentWillMount() {
        //Disable menu on type === 1
        const url = window.location.href.substr(window.location.href.indexOf('?'));
        const query = new URLSearchParams(url);
        const type = query.get('type');
        if (type === '1') this.setState({ noMenu: true });

        //Added loadindicator
        axios.interceptors.request.use(
            config => {
                const isBackground = config.headers['background-request'];
                if (isBackground === undefined) document.body.classList.add('loading-indicator');
                this.requestCounter = this.requestCounter + 1;
                return config;
            },
            error => Promise.reject(error)
        );

        //remove loadindicator
        axios.interceptors.response.use(
            response => {
                this.requestCounter = this.requestCounter - 1;
                if (this.requestCounter === 0) document.body.classList.remove('loading-indicator');
                return response;
            },
            error => Promise.reject(error)
        );

        //  S.config.language.list({ filter: [pair('tag', 'SITE_NUMO_PT_JSON')] }).then(r => {
        //        console.log(r);
        //   });

        this.checkWs();
    }

    onWrapperClick() {
        if (!this.menuClick) this.setState({ overlayMenuActive: false, mobileMenuActive: false });
        this.menuClick = false;
    }

    onSidebarClick() {
        this.menuClick = true;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    handlerLogin = data => this.setState({ userLogged: data }, () => this.checkWs());

    onToggleMenu(event) {
        this.menuClick = true;
        console.log(this.state.layoutMode);
        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') this.setState({ overlayMenuActive: !this.state.overlayMenuActive });
            else if (this.state.layoutMode === 'static') this.setState({ staticMenuInactive: !this.state.staticMenuInactive });
        } else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({ mobileMenuActive: !mobileMenuActive });
        }
        if (event) event.preventDefault();
    }

    isMenuOpen() {
        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                return this.state.overlayMenuActive;
            } else if (this.state.layoutMode === 'static') {
                return !this.state.staticMenuInactive;
            }
        } else {
            return this.state.mobileMenuActive;
        }
    }

    addClass(element, className) {
        if (element.classList) element.classList.add(className);
        else element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    componentDidUpdate() {
        if (JSON.parse(localStorage.getItem('loggedUserN')) !== null) document.body.style.background = '#edf0f5';
        if (this.state.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
        else this.removeClass(document.body, 'body-overflow-hidden');
        this.checkWs();
    }

    socket = null;
    sstomp = null;
    lasttopics = [];
    counter = 0;
    timeout = false;
    checkWs = timeout => {
        if (this.state.noMenu || window.opener !== null) return;
        this.counter++;
        let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN === null) {
            this.lasttopics = [];
            if (this.socket) this.socket.close();
            this.socket = null;
            this.sstomp = null;
            window.stompC = null;
            window.stompConnect = false;
            return;
        }
        let topics = [];
        if (g_checkAlc(4, 'life-button-active'))
            topics.push({
                topic: '/topic/livebuttonrequests',
                url: '/#/life-button-active',
                topname: 'Botao vida',
            });
        if (g_checkAlc(4, 'ambulance-active'))
            topics.push({
                topic: '/topic/ambulancerequests',
                url: '/#/ambulance-active',
                topname: 'Ambulacia',
            });
        if (g_checkAlc(4, 'calls')) topics.push({ topic: 'calls', donot: true, url: null, topname: 'calls' });
        topics = topics.filter(topic => this.lasttopics.indexOf(topic.topic) === -1);
        if (topics.length === 0 && this.socket !== null) return;
        if (this.socket === null) {
            this.socket = new SockJS(config.SOCKET);
            this.socket.addEventListener('close', e => {
                this.socket = null;
                this.sstomp = null;
                window.stompC = null;
                window.stompConnect = false;
                this.lasttopics = [];
                if (e.code === 1003) {
                    localStorage.removeItem('loggedUserN');
                    window.location.href = '/#/login';
                    window.location.reload();
                    return;
                }
                setTimeout(this.checkWs(), 5000);
                return;
            });
            setTimeout(this.checkWs(), 5000);
            return;
        }
        try {
            window.Notification.requestPermission().then();
        } catch (e) {
            return;
        }
        if (!this.socket) {
            console.log(this.socket);
            this.socket = null;
            return;
        }
        window.stompC = Stomp.over(this.socket);
        window.stompC.debug = null;
        let yyy = this.lasttopics;
        let sstomp = this.sstomp;

        let createNoti = (message, topic) => {
            let canRecive = localStorage.getItem('canRecive');
            let oncall = localStorage.getItem('oncall');
            if (canRecive === 'false' || oncall === 'true') return;
            let noti = new window.Notification(topic.topname, {
                body: JSON.parse(message.body).description,
            });
            noti.onclick = () => {
                window.location.href = topic.url;
                window.location.reload();
            };
        };

        let rtimeout = false;
        if (sstomp) {
            topics.forEach(topic => {
                if (!topic.donot) window.stompC.subscribe(topic.topic, message => createNoti(message, topic));
                yyy.push(topic.topic);
            });
        } else {
            window.stompC.connect({ token: loggedUserN.token }, function () {
                console.log('connected websocket ');
                sstomp = this;
                rtimeout = true;
                window.stompConnect = true;
                topics.forEach(topic => {
                    if (!topic.donot) window.stompC.subscribe(topic.topic, message => createNoti(message, topic));
                    yyy.push(topic.topic);
                });
            });
        }
        if (rtimeout) this.timeout = false;
        this.lasttopics = yyy;
        window.topicsConected = yyy;
        this.sstomp = sstomp;
        if (!this.sstomp && timeout && !this.timeout)
            setTimeout(() => {
                this.timeout = true;
                if (this.counter > 5) {
                    if (this.socket) this.socket.close();
                    this.socket = null;
                    console.log('socket clear');
                    setTimeout(() => this.checkWs(true), 1000);
                    this.counter = 0;
                }
                this.checkWs(true);
            }, 1000);
    };

    getRoutes() {
        let dashbords = [];
        let a = 0;
        g_getDashbords().forEach((v, i) => {
            if (i === 0) dashbords.push(<Route key={a} path="/" exact component={ComponentTagMath[v] ? ComponentTagMath[v] : Dashboard} />);
            else dashbords.push(<Route key={a} path={'/' + v} exact component={ComponentTagMath[v] ? ComponentTagMath[v] : Dashboard} />);
            a++;
        });
        if (dashbords.length === 0) dashbords.push(<Route key="0" path="/" exact component={Dashboard} />);
        let reports = [];
        g_getReports().forEach(v => {
            reports.push(<Route key={a} path={'/' + v} exact component={ComponentTagMath[v] ? ComponentTagMath[v] : Dashboard} />);
            a++;
        });
        return (
            <>
                {dashbords}
                {reports}

                <Route path="/login" exact render={p => <Login location={p.location} handlerLogin={this.handlerLogin} />} />
                <Route path="/recover-password" exact component={RecoverPassword} />

                <Route path="/admin-user-management" component={UserManagement} />
                <Route path="/user" component={User} />
                <Route path="/LUser" component={LUser} />

                <Route path="/admin-user-management-app" component={UserManagementApp} />
                <Route path="/user-app" component={UserApp} />

                <Route path="/admin-user-management-serv" component={UserManagementServ} />
                <Route path="/user-serv" component={UserServ} />

                <Route path="/consignee-management" render={consigneeManagement.render} />
                <Route path="/consignee" component={Consignee} />
                <Route path="/contact" component={Contact} />
                <Route path="/subscribe" component={Subscribe} />
                <Route path="/payroll" component={PayrollDetail} />
                <Route path="/clocking" component={Clocking} />

                <Route path="/consignator-management" render={() => consignatorManagement.render} />
                <Route path="/consignator" component={Consignator} />
                <Route path="/clockinglocation" component={ClockingLocation} />
                <Route path="/payrollbudget" component={BatchMoney} />
                <Route path="/newuserfac" component={UserCreateConsignator} />

                <Route path="/change-password" exact component={ChangePassword} />

                <Route path="/product-management" render={e => productManagement.render(new URLSearchParams(e.location.search).get('mode'))} />
                <Route path="/product" component={Product} />
                <Route path="/stock" component={Stock} />

                <Route path="/notification-management" component={NotificationManagement} />
                <Route path="/notification" component={Notification} />

                <Route path="/contract-management" component={ContractManagement} />
                <Route path="/services-management" component={ServicesManagement} />
                <Route path="/requests-management" component={RequestsManagement} />
                <Route path="/contract-managemnet-groupby-template" render={contractGroupByTemplate} />
                <Route path="/contract-managemnet-groupby-product-type" render={contractGroupByProductType} />
                <Route path="/contract" component={Contract} />

                <Route path="/provider-management" render={providerList.render} />
                <Route path="/provider" component={Provider} />
                <Route path="/provider-service-contract" render={e => <AssociationServiceContractTemplate id={new URLSearchParams(e.location.search).get('id')} />} />
                <Route path="/calendar-event" component={CalendarEvent} />
                <Route path="/business-hours" component={BusinessHours} />
                <Route path="/newuserfasp" component={ProviderCreateUser} />

                <Route path="/financier-management" component={FinancierManagement} />
                <Route path="/financier" component={Financier} />
                <Route path="/newuserfaf" component={UserCreateFinancier} />

                <Route path="/campaign-management" component={CampaignManagement} />
                <Route path="/campaign" component={Campaign} />
                <Route path="/copyCampaign" component={CopyCampaign} />

                <Route path="/contractTemplate-management" component={ContractTemplateManagement} />
                <Route path="/contractTemplate" component={ContractTemplate} />
                <Route path="/copyContractTemplate" component={CopyTemplate} />
                <Route path="/margin-control" component={Margin} />
                <Route path="/ratting" component={Rating} />

                <Route path="/campaignProduct-management" component={CampaignProductManagement} />
                <Route path="/campaignProduct" component={CampaignProduct} />

                <Route path="/packageProduct-management" component={ProductsPackageManagement} />
                <Route path="/packageProduct" component={ProductsPackage} />

                <Route path="/product-type-management" component={ProductTypeManagement} />
                <Route path="/zipcode-management" component={ZipCodeManagement} />
                <Route path="/zipcode" component={ZipCode} />

                <Route path="/config-contex-management" component={ConfigContextManagement} />
                <Route path="/config-contex" component={ConfigContext} />

                <Route path="/contractTemplateField-management" component={ContractTemplateFieldManagement} />
                <Route path="/contractTemplateField" component={ContractTemplateField} />

                <Route path="/profile-management" component={ProfileManagement} />
                <Route path="/profile" component={Profile} />
                <Route path="/copyProfile" component={CopyProfile} />
                <Route path="/control-item-management" component={ControlItemManagement} />
                <Route path="/control-item" component={ControlItem} />

                <Route path="/service-batch-management" component={ServiceBatchManagement} />
                <Route path="/service-batch" component={ServiceBatch} />

                <Route path="/multilanguage-management" component={MultilanguageManagement} />
                <Route path="/multilanguage-management-preview" component={HTMLPreview} />
                <Route path="/multilanguage" component={Multilanguage} />

                <Route path="/cache-management" component={CacheManagement} />
                <Route path="/cache" component={CacheEdit} />

                <Route path="/cache-management" component={CacheManagement} />
                <Route path="/cache" component={CacheEdit} />

                <Route path="/staging-consignee-management" component={StagingConsigneeManagement} />
                <Route path="/staging-consignee" component={StagingConsignee} />

                <Route path="/voucher-management" component={VoucherManagement} />
                <Route path="/voucher" component={Voucher} />

                {/*CMS*/}

                <Route path="/searcharea-active" component={SearchAreaActive} />
                <Route path="/searcharea-past" component={SearchAreaPast} />
                <Route path="/searcharea" component={SearchArea} />
                <Route path="/searcharea-services" render={() => SearchAreaServiceTypes} />
                <Route path="/searcharea-service" component={SearchService} />
                <Route path="/searcharea-categories" render={() => SearchAreaServiceCategories} />
                <Route path="/searchAreaCategory" component={SearchCategory} />

                <Route path="/promotions-active" component={PromotionsActive} />
                <Route path="/promotions-past" component={PromotionsPast} />
                <Route path="/promotion" component={Promotion} />

                <Route path="/segments" render={e => segment.render(new URLSearchParams(e.location.search))} />
                <Route path="/segment" component={News} />

                <Route path="/groups" render={e => groups.render(new URLSearchParams(e.location.search).get('t'))} />
                <Route path="/group" component={Group} />

                <Route path="/map-page" render={e => mapPage(e)} />

                {/*ICargo*/}
                <Route path="/freight-management" render={e => freightList(new URLSearchParams(e.location.search).get('t'))} />
                <Route path="/freight" component={Freight} />
                <Route path="/bid" component={Bid} />
            </>
        );
    }

    render() {
        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive,
        });

        const sidebarClassName = classNames('layout-sidebar', {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light',
        });

        if (!this.state.userLogged)
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                    {' '}
                    {this.getRoutes()}{' '}
                </div>
            );
        else if (this.state.noMenu)
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                    {' '}
                    <div className={wrapperClass} onClick={this.onWrapperClick}>
                        {' '}
                        {this.getRoutes()}{' '}
                    </div>{' '}
                </div>
            );
        else {
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                    <AppTopbar handlerLogin={this.handlerLogin} onToggleMenu={this.onToggleMenu} />
                    <div ref={el => (this.sidebar = el)} className={sidebarClassName} onClick={this.onSidebarClick}>
                        <div className="layout-logo">{/* <img alt="Logo" src={logo} /> */}</div>
                        <AppProfile handlerLogin={this.handlerLogin} />
                        <Route component={SidebarMenu} />
                    </div>
                    <div className="layout-main"> {this.getRoutes()} </div>
                </div>
            );
        }
    }
}

export default App;
