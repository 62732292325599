import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import React from 'react';
import ConsigneeService from '../../service/ConsigneeService';
import {
    LS,
    pair,
    StateIcon,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_handleChange,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_dropdown,
    g_template_inputArea,
    g_wraper,
} from '../GenericFunctions';

export default class Clocking extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                dateManualRecording: new Date(),
                clockingType: '',
                clockingActionType: '',
                biometricRecognitionStatus: 0,
                clockingStatus: 0,
                motiveInfo: '',
                clockingMotiveType: '',
            },
            idUUID: '',
            cid: '',
            items: [{ label: 'Ponto' }],
        };
    }

    /*
     updateStatusClick = async (e) => {
        e.preventDefault()
        const input = {
            idUUID: this.state.data.idUUID,
            companyStatus: 1,
        }
        let response = await ConsigneeService.clockingUpdateStatus(input)
        if (g_displayMessageerror(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: 'Estado alterado com sucesso!' });
        this.getData();
    }
    */

    blockStatus = async e => {
        e.preventDefault();
        const input = {
            idUUID: this.state.data.idUUID,
            clockingStatus: 3,
        };
        let response = await ConsigneeService.clockingUpdateStatus(input);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let cid = query.get('cid');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, cid: cid, mode: mode }, this.getData);
    }

    async getData() {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [pair('idUUID', this.state.idUUID)],
        };
        let response = await ConsigneeService.clockingList(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (response.clockings[0]) {
            g_nullarrayresponse(this.messages);
            return;
        }
        let rdata = response.clockings[0];
        rdata.clockingActionType = rdata.clockingActionType ? String(rdata.clockingActionType) : '';
        rdata.clockingType = rdata.clockingActionType ? String(rdata.clockingType) : '';
        rdata.clockingMotiveType = rdata.clockingMotiveType ? String(rdata.clockingMotiveType) : '';
        rdata.dateManualRecording = rdata.dateManualRecording ? new Date(rdata.dateManualRecording) : '';
        this.setState({ data: rdata });
    }

    submitForm = async event => {
        event.preventDefault();
        let data = this.state.data;
        if (data.dateManualRecording) {
            let d = new Date(data.dateManualRecording);
            data.dateManualRecording = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T${String(d.getHours()).padStart(2, '0')}:${String(
                d.getMinutes()
            ).padStart(2, '0')}:${String(d.getSeconds()).padStart(2, '0')}.000Z`;
        }
        if (this.state.idUUID) {
            let response = ConsigneeService.clockingUpdate({ clocking: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.getData();
            return;
        }
        if (!this.state.cid) return;
        data.consigneeIdUUID = this.state.cid;
        let response = ConsigneeService.clockingAdd({ clocking: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({ idUUID: response.clocking.idUUID }, this.getData);
    };

    render() {
        return g_checkLoginACL(this.grender);
    }
    grender = e => {
        if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
        return (
            <div className="card card-w-title">
                {g_tabViewRenderOptions(this, [
                    <form className="p-col" onSubmit={this.submitForm}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                <Panel style={{ border: 'none !important' }}>
                                    {g_wraper('clockingType', LS('clockingType'), [
                                        g_template_dropdown(
                                            this,
                                            'clockingActionType',
                                            this.state.data.clockingActionType,
                                            '',
                                            [
                                                { label: LS('0', 'clockingActionType'), value: '0' },
                                                { label: LS('1', 'clockingActionType'), value: '1' },
                                            ],
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-2'
                                        ),
                                        g_template_dropdown(
                                            this,
                                            'clockingType',
                                            this.state.data.clockingType,
                                            '',
                                            [
                                                { label: LS('1', 'clockingType'), value: '1' },
                                                { label: LS('2', 'clockingType'), value: '2' },
                                            ],
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-2'
                                        ),
                                    ])}
                                    {g_wraper(
                                        'dateManualRecording',
                                        LS('dateManualRecording'),
                                        <div className="p-col-3">
                                            <Calendar
                                                id="dateManualRecording"
                                                onChange={e => g_handleChange(e, {}, this)}
                                                value={new Date(this.state.data.dateManualRecording)}
                                                dateFormat="dd-mm-yy"
                                                hourFormat="24"
                                                showTime={true}
                                            />
                                        </div>
                                    )}
                                    {g_template_dropdown(
                                        this,
                                        'clockingMotiveType',
                                        this.state.data.clockingMotiveType,
                                        LS('clockingMotiveType'),
                                        [{ label: LS('other', 'generic'), value: 9999 }],
                                        this.state.mode,
                                        false,
                                        false,
                                        'p-col-2'
                                    )}
                                    {g_template_inputArea(this, 'motiveInfo', this.state.data.motiveInfo, LS('motiveInfo'), this.state.mode, false, false, 'p-col-6', 'p-col-3', '', {
                                        minHeight: '5em',
                                    })}
                                </Panel>
                            </div>
                            <div className="p-col-12 p-md-3 p-lg-3">
                                <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                    {g_wraper(
                                        null,
                                        LS('state'),
                                        <div className="p-col">
                                            <StateIcon
                                                state={this.state.data.clockingStatus}
                                                custom={{
                                                    1: { class: 'pi-check', color: 'green' },
                                                    2: { class: 'pi-step-forward', color: 'orange' },
                                                    3: { class: 'pi-ban', color: 'red' },
                                                }}
                                                small
                                            />
                                        </div>,
                                        !this.state.idUUID,
                                        'p-col-7'
                                    )}
                                    {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                    {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    {g_wraper(
                                        null,
                                        LS('biometricRecognitionStatus'),
                                        <div className="p-col">
                                            <StateIcon
                                                state={this.state.data.biometricRecognitionStatus}
                                                small
                                                custom={{
                                                    0: { class: 'pi-minus', color: 'red' },
                                                    1: { class: 'pi-user', color: 'green' },
                                                    2: { class: 'pi-volume-up', color: 'green' },
                                                    3: { class: '', color: 'green', text: '\u261D' },
                                                    101: { class: 'pi-user', color: 'red' },
                                                    102: { class: 'pi-volume-off', color: 'red' },
                                                    103: { class: '', color: 'red', text: '\u261D' },
                                                }}
                                            />
                                        </div>,
                                        !this.state.idUUID,
                                        'p-col-7'
                                    )}
                                </Panel>
                            </div>
                        </div>

                        <div className="p-grid p-fluid">
                            {g_template_button(this.state.idUUID ? 'Salvar' : 'Criar', '', this.state.data.clockingStatus === 3, null, this.state.mode, 'p-col-2')}
                            {g_template_dialog(
                                this,
                                LS('askBlockP'),
                                'block',
                                this.blockStatus,
                                LS('block', 'btt'),
                                !this.state.idUUID,
                                this.state.data.clockingStatus === 3,
                                'p-button-danger',
                                '',
                                '',
                                '',
                                'p-col-2',
                                'p-button-danger'
                            )}
                        </div>
                    </form>,
                ])}
            </div>
        );
    };
}
