import { LabelSelector } from '../GenericFunctions';

export function g_ApiStagingConsigneeResponse() {
    return {
        dateCreation: '',
        dateLastLogin: '',
        dateLegalAcceptance: '',
        dateMobileEnrollment: '',
        dateUpdate: '',
        email: '',
        fullname: '',
        idUUID: '',
        legalAcceptanceStatus: 0,
        mobileEnrollmentStatus: 0,
        passwordRetries: 0,
        phoneMobile: '',
        photo: '',
        profile: 0,
        consigneeStatus: 0,
        consigneeType: 0,
        username: '',
        vatNumber: '',
        dateBirth: '',
        civilStatus: 0,
        consignatorIdUUID: '',
        dependentNumber: 0,
        employeeId: '',
        entityType: 0,
        fatherName: '',
        gender: 0,
        hablitation: 0,
        motherName: '',
        phoneCommercial: '',
        phoneHome: '',
        registerId: '',
        totalMargin: 0,
        usedMargin: 0,
        loyaltyPointsBalance: 0,
        employeeGovId: '',
    };
}

export function g_ApiUserResponse(userType) {
    return {
        appVersion: '',
        applicationId: '',
        associatedEntities: [],
        dateCreation: '',
        dateLastLogin: '',
        dateLastPasswordChange: '',
        dateLastUserLock: '',
        dateLegalAcceptance: '',
        dateMobileEnrollment: '',
        dateUpdate: '',
        deviceBrand: '',
        deviceId: '',
        deviceModel: '',
        deviceType: '',
        email: '',
        fullname: '',
        idUUID: '',
        languageId: '',
        legalAcceptanceStatus: 0,
        mobileEnrollmentStatus: 0,
        passwordRetries: 0,
        phoneMobile: '',
        photo: '',
        profileACLs: [],
        userStatus: 0,
        userType: userType,
        username: '',
    };
}

export function g_ApiConsignee() {
    return {
        dateCreation: '',
        dateLastLogin: '',
        dateLegalAcceptance: '',
        dateMobileEnrollment: '',
        dateUpdate: '',
        email: '',
        fullname: '',
        idUUID: '',
        legalAcceptanceStatus: 0,
        mobileEnrollmentStatus: 0,
        passwordRetries: 0,
        phoneMobile: '',
        photo: '',
        profile: 0,
        consigneeStatus: 0,
        consigneeType: 0,
        username: '',
        vatNumber: '',
        dateBirth: '',
        civilStatus: 0,
        consignatorIdUUID: '',
        dependentNumber: 0,
        employeeId: '',
        entityType: 0,
        fatherName: '',
        gender: 0,
        hablitation: 0,
        motherName: '',
        phoneCommercial: '',
        phoneHome: '',
        registerId: '',
        totalMargin: 0,
        usedMargin: 0,
        loyaltyPointsBalance: 0,
        employeeGovId: '',
    };
}

export function g_ApiPayroll() {
    return {
        baseFGTS: 0,
        baseIR: 0,
        consigneeIdUUID: '',
        dateCreation: '',
        dateUpdate: '',
        details: [],
        employeeGovId: '',
        extraHours: 0,
        fGTS: 0,
        iR: 0,
        idUUID: '',
        jobCode: '',
        jobName: '',
        jobStartDate: '',
        marginValue: 0,
        message: '',
        month: 0,
        payrollStatus: 0,
        salaryBase: 0,
        salaryGross: 0,
        salaryNet: 0,
        socialSecurity: 0,
        totalDiscounts: 0,
        totalIncomings: 0,
        workLocationCode: '',
        workLocationName: '',
        year: 0,
    };
}

export function g_ApiConsignator() {
    return {
        consignatorPullMode: '',
        erpCode: '',
        dateCreation: '',
        dateLastLogin: '',
        dateLegalAcceptance: '',
        dateMobileEnrollment: '',
        dateUpdate: '',
        email: '',
        name: '',
        idUUID: '',
        id: '',
        entityId: '',
        entityType: 0,
        legalAcceptanceStatus: 0,
        mobileEnrollmentStatus: 0,
        passwordRetries: 0,
        phoneMobile: '',
        phoneHome: '',
        vatNumber: '',
        photo: '',
        profile: 0,
        consignatorStatus: 0,
        consignatorType: 0,
        fantasyName: '',
        shortDescription: '',
        longDescription: '',
    };
}

export function g_ApiBatchService() {
    return {
        code: '',
        consignatorIdUUID: '',
        dateCreation: '',
        dateUpdate: '',
        description: '',
        idUUID: '',
        movementLayout: '',
        payrollBudgetStatus: '',
        productIdUUID: '',
        productName: '',
    };
}

export function g_ApiProduct() {
    return {
        contractActionText: '',
        dateCreation: '',
        dateUpdate: '',
        entityProviderIdUUID: '',
        idUUID: '',
        parentIdUUID: '',
        parentName: '',
        longDescription: '',
        productType: '',
        shortDescription: '',
        maxPeriod: 0,
        maxValue: 0,
        minPeriod: 0,
        minValue: 0,
        name: '',
        periodValue: 0,
        photoBig: '',
        photoSmall: '',
        priceContextTitle: '',
        priceValue: 0,
        productMode: 1,
        quantity: 0,
        stepPeriod: 0,
        stepValue: 0,
        parameterA: '',
        parameterB: '',
        parameterC: '',
        parameterD: '',
        parameterE: '',
        parameterF: '',
        showOrder: '999',
        interfaceName: '',
    };
}

export function g_ApiNotification() {
    return {
        dateCreation: '',
        dateUpdate: '',
        description: '',
        destinationIDUUID: '',
        id: 0,
        idUUID: '',
        longDescription: '',
        notificationBroadcastType: '',
        notificationCheckMode: '',
        notificationStatus: '',
        notificationType: '',
        photoBig: '',
        photoSmall: '',
        shortDescription: '',
    };
}

export function g_ApiContract() {
    return {
        ratting: 0,
        rattings: [],
        consignatorIdUUID: '',
        consigneeIdUUID: '',
        contractInterestsPercentage: 0,
        contractStatus: 0,
        contractTemplateIdUUID: '',
        contractTemplateType: 0,
        contractTotalCapitalValue: 0,
        contractTotalInterestsValue: 0,
        contractTotalValue: 0,
        contractType: 0,
        dateContractEnd: '',
        dateContractStart: '',
        dateCreation: '',
        dateUpdate: '',
        documentDigitalized: '',
        documentTemplate: '',
        idUUID: '',
        installmentTotal: 0,
        productIdUUID: '',
        productPriceValue: 0,
        fillerField1: '',
    };
}

export function g_ApiProvider() {
    return {
        dateCreation: '',
        dateUpdate: '',
        email: '',
        name: '',
        idUUID: '',
        id: '',
        phoneMobile: '',
        phoneHome: '',
        vatNumber: '',
        photo: '',
        longDescription: '',
        providerStatus: 0,
        providerType: 0,
        fantasyName: '',
        entityId: 0,
        entityType: 0,
        shortDescription: '',
        schedulingMode: '',
        chatMode: '',
    };
}

export function g_ApiFinancier() {
    return {
        dateCreation: '',
        dateUpdate: '',
        email: '',
        name: '',
        idUUID: '',
        id: '',
        phoneMobile: '',
        phoneHome: '',
        vatNumber: '',
        photo: '',
        longDescription: '',
        financierStatus: 0,
        financierType: 0,
        fantasyName: '',
        entityId: 0,
        entityType: 0,
        shortDescription: '',
    };
}

export function g_ApiCampaign(ct = 2) {
    return {
        campaignAggregatedImage: '',
        campaignMainImage: '',
        campaignMode: 0,
        campaignModeDescription: '',
        campaignStatus: 0,
        campaignType: ct,
        consignatorIdUUID: '',
        dateCreation: '',
        dateEnd: '',
        dateStart: '',
        dateUpdate: '',
        idUUID: '',
        longDescription: '',
        name: '',
        shortDescription: '',
    };
}

export function g_ApiContractTemplate(type = 0) {
    return {
        allowToredeal: 0,
        allowsSyncCall: 0,
        billingToMode: 0,
        consignatorIdUUID: '',
        consignatorMarginCloseMode: 0,
        consignatorMarginInstallmentMode: 0,
        consignatorMarginStartMode: 0,
        consignatorName: '',
        consignatorRevenueCloseMode: 0,
        consignatorRevenueInstallmentMode: 0,
        consignatorRevenueStartMode: 0,
        contractDocumentBase: '',
        contractForms: [
            /*          {
            contractFields: [
              {
                contractTemplateFieldStatus: 0,
                contractTemplateFormIdUUID: '',
                dateCreation: '',
                dateUpdate: '',
                fieldListValues: '',
                fieldName: '',
                fieldPosition: 0,
                fieldType: 0,
                fieldValue: '',
                help: '',
                idUUID: '',
                mandatory: 0,
                title: ''
              }
            ],
            contractTemplateFormStatus: 0,
            contractTemplateFormType: 0,
            contractTemplateIdUUID: '',
            dateCreation: '',
            dateUpdate: '',
            help: '',
            idUUID: '',
            page: 0,
            title: ''
          } */
        ],
        contractSignatureMode: 0,
        contractSignatureModeDescription: '',
        contractTemplateStatus: 0,
        contractTemplateType: type,
        contractTemplateTypeDescription: '',
        dateCreation: '',
        dateUpdate: '',
        description: '',
        idUUID: '',
        iofadditional: 0,
        iofannual: 0,
        lenderTax: 0,
        liquidationEntityType: 0,
        loyaltyAngariationType: 0,
        loyaltyAngariationValue: 0,
        marginControl: 0,
        maxPeriod: 0,
        maxValue: 0,
        minPeriod: 0,
        minValue: 0,
        monthlyTax: 0,
        numoMarginCloseMode: 0,
        numoMarginInstallmentMode: 0,
        numoMarginStartMode: 0,
        numoRevenueCloseMode: 0,
        numoRevenueInstallmentMode: 0,
        numoRevenueStartMode: 0,
        periodValue: 0,
        priceValue: 0,
        productIdUUID: '',
        productInterfaceName: '',
        productName: '',
        scriptInstallmentSimulation: '',
        stepPeriod: 0,
        stepValue: 0,
        tag: '',
        templateVersion: '',
        terms: '',
        title: '',
        workflowType: 0,
    };
}

export function g_ApiVoucher() {
    return {
        additionalCode: '',
        additionalDescription: '',
        batchIdUUID: '',
        batchLoadNumber: '',
        checkDigit: '',
        controlNumber: '',
        dateCreation: '',
        dateExecution: '',
        dateUpdate: '',
        description: '',
        idUUID: '',
        number: '',
        serialNumber: '',
        voucherStatus: 0,
        voucherValue: '',
    };
}

export function g_ApiCard() {
    return {
        brand: '',
        cardStatus: 0,
        cardType: 0,
        creationUserIdUUID: '',
        dateCreation: '',
        dateExecution: '',
        dateUpdate: '',
        description: '',
        idUUID: '',
        name: '',
        panMasked: '',
        pciData: '',
        referenceDescription: '',
        referenceIdUUID: '',
    };
}

export function g_ApiAsset() {
    return {
        assetCategory: 0,
        assetStatus: 0,
        assetType: 0,
        brand: '',
        consignatorIdUUID: '',
        creationUserIdUUID: '',
        dateAsset: '',
        dateCreation: '',
        dateUpdate: '',
        description: '',
        idUUID: '',
        model: '',
        otherSpecs: '',
        plateId: '',
        referenceDescription: '',
        referenceIdUUID: '',
        serialNumber: '',
    };
}

export function g_ApiContact() {
    return {
        contact: '',
        contactRelationshipName: '',
        contactRelationshiptType: 0,
        contactStatus: 0,
        contactType: 0,
        dateCreation: '',
        dateUpdate: '',
        idUUID: '',
        principal: 0,
        refereceEntityIdUUID: '',
        relationshipEntity: {
            address: [],
            bankAccounts: [],
            civilStatus: 0,
            dateCreation: '',
            dateUpdate: '',
            email: '',
            entityIdUUID: '',
            entityType: 0,
            fantasyName: '',
            fullname: '',
            gender: 0,
            hablitation: 0,
            idUUID: '',
            phoneHome: '',
            phoneMobile: '',
            photo: '',
            registerId: '',
            registerTypeId: 0,
            vatNumber: '',
        },
    };
}

export function g_ApiPromotions() {
    return {
        consignatorIdUUID: '',
        consignatorName: '',
        creationUserIdUUID: '',
        dateCreation: '',
        dateEnd: '',
        dateStart: '',
        dateUpdate: '',
        description: '',
        featuringMode: 0,
        htmlMainContent: '',
        htmlTeaserContent: '',
        idUUID: '',
        imageUrlMain: '',
        imageUrlTeaser: '',
        price: 0,
        promotionCategory: 0,
        promotionStatus: 0,
        promotionType: 0,
        referenceDescription: '',
        referenceIdUUID: '',
        showOnApp: 0,
        showOnFB: 0,
        showOnInstagram: 0,
        showOnSite: 0,
        showOnTweeter: 0,
    };
}

export function g_ApiSearchArea() {
    return {
        consignatorIdUUID: '',
        consignatorName: '',
        creationUserIdUUID: '',
        dateCreation: '',
        dateEnd: '',
        dateStart: '',
        dateUpdate: '',
        description: '',
        featuringMode: 0,
        htmlMainContent: '',
        htmlTeaserContent: '',
        idUUID: '',
        imageUrlMain: '',
        imageUrlTeaser: '',
        price: 0,
        searchAreaCategory: 0,
        searchAreaStatus: 0,
        searchAreaType: 0,
        showOnApp: 0,
        showOrder: '',
        showOnSite: 0,
    };
}

export function g_ApiNews() {
    return {
        consignatorIdUUID: '',
        consignatorName: '',
        creationUserIdUUID: '',
        dateCreation: '',
        dateEndNews: '',
        dateStartNews: '',
        dateUpdate: '',
        description: '',
        featuringMode: 0,
        htmlMainContent: '',
        htmlTeaserContent: '',
        idUUID: '',
        imageUrlMain: '',
        imageUrlTeaser: '',
        newsCategory: 0,
        newsStatus: 0,
        newsType: 0,
        referenceDescription: '',
        referenceIdUUID: '',
        sendByEmail: 0,
        showOnApp: 0,
        showOnFB: 0,
        showOnInstagram: 0,
        showOnSite: 0,
        showOnTweeter: 0,
        showOrder: '',
        totalClicksOnApp: 0,
        totalClicksOnSite: 0,
        tag: '',
    };
}

export function g_ApiGroup() {
    return { dateCreation: '', dateUpdate: '', description: '', groupStatus: 0, groupType: 0, idUUID: '', name: '', parentGroupIdUUID: '', referenceOwnerIdUUID: '', referenceOwnerName: '' };
}

export function g_ApiContractTemplateMargin() {
    return {
        contextValue: '',
        contractTemplateIdUUID: '',
        contractTemplateMarginCategory: '',
        contractTemplateMarginContext: '',
        contractTemplateMarginType: '',
        dateCreation: '',
        dateUpdate: '',
        idUUID: '',
        marginValueControl: '',
    };
}
export function g_ApiRatting() {
    return { contractTemplateIdUUID: '', contractTemplateName: '', description: '', idUUID: '', languageTag: '', prefix: '', status: 0 };
}

export function g_ApiScheduling() {
    return {
        contractIdUUID: '',
        creationUserIdUUID: '',
        dateCreation: '',
        dateSchedulingEnd: '',
        dateSchedulingStart: '',
        dateUpdate: '',
        idUUID: '',
        providerIdUUID: '',
        schedulingMode: 1,
        schedulingStatus: 1,
        schedulingSyncStatus: 1,
        schedulingType: 1,
        description: LabelSelector('emptyCalendarEvent', 'default'),
    };
}

export function g_ApiBusinessHours() {
    return {
        businessHoursCategory: 0,
        businessHoursType: 0,
        creationUserIdUUID: '',
        dateCreation: '',
        dateUpdate: '',
        extraDescription: '',
        idUUID: '',
        periodOneEnd: '',
        periodOneStart: '',
        periodTwoEnd: '',
        periodTwoStart: '',
        providerIdUUID: '',
        providerName: '',
        weekday: 0,
        showOrder: '999',
    };
}
