import React, { Component } from 'react';
import UserService from '../../service/UserService';
import User from '../admin/backUsers/User';
import { LS, g_checkAlc, g_checkLoginACL, g_displayMessageError, g_entities } from '../GenericFunctions';

export default class UserCreateConsignator extends Component {
    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        if (!id) {
            window.close();
            return;
        }
        if (g_entities().indexOf(id) === -1 && !g_checkAlc(5, 'association-provider-user#accessall')) window.close();
        this.setState({ idUUID: id });
    }

    onSubmit = async (e, context) => {
        e.preventDefault();
        if (context.state.idUUID || !this.state.idUUID) return;
        let data = context.state.data;
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : data.phoneMobile;
        data = { user: data };
        let response = await UserService.createUser(data);
        if (g_displayMessageError(context.messages, response)) return;
        if (context.state.holderphoto !== null) await context.upload(context.state.holderphoto, 1, response.user.idUUID);
        let useriduuid = response.user.idUUID;
        let err = false;
        data = { userEntity: { entityIdUUID: this.state.idUUID, entityUserType: 3, userIdUUID: useriduuid } };
        response = await UserService.entityAdd(data);
        if (g_displayMessageError(context.messages, response)) {
            err = true;
            context.messages({ severity: 'error', summary: LS('couldNotAssociateUser', 'messages') });
        }
        if (!err) window.close();
        if (window.addProfile) {
            window.close();
            window.addProfile(useriduuid);
        }
    };

    render() {
        return g_checkLoginACL(() => {
            return <User checkACL={false} create={true} onSubmit={this.onSubmit} />;
        });
    }
}
