import React from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Panel } from 'primereact/panel';
import PayrollService from '../../service/PayrollSerive';
import { StateIcon, LS, g_checkLoginACL, g_getData, g_getDateTemplate, g_tabViewRenderOptions, g_wraper } from '../GenericFunctions';
import Log from '../logs/Log';

export default class PayrollDetatil extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                baseFGTS: 0,
                baseIR: 0,
                consigneeIdUUID: '',
                dateCreation: '',
                dateUpdate: '',
                details: [],
                employeeGovId: '',
                extraHours: 0,
                fGTS: 0,
                iR: 0,
                idUUID: '',
                jobCode: '',
                jobName: '',
                jobStartDate: '',
                marginValue: 0,
                message: '',
                month: 0,
                payrollStatus: 0,
                salaryBase: 0,
                salaryGross: 0,
                salaryNet: 0,
                socialSecurity: 0,
                totalDiscounts: 0,
                totalIncomings: 0,
                workLocationCode: '',
                workLocationName: '',
                year: 0,
            },
            items: [{ label: LS('payroll', 'tab') }, { label: LS('details', 'tab') }, { label: LS('log', 'tab') }],
        };
    }
    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        this.setState({ idUUID: id }, this.getData);
    }

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  () => PayrollService.get(this.state.idUUID),
                  response => this.setState({ data: response.payroll })
              )
            : null;

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            let dd = (text, className = 'p-col-2') => <div className={className}>{text}</div>;
            let et = text => (text ? text : '');
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.submitForm}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel style={{ border: 'none !important', overflowY: 'auto', height: '80vh' }}>
                                        {g_wraper('', LS('date'), dd(`${this.state.data.month}-${this.state.data.year}`), 0, 'p-col-2')}
                                        {g_wraper('', LS('socialSecurity'), dd(this.state.data.socialSecurity), 0, 'p-col-2')}
                                        {g_wraper('', LS('baseFGTS'), [dd(this.state.data.baseFGTS), g_wraper('', LS('FGTS'), dd(this.state.data.fGTS), 0, 'p-col-2', 1)], 0, 'p-col-2')}
                                        {g_wraper('', LS('baseIR'), [dd(this.state.data.baseIR), g_wraper('', LS('iR'), dd(this.state.data.iR), 0, 'p-col-2', 1)], 0, 'p-col-2')}
                                        {g_wraper(
                                            '',
                                            LS('extraHours'),
                                            [dd(this.state.data.extraHours), g_wraper('', LS('marginValue'), dd(this.state.data.marginValue), 0, 'p-col-2', 1)],
                                            0,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            '',
                                            LS('salaryBase'),
                                            [
                                                dd(this.state.data.salaryBase),
                                                g_wraper('', LS('salaryNet'), dd(this.state.data.salaryNet), 0, 'p-col-2', true),
                                                g_wraper('', LS('salaryGross'), dd(this.state.data.salaryGross), 0, 'p-col-2', 1),
                                            ],
                                            0,
                                            'p-col-2'
                                        )}
                                        <h1> </h1>
                                        <h4>{LS('job', 'title')}</h4>
                                        {g_wraper(
                                            '',
                                            LS('jobName'),
                                            [
                                                dd(`${et(this.state.data.jobCode)}-${et(this.state.data.jobName)}`),
                                                g_wraper('', LS('jobStartDate'), g_getDateTemplate(this.state.data.jobStartDate), 0, 'p-col-2', 1),
                                            ],
                                            0,
                                            'p-col-2'
                                        )}
                                        {g_wraper('', LS('workLocationName'), dd(`${et(this.state.data.workLocationCode)}-${et(this.state.data.workLocationName)}`), 0, 'p-col-2')}
                                        <h1> </h1>
                                        <h4>{LS('message')}</h4>
                                        {g_wraper('', '', dd(this.state.data.message), 0, 'p-col-2')}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_wraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon
                                                    small
                                                    state={this.state.data.payrollStatus}
                                                    custom={{
                                                        0: { class: 'pi-spin pi-spinner', color: 'grey' },
                                                        2: { class: 'pi-cross', color: 'red' },
                                                        3: { class: 'pi-circle-on', color: 'red' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                        </form>,
                        <div className="p-col">
                            <DataTable
                                autoLayout={true}
                                header={
                                    <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                        <Button
                                            icon="pi pi-refresh"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.getData();
                                            }}
                                            style={{ float: 'right', marginLeft: '3px' }}
                                        />
                                    </div>
                                }
                                responsive={true}
                                value={this.state.data.details}
                            >
                                <Column header={LS('code')} field="code" />
                                <Column header={LS('description')} field="description" />
                                <Column header={LS('discounts')} field="discounts" />
                                <Column header={LS('incomigs')} field="incoming" />
                            </DataTable>
                        </div>,
                        <Log idUUID={this.state.idUUID} logContextType={2} />,
                    ])}
                </div>
            );
        });
    }
}
