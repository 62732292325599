import {
    S,
    ls,
    lm,
    lb,
    g_getDataFields,
    g_ApiNews,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_form,
    g_treatDate,
    g_wraper,
    g_openWindowDialog,
    g_convertResponse,
    g_template_inputArea,
} from '../GenericFunctions';
import { Editor } from 'primereact/editor';
import React from 'react';
import Log from '../logs/Log';
import GSelector from '../generic/GSelector';
import * as config from '../../config/config';
import Images from './Images';
import ProviderService from '../../service/ProviderService';
import NewsService from '../../service/NewsService';

export class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: g_ApiNews(),
            items: [{ label: 'Segment' }],
            idUUID: null,
            mode: true,
            newsTypes: [],
            featuringModes: [
                { label: 'Normal', value: '1' },
                { label: 'No Topo', value: '2' },
                { label: 'Em Primeiro', value: '3' },
                { label: 'Pop Up', value: '4' },
            ],
            newsCategories: [],
        };
    }

    getModel = () => [{ label: 'A Criar' }, { label: 'Pendente Aporovação' }, { label: 'Pendente Pagamento' }, { label: 'Aprovado' }, { label: 'Ativo' }, { label: 'Fim' }];

    getProviders = context => g_getData(context, ProviderService.getProviders, 'providers');
    onDelete = e => NewsService.updateStatus(this, this.state.data.idUUID, 3, this.getData, lm('simpleUpdate'), this.messages, e);
    onEnd = e => NewsService.updateStatus(this, this.state.data.idUUID, 4, this.getData, lm('simpleUpdate'), this.messages, e);
    onCancel = e => NewsService.updateStatus(this, this.state.data.idUUID, this.state.data.newsStatus === 1 ? 2 : 1, this.getData, lm('simpleUpdate'), this.messages, e);
    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  NewsService.list,
                  response => {
                      let rdata = g_convertResponse(['newsType', 'newsCategory', 'featuringMode'], response.news[0]);
                      this.setState({ data: rdata });
                  },
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }] }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        let st = query.get('st') ?? -1;
        let items = [{ label: ls('segment' + ls(st, 'segmentType'), 'tab') }];
        if (idUUID) {
            items.push({ label: ls('textEditor', 'tab') });
            items.push({ label: ls('images', 'tab') });
            items.push({ label: ls('statistics', 'tab') });
            items.push({ label: ls('log', 'tab'), icon: 'pi pi-list' });
        }
        g_getDataFields(this, NewsService.types, 'newsTypes', 'description', 'id', d => this.setState({ newsTypes: d.filter(a => (st === -1 ? true : st === a.value || idUUID)) }));
        g_getDataFields(this, NewsService.categories, 'newsCategories', 'description', 'id', d => this.setState({ newsCategories: d }));
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }
    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.dateStartNews = g_treatDate(data.dateStartNews);
        data.dateEndNews = g_treatDate(data.dateEndNews);
        if (!this.state.idUUID) {
            const response = await NewsService.add({ news: data });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/segment?type=1&id=${response.news.idUUID}`;
            window.location.reload();
            return;
        }
        const response = await NewsService.update({ news: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
    };

    getConsignators = S.consignator.processed.list;

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            let pS = this.state.data.newsStatus;
            let cancel = [0, 1, 2].indexOf(this.state.data.newsStatus) === -1;
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                <GSelector
                                    labelwraper="p-col-2"
                                    label={ls('gestorialEntity')}
                                    ref={e => (this.selector1 = e)}
                                    getData={this.getConsignators}
                                    gobtt={g_checkAlc(4, 'consignator') && this.state.data.consignatorIdUUID}
                                    jump={() => g_openWindowDialog(`#/consignator?type=1&id=${this.state.data.consignatorIdUUID}`, this.state.data.consignatorIdUUID, 900, 600)}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector1.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    disabled={mode}
                                    startV={this.state.data?.consignatorName}
                                />,

                                {
                                    t: 'array',
                                    dTypeFs: { _: 't', 2: 'date', 3: 'date', 4: ['drop', 't'], 5: 'drop', 6: 'drop', 8: 'drop' },
                                    filter: ['title', 'description', 'dateStartNews', 'dateEndNews', ['newsType', 'tag'], 'newsCategory', 'featuringMode', 'showOrder', 'languageId'],
                                    options: { 4: [this.state.newsTypes], 5: this.state.newsCategories, 6: this.state.featuringModes, 8: config.languages },
                                    handleExtraData: { 7: { regex: /\d*/g } },
                                    req: { _: true, 4: [true, this.state.data.newsType === '2'] },
                                    cc: { 0: 4, 4: [4, 4], 5: 4, 6: 4, 7: 2, 8: 2 },
                                },
                            ],
                            mode => [
                                g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', [0, 1].indexOf(pS) === -1, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    lm('askFinish'),
                                    'end',
                                    this.onEnd,
                                    lb('finish'),
                                    this.state.data.newsStatus !== 1,
                                    mode,
                                    'p-button-success',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-success'
                                ),
                                g_template_dialog(
                                    this,
                                    lm(`ask${this.state.data.newsStatus === 1 ? 'Deactivate' : 'Activate'}`),
                                    'cancel ',
                                    this.onCancel,
                                    lb(this.state.data.newsStatus === 1 ? 'deactivate' : 'activate'),
                                    cancel || !this.state.data.idUUID,
                                    mode,
                                    '',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                                g_template_dialog(
                                    this,
                                    lm('askBlockP'),
                                    'delete',
                                    this.onDelete,
                                    lb('block'),
                                    !this.state.data.idUUID || [3, 4].indexOf(this.state.data.newsStatus) !== -1,
                                    mode,
                                    'p-button-danger',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-danger'
                                ),
                            ],
                            () => [[{ t: 'array', filter: ['newsStatus', 'dateCreation', 'dateUpdate'], dTypeF: 'view', dTypeFs: { 0: 'state' }, class: 'l', states: { 0: newsStates } }]],
                            this.submit,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <DataEditor context={() => this} />,
                        <Images context={() => this} />,
                        <></>,
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            );
        });
    }
}

export class DataEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: { data: '' } };
    }
    componentDidMount() {
        this.props
            .context()
            .getData()
            .then(() => this.setState({ data: { data: this.props.context().state.data.htmlMainContent } }));
    }
    editorChange = e => this.setState({ data: { data: e.htmlValue ?? '' } });
    preview = () => {
        this.setState({ showHtml: !this.state.showHtml });
    };
    save = async () => {
        if (!this.props.context().state.idUUID) return;
        const response = await NewsService.updateContent({ contentType: 0, htmlContent: this.state.data.data, idUUID: this.props.context().state.idUUID });
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.context().messages.show({ severity: 'success', summary: lm('simpleSave') });
    };
    render() {
        return (
            <div className="p-grid p-col-12 p-fluid">
                {g_wraper(
                    `value`,
                    '',
                    <Editor
                        className="p-col-12"
                        style={{ height: '20em' }}
                        disabled={this.props.context().state.mode}
                        required={true}
                        value={this.state.data.data}
                        onTextChange={e => this.editorChange(e)}
                        id="value"
                    />,
                    false,
                    `p-col-2`
                )}
                {g_template_inputArea(this, 'data', this.state.data.data, '', this.props.context().state.mode, this.state.showHtml, true, 'p-col-12', '', '', { minHeigth: '5em' })}
                {g_template_button(lb('save'), '', false, this.save, this.props.context().state.mode, 'p-col-2')}
                {g_template_button('HTML', '', false, this.preview, this.props.context().state.mode, 'p-col-2')}
            </div>
        );
    }
}

export const newsStates = {
    0: { color: 'orange', class: 'pi-step-forward' },
    1: { color: 'green', class: 'pi-play' },
    2: { color: 'red', class: 'pi-times' },
    3: { color: 'red', class: 'pi-trash' },
    4: { color: 'green', class: 'pi-minus' },
};
