import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import React, { Component } from "react";
import ProfileService from "../../../service/ProfileService";
import { LS, g_checkLoginACL, g_displayMessageError, g_genericRequired, g_getData, g_getHeaderTemplatecF, g_pair, g_template_dataTable } from "../../GenericFunctions";
import GAddNew from "../../profile/G_AddNew";

export default class AssociationProfile extends Component {

    constructor(props) { super(props); this.state = g_genericRequired(); }
    componentDidMount() { this.getData(); }

    getData = () => this.props.idUUID ? g_getData(this, ProfileService.getProfileUserList, 'profileUsers', {filters: [{key: 'userIdUUID', value: this.props.idUUID},{key: 'userType', value: this.props.userType !== null || this.props.userType !== undefined ? this.props.userType : 1},...this.state.filter]}) : null

    remove = async raw => {
        console.log(raw);
        let response = await ProfileService.removeProfileFromUser(raw.profileIdUUID, raw.userIdUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    }

    getAddNewData = context => g_getData(context, ProfileService.list, 'profiles', {filters: [{key: 'notInUserIdUUID', value: this.props.idUUID}, g_pair('profileStatus', '1'), ...this.g_add.state.filter], userType: this.props.userType !== null || this.props.userType !== undefined ? this.props.userType : 1})
    
    addNew = async raw => {
        let data = {
            profileUser: {
                profileIdUUID: raw.idUUID,
                userIdUUID: this.props.idUUID,
                userType: this.props.userType !== null || this.props.userType !== undefined  ? this.props.userType : 1
            }
        };
        let response = await ProfileService.addProfileUser(data);
        if (g_displayMessageError(this.g_add.messages, response)) return;
        this.getAddNewData(this.g_add);
        this.g_add.messages.show({severity: 'success', summary: LS('addProfile', 'messages')});
    }

    render() { 
        return g_checkLoginACL( e => (
            <div className='card'>
              <Messages ref={e => this.messages = e}/>
              <Dialog style={{minWidth: '75vw'}}visible={this.state.visible} onHide={() => {this.setState({visible: false}, this.getData);}}>
                <GAddNew ref={e => this.g_add = e} getData={this.getAddNewData} tableBody={
                    [
			{type: 'd', data:'description', filter: true, sortable: true},
			{type: 'btt', icon: 'pi pi-plus', click: this.addNew}
                    ]
                } />
              </Dialog>
              {g_template_dataTable(this, g_getHeaderTemplatecF(this, () => {this.setState({visible: true});}, this.getData, e.c), [
                  {type: 'd', data: 'profileDescription'},
                  {type: 'url', c: e.u, url: raw => `/#/profile?type=1&id=${raw.profileIdUUID}`, width: 1300, height: 500},
		  {type: 'dil', text: LS('removeProfileFromUser', 'messages'), id: raw => raw.profileIdUUID, onConfirm: this.remove, title: LS('remove', 'btt'), buttonClassName: 'p-button-danger', icon: 'pi pi-trash'},
              ], this.getData, {1: 'profileDescription'})}
            </div>
        ), true, "user#associationprofileuser");
    }
}
