import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import Config from '../../config/config';
import ProductService from '../../service/ProductService';
import { LS, g_checkLoginACL, g_displayMessageError } from '../GenericFunctions';

export default class ProductMarketingPhotos extends Component {
    constructor() {
        super();
        this.state = {
            lista: [],
            mode: false,
            documentType: '',
            contractTemplates: [],
            productImages: [],
        };
        this.responsiveSettings = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3,
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2,
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1,
            },
        ];
        this.uploadDocument = this.uploadDocument.bind(this);
        this.productTemplate = this.productTemplate.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const idUUID = this.props.idUUID;
        if (idUUID !== null) {
            this.setState({
                idUUID: idUUID,
                mode: false,
            });
            this.getProductImages(idUUID);
        }
    }

    async getProductImages(productIdUUID) {
        const filters = { productIdUUID: productIdUUID };
        const response = await ProductService.getProductImages(filters);
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({ productImages: response.productImages });
    }

    HandleBrowseClick(type, e) {
        e.preventDefault();
        this.setState({
            documentType: type,
        });
        var input = document.getElementById('file');
        input.click();
    }

    uploadDocument(e) {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadPhotoHandler(file, this.state.documentType);
        };
        readerArray.readAsArrayBuffer(file);
    }

    async uploadPhotoHandler(file, type) {
        const formData = new FormData();
        formData.append('file', file);
        var response = await ProductService.imageUpload(formData, type, this.props.idUUID);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleUpload', 'messages') });
        this.getData();
    }

    uploadHandler = async data => {
        const formData = new FormData();
        formData.append('file', data.file);
        var response = await ProductService.imageUpload(formData, data.documentTypeId, this.props.idUUID);
        if (response.code === 0) {
            this.getData();
        }
    };

    async deletePhotoClick(photoIdUUID, e) {
        e.preventDefault();
        const response = await ProductService.deleteProductImage(photoIdUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleRemove') });
        this.getData();
    }

    productTemplate(product) {
        return (
            <div className="details">
                <div className="p-grid p-nogutter">
                    <div className="p-col-12">
                        <img src={Config.PHOTO_PRODUCT_URL + product.filename} width={120} alt={product.description} />
                    </div>
                    <div className="p-col-12 data">
                        <div className="buttons">
                            <Button icon="pi pi-times" onClick={e => this.deletePhotoClick(product.idUUID, e)} className="p-button-secondary" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return g_checkLoginACL(
            () => (
                <div>
                    <div className="p-grid-12">
                        <div className="card card-w-title">
                            <Messages ref={el => (this.messages = el)} />
                            <div className="p-col-2">
                                {' '}
                                <Button label={LS('uploadMarketing')} onClick={e => this.HandleBrowseClick('100', e)} style={{ display: this.state.mode ? 'none' : 'block' }} />
                            </div>
                            <input id="file" type="file" accept="image/*" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                            <div className="p-grid">
                                <Carousel
                                    value={this.state.productImages}
                                    itemTemplate={this.productTemplate}
                                    numVisible={4}
                                    numScroll={3}
                                    style={{ marginTop: '2em' }}
                                    responsive={this.responsiveSettings}
                                ></Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            ),
            false
        );
    }
}
