import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ProfileService from '../../service/ProfileService';
import {
    LS,
    StateIcon,
    g_template_button,
    g_template_dialog,
    g_checkLoginACL,
    g_displayMessageError,
    g_dropdownTemplate,
    g_getDateTemplate,
    g_getWraper,
    g_handleChange,
    g_inputTemplate,
    g_tabViewRenderOptions,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class ControlItem extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                accessItemStatus: '',
                accessItemTag: '',
                accessItemType: '',
                accessItemTypeDescription: '',
                dateCreation: '',
                dateUpdate: '',
                description: '',
                idUUID: '',
                languageTag: '',
            },
            idUUID: null,
            mode: false,
            items: [{ label: LS('aclItem', 'tab') }],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, mode: mode }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = {
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
            orderBy: [],
            pageSize: 1,
            pageNumber: 1,
        };
        let response = await ProfileService.accessItensList(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.accessItems[0];
        rdata.accessItemType = String(rdata.accessItemType);
        rdata.accessItemStatus = String(rdata.accessItemStatus);
        this.setState({
            data: rdata,
            items: [{ label: LS('aclItem', 'tab') }, { label: LS('log', 'tab'), icon: 'pi pi-list' }],
            activeItem: null,
        });
        if (this.state.data.accessItemStatus === 3) this.setState({ mode: true });
    };

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.idUUID !== null) {
            let data = { accessItem: this.state.data };
            let response = await ProfileService.accessItemUpdate(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let data = { accessItem: this.state.data };
            let response = await ProfileService.accessItemAdd(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.setState({ idUUID: response.accessItem.idUUID }, this.getData);
        }
    };

    updateStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            idUUID: this.state.data.idUUID,
            accessItemStatus: this.state.data.accessItemStatus === 1 ? 2 : 1,
        };
        let response = await ProfileService.accessItemUpdateStatus(data);
        if (g_displayMessageError(this.messages, response));
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        if (this.state.data.idUUID === null) return;
        let data = {
            accessItemStatus: 3,
            idUUID: this.state.data.idUUID,
        };
        let response = await ProfileService.accessItemUpdateStatus(data);
        if (g_displayMessageError(this.messages, response));
        this.messages.show({ severity: 'success', summary: LS('simpleBlock', 'messages') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        {g_inputTemplate('description', this.state.data.description, LS('description'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col-7')}
                                        {g_dropdownTemplate(
                                            'accessItemType',
                                            this.state.data.accessItemType,
                                            LS('accessItemType'),
                                            this,
                                            [
                                                { label: LS('1', 'aclItems'), value: '1' },
                                                { label: LS('2', 'aclItems'), value: '2' },
                                                { label: LS('3', 'aclItems'), value: '3' },
                                                { label: LS('4', 'aclItems'), value: '4' },
                                                { label: LS('5', 'aclItems'), value: '5' },
                                                { label: LS('6', 'aclItems'), value: '6' },
                                            ],
                                            '',
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {}
                                        )}
                                        {g_inputTemplate('accessItemTag', this.state.data.accessItemTag, LS('accessItemTag'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col-7')}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            LS('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.accessItemStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            this.state.idUUID == null,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID == null, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID == null, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.profileStatus === 3, null, this.state.mode, 'p-col-2')}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.accessItemStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    'acltStatus',
                                    this.updateStatus,
                                    LS(this.state.data.accessItemStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                    !this.state.idUUID || this.state.data.accessItemStatus === 3,
                                    this.state.mode,
                                    undefined,
                                    undefined,
                                    '',
                                    '',
                                    '',
                                    'p-button-danger'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS(`askBlockP'}`, 'messages'),
                                    'acltStatus',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    !this.state.idUUID || this.state.data.accessItemStatus === 3,
                                    this.state.mode || e.d !== 1,
                                    undefined,
                                    undefined,
                                    '',
                                    '',
                                    '',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                        <Log idUUID={this.state.idUUID} logContextType={16} />,
                    ])}
                </div>
            );
        });
    }
}
