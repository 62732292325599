import React from 'react';
import ContractTemplateService from '../../service/ContractTemplateService'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { LabelSelector, g_getHeaderTemplatecF, g_genericRequired, g_hideSearch, g_displayMessageError, g_template_dataTable, g_checkLoginACL, g_pair, g_checkMode, g_getData, g_formMaper } from '../GenericFunctions';

export default class ContractTemplateForm extends React.Component {

  constructor() { super();
    this.state = {
      ...g_genericRequired([g_pair('page', 'asc')]),
      forms: {
        contractTemplateFormStatus: 0, contractTemplateFormMode: "1", contractTemplateFormType: "0", page: "0",
        contractTemplateIdUUID: "", dateCreation: "", dateUpdate: "", help: "", idUUID: "", title: "",
      },
      types: [ { label: 'Pagina', value: '1' }, { label: 'Termos', value: '2' }, ],
      modes: [ { label: 'Simples', value: '1' }, { label: 'Tabela', value: '2' }, { label: 'Document', value: '3' }, ]
    };
  }

  componentDidMount() {
    g_hideSearch();
    let query = new URLSearchParams(this.props.context.props.location.search);
    this.setState({mode: query.get('type') !== '1'}, this.getData);
  }

  getData = () => g_getData(this, ContractTemplateService.getContractTemplateFormList, 'contractTemplateForms', {filters: [ ...this.state.filter, g_pair("contractTemplateIdUUID", this.props.context.state.idUUID) ]} )

  addNew = () => this.setState({
    forms: {
      contractTemplateFormStatus: 1,
      contractTemplateIdUUID: this.props.context.state.idUUID,
      page: 0,
      help: "", title: "", idUUID:"",
    },
    displayDialog: true
  });

  onFormSelect = data => this.setState({
    forms: {
      contractTemplateFormStatus: String(data.contractTemplateFormStatus),
      contractTemplateFormType: String(data.contractTemplateFormType),
      contractTemplateIdUUID: data.contractTemplateIdUUID,
      idUUID: data.idUUID,
      help: data.help,
      page: data.page,
      title: data.title,
      contractFormMode: String(data.contractFormMode),
      contractFormModeValue: data.contractFormModeValue
    },
    displayDialog: true
  });

  handleSubmit = async e => {
    e.preventDefault();
    var response;
    if (this.state.forms.idUUID === "") response = await ContractTemplateService.addContractTemplateForm({contractTemplateForm: this.state.forms});
    else response = await ContractTemplateService.updateContractTemplateForm({ contractTemplateForm: this.state.forms});
    if (g_displayMessageError(this.props.context.messages, response)) return;
    this.props.context.messages.show({ severity: 'success', summary: LabelSelector('simpleSave', 'messages') });
    this.setState({displayDialog: false}, this.getData);
  }

  render() { return g_checkLoginACL( e => g_checkMode(this, e, this.state.idUUID, e => (
    <div className="card card-w-title">
      <div class="p-grid-12">
        {g_template_dataTable(this, g_getHeaderTemplatecF(this,(event) => this.addNew(event), this.getData), [
          {type: "d", data: "page", sortable: true, filter: true},
          {type: "d", data: "title", sortable: true, filter: true},
          {type: "di", data: "contractTemplateFormType", di: raw => raw.contractTemplateFormType === 1 ? 'Página' : 'Termos', sortable: true },
          {type: 'date', data: 'dateCreation', sortable: true, filter: true},
          {type: 'state', data: 'contractTemplateFormStatus', states: {'1': {color: 'green', class:"pi-check"}, '2': {color: 'red', class:"pi-times"}, '3': {color:'red', class:'pi-ban'}}},
          {type: 'button', icon: 'pi pi-pencil', c: e.u === 1 || e.d === 1, click: this.onFormSelect, class: 'p-button-warning'},
          {type: 'url', icon: 'pi pi-list', c: e.u === 1, url: raw => '#/contractTemplateField-Management?type=1&id='+ raw.idUUID, width: 800, height: 500, callback: this.getData, tooltip: LabelSelector('editFields', 'tooltip'), class: 'p-button-warning'},
        ], this.getData)}
      </div>
      <form onSubmit={this.handleSubmit}>
        <Dialog visible={this.state.displayDialog} style={{width: '60vw'}} header="Formulário" modal={true} footer={
          <div className="ui-dialog-buttonpane p-clearfix"> <Button label="Salvar" icon="pi pi-check"/> </div>
        } onHide={() => this.setState({displayDialog: false})}>
          <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
            {g_formMaper(this, [
              { t: 'array', cc: {_: 6, 3: 3, 4: [4,4]}, options: {3: this.state.types, 4: [this.state.modes]}, dTypeFs: {_: 't', 3: 'drop', 4: { _: 'drop', 1: 't' }, 5: 'switch'}, c: { 4: {0: false, 1: (this.state.forms.contractFormMode ?? '1') === '1'}}, handleExtraData: {_: {data: 'forms'}}, origin: this.state.forms
                , filter: ['title', 'page', 'help', 'contractTemplateFormType', ['contractFormMode', 'contractFormModeValue'], 'contractTemplateFormStatus']
              }
            ], this.state.mode, 2)}
          </div>
        </Dialog>
      </form>
    </div>
  )), true, 'contractTemplateForms') }
}
