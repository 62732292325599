import axios from './AxiosService'

class ProfileService {

    copy = data => axios.request('PUT',`/profile/copy`, data);
    list = data => axios.request('POST','/profile/list', data)

    async add(data) {
        return await axios.request('POST','/profile/add', data)
    }

    async update(data) {
        return await axios.request('PUT','/profile/update', data)
    }

    async updateStatus(data) {
        return await axios.request('PUT','/profile/update/status', data)
    }

    async accessItensList(data) {
        return await axios.request('POST','/profile/accessItens/list', data)
    }

    async aclList(data) {
        return await axios.request('POST','/profile/acl/list', data)
    }

    async aclSet(data) {
        return await axios.request('POST','/profile/acl/set', data)
    }

    async removeProfileFromUser(profileIdUUID, userIdUUID) {
        return await axios.request('DELETE',`/profile/user/${profileIdUUID}/${userIdUUID}`)
    }

    async accessItemUpdate(data) {
        return await axios.request('PUT','profile/accessItem/update', data)
    }

    async accessItemUpdateStatus(data) {
        return await axios.request('PUT','profile/accessItem/update/status', data)
    }

    async getProfileUserList(data) {
        return await axios.request('POST', `/profile/user/list`, data);
    }

    async addProfileUser(data) {
        return await axios.request('POST', `/profile/user/add`, data);
    }

    async accessItemAdd(data) {
        return await axios.request('POST','profile/accessItem/add', data)
    }

}

export default new ProfileService();
