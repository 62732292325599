import React from 'react';
import Config from '../../config/config';
import SearchSegmentService from '../../service/searchSegmentService';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import { lm, lb, ls, g_checkLoginACL, g_displayMessageError } from '../GenericFunctions';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';

export default class Images extends React.Component {
    constructor() {
        super();
        this.state = { lista: [], mode: false, documentType: '', contractTemplates: [], productImages: [] };
        this.responsiveSettings = [
            { breakpoint: '1024px', numVisible: 3, numScroll: 3 },
            { breakpoint: '768px', numVisible: 2, numScroll: 2 },
            { breakpoint: '560px', numVisible: 1, numScroll: 1 },
        ];
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        if (!this.props.context().state.idUUID) return;
        const response = await SearchSegmentService.getSearchImages({ searchAreaIdUUID: this.props.context().state.idUUID });
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.setState({ productImages: response.searchAreaImages });
    };

    HandleBrowseClick = (type, e) => {
        e.preventDefault();
        this.setState({ documentType: type });
        var input = document.getElementById('file');
        input.click();
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadPhotoHandler(file, this.state.documentType);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadPhotoHandler = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await SearchSegmentService.imageUpload(formData, type, this.props.context().state.idUUID);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.context().messages.show({ severity: 'success', summary: lm('simpleUpload') });
        this.getData();
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        this.upload(formData, data.documentTypeId, this.props.context().state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.props.context().messages.show({ severity: 'sucess', summary: lm('simpleUploadStart') });
        const response = await SearchSegmentService.imageUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
        this.props.context().getData();
    };

    deletePhotoClick = async (photoIdUUID, e) => {
        e.preventDefault();
        const response = await SearchSegmentService.deletePromotionImage(photoIdUUID);
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.context().messages.show({ severity: 'success', summary: lm('simpleRemove') });
        this.getData();
    };

    productTemplate = product => (
        <div className="details">
            <div className="p-grid p-nogutter">
                <div className="p-col-12">
                    <img src={Config.PHOTO_SEARCHAREA_URL + product.filename} width={120} alt={product.description} />
                </div>
                <div className="p-col-12 data">
                    <div className="buttons">
                        <Button icon="pi pi-times" onClick={e => this.deletePhotoClick(product.idUUID, e)} className="p-button-secondary" />
                    </div>
                </div>
            </div>
        </div>
    );

    render() {
        return g_checkLoginACL(
            () => (
                <div className="card card-w-title">
                    <div className="p-grid p-fluid p-col-12">
                        <div className="p-col-3"></div>
                        <div className="p-col-4">
                            {ls('teaser')}:
                            <GPhotoUpload
                                style={{ width: '105px', height: '105px' }}
                                handlePhotoUpload={this.onUpload}
                                photo={this.props.context().state.data.imageUrlTeaser}
                                documentTypeId={411}
                                prefixPath={Config.PHOTO_SEARCHAREA_URL}
                                alt={'/assets/images/image.png'}
                            />
                        </div>
                        <div className="p-col-5">
                            {ls('principal')}:
                            <GPhotoUpload
                                style={{ width: '105px', height: '105px' }}
                                handlePhotoUpload={this.onUpload}
                                photo={this.props.context().state.data.imageUrlMain}
                                documentTypeId={412}
                                prefixPath={Config.PHOTO_SEARCHAREA_URL}
                                alt={'/assets/images/image.png'}
                            />
                        </div>
                        <div className="p-col-2">
                            {' '}
                            <Button label={lb('uploadMarketing')} onClick={e => this.HandleBrowseClick('413', e)} style={this.state.mode ? { display: 'none' } : { display: 'block' }} />
                        </div>
                        <input id="file" type="file" accept="image/*" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                    </div>
                    <Carousel
                        value={this.state.productImages}
                        itemTemplate={this.productTemplate}
                        numVisible={4}
                        numScroll={3}
                        style={{ marginTop: '2em' }}
                        responsive={this.responsiveSettings}
                    ></Carousel>
                </div>
            ),
            false
        );
    }
}
