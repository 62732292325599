import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import config from '../../config/config';
import ConsignatorService from '../../service/ConsignatorService';
import Services from '../../service/Services';
import FinancierService from '../../service/FinancierService';
import ProductService from '../../service/ProductService';
import ProviderService from '../../service/ProviderService';
import Attachment from '../attachment/Attachment';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import GSelector from '../generic/GSelector';
import {
    lb,
    LS,
    LabelSelector,
    ListPage,
    g_genericRequired,
    g_hideSearch,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_ApiProduct,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_entityFilter,
    g_getData,
    g_getDataFields,
    g_getDateTemplate,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_template_inputArea,
    g_template_numeric,
    g_treatNumric,
    g_wraper,
    g_entities,
    g_checkMode,
    g_pair,
    g_treatNumricR,
} from '../GenericFunctions';
import Log from '../logs/Log';
import ProductMarketingPhotos from '../product/ProductMarketingPhotos';
import { StateIcon } from '../Generic';

export class Product extends React.Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiProduct(),
            documentType: '',
            productTypes: [],
            productModes: [],
            entityProvider: [],
            contractTemplates: [],
            interfaceNames: [],
            productImages: [],
            items: [{ label: LS('product', 'tab') }],
            visibilityModes: [
                { label: LS('0', 'visibilityModes'), value: '0' },
                { label: LS('1', 'visibilityModes'), value: '1' },
            ],
            mode: false,
            holderPhotoSmall: null,
            holderPhotoBig: null,
            idUUID: null,
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('mode');
        g_getDataFields(this, ProductService.getProductModes, 'productModes', 'description', 'id', 'productModes');
        g_getDataFields(this, ProductService.getProductTypes, 'productTypes', 'description', 'id', 'productTypes');
        this.getInterfacesNames();
        let items = this.state.items;
        if (id) {
            items = [...items, { label: LS('marketingPhotos', 'tab'), icon: 'pi pi-images' }];
            if (g_checkAlc(4, 'product#configs')) items = [...items, { label: LS('configs', 'tab'), icon: 'pi pi-cog' }, { label: LS('aclAccess', 'tab'), icon: 'pi pi-cog' }];
            if (g_checkAlc(4, 'product#attachment')) items.push({ label: LS('attachments', 'tab'), icon: 'pi pi-paperclip' });
            items = [...items, { label: LS('log', 'tag'), icon: 'pi pi-list' }];
        }
        this.setState({ mode: query.get('type') === 1, idUUID: query.get('id'), items: items, defMode: mode }, this.getData);
        if (mode) this.setState({ data: { ...this.state.data, productMode: mode } });
        if (window.finicierData) {
            this.setState({ data: { ...this.state.data, entityProviderIdUUID: window.finicierData.idUUID } });
            this.selector1.setState({ selected: window.finicierData.name });
        }
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        const response = await Services.product.list({ filters: [g_pair('idUUID', this.state.idUUID)] });
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.products[0]) return g_nullarrayresponse(this);
        let rdata = response.products[0];
        rdata.productMode = String(rdata.productMode ?? '');
        rdata.visibility = String(rdata.visibility ?? '');
        rdata.productType = String(rdata.productType ?? '');
        rdata.priceValue = g_treatNumricR(rdata.priceValue);
        rdata.minValue = g_treatNumricR(rdata.minValue);
        rdata.maxValue = g_treatNumricR(rdata.maxValue);
        rdata.stepValue = g_treatNumricR(rdata.stepValue);
        this.setState({ data: rdata });
    };

    getProviders = context => g_getData(context, ProviderService.getProviders, 'providers');
    updateStatusClick = e =>
        Services.product.processed.status(this, this.state.data.idUUID, this.state.data.productStatus === 1 ? 2 : 1, this.getData, LabelSelector('simpleStatus', 'messages'), this.messages, e);
    blockStatus = e => Services.product.processed.status(this, this.state.data.idUUID, 3, this.getData, LabelSelector('simpleBlock', 'messages'), this.messages, e);
    getFinanciers = context =>
        g_getData(
            context,
            data => {
                let f = g_entityFilter(data.filters, 'idUUID', 'idUUIDs', 5, 'financier-management#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, financiers: [] };
                data.filters = f[0];
                return FinancierService.getFinanciers(data);
            },
            'financiers'
        );

    async getInterfacesNames() {
        let array = ['NUMO', 'SICOOB', 'PREVISUL', 'TENDENCIA', 'TRACK', 'PROTECTOR', 'ICARGO', 'NEORA', 'EXISTO'];
        array.sort();
        array = ['DEFAULT', ...array];
        this.setState({ interfaceNames: array.map(a => ({ value: a, label: a })) });
    }

    handleSubmit = async (event, configs) => {
        event.preventDefault();
        let rdata = this.state.data;
        rdata.minValue = g_treatNumric(rdata.minValue);
        rdata.maxValue = g_treatNumric(rdata.maxValue);
        rdata.priceValue = g_treatNumric(rdata.priceValue);
        rdata.stepValue = g_treatNumric(rdata.stepValue);
        rdata.parameterA = rdata.parameterA ? rdata.parameterA : '';
        rdata.parameterB = rdata.parameterB ? rdata.parameterB : '';
        rdata.parameterC = rdata.parameterC ? rdata.parameterC : '';
        if (this.state.idUUID !== null) {
            let response = configs ? await ProductService.updateProductConfigs({ product: rdata }) : await ProductService.updateProduct({ product: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            rdata.productAcl = g_entities()
                .split(',')
                .map(a => ({ referenceIdUUID: a, productAclType: 0 }));
            let response = await ProductService.addProduct({ product: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.props.history.push(`${window.location.href.substr(window.location.href.indexOf('?'))}&id=${response.product.idUUID}`);
            if (this.state.holderPhotoBig !== null) {
                await this.upload(this.state.holderPhotoBig, 102, response.product.idUUID);
                this.setState({ holderPhotoBig: null });
            }
            if (this.state.holderPhotoSmall !== null) {
                await this.upload(this.state.holderPhotoSmall, 101, response.product.idUUID);
                this.setState({ holderPhotoSmall: null });
            }
            window.location.href = `#/product?type=1&id=${response.product.idUUID}`;
            window.location.reload();
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) {
            if (data.documentTypeId === 102) {
                this.setState({ holderPhotoBig: formData });
                return;
            } else {
                //data.documentTypeId === 101 small
                this.setState({ holderPhotoSmall: formData });
                return;
            }
        } else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await ProductService.imageUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    configs = () => {
        if (!g_checkAlc(4, 'product#configs')) return null;
        let mode = this.state.mode || g_checkAlc(4, 'product#configs')[0].u !== 1 || !this.state.idUUID;
        return (
            <form className="p-col" onSubmit={e => this.handleSubmit(e, true)}>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                        {g_template_dropdown(this, 'interfaceName', this.state.data.interfaceName, LS('interfaceName'), this.state.interfaceNames, mode, false, false, 'p-col-5', 'p-col-2')}
                        {g_template_input(this, 'parameterA', this.state.data.parameterA, LS('parameterA'), mode, false, false, 'p-col-8', 'p-col-2')}

                        {g_template_input(this, 'parameterB', this.state.data.parameterB, LS('parameterB'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterC', this.state.data.parameterC, LS('parameterC'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterD', this.state.data.parameterD, LS('parameterD'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterE', this.state.data.parameterE, LS('parameterE'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterF', this.state.data.parameterF, LS('parameterF'), mode, false, false, 'p-col-8', 'p-col-2')}
                    </div>
                </div>
                <div className="p-grid p-fluid"> {g_template_button(LS('save', 'btt'), '', this.state.data.productStatus === '3', null, mode, 'p-col-1')} </div>
            </form>
        );
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(
                        this,
                        [
                            <form className="p-col" onSubmit={this.handleSubmit}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                        <Panel>
                                            <GSelector
                                                labelwraper="p-col-2"
                                                label={LS('providerName')}
                                                ref={e => (this.selector1 = e)}
                                                getData={this.getProviders}
                                                tableBody={[
                                                    { type: 'd', data: 'name', sortable: true, filter: true },
                                                    {
                                                        type: 'button',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector1.setState({ selected: raw.name, visible: false });
                                                            this.setState({ data: { ...this.state.data, entityProviderIdUUID: raw.idUUID } });
                                                        },
                                                        icon: 'pi pi-plus',
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                disabled={this.state.mode}
                                                startV={this.state.data.providerName}
                                            />
                                            <GSelector
                                                labelwraper="p-col-2"
                                                label={LS('financierName')}
                                                ref={e => (this.selector0 = e)}
                                                getData={this.getFinanciers}
                                                tableBody={[
                                                    { type: 'd', data: 'name', sortable: true, filter: true },
                                                    {
                                                        type: 'button',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector0.setState({ selected: raw.name, visible: false });
                                                            this.setState({ data: { ...this.state.data, entityFinancierIdUUID: raw.idUUID } });
                                                        },
                                                        icon: 'pi pi-plus',
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                disabled={this.state.mode}
                                                startV={this.state.data.financierName}
                                            />
                                            {g_template_dropdown(
                                                this,
                                                'productType',
                                                this.state.data.productType,
                                                LS('productType'),
                                                this.state.productTypes,
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-5',
                                                'p-col-2'
                                            )}
                                            {g_wraper(
                                                'showOrder',
                                                LS('showOrder'),
                                                [
                                                    g_template_input(this, 'showOrder', this.state.data.showOrder, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                        regex: new RegExp('\\d{0,3}', 'g'),
                                                    }),
                                                    g_wraper(
                                                        'showOnSite',
                                                        LS('showOnSite'),
                                                        g_template_checkBox(this, 'showOnSite', this.state.data.showOnSite, '', this.state.mode, false, '', 'p-col-1'),
                                                        false,
                                                        'p-col-2',
                                                        true
                                                    ),
                                                ],
                                                false,
                                                'p-col-2'
                                            )}
                                            {g_template_dropdown(
                                                this,
                                                'visibility',
                                                this.state.data.visibility,
                                                LS('visibility'),
                                                this.state.visibilityModes,
                                                this.state.mode,
                                                false,
                                                false,
                                                'p-col-3',
                                                'p-col-2'
                                            )}
                                            {g_template_dropdown(
                                                this,
                                                'productMode',
                                                this.state.data.productMode,
                                                LS('productMode'),
                                                this.state.productModes,
                                                this.state.mode || this.state.defMode,
                                                false,
                                                true,
                                                'p-col-5',
                                                'p-col-2'
                                            )}
                                            {g_template_input(this, 'name', this.state.data.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                            {g_template_input(this, 'shortDescription', this.state.data.shortDescription, LS('shortDescription'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                            {g_template_inputArea(
                                                this,
                                                'longDescription',
                                                this.state.data.longDescription,
                                                LS('longDescription'),
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-8',
                                                'p-col-2',
                                                '',
                                                {
                                                    minHeight: '12em',
                                                }
                                            )}

                                            {g_template_input(
                                                this,
                                                'contractActionText',
                                                this.state.data.contractActionText,
                                                LS('contractActionText'),
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-8',
                                                'p-col-2'
                                            )}
                                            {g_template_input(
                                                this,
                                                'priceContextTitle',
                                                this.state.data.priceContextTitle,
                                                LS('priceContextTitle'),
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-8',
                                                'p-col-2'
                                            )}
                                            {g_wraper(
                                                'priceValue',
                                                LS('priceValue'),
                                                [
                                                    g_template_numeric(this, 'priceValue', this.state.data.priceValue, '', this.state.mode, false, false, 'p-col-1'),
                                                    g_wraper(
                                                        'minValue',
                                                        LS('minValue'),
                                                        g_template_numeric(this, 'minValue', this.state.data.minValue, '', this.state.mode, false, false, 'p-col-1'),
                                                        false,
                                                        'p-col-2',
                                                        true
                                                    ),
                                                    g_wraper(
                                                        'maxValue',
                                                        LS('maxValue'),
                                                        g_template_numeric(this, 'maxValue', this.state.data.maxValue, '', this.state.mode, false, false, 'p-col-1'),
                                                        false,
                                                        'p-col-2',
                                                        'true'
                                                    ),
                                                    g_wraper(
                                                        'stepValue',
                                                        LS('stepValue'),
                                                        g_template_numeric(this, 'stepValue', this.state.data.stepValue, '', this.state.mode, false, false, 'p-col-1'),
                                                        false,
                                                        'p-col-1',
                                                        true
                                                    ),
                                                ],
                                                false,
                                                'p-col-2'
                                            )}
                                            {g_wraper(
                                                'periodValue',
                                                LS('periodValue'),
                                                [
                                                    g_template_input(this, 'periodValue', this.state.data.periodValue, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                        regex: new RegExp('\\d*', 'g'),
                                                    }),
                                                    g_wraper(
                                                        'minPeriod',
                                                        LS('minPeriod'),
                                                        g_template_input(this, 'minPeriod', this.state.data.minPeriod, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                            regex: new RegExp('\\d*', 'g'),
                                                        }),
                                                        false,
                                                        'p-col-2',
                                                        true
                                                    ),
                                                    g_wraper(
                                                        'maxPeriod',
                                                        LS('maxPeriod'),
                                                        g_template_input(this, 'maxPeriod', this.state.data.maxPeriod, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                            regex: new RegExp('\\d*', 'g'),
                                                        }),
                                                        false,
                                                        'p-col-2',
                                                        'true'
                                                    ),
                                                    g_wraper(
                                                        'stepPeriod',
                                                        LS('stepPeriod'),
                                                        g_template_input(this, 'stepPeriod', this.state.data.stepPeriod, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                            regex: new RegExp('\\d*', 'g'),
                                                        }),
                                                        false,
                                                        'p-col-1',
                                                        true
                                                    ),
                                                ],
                                                false,
                                                'p-col-2'
                                            )}
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-lg-3">
                                        <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                            <div className="p-grid">
                                                {g_wraper(
                                                    null,
                                                    LS('state'),
                                                    <div className="p-col">
                                                        <StateIcon
                                                            state={this.state.data.productStatus}
                                                            custom={{
                                                                1: { color: 'green', class: 'pi-check' },
                                                                2: { color: 'red', class: 'pi-times' },
                                                                3: { color: 'red', class: 'pi-ban' },
                                                            }}
                                                            small
                                                        />
                                                    </div>,
                                                    !this.state.idUUID,
                                                    'p-col-7'
                                                )}
                                                {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                                {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                                {g_wraper(
                                                    null,
                                                    LS('bigPhoto', 'generic'),
                                                    <div className="p-col-12 p-fluid p-grid">
                                                        <div className="p-col-3"></div>
                                                        <GPhotoUpload
                                                            style={{ height: '74px', width: '164px' }}
                                                            handlePhotoUpload={this.onUpload}
                                                            photo={this.state.data.photoBig}
                                                            documentTypeId={102}
                                                            prefixPath={config.PHOTO_PRODUCT_URL}
                                                            alt={window.location.origin + '/assets/images/bigcampaign.png'}
                                                            disabled={this.state.mode}
                                                        />
                                                    </div>,
                                                    false,
                                                    'p-col-12'
                                                )}
                                                {g_wraper(
                                                    null,
                                                    LS('smallPhoto', 'generic'),
                                                    <div className="p-col-12 p-fluid p-grid">
                                                        <div className="p-col-3"></div>
                                                        <GPhotoUpload
                                                            style={{ height: '64px', width: '82px' }}
                                                            handlePhotoUpload={this.onUpload}
                                                            photo={this.state.data.photoSmall}
                                                            documentTypeId={101}
                                                            prefixPath={config.PHOTO_PRODUCT_URL}
                                                            alt={window.location.origin + '/assets/images/notificationsmall.png'}
                                                            disabled={this.state.mode}
                                                        />
                                                    </div>,
                                                    false,
                                                    'p-col-12'
                                                )}
                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                                <div className="p-grid p-fluid">
                                    {g_template_button(LS(this.state.idUUID ? 'save' : 'create'), '', this.state.data.productStatus === '3', null, this.state.mode, 'p-col-1')}
                                    {g_template_dialog(
                                        this,
                                        LS(this.state.data.productStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                        'productStatus',
                                        this.updateStatusClick,
                                        LS(this.state.data.productStatus === '1' ? 'deactivate' : 'activate', 'btt'),
                                        !this.state.idUUID || this.state.data.productStatus === 3,
                                        this.state.mode
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('askBlockP', 'messages'),
                                        'productStatusBloquear',
                                        this.blockStatus,
                                        LS('block', 'btt'),
                                        !this.state.idUUID || this.state.data.productStatus === 3,
                                        this.state.mode,
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'p-col-2',
                                        'p-button-danger'
                                    )}
                                </div>
                            </form>,
                            <ProductMarketingPhotos idUUID={this.state.idUUID} />,
                            ...(g_checkAlc(4, 'product#configs') ? [this.configs(), <ConsignatorAcl context={() => this} />] : []),
                            ...(g_checkAlc(4, 'product#attachment') ? [<Attachment idUUID={this.state.idUUID} documentContextType={6} service={'product'} />] : []),
                            <Log idUUID={this.state.idUUID} logContextType={6} />,
                        ],
                        null,
                        this.getData
                    )}
                </div>
            ))
        );
    }
}

export class Stock extends React.Component {
    constructor() {
        super();
        this.state = {
            data: { ...g_ApiProduct(), productMode: '2', priceContextTitle: 'por unidade' },
            items: [{ label: LS('product', 'tab') }],
            productTypes: [],
            productModes: [],
            entityProvider: [],
            contractTemplates: [],
            productImages: [],
            mode: false,
            holderPhotoSmall: null,
            holderPhotoBig: null,
            idUUID: null,
            documentType: '',
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        if (!window.finicierData && !id) {
            window.close();
            return;
        }
        g_getDataFields(this, ProductService.getProductModes, 'productModes', 'description', 'id', 'productModes');
        g_getDataFields(this, ProductService.getProductTypes, 'productTypes', 'description', 'id', 'productTypes');
        let items = this.state.items;
        if (id) items = [...items, { label: LS('marketingPhotos', 'tab'), icon: 'pi pi-images' }, { label: LS('log', 'tab'), icon: 'pi pi-list' }];
        this.setState({ mode: query.get('type') === 1, idUUID: query.get('id'), items: items }, () => this.getData());
        if (window.finicierData) this.setState({ data: { ...this.state.data, entityProviderIdUUID: window.finicierData.idUUID, providerName: window.finicierData.name } });
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        const response = await ProductService.getProducts({ filters: [g_pair('idUUID', this.state.idUUID)] });
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.products[0]) return g_nullarrayresponse(this);
        let rdata = response.products[0];
        rdata.productMode = String(rdata.productMode ?? '');
        rdata.visibility = String(rdata.visibility ?? '');
        rdata.productType = String(rdata.productType ?? '');
        this.setState({ data: response.products[0] });
    };

    getProviders = context => g_getData(context, ProviderService.getProviders, 'providers');
    updateStatusClick = e =>
        Services.product.processed.status(this, this.state.data.idUUID, this.state.data.productStatus === 1 ? 2 : 1, this.getData, LabelSelector('simpleStatus', 'messages'), this.messages, e);
    blockStatus = e => Services.product.processed.status(this, this.state.data.idUUID, 3, this.getData, LabelSelector('simpleBlock', 'messages'), this.messages, e);

    handleSubmit = async (event, configs) => {
        event.preventDefault();
        let rdata = this.state.data;
        rdata.minValue = g_treatNumric(rdata.minValue);
        rdata.maxValue = g_treatNumric(rdata.maxValue);
        rdata.priceValue = g_treatNumric(rdata.priceValue);
        rdata.stepValue = g_treatNumric(rdata.stepValue);
        rdata.parameterA = rdata.parameterA ?? '';
        rdata.parameterB = rdata.parameterB ?? '';
        rdata.parameterC = rdata.parameterC ?? '';
        if (this.state.idUUID !== null) {
            let response = configs ? await ProductService.updateProductConfigs({ product: rdata }) : await ProductService.updateProduct({ product: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            rdata.productAcl = g_entities()
                .split(',')
                .map(a => ({ referenceIdUUID: a, productAclType: 0 }));
            let response = await ProductService.addProduct({ product: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.props.history.push(`${window.location.href.substr(window.location.href.indexOf('?'))}&id=${response.product.idUUID}`);
            if (this.state.holderPhotoBig !== null) {
                await this.upload(this.state.holderPhotoBig, 102, response.product.idUUID);
                this.setState({ holderPhotoBig: null });
            }
            if (this.state.holderPhotoSmall !== null) {
                await this.upload(this.state.holderPhotoSmall, 101, response.product.idUUID);
                this.setState({ holderPhotoSmall: null });
            }
            window.location.href = `#/stock?type=1&id=${response.product.idUUID}`;
            window.location.reload();
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) {
            if (data.documentTypeId === 102) {
                this.setState({ holderPhotoBig: formData });
                return;
            } else {
                //data.documentTypeId === 101 small
                this.setState({ holderPhotoSmall: formData });
                return;
            }
        } else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await ProductService.imageUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(
                        this,
                        [
                            <form className="p-col" onSubmit={this.handleSubmit}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                        <Panel>
                                            <GSelector
                                                labelwraper="p-col-2"
                                                label={LS('providerName')}
                                                ref={e => (this.selector1 = e)}
                                                getData={this.getProviders}
                                                tableBody={[
                                                    { type: 'd', data: 'name', sortable: true, filter: true },
                                                    {
                                                        type: 'button',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector1.setState({ selected: raw.name, visible: false });
                                                            this.setState({ data: { ...this.state.data, entityProviderIdUUID: raw.idUUID } });
                                                        },
                                                        icon: 'pi pi-plus',
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                disabled
                                                startV={this.state.data.providerName}
                                                tToF={{ 0: 'name' }}
                                            />
                                            {g_template_dropdown(
                                                this,
                                                'productType',
                                                this.state.data.productType,
                                                LS('productType'),
                                                this.state.productTypes,
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-5',
                                                'p-col-2'
                                            )}
                                            {g_template_input(this, 'name', this.state.data.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                            {g_template_input(this, 'shortDescription', this.state.data.shortDescription, LS('shortDescription'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                            {g_template_inputArea(
                                                this,
                                                'longDescription',
                                                this.state.data.longDescription,
                                                LS('longDescription'),
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-8',
                                                'p-col-2',
                                                '',
                                                {
                                                    minHeight: '12em',
                                                }
                                            )}
                                            {g_template_input(
                                                this,
                                                'priceContextTitle',
                                                this.state.data.priceContextTitle,
                                                LS('priceContextTitle'),
                                                this.state.mode,
                                                false,
                                                true,
                                                'p-col-8',
                                                'p-col-2'
                                            )}
                                            {g_template_numeric(this, 'priceValue', this.state.data.priceValue, LabelSelector('priceValue'), this.state.mode, false, false, 'p-col-1', 'p-col-2')}
                                            {g_template_input(this, 'quantity', this.state.data.quantity, LabelSelector('quantity'), this.state.mode, false, false, 'p-col-2', 'p-col-2', '', {
                                                regex: new RegExp('\\d*', 'g'),
                                            })}
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-lg-3">
                                        <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                            <div className="p-grid">
                                                {g_wraper(
                                                    null,
                                                    LS('state'),
                                                    <div className="p-col">
                                                        <StateIcon
                                                            state={this.state.data.productStatus}
                                                            small
                                                            custom={{
                                                                1: { color: 'green', class: 'pi-check' },
                                                                2: { color: 'red', class: 'pi-times' },
                                                                3: { color: 'red', class: 'pi-ban' },
                                                            }}
                                                        />
                                                    </div>,
                                                    !this.state.idUUID,
                                                    'p-col-7'
                                                )}
                                                {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                                {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                                {g_wraper(
                                                    null,
                                                    LS('bigPhoto', 'generic'),
                                                    <div className="p-col-12 p-fluid p-grid">
                                                        <div className="p-col-3"></div>
                                                        <GPhotoUpload
                                                            style={{ height: '74px', width: '164px' }}
                                                            handlePhotoUpload={this.onUpload}
                                                            photo={this.state.data.photoBig}
                                                            documentTypeId={102}
                                                            prefixPath={config.PHOTO_PRODUCT_URL}
                                                            alt={window.location.origin + '/assets/images/bigcampaign.png'}
                                                            disabled={this.state.mode}
                                                        />
                                                    </div>,
                                                    false,
                                                    'p-col-12'
                                                )}
                                                {g_wraper(
                                                    null,
                                                    LS('smallPhoto', 'generic'),
                                                    <div className="p-col-12 p-fluid p-grid">
                                                        <div className="p-col-3"></div>
                                                        <GPhotoUpload
                                                            style={{ height: '64px', width: '82px' }}
                                                            handlePhotoUpload={this.onUpload}
                                                            photo={this.state.data.photoSmall}
                                                            documentTypeId={101}
                                                            prefixPath={config.PHOTO_PRODUCT_URL}
                                                            alt={window.location.origin + '/assets/images/notificationsmall.png'}
                                                            disabled={this.state.mode}
                                                        />
                                                    </div>,
                                                    false,
                                                    'p-col-12'
                                                )}
                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                                <div className="p-grid p-fluid">
                                    {g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', this.state.data.productStatus === '3', null, this.state.mode, 'p-col-1')}
                                    {g_template_dialog(
                                        this,

                                        LS(this.state.data.productStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                        'productStatus',
                                        this.updateStatusClick,

                                        LS(this.state.data.productStatus === '1' ? 'deactivate' : 'activate', 'btt'),
                                        !this.state.idUUID || this.state.data.productStatus === 3,
                                        this.state.mode
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('askBlockP', 'messages'),
                                        'productStatusBloquear',
                                        this.blockStatus,
                                        LS('block', 'btt'),
                                        !this.state.idUUID || this.state.data.productStatus === 3,
                                        this.state.mode,
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'p-col-2',
                                        'p-button-danger'
                                    )}
                                </div>
                            </form>,
                            <ProductMarketingPhotos idUUID={this.state.idUUID} />,
                            <Log idUUID={this.state.idUUID} logContextType={6} />,
                        ],
                        null,
                        this.getData
                    )}
                </div>
            ))
        );
    }
}

class ConsignatorAcl extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.context().state.idUUID !== null) this.getData();
    }
    getData = () =>
        this.props.context().state.idUUID
            ? g_getData(this, ProductService.productAcl.list, 'productsAcls', { filters: [...this.state.filter, { key: 'productIdUUID', value: this.props.context().state.idUUID }] })
            : null;

    remove = async raw => {
        if (!this.props.context().state.idUUID) return;
        let response = await ProductService.productAcl.delete(raw.idUUID);
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.getData();
    };
    render() {
        return (
            <div className="card">
                <Dialog header={LS('addNewOne', 'titles')} onHide={() => this.setState({ showNewOne: false })} visible={this.state.showNewOne}>
                    <div style={{ minWidth: '50vw' }}>{addNew(this.props.context().state.idUUID, this.getData)}</div>
                </Dialog>
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ showNewOne: !this.state.showNewOne }), this.getData),
                    [
                        { type: 'd', data: 'referenceName', filter: true },
                        { type: 'dil', text: LS('askRemove', 'messages'), icon: 'pi pi-trash', buttonClassName: 'p-button-danger', id: raw => raw.idUUID, onConfirm: this.remove },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}

const addNew = (idUUID, callBackData) =>
    idUUID && callBackData ? (
        <ListPage
            title={''}
            header={context => g_getHeaderTemplatecF(context, null, context.getData, false)}
            table={context => [
                { type: 'd', data: 'fantasyName', title: LS('fantasyNameSimple'), filter: true },
                {
                    type: 'button',
                    icon: 'pi pi-plus',
                    click: async e => {
                        const data = { productAcl: { referenceIdUUID: e.idUUID, productIdUUID: idUUID, productAclType: 0 } };
                        const response = await ProductService.productAcl.add(data);
                        if (g_displayMessageError(context.messages, response)) return;
                        context.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
                        callBackData();
                        context.getData(context);
                    },
                },
            ]}
            getData={context => ConsignatorService.processed.list(context, { pageSize: 5, filters: [...context.state.filter, { key: 'notinproductacl', value: idUUID }] })}
        />
    ) : null;
