import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import Config from '../../config/config';
import * as config from '../../config/config';
import UserService from '../../service/UserService';
import AssociationProfile from '../admin/backUsers/AssosiationProfile';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import {
    StateIcon,
    LS,
    g_ApiUserResponse,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_getWraper,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_template_inputMask,
    g_template_textcopy,
    g_wraper,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class UserApp extends Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiUserResponse(0),
            idUUID: '',
            mode: false,
            items: [{ label: LS('user', 'tab') }],
            holderphoto: null,
        };
    }

    handlerResetClick = async e => {
        e.preventDefault();
        const input = {
            idUUID: this.state.data.idUUID,
            userType: this.state.data.userType,
            email: this.state.data.email,
            notificationChannel: 1,
        };
        const response = await UserService.resetPassword(input);
        if (g_displayMessageError(this.message, response)) return;
        this.messages.show({ severity: 'success', summary: LS('passwordResetSuccess', 'messages') });
    };

    resetPin = async e => {
        e.preventDefault();
        const input = {
            idUUID: this.state.data.idUUID,
            userType: this.state.data.userType,
            email: this.state.data.email,
            notificationChannel: 1,
        };
        const response = await UserService.resetPin(input);
        if (g_displayMessageError(this.message, response)) return;
        this.messages.show({ severity: 'success', summary: LS('passwordResetSuccess', 'messages') });
    };

    updateStatusClick = async e => {
        e.preventDefault();
        let input = {
            idUUID: this.state.data.idUUID,
            userStatus: this.state.data.userStatus === 1 ? 2 : 1,
            userType: 0,
        };
        let response = await UserService.updateStatus(input);
        if (g_displayMessageError(this.message, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({ idUUID: query.get('id'), mode: query.get('type') === '1' ? false : true }, this.getData);
    }

    async getData() {
        if (!this.state.idUUID) return;
        let data = { pageSize: 1, pageNumber: 1, userType: 0, filters: [{ key: 'idUUID', value: this.state.idUUID }] };
        let response = await UserService.listUsers(data);
        if (g_displayMessageError(this.message, response)) return;
        this.setState({
            data: response.users[0],
            items: !this.props.mode
                ? [
                      { label: LS('user', 'tab') },
                      ...(g_checkAlc(4, `userapp#associationprofileuser`) ? [{ label: LS('profile', 'tab'), icon: 'pi pi-tags' }] : []),
                      { label: LS('log', 'tab'), icon: 'pi pi-list' },
                  ]
                : [{ label: LS('user', 'tab') }],
        });
    }

    handleSubmit = async event => {
        event.preventDefault();
        let data = this.state.data;
        data.phoneMobile = (data.phoneMobile ?? '').replace(/[^\d]/g, '');
        data = { user: data };
        if (this.state.idUUID !== null) {
            let response = await UserService.updateUser(data);
            if (g_displayMessageError(this.message, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let response = await UserService.createUser(data);
            if (g_displayMessageError(this.message, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            if (this.state.holderphoto !== null) {
                await this.upload(this.state.holderphoto, 1, response.user.idUUID);
                this.setState({ holderphoto: null });
            }
            this.setState({ idUUID: response.user.idUUID }, this.getData);
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) this.setState({ holderphoto: formData });
        else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await UserService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.message, response)) return;
        window.location.reload();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.handleSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-7 p-lg-7 p-fluid contact-form">
                                    <Panel>
                                        {g_template_input(this, 'fullname', this.state.data.fullname, LS('fullname'), this.state.mode, false, true, 'p-col', 'p-col-2')}
                                        {g_template_input(this, 'username', this.state.data.username, LS('username'), this.state.mode, false, true, 'p-col-4', 'p-col-2')}
                                        {g_template_input(this, 'email', this.state.data.email, LS('email'), this.state.mode, false, true, 'p-col-5', 'p-col-2', '', {}, 'email')}
                                        {g_template_inputMask(
                                            this,
                                            'phoneMobile',
                                            this.state.data.phoneMobile,
                                            LS('phoneMobile'),
                                            LS('phoneMobile', 'mask'),
                                            '(99) 99999-9999',
                                            true,
                                            this.state.mode,
                                            false,
                                            'p-col-3',
                                            'p-col-2',
                                            '',
                                            LS('phoneMobile', 'placeholder')
                                        )}
                                        {g_template_dropdown(this, 'languageId', this.state.data.languageId, LS('languageId'), config.languages, this.state.mode, 0, 0, 'p-col-2', 'p-col-2')}
                                        {g_template_textcopy(this, LS('applicationId'), this.state.data.applicationId, 'applicationId', !this.state.idUUID, 'p-col-2')}
                                        {g_wraper(
                                            'enroll',
                                            LS('mobileEnrollmentStatus'),
                                            [
                                                g_template_checkBox(this, 'mobileEnrollmentStatus', this.state.data.mobileEnrollmentStatus, null, true, false, 'p-col-1'),
                                                g_getDateTemplate(this.state.data.dateMobileEnrollment),
                                            ],
                                            !this.state.idUUID,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'terms',
                                            LS('legalAcceptanceStatus'),
                                            [
                                                g_template_checkBox(this, 'legalAcceptanceStatus', this.state.data.legalAcceptanceStatus, null, true, false, 'p-col-1'),
                                                g_getDateTemplate(this.state.data.dateLegalAcceptance),
                                            ],
                                            !this.state.idUUID,
                                            'p-col-2'
                                        )}
                                        {g_wraper('', '', <h1> </h1>, !this.state.idUUID)}
                                        {g_wraper('', '', <h4>Dispositivo</h4>, !this.state.idUUID)}
                                        {g_template_textcopy(this, LS('deviceBrand'), this.state.data.deviceBrand, 'deviceBrand', !this.state.idUUID, 'p-col-2')}
                                        {g_template_textcopy(this, LS('deviceType'), this.state.data.deviceType, 'deviceType', !this.state.idUUID, 'p-col-2')}
                                        {g_template_textcopy(this, LS('deviceModel'), this.state.data.deviceModel, 'deviceModel', !this.state.idUUID, 'p-col-2')}
                                        {g_template_textcopy(this, LS('deviceId'), this.state.data.Id, 'deviceId', !this.state.idUUID, 'p-col-2')}
                                        {g_template_textcopy(this, LS('versionapp'), this.state.data.appVersion, 'versionapp', !this.state.idUUID, 'p-col-2')}
                                    </Panel>
                                </div>
                                <div style={{ display: !this.state.idUUID ? 'none' : '' }} className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon
                                                    small
                                                    state={this.state.data.userStatus}
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            this.state.idUUID == null,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID === null, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID === null, 'p-col')}
                                        {g_getWraper(null, LS('dateLastLogin'), g_getDateTemplate(this.state.data.dateLastLogin), this.state.idUUID === null, 'p-col')}
                                        {g_getWraper(null, LS('dateLastUserLock'), g_getDateTemplate(this.state.data.dateLastUserLock), this.state.idUUID === null, 'p-col')}
                                        {g_getWraper(null, LS('dateLastPasswordChange'), g_getDateTemplate(this.state.data.dateLastPasswordChange), this.state.idUUID === null, 'p-col')}
                                        {g_getWraper(null, LS('passwordRetries'), <span className="p-col-1">{this.state.data.passwordRetries}</span>, this.state.idUUID === null, 'p-col-7')}
                                    </Panel>
                                </div>
                                <div style={{ display: this.state.idUUID === null ? 'none' : '' }} className="p-col-12 p-sm-2 p-md-2 p-lg-2">
                                    <GPhotoUpload
                                        style={{ width: '80px', height: '80px' }}
                                        handlePhotoUpload={this.onUpload}
                                        photo={this.state.data.photo}
                                        documentTypeId={1}
                                        prefixPath={Config.PHOTO_URL}
                                    />
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', false, null, this.state.mode || this.state.data.userStatus === 2, 'p-col-1')}
                                {g_template_button(LS('resetPassword', 'btt'), '', !this.state.idUUID || this.state.data.userStatus === '3', this.handlerResetClick, this.state.mode, 'p-col-2')}
                                {g_template_button(LS('resetPin', 'btt'), '', !this.state.idUUID || this.state.data.userStatus === '3', this.resetPin, this.state.mode, 'p-col-2')}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.userStatus === 1 ? 'Deactivate' : 'Activate'}`),
                                    'changestatusdialog',
                                    this.updateStatusClick,
                                    LS(this.state.data.userStatus === 1 ? 'deactivate' : 'activate'),
                                    false,
                                    this.state.mode,
                                    null,
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                        ...(g_checkAlc(4, `userapp#associationprofileuser`) ? [<AssociationProfile idUUID={this.state.idUUID} userType={0} />] : []),
                        <Log idUUID={this.state.idUUID} logContextType={1} />,
                    ])}
                </div>
            );
        });
    }
}
export const states = { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } };
