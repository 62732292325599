import React from 'react';
import { Dialog } from 'primereact/dialog';
import Log from '../logs/Log';
import * as GF from '../GenericFunctions';
import { states } from './freight';
import './bid.css';

export const bidData = {
    url: (idUUID, niduuid, type = 1) => `#/bid?type=${type}${idUUID ? '&id=' + idUUID : ''}${niduuid ? '&nid=' + niduuid : ''}`,
    size: [1400, 700],
};

export default class Bid extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                ...GF.g_ApiContract(),
                contractType: 1,
                contractStatus: 450,
                consignatorIdUUID: GF.g_consignators().split(',')[0],
                //TODO: Change idUUID for prod
                contractTemplateIdUUID: '04120b4f-5bb6-4be1-8bfd-8e5a265fe7b0',
            },
            items: [{ label: GF.LabelSelector('bid', 'tab') }],
            mode: false,
            idUUID: null,
            cargo: {},
            negationMessage: '',
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let items = this.state.items;
        items = idUUID ? [...items, { label: 'Logs', icon: 'pi pi-list' }] : items;
        this.setState({ idUUID: idUUID, fidUUID: query.get('nid'), items: items }, this.getData);
    }

    aproveStatus = e => GF.g_ChangeStatus(this, () => GF.S.contract.status({ idUUID: this.state.data.idUUID, contractStatus: 453 }), this.getData(), GF.LS('simpleStatus', 'messages'), null, e);
    negateStatus = e =>
        GF.g_ChangeStatus(
            this,
            () => GF.S.contract.status({ idUUID: this.state.data.idUUID, contractStatus: 454, contractStatusMessage: this.state.negationMessage, extraLogEntry: 'BID' }),
            () => this.setState({ negationMessage: '', dialog_state_negateStatus: false }, this.getData),
            GF.LS('negate', 'messages'),
            this.state.messages,
            e
        );

    respondStatus = e =>
        GF.g_ChangeStatus(
            this,
            () => GF.S.contract.status({ idUUID: this.state.data.idUUID, contractStatus: 452, contractStatusMessage: this.state.negationMessage, extraLogEntry: 'BID' }),
            () => this.setState({ negationMessage: '', dialog_state_respondStatus: false }, this.getData),
            GF.LS('simpleStatus', 'messages'),
            this.state.messages,
            e
        );

    getData = async () => {
        let extract = id =>
            GF.g_getData(
                this,
                () => GF.S.icargo.cargo.list({ pageNumber: 1, pageSize: 1, contractIdUUID: id, filters: [GF.g_pair('joinType', 2)] }),
                r => {
                    let cargo = r.cargos;
                    if (cargo.length === 0) return;
                    cargo = r.cargos[0];
                    this.setState({ cargo: cargo });
                }
            );

        if (this.state.idUUID)
            GF.g_getData(
                this,
                () => GF.S.contract.get(this.state.idUUID),
                r => {
                    let contract = r.contract;
                    extract(contract.parentContractIdUUID);
                    this.setState({ data: contract });
                }
            );

        if (!this.state.idUUID && this.state.fidUUID) extract(this.state.fidUUID);
    };

    handleSubmit = async e => {
        if (e.preventDefault) e.preventDefault();
        if (this.state.idUUID) {
            let response = await GF.S.contract.update({ contract: this.state.data });
            if (GF.g_displayMessageError(this.messages, response)) return;
            await GF.S.contract.status({
                idUUID: this.state.idUUID,
                contractStatus: 451,
                contractStatusMessage: `${GF.LS('bidAutoMsg', 'messages')} ${this.state.data.contractTotalValue} ${GF.LS('moneySymbol', 'default')}`,
                extraLogEntry: 'BID',
            });
            if (GF.g_displayMessageError(this.messages, response)) return;
            this.getData();
        } else if (this.state.fidUUID) {
            let data = this.state.data;
            data.parentContractIdUUID = this.state.fidUUID;
            GF.g_getData(
                this,
                GF.S.contract.add,
                async r => {
                    await GF.S.contract.status({
                        idUUID: r.contract.idUUID,
                        contractStatus: 451,
                        contractStatusMessage: GF.ls('initialBid', 'messages').replace('$price', this.state.data.contractTotalValue),
                        extraLogEntry: 'BID',
                    });
                    window.location.href = `#/bid?id=${r.contract.idUUID}&type=1`;
                    window.location.reload();
                },
                { contract: data, syncCall: 0 }
            );
        }
    };

    render() {
        return GF.g_checkLoginACL(e =>
            GF.g_checkMode(this, e, this.state.idUUID, () => {
                let footer = (
                    <div>
                        {GF.g_template_button('Sim')}
                        {GF.g_template_button(
                            'Não',
                            '',
                            0,
                            e => {
                                e.preventDefault();
                                this.setState({ dialog_state_stepclick: false, dialog_state_negateStatus: false, toSelected: '', toSelectedMessage: '' });
                            },
                            0,
                            '',
                            'p-button-danger'
                        )}
                    </div>
                );
                return (
                    <div className="card card-w-title">
                        {GF.g_tabViewRenderOptions(
                            this,
                            [
                                GF.g_template_form(
                                    this,
                                    mode => [
                                        <div
                                            className="mgrid"
                                            style={{ border: '1px #d3dbe6 solid', background: '#eaedf2', padding: '8px', marginTop: '2em', marginBottom: '2em', borderRadius: '10px' }}
                                        >
                                            <div className="title">
                                                <h2>Tipo de oferta: {this.state.cargo?.cargoPaymentSpecs?.quotationTypeDescription ?? ''}</h2>
                                            </div>
                                            <div className="img-dot-dot">
                                                <img src="/assets/images/icone2-01.png" />
                                            </div>

                                            <div className="origin p-grid">
                                                <div className="p-col-12">
                                                    <h2>Origem</h2>
                                                </div>
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11"> {this.state.cargo?.cargoAddress?.originAddress?.city ?? ''} </div>
                                                <br />
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11"> {this.state.cargo?.cargoAddress?.originAddress?.street ?? ''} </div>
                                                <br />
                                            </div>

                                            <div className="destination p-grid">
                                                <div className="p-col-12">
                                                    <h2>Destino</h2>
                                                </div>
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11"> {this.state.cargo?.cargoAddress?.destinationAddress?.city ?? ''} </div>
                                                <br />
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11"> {this.state.cargo?.cargoAddress?.destinationAddress?.street ?? ''} </div>
                                                <br />
                                            </div>

                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <h2>Tipo de veículo</h2>
                                                </div>
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11">
                                                    {' '}
                                                    {this.state.cargo?.cargoInfo?.categoryDescription ?? ''} - {this.state.cargo?.cargoInfo?.categoryTypeDescription ?? ''} -{' '}
                                                    {this.state.cargo?.cargoInfo?.truckTypeDescription ?? ''}{' '}
                                                </div>
                                                <br />
                                            </div>
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <h2>Peso(kg)</h2>
                                                </div>
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11"> {this.state.cargo?.cargoInfo?.cargoWeight ?? ''}</div>
                                                <br />
                                            </div>
                                            {GF.g_wraper(
                                                null,
                                                'Estado',
                                                <div className="p-col">
                                                    <GF.StateIcon state={this.state.data.contractStatus} custom={states} style={{ fontSize: '1.4em' }} />
                                                </div>,
                                                !this.state.idUUID,
                                                'p-col-2'
                                            )}

                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <h2>Tipo de carga</h2>
                                                </div>
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11">
                                                    {' '}
                                                    {this.state.cargo?.cargoInfo?.cargoTypeDescription ?? ''} - {this.state.cargo?.cargoInfo?.productSegmentDescription ?? ''}
                                                </div>
                                                <br />
                                            </div>
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <h2>Valor(R$)</h2>
                                                </div>
                                                <div className="p-col-1"></div>
                                                <div className="p-col-11">
                                                    {' '}
                                                    {(!isNaN(Number(this.state.cargo?.cargoPaymentSpecs?.cargoPrice)) ? Number(this.state.cargo?.cargoPaymentSpecs?.cargoPrice) : 0).toLocaleString(
                                                        'pt-BR',
                                                        { minimumFractionDigits: 2 }
                                                    ) ?? ''}
                                                </div>
                                                <br />
                                            </div>
                                            {GF.g_formMaper(
                                                this,
                                                [
                                                    {
                                                        t: 'array',
                                                        dTypeFs: { 0: 'process' },
                                                        style: [{ fontSize: '1.5em' }],
                                                        filter: [
                                                            {
                                                                t: 'array',
                                                                filter: [['contractTotalValue', '']],
                                                                dTypeFs: { 0: [![450, 452].includes(this.state.data.contractStatus) ? 'view' : 'numeric', 'btt'], _: 'btt' },
                                                                extra: { 0: { 1: mode || ![450, 452].includes(this.state.data.contractStatus) ? 'p-button-secondary' : 'p-button-success' } },
                                                                l: {
                                                                    0: {
                                                                        0: GF.LabelSelector(![450, 452].includes(this.state.data.contractStatus) ? 'bidedValue' : 'value'),
                                                                        1: GF.LabelSelector('bid', 'btt'),
                                                                    },
                                                                },
                                                                cc: [[4, 5]],
                                                                c: [[false, ![450, 452].includes(this.state.data.contractStatus)]],
                                                                disabled: {
                                                                    0: {
                                                                        0: mode || this.state.cargo.quotation_type_id === '1' || ![450, 452].includes(this.state.data.contractStatus),
                                                                        1: mode || ![450, 452].includes(this.state.data.contractStatus),
                                                                    },
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                                mode,
                                                3
                                            )}
                                        </div>,
                                        this.state.idUUID ? <Log pageSize={3} simple filter={[GF.g_pair('context', 'BID')]} idUUID={this.state.idUUID} logContextType={4} /> : null,
                                    ],
                                    () => [
                                        {
                                            t: 'b',
                                            l: GF.LS('accept', 'btt'),
                                            extra: 'p-button-success',
                                            c: this.state.data.contractStatus !== 451 || !GF.g_checkAlc(5, 'bid#aprove'),
                                            disabled: false,
                                            click: this.aproveStatus,
                                        },
                                        {
                                            t: 'b',
                                            l: GF.LS('respond', 'btt'),
                                            extra: 'p-button-warning',
                                            c: this.state.data.contractStatus !== 451 || !GF.g_checkAlc(5, 'bid#aprove') || this.state.cargo.quotation_type_id === '1',
                                            disabled: false,
                                            click: e => {
                                                e.preventDefault();
                                                this.setState({ dialog_state_respondStatus: true });
                                            },
                                        },
                                        {
                                            t: 'b',
                                            l: GF.LS('negate', 'btt'),
                                            extra: 'p-button-danger',
                                            c: this.state.data.contractStatus !== 451 || !GF.g_checkAlc(5, 'bid#aprove'),
                                            disabled: false,
                                            click: e => {
                                                e.preventDefault();
                                                this.setState({ dialog_state_negateStatus: true });
                                            },
                                        },
                                    ],
                                    () => [[]],
                                    this.handleSubmit,
                                    2,
                                    false,
                                    this.state.mode || this.state.data.contractStatus >= 453,
                                    12,
                                    0,
                                    '68vh',
                                    e => (this.formRef = e)
                                ),
                                <Log idUUID={this.state.idUUID} logContextType={4} />,
                            ],
                            null
                        )}
                        <form onSubmit={this.negateStatus}>
                            <Dialog
                                visible={this.state['dialog_state_negateStatus']}
                                onHide={() => this.setState({ dialog_state_negateStatus: false })}
                                footer={footer}
                                style={{ width: '50vw', overflowX: 'none' }}
                                header="Confirmar"
                            >
                                <div className="p-grid p-fluid" style={{ overflowX: 'none', width: '40vw' }}>
                                    <div className="p-col-12">Tem a certeza que quer Negar?</div>
                                    {GF.g_template_inputArea(
                                        this,
                                        'negationMessage',
                                        this.state.negationMessage,
                                        'Motivio',
                                        0,
                                        0,
                                        1,
                                        'p-col-12',
                                        'p-col-12',
                                        '',
                                        { width: '48vw' },
                                        { data: 'negationMessage' }
                                    )}
                                </div>
                            </Dialog>
                        </form>
                        <form onSubmit={this.respondStatus}>
                            <Dialog
                                visible={this.state['dialog_state_respondStatus']}
                                onHide={() => this.setState({ dialog_state_respondStatus: false, negationMessage: '' })}
                                footer={footer}
                                style={{ width: '50vw', overflowX: 'none' }}
                                header="Confirmar"
                            >
                                <div className="p-grid p-fluid" style={{ overflowX: 'none', width: '40vw' }}>
                                    {GF.g_template_inputArea(
                                        this,
                                        'negationMessage',
                                        this.state.negationMessage,
                                        GF.LabelSelector('respond', 'messages'),
                                        0,
                                        0,
                                        1,
                                        'p-col-12',
                                        'p-col-12',
                                        '',
                                        { width: '48vw' },
                                        { data: 'negationMessage' }
                                    )}
                                </div>
                            </Dialog>
                        </form>
                    </div>
                );
            })
        );
    }
}
