import React from 'react';
import ConsignatorService from '../../../service/ConsignatorService';
import ConsigneeService from '../../../service/ConsigneeService';
import Services from '../../../service/Services';
import { g_ApiContractTemplateMargin } from '../../generic/ApiResponsesTemplate';
import {
    LS,
    pair,
    g_checkLoginACL,
    g_checkMode,
    g_convertList,
    g_displayMessageError,
    g_getData,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_form,
    g_treatNumric,
    g_treatNumricR,
} from '../../GenericFunctions';
import Log from '../../logs/Log';
import { category, contexts, types } from './lists';

export class Margin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiContractTemplateMargin(), items: [{ label: 'Margin' }], idUUID: null, ctid: null, mode: true };
    }
    onCancel = e =>
        Services.contract.template.margin.processed.status(
            this,
            this.state.data.idUUID,
            this.state.data.contractTemplateMarginStatus === 1 ? 2 : 1,
            this.getData,
            LS('simpleStatus', 'messages'),
            this.messages,
            e
        );

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  Services.contract.template.margin.list,
                  response => {
                      let rdata = response.contractTemplateMargins[0];
                      rdata.contractTemplateMarginCategory = String(rdata.contractTemplateMarginCategory);
                      rdata.contractTemplateMarginContext = String(rdata.contractTemplateMarginContext);
                      rdata.contractTemplateMarginType = String(rdata.contractTemplateMarginType);
                      rdata.marginValueControl = g_treatNumricR(rdata.marginValueControl);
                      this.setState({ data: rdata });
                      this.selector1.setState({ selected: rdata.contextDescription });
                  },
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }] }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let ctid = query.get('ctid');
        if (!idUUID && !ctid) {
            window.close();
            return;
        }
        let mode = query.get('type') !== '1';
        let items = [{ label: LS('margin', 'tab') }];
        if (idUUID) items.push({ label: LS('log', 'tab'), icon: 'pi pi-list' });
        this.setState({ mode: mode, idUUID: idUUID, ctid: ctid, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.marginValueControl = g_treatNumric(data.marginValueControl);
        if (!this.state.idUUID && !this.state.ctid) {
            window.close();
            return;
        }
        if (!this.state.idUUID) {
            const response = await Services.contract.template.margin.add({ contractTemplateMargin: { ...data, contractTemplateIdUUID: this.state.ctid } });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/margin-control?type=1&id=${response.contractTemplateMargin.idUUID}`;
            window.location.reload();
            return;
        }
        const response = await Services.contract.template.margin.update({ contractTemplateMargin: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
    };

    convert = e => Object.keys(e).map(a => ({ value: a, label: e[a] }));

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                {
                                    t: 'array',
                                    dTypeF: 'drop',
                                    options: [g_convertList(types), g_convertList(category), g_convertList(contexts)],
                                    cc: { _: 3, 1: 5, 3: 5 },
                                    dTypeFs: { 3: 'selector', 4: 'numeric' },
                                    c: { 3: this.state.data.contractTemplateMarginContext === '' },
                                    req: { _: true },
                                    getData: {
                                        3: {
                                            1: c => ConsignatorService.processed.list(c, { pageSize: 5 }),
                                            2: c => Services.group.processed.list(c, { pageSize: 5, filters: [...c.state.filter, pair('groupType', 1)] }),
                                            3: c => ConsigneeService.processed.list(c, { pageSize: 5 }),
                                        }[this.state.data.contractTemplateMarginContext],
                                    },
                                    ref: { 3: e => (this.selector1 = e) },
                                    startV: { 3: this.state.data.contextDescription },
                                    handleExtraData: { 2: { callback: () => this.setState({ data: { ...this.state.data, contextValue: '' } }, () => this.selector1.setState({ selected: ' ' })) } },
                                    tableBody: {
                                        3: [
                                            { type: 'd', data: (() => (this.state.data.contractTemplateMarginContext === '3' ? 'fullname' : 'name'))() },
                                            {
                                                type: 'button',
                                                icon: 'pi pi-plus',
                                                click: (raw, e) => {
                                                    e.preventDefault();
                                                    this.selector1.setState({ selected: raw[this.state.data.contractTemplateMarginContext === '3' ? 'fullname' : 'name'], visible: false });
                                                    this.setState({ data: { ...this.state.data, contextValue: raw.idUUID } });
                                                },
                                            },
                                        ],
                                    },
                                    filter: ['contractTemplateMarginType', 'contractTemplateMarginCategory', 'contractTemplateMarginContext', 'contextValue', 'marginValueControl'],
                                },
                            ],
                            mode => [
                                g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.contractTemplateMarginStatus === 2, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    LS(this.state.data.contractTemplateMarginStatus === 1 ? 'simpleDeactivate' : 'simpleActivate', 'messages'),
                                    'cancel ',
                                    this.onCancel,
                                    LS(this.state.data.contractTemplateMarginStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                    !this.state.data.idUUID,
                                    this.state.mode,
                                    '',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                            ],
                            () => [
                                [
                                    {
                                        t: 'array',
                                        filter: ['contractTemplateMarginStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        class: 'l',
                                        states: { 0: groupsStates },
                                    },
                                ],
                            ],
                            this.submit,
                            2,
                            true,
                            this.state.mode || this.state.data.contractTemplateMarginStatus === 2,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            ))
        );
    }
}

export const groupsStates = { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' } };
