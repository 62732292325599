import React from 'react';
import ContractTemplateFieldService from '../../service/ContractTemplateFieldService';
import {
    LS,
    StateIcon,
    g_checkLoginACL,
    g_displayMessageError,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_inputArea,
    g_wraper,
    g_template_numeric,
    g_treatNumric,
    g_treatNumricR,
    g_handleChange,
    g_checkMode,
    g_formMaper,
    g_getDataFields,
} from '../GenericFunctions';
import { Panel } from 'primereact/panel';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';

export default class ContractTemplateField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                contractTemplateFieldStatus: 1,
                contractTemplateFormIdUUID: '',
                fieldListValues: '',
                fieldName: '',
                fieldPosition: 0,
                fieldType: 0,
                fieldValue: '',
                help: '',
                idUUID: '',
                mandatory: '',
                title: '',
            },
            idUUID: '',
            contractTemplateFieldTypes: [],
            items: [{ label: LS('contractTemplateField', 'tab') }],
            mandatory: [
                { label: LS('yes'), value: '1' },
                { label: LS('no'), value: '0' },
            ],
            states: [
                { label: LS('active', 'tooltip'), value: '1' },
                { label: LS('canceld', 'tooltip'), value: '2' },
                { label: LS('deleted', 'tooltip'), value: '3' },
            ],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        let contractTemplateForm = query.get('contractTemplateForm');
        if (!contractTemplateForm && !idUUID) window.close();
        g_getDataFields(this, ContractTemplateFieldService.getContractTemplateFieldTypes, 'contractTemplateFieldTypes', 'description', 'id', 'contractTemplateFieldTypes', {
            orderBy: [{ key: 'description', value: 'asc' }],
        });
        this.setState({ idUUID: idUUID, mode: mode, data: { ...this.state.data, contractTemplateFormIdUUID: contractTemplateForm } }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let response = await ContractTemplateFieldService.getContractTemplateField({}, this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.contractTemplateField) {
            g_nullarrayresponse(this);
            return;
        }
        let rdata = response.contractTemplateField;
        rdata.fieldType = String(rdata.fieldType);
        rdata.mandatory = String(rdata.mandatory);
        if (rdata.fieldType === '2') rdata.fieldValue = g_treatNumricR(rdata.fieldValue);
        if (rdata.fieldType === '3') rdata.fieldValue = new Date(rdata.fieldValue);
        this.setState({ data: rdata, oldFieldType: rdata.fieldType });
    };

    submitForm = async e => {
        e.preventDefault();
        let rdata = this.state.data;
        if (rdata.fieldType === '2') rdata.fieldValue = g_treatNumric(rdata.fieldValue);
        if (rdata.dateContractStart && rdata.fieldType === '3') {
            let d = new Date(rdata.dateContractStart);
            rdata.dateContractStart = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (this.state.idUUID) {
            let response = await ContractTemplateFieldService.updateContractTemplateField({ contractTemplateField: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            let response = await ContractTemplateFieldService.addContractTemplateField({ contractTemplateField: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            this.props.history.push(`${window.location.href.substr(window.location.href.indexOf('?'))}&id=${response.contractTemplateField.idUUID}`);
            this.setState({ idUUID: response.contractTemplateField.idUUID }, this.getData);
        }
    };

    updateStatusClick = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.contractTemplateFieldStatus = data.contractTemplateFieldStatus === 1 ? 2 : 1;
        let response = await ContractTemplateFieldService.updateContractTemplateField({ contractTemplateField: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.contractTemplateFieldStatus = 3;
        let response = await ContractTemplateFieldService.updateContractTemplateField({ contractTemplateField: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    selectFiled = () => {
        if (this.state.data.fieldType !== this.state.oldFieldType) this.setState({ oldFieldType: this.state.data.fieldType, data: { ...this.state.data, fieldValue: '', fieldListValues: '' } });
        let normal = (label = LS('value')) => g_template_inputArea(this, 'fieldValue', this.state.data.fieldValue, label, this.state.mode, false, false, 'p-col-8', 'p-col-2', '', { width: '100%' });
        let lista = (label = 'Lista de Valores', label2 = LS('value')) => [
            normal(label2),
            g_template_inputArea(this, 'fieldListValues', this.state.data.fieldListValues, label, this.state.mode, false, false, 'p-col-8', 'p-col-2', '', { width: '100%', height: '30vh' }),
        ];
        let terms = text =>
            g_wraper(
                'fieldValue',
                LS(text),
                <Editor
                    style={{ height: '30vh', width: '100%' }}
                    value={this.state.data.fieldValue}
                    onTextChange={e => (!this.state.mode ? this.setState({ data: { ...this.state.data, fieldValue: e.htmlValue } }) : null)}
                />,
                false,
                'p-col-12'
            );
        return {
            2: g_template_numeric(this, 'fieldValue', this.state.data.fieldValue, LS('fieldValue'), this.state.mode, false, false, 'p-col-3', 'p-col-2'),
            3: g_wraper(
                'fieldValue',
                LS('fieldValue'),
                <div className="p-col-2">
                    <Calendar
                        id="fieldValue"
                        yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                        monthNavigator={true}
                        yearNavigator={true}
                        dateFormat="dd-mm-yy"
                        value={new Date(this.state.data.fieldValue)}
                        onChange={e => (!this.state.mode ? g_handleChange(e, {}, this) : null)}
                    />
                </div>,
                false,
                'p-col-2'
            ),
            4: lista(),
            5: lista(),
            6: lista(),
            7: lista(),
            11: lista(LS('parameter')),
            13: lista(),
            1: normal(),
            8: normal(),
            9: normal(),
            10: normal(),
            14: normal(),
            12: terms('terms'),
            16: terms('text'),
        }[this.state.data.fieldType];
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, () => (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.submitForm}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel style={{ border: 'none !important', overflowY: 'auto', height: '80vh' }}>
                                        {g_formMaper(
                                            this,
                                            [
                                                {
                                                    t: 'array',
                                                    dTypeFs: { _: 't', 0: 'copy', 4: 'drop' },
                                                    req: { _: true, 3: { _: false, 0: true } },
                                                    filter: ['idUUID', 'fieldName', 'title', ['fieldPosition', 'fieldMaxSize', 'fieldWidth', 'fieldHeight'], ['fieldType', 'mandatory']],
                                                    options: { 4: [this.state.contractTemplateFieldTypes, this.state.mandatory] },
                                                    cc: { 3: [1, 1, 2, 2], 4: [4, 2] },
                                                    class: { 3: [2, 2, 1, 1] },
                                                    handleExtraData: { 3: { 0: { regex: new RegExp('\\d*', 'g') }, 1: { regex: new RegExp('\\d*', 'g') }, _: {} } },
                                                },
                                                this.selectFiled(),
                                            ],
                                            this.state.mode,
                                            '2'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_wraper(
                                            null,
                                            LS('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.contractTemplateFieldStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-5'
                                        )}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.contractTemplateFieldStatus === '3', null, this.state.mode, 'p-col-2')}
                                {g_template_dialog(
                                    this,
                                    LS(this.state.data.contractTemplateFieldStatus === 2 ? 'simpleActivate' : 'simpleDeactivate', 'messages'),
                                    'activate',
                                    this.updateStatusClick,
                                    LS(this.state.data.contractTemplateFieldStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                    !this.state.idUUID || this.state.data.contractTemplateFieldStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlock', 'messages'),
                                    'block',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    !this.state.idUUID || this.state.data.contractTemplateFieldStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                    ])}
                </div>
            ))
        );
    }
}
