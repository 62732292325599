import {
    S,
    lt,
    ls,
    lm,
    lb,
    g_openWindowDialog,
    g_ApiPromotions,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_form,
    g_treatData,
    g_wraper,
    g_getDataFields,
} from '../GenericFunctions';
import { Editor } from 'primereact/editor';
import { Steps } from 'primereact/steps';
import React from 'react';
import Log from '../logs/Log';
import GSelector from '../generic/GSelector';
import Images from './Images';
import PromotionsService from '../../service/PromotionsService';
import ProviderService from '../../service/ProviderService';

export default class Promotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: g_ApiPromotions(),
            minDate: '',
            maxDate: '',
            items: [{ label: lt('promotion') }],
            idUUID: null,
            mode: true,
            featuringModes: [
                { label: ls('1', 'featuringModes'), value: '1' },
                { label: ls('2', 'featuringModes'), value: '2' },
                { label: ls('3', 'featuringModes'), value: '3' },
                { label: ls('4', 'featuringModes'), value: '4' },
            ],
            promotionTypes: [],
            promotionCategories: [],
        };
    }
    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  PromotionsService.list,
                  response => {
                      let rdata = response.promotions[0];
                      rdata.featuringMode = String(rdata.featuringMode);
                      rdata.promotionType = String(rdata.promotionType);
                      rdata.promotionCategory = String(rdata.promotionCategory);
                      this.setState({ data: response.promotions[0] });
                  },
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }] }
              )
            : null;

    getModel = () => [
        { label: ls('0', 'promotionModel') },
        { label: ls('1', 'promotionModel') },
        { label: ls('2', 'promotionModel') },
        { label: ls('3', 'promotionModel') },
        { label: ls('4', 'promotionModel') },
        { label: ls('5', 'promotionModel') },
    ];

    getProviders = context => g_getData(context, ProviderService.getProviders, 'providers');
    onSubmit = e => PromotionsService.updateStatus(this, this.state.data.idUUID, 2, this.getData, lm('simpleStatus'), this.messages, e);
    onNegate = e =>
        PromotionsService.updateStatus(
            this,
            this.state.data.idUUID,
            this.state.data.promotionStatus === 2 ? 103 : this.state.data.promotionStatus === 3 ? 102 : 101,
            this.getData,
            lm('simpleStatus'),
            this.messages,
            e
        );
    onCancel = e => PromotionsService.updateStatus(this, this.state.data.idUUID, 101, this.getData, lm('simpleStatus'), this.messages, e);
    onAprove = e => PromotionsService.updateStatus(this, this.state.data.idUUID, this.state.data.promotionStatus + 1, this.getData, lm('simpleStatus'), this.messages, e);
    onChange = e => PromotionsService.updateStatus(this, this.state.data.idUUID, 1, this.getData, lm('simpleStatus'), this.messages, e);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        if (query.get('ptype') === '2') {
            if (!window.baseObj) {
                window.close();
                return;
            }
            this.setState({
                data: {
                    ...this.state.data,
                    consignatorIdUUID: window.baseObj.consignatorIdUUID,
                    consignatorName: window.baseObj.consignatorName,
                    promotionType: '2',
                    searchAreaIdUUID: window.baseObj.idUUID,
                },
                promotionTypes: [{ label: 'Pesquisa', value: '2' }],
                minDate: window.baseObj.dateStart,
                maxDate: window.baseObj.dateEnd,
            });
        } else
            g_getDataFields(this, PromotionsService.getPromotionTypes, 'promotionTypes', 'description', 'id', d =>
                this.setState({ promotionTypes: d.filter(f => idUUID || (!idUUID && f.label !== 'Pesquisa')) })
            );
        let items = this.state.items;
        if (idUUID) {
            items.push({ label: lt('text') });
            items.push({ label: lt('images') });
            items.push({ label: lt('statistics') });
            items.push({ label: lt('log'), icon: 'pi pi-list' });
        }
        g_getDataFields(this, PromotionsService.getPromotionCategories, 'promotionCategories', 'description', 'id', d => this.setState({ promotionCategories: d }));
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (!data.showOnSite && !data.showOnApp && !data.showOnFB && !data.showOnInstagram && !data.sendByEmail && !data.showOnTweeter) return;
        data.dateStart = g_treatData(data.dateStart);
        data.dateEnd = g_treatData(data.dateEnd);
        if (!this.state.idUUID) {
            const response = await PromotionsService.add({ promotion: data });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/promotion?type=1&id=${response.promotion.idUUID}`;
            window.location.reload();
            return;
        }
        const response = await PromotionsService.update({ promotion: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
    };

    updatePrice = async () => {
        let data = this.state.data;
        if (!data.showOnSite && !data.showOnApp && !data.showOnFB && !data.showOnInstagram && !data.sendByEmail && !data.showOnTweeter) return;
        data.dateStart = g_treatData(data.dateStart);
        data.dateEnd = g_treatData(data.dateEnd);
        const response = await PromotionsService.updatePrice({ promotion: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({ data: { ...this.state.data, price: response.price } });
    };

    getConsignators = S.consignator.processed.list;

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            let autoUpdate = { callback: this.updatePrice };
            let pS = this.state.data.promotionStatus;
            let cancel = [1, 2, 3, 4, 5].indexOf(pS) === -1;
            let aprove = !([2, 3].indexOf(pS) !== -1 && g_checkAlc(5, 'promotion#backoffice'));
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                <GSelector
                                    labelwraper="p-col-2"
                                    label={ls('gestorialEntity')}
                                    ref={e => (this.selector1 = e)}
                                    getData={this.getConsignators}
                                    gobtt={g_checkAlc(4, 'consignator') && this.state.data.consignatorIdUUID}
                                    jump={() => g_openWindowDialog(`#/consignator?type=1&id=${this.state.data.consignatorIdUUID}`, this.state.data.consignatorIdUUID, 900, 600)}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector1.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    disabled={mode || [0, 1].indexOf(this.state.data.promotionStatus) === -1}
                                    startV={this.state.data?.consignatorName}
                                />,

                                <GSelector
                                    labelwraper="p-col-2"
                                    label={ls('promotor')}
                                    ref={e => (this.selector2 = e)}
                                    getData={this.getProviders}
                                    gobtt={g_checkAlc(4, 'provider') && this.state.data.referenceIdUUID}
                                    jump={() => g_openWindowDialog(`#/provider?type=1&id=${this.state.data.referenceIdUUID}`, this.state.data.referenceIdUUID, 1000, 550)}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector2.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, referenceIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    disabled={mode || [0, 1].indexOf(this.state.data.promotionStatus) === -1}
                                    startV={this.state.data?.referenceDescription}
                                />,

                                {
                                    t: 'array',
                                    filter: [
                                        'description',
                                        'dateStart',
                                        'dateEnd',
                                        'promotionType',
                                        'promotionCategory',
                                        [ls('options', 'text'), 'showOnApp', 'showOnSite', 'showOnFB', ' ', 'showOnInstagram', 'showOnTweeter', 'sendByEmail'],
                                        'featuringMode',
                                        'price',
                                    ],
                                    dTypeF: 't',
                                    dTypeFs: { 1: 'date', 2: 'date', 5: { 0: 'text', 4: 'text', _: 'checkr' }, 4: 'drop', 3: 'drop', 6: 'drop' },
                                    options: { 6: this.state.featuringModes, 3: this.state.promotionTypes, 4: this.state.promotionCategories },
                                    minDate: { _: new Date(this.state.minDate) },
                                    maxDate: { _: new Date(this.state.maxDate) },
                                    handleExtraData: { 1: autoUpdate, 2: autoUpdate, 5: { _: autoUpdate, 6: {} }, 3: autoUpdate, 6: autoUpdate, 4: autoUpdate },
                                    cc: { 6: 2, 7: 1, 5: { 0: 2, _: 1, 4: 2 }, 3: 2, 4: 3 },
                                    c: { 5: { 0: false, 1: false, 2: false, _: this.state.data.promotionType === '2' } },
                                    disabled: { _: mode || [0, 1].indexOf(this.state.data.promotionStatus) === -1, 7: true },
                                    req: { _: true, 7: false, 5: { _: false } },
                                    class: { 5: { _: 2, 3: 3 } },
                                },
                            ],
                            mode => [
                                g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', [0, 1].indexOf(pS) === -1, null, mode, 'p-col-2'),
                                g_template_dialog(this, lm('askSubmit'), 'submit', this.onSubmit, lb('submit'), pS !== 1, mode, 'p-button-success', null, null, null, 'p-col-2', 'p-button-success'),
                                g_template_dialog(this, lm('askAprove'), 'aprove', this.onAprove, lb('aprove'), aprove, mode, 'p-button-success', null, null, null, 'p-col-2', 'p-button-success'),
                                g_template_dialog(this, lm('askReject'), 'negate', this.onNegate, lb('reject'), aprove, mode, 'p-button-danger', null, null, null, 'p-col-2', 'p-button-danger'),
                                g_template_dialog(this, lm('askChange'), 'change', this.onChange, lb('change'), aprove, mode, 'p-button-warning', null, null, null, 'p-col-2', 'p-button-warning'),
                                g_template_dialog(this, lm('askBlockP'), 'cancel', this.onCancel, lb('block'), cancel, mode, 'p-button-danger', null, null, null, 'p-col-2', 'p-button-danger'),
                            ],
                            () => [
                                [{ t: 'array', filter: ['promotionStatus', 'dateCreation', 'dateUpdate'], dTypeF: 'view', dTypeFs: { 0: 'state' }, class: 'l', states: { 0: promotionsStates } }],
                                [],
                                this.state.idUUID && this.state.data.promotionStatus < 7 ? [<Steps model={this.getModel()} activeIndex={this.state.data.promotionStatus - 1} />] : [],
                            ],
                            this.submit,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <DataEditor context={() => this} />,
                        <Images context={() => this} />,
                        <></>,
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            );
        });
    }
}

export class DataEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: '' };
    }
    componentDidMount() {
        this.props
            .context()
            .getData()
            .then(() => this.setState({ data: this.props.context().state.data.htmlMainContent }));
    }
    editorChange = e => this.setState({ data: e.htmlValue });
    preview = () => {};

    save = async () => {
        if (!this.props.context().state.idUUID) return;
        const response = await PromotionsService.updateContent({ contentType: 0, htmlContent: this.state.data, idUUID: this.props.context().state.idUUID });
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.context().messages.show({ severity: 'success', summary: lm('simpleSave') });
    };

    render() {
        return (
            <div className="p-grid p-col-12 p-fluid">
                {g_wraper(
                    `value`,
                    '',
                    <Editor
                        className="p-col-12"
                        style={{ height: '20em' }}
                        disabled={this.props.context().state.mode}
                        required={true}
                        value={this.state.data}
                        onTextChange={e => this.editorChange(e)}
                        id="value"
                    />,
                    false,
                    `p-col-2`
                )}
                {g_template_button(lb('save'), '', false, this.save, this.props.context().state.mode, 'p-col-2')}
                {g_template_button(lb('preview'), '', false, this.preview, this.props.context().state.mode, 'p-col-2')}
            </div>
        );
    }
}

export const promotionsStates = {
    1: { color: 'orange', class: 'pi-step-forward' },
    2: { color: 'orange', class: 'pi-circle-on' },
    3: { color: 'orange', class: 'pi-dollar' },
    4: { color: 'green', class: 'pi-circle-on' },
    5: { color: 'green', class: 'pi-play' },
    6: { color: 'green', class: 'pi-check' },
    100: { color: 'red', class: 'pi-trash' },
    101: { color: 'red', class: 'pi-minus' },
    102: { color: 'red', class: 'pi-dollar' },
    103: { color: 'red', class: 'pi-circle-off' },
};
