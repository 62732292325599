import { Panel } from 'primereact/panel';
import React from 'react';
import CampaignService from '../../service/CampaignService';
import GSelector from '../generic/GSelector';
import { S, LS, g_checkLoginACL, g_displayMessageError, g_tabViewRenderOptions, g_template_button, g_openWindowDialog } from '../GenericFunctions';

export default class CopyCampaign extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1' || !idUUID;
        this.setState({ mode: mode, idUUID: idUUID }, this.getData);
    }

    handleSubmit = async e => {
        e.preventDefault();
        if (!this.state.idUUID) return;
        console.log({ destinationConsignatorIdUUID: this.state.idUUID, originCampaignIdUUID: this.state.campaignIdUUID });
        let response = await CampaignService.copy({ destinationConsignatorIdUUID: this.state.consignatorIdUUID, originCampaignIdUUID: this.state.idUUID });
        if (g_displayMessageError(this.messages, response)) return;
        g_openWindowDialog(`#/campaign?type=1&id=${response.campaign?.idUUID}`, response.campaign?.idUUID, 1200, 700);
        window.close();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.c !== 1 && this.state.idUUID === null) || (e.u !== 1 && this.state.idUUID !== null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.handleSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel style={{ border: 'none !important', height: '80vh' }}>
                                        <GSelector
                                            labelwraper="p-col-2"
                                            label={LS('consignatorName')}
                                            ref={e => (this.selector0 = e)}
                                            getData={S.consignator.processed.list}
                                            tableBody={[
                                                { type: 'd', data: 'name', sortable: true, filter: true },
                                                {
                                                    type: 'btt',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector0.setState({ selected: raw.name, visible: false });
                                                        this.setState({ consignatorIdUUID: raw.idUUID });
                                                    },
                                                },
                                            ]}
                                            inputtextwarper="p-col-5"
                                            req={true}
                                            disabled={this.state.mode}
                                            startV={''}
                                        />
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid"> {g_template_button(LS('copy', 'btt'), '', 0, null, this.state.mode, 'p-col-3')} </div>
                        </form>,
                    ])}
                </div>
            );
        });
    }
}
