import axios from './AxiosService';

class ProductService {

    productAcl = {
        add: data => axios.request('POST', '/product/acl/add', data),
        delete: idUUID => axios.request('DELETE', `/product/acl/${idUUID}`, {}),
        list: data => axios.request('POST', '/product/acl/list', data),
    }

    async getProducts(productFilter) { return await axios.request('POST','/product/list', productFilter); }

    async addProduct(data) {
        return await axios.request('POST', `/product/add`, data)
    }

    async getLogs(data){
        return await axios.request('POST', `/product/log/list`, data);
    }

    async updateStatus(data){
        return await axios.request('PUT', `/product/update/status`, data)
    }

    async getProductTypes(data){
        return await axios.request('POST', `/product/productType/list`, data);
    }

    async getProductModes(data){
        return await axios.request('POST', `/product/productMode/list`, data);
    }

    async updateProduct(data){
        return await axios.request('PUT', `/product/update`, data)
    }

    async updateProductConfigs(data){
        return await axios.request('PUT', `/product/update/config`, data)
    }

    async getProductImages(data){
        return await axios.request('POST', `/product/image/list`, data)
    }

    async documentUpload(formData, documentTypeId, productUUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/product/document/upload/${documentTypeId}/${productUUID}`, formData, config)
    }

    async imageUpload(formData, documentTypeId, productUUID){
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true
        };

        return await axios.request('POST', `/product/image/upload/${documentTypeId}/${productUUID}`, formData, config)
    }

    async deleteProductImage(imageIdUUID){
        return await axios.request('DELETE', `/product/image/${imageIdUUID}`, '')
    }

}

export default new ProductService();
