import axios from './AxiosService'

class UserService {
    
    login = body => axios.request('POST','/user/login', body);
    listUsers = data => axios.request('POST', `/user/list`, data);
    changePassword = data => axios.request('PUT', `/user/changePassword`, data);
    createUser = data => axios.request('POST', `/user/add`, data);
    updateUser = data => axios.request('PUT', `/user/update`, data);
    resetPassword = data => axios.request('POST', `/user/reset`, data);
    resetPin = data => axios.request('POST', `/user/pin/reset`, data);
    updateStatus = data => axios.request('PUT', `/user/update/status`, data);
    listUsersProfiles = data => axios.request('POST', `/user/profile/list`, data);

    entityAdd = data => axios.request('POST', `/user/entity/add`, data);
    entityRemove = idUUID => axios.request('DELETE', `/user/entity/${idUUID}`);
    entityList = data => axios.request('POST', `/user/entity/list`, data);

    documentUpload(formData, documentTypeId, userUUID){
        const config = { 'content-type': 'multipart/form-data', 'background-request': true };
        return axios.request('POST', `/user/document/upload/${documentTypeId}/1/${userUUID}`, formData, config)
    }

}

export default new UserService();
