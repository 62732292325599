import axios from './AxiosService';

class ChatList {

    async messageList(data) { return await axios.request('POST', `/chat/message/list`, data, {'background-request': true}); }

    async messageAdd(data) { return await axios.request('POST', `/chat/message/add`, data, {'background-request': true}); }

    async messageMarkRead(referenceId, messageFlow) { return await axios.request('POST', `/chat/message/markread/${referenceId}/${messageFlow}`, {}, {'background-request': true}); }
}

export default new ChatList();
