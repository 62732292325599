import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ProfileService from '../../service/ProfileService';
import UserService from '../../service/UserService';
import { g_buttonTemplate, g_checkLoginACL, g_dialogTemplate, g_displayMessageError, g_genericRequired, g_getHeaderTemplatecF, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import GAddNew from './G_AddNew';

export default class AssociciationUser extends Component{
    
    constructor(props) { super(props); this.state = g_genericRequired(); };
    componentDidMount() { g_hideSearch(); this.getData(); }
    render() { return g_checkLoginACL(this.grender, true, 'user#associationprofileuser'); }

    getData = async () => {
        if (this.props.idUUID === null) return;
        let data = {
            filters: [{key: 'profileIdUUID', value: this.props.idUUID},...this.state.filter],
            orderBy: [ ...this.state.orderBy,
                { 
                    key: "idUUID",
                    value: "asc"
                  }
                ],
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        };
        let response = await ProfileService.getProfileUserList(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({
            data: response.profileUsers,
            pageTotal: response.pageTotal,
            totalRecords: response.recordsTotal
        });
    }

    remove = async raw => {
        let response = await ProfileService.removeProfileFromUser(raw.profileIdUUID, raw.userIdUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    getAddNewData = async () => {
        let data = {
            filters: [{key: 'notInProfileIdUUID', value: this.props.idUUID},...this.g_add.state.filter],
            orderBy: this.g_add.state.orderBy,
            pageNumber: this.g_add.state.pageNumber,
            pageSize: this.g_add.state.pageSize,
            userType: 1,
        };
        let response = await UserService.listUsers(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.g_add.setState({
            data: response.users,
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal
        });
    }

    addNew = async raw => {
        console.log([raw, this.g_add]);
        let data = {
            profileUser: {
                profileIdUUID: this.props.idUUID,
                userIdUUID: raw.idUUID,
                userType: 1
            }
        };
        let response = await ProfileService.addProfileUser(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.getAddNewData();
        this.g_add.messages.show({severity: 'success', summary: 'Perfil adicionado com sucesso!'});
    }

    grender = e => {
        return (
            <div className='card' >
              <Messages ref={e => this.messages = e}/>
              <Dialog visible={this.state.visible} onHide={() => {this.setState({visible: false}, this.getData);}}>
                <GAddNew ref={e => this.g_add = e} getData={this.getAddNewData} tableBody={
                    [
                        <Column key='1' header='Username' field='username' sortable={true} filter={true}/>,
                        <Column key='2' header='Nome' field='fullname' sortable={true} filter={true}/>,
                        <Column key='3' header='' body={raw => g_buttonTemplate('', 'pi pi-plus', '', false, () => this.addNew(raw))} />,
                    ]
                } tableFilter={{0:'username', 1: 'fullname',}} />
              </Dialog>
              {g_template_dataTable(this, g_getHeaderTemplatecF(this, () => {this.setState({visible: true});}, this.getData, e.c === 1 ), [
                  <Column key='d' header='Nome do Utilizador' field='userFullname' sortable={true} filter={true}/>,
                  {type: 'url', width: 1300, height: 500, url: raw => `/#/user?type=1&id=${raw.userIdUUID}`,  title: 'Usuário'},
                  <Column key='1' header='' style={{'textAlign': 'center', 'width': ' 8em'}} body={raw => g_dialogTemplate('Tem a certesa que deseja desassociar este user do perfil?', 'separate', this, () => this.remove(raw), '', 'Sim', 'Não', 'pi pi-trash', 'p-button-danger', false, e.d !== 1, `p-button-danger`, '', '', '', '', '')} />,
              ], this.getData, {0: 'userFullname'})}
            </div>);
    }
    
}
